'use strict';

const env = require('../../../environments/environment').environment;

/**
 * @ngdoc function
 * @name gestiondecriseApp.controller:TeamCtrl
 * @description
 * # TeamCtrl
 * Controller of the gestiondecriseApp
 */
angular
  .module('gestiondecriseApp')
  .controller('TeamCtrl', function (
    $rootScope,
    $scope,
    $mdDialog,
    $translate,
    ErpMailService,
    ErpSmsService,
    FunctionsStoreManager,
    ErpFunctionsService,
    OptionsService,
    CrisisStoreManager,
    CommonStoreManager,
    ModalsService,
    ErpUsersService
  ) {
    $scope.functions = [];
    $scope.users = [];
    $scope.affectatingfuncUserWorking = false;
    $scope.DETAILS_FUNCTION_MODE_TASKS = 'tasks';
    $scope.DETAILS_FUNCTION_MODE_USERS = 'users';
    $scope.currentFilter = 'FUNCTION';
    $scope.detailsFunctionMode = $scope.DETAILS_FUNCTION_MODE_TASKS;
    $scope.crisisImageUrl = OptionsService.getDefaultCrisisImageUrl();

    const curentUserSub = CommonStoreManager.currentUser.subscribe(user => {
      $scope.currentUserId = user.userId;
    });
    $scope.currentFunction = null;
    $scope.crisisRoles = {};

    // Rights
    // ERD holder?
    $scope.isCurrentUserCrisisDirectoryHolder = false;
    $scope.isCurrentUserInCrisisDirectorTeam = false;
    // HRD holder?
    $scope.isCurrentUserHumanResourcesDirectoryHolder = false;

    const currentUserCrisisRoles$ = FunctionsStoreManager.currentUserCrisisRoles.subscribe(roles => {
      $scope.isCurrentUserCrisisDirectoryHolder = roles.crisisDirector.isHolder;
      $scope.isCurrentUserInCrisisDirectorTeam = roles.crisisDirector.isInTeam;
      $scope.isCurrentUserHumanResourcesDirectoryHolder = roles.humanResource.isHolder;
    });

    const teamUsersByFunction$ = FunctionsStoreManager.teamUsersByFunction.subscribe(team => {
      $scope.team = team;
      $scope.functions = team.reduce((current, next) => {
        return current.concat(next.functions);
      }, []);
      if ($scope.currentFunction) {
        $scope.currentFunction = $scope.functions.find(f => f.functionId === $scope.currentFunction.functionId);
      }
    });

    const teamFunctionsByUser$ = FunctionsStoreManager.teamFunctionsByUser.subscribe(users => {
      $scope.users = users;
    });

    const crisis$ = CrisisStoreManager.crisisErpState.subscribe(crisis => {
      $scope.crisis = crisis;
    });

    $scope.isActiveFilter = function (filterId) {
      return $scope.currentFilter === filterId;
    };

    $scope.toggleOpenFunction = function (func, opened) {
      if (opened !== true && $scope.isActiveFilter('FUNCTION')) {
        $scope.detailsFunctionMode = $scope.DETAILS_FUNCTION_MODE_TASKS;
        $scope.currentFunction = func;
      } else {
        $scope.currentFunction = null;
      }
    };

    $scope.toggleUserManagement = function () {
      if ($scope.detailsFunctionMode === $scope.DETAILS_FUNCTION_MODE_TASKS) {
        $scope.detailsFunctionMode = $scope.DETAILS_FUNCTION_MODE_USERS;
      } else {
        $scope.detailsFunctionMode = $scope.DETAILS_FUNCTION_MODE_TASKS;
      }
    };

    $scope.sendMail = function (email) {
      window.open('mailto:' + email);
    };

    $scope.callPhone = function (phone) {
      window.open('tel:' + phone);
    };

    $scope.showUserStatusDetail = function (ev, user) {
      const userWithFunctions = $scope.users.find(u => u.userId === user.userId);
      $mdDialog.show({
        controller: 'ChangeUserStatusModalCtrl',
        templateUrl: 'views/team/changeuserstatusmodal.html',
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          user: userWithFunctions,
          func: $scope.currentFunction,
          canChangeHolder: $scope.canChangeHolder(user.userId),
          isCurrentUserCrisisDirectoryHolder: $scope.isCurrentUserCrisisDirectoryHolder,
          currentUserId: $scope.currentUserId,
        },
      });
    };

    $scope.toggleUserAffectationToCurrentFunction = function (user) {
      if ($scope.isHolder(user.userId, $scope.currentFunction)) {
        $mdDialog.show(
          $mdDialog.holAlert({
            title: 'Forbidden action',
            textContent: 'You can not remove the current holder of his function.',
            ok: 'OK',
          })
        );
        return;
      }

      $rootScope.showFullOverlay();
      $scope.affectatingfuncUserWorking = true;
      $scope.affectatingfuncUserWorkingForUser = user.userId;

      const onActionPerformed = () => {
        $scope.affectatingfuncUserWorking = false;
        $rootScope.hideFullOverlay();
      };
      if ($scope.isUserAffectedToCurrentFunction(user.userId)) {
        ErpFunctionsService.removeUserFromFunction(user.userId, $scope.currentFunction.functionId).finally(onActionPerformed);
      } else {
        ErpFunctionsService.addUserToFunction(user.userId, $scope.currentFunction.functionId).finally(onActionPerformed);
      }
    };

    // rights

    /**
     * Return flag indicating if the current user can add (ie create) member.
     */
    $scope.canAddMember = function () {
      return (
        $scope.isCurrentUserCrisisDirectoryHolder ||
        $scope.isCurrentUserHumanResourcesDirectoryHolder ||
        $scope.isHolder($scope.currentUserId, $scope.currentFunction)
      );
    };

    /**
     * Return flag indicating if the current user can manage members.
     * (ie add/remove members into function)
     */
    $scope.canManageMembers = function () {
      return (
        $scope.isCurrentUserCrisisDirectoryHolder ||
        $scope.isCurrentUserHumanResourcesDirectoryHolder ||
        $scope.isHolder($scope.currentUserId, $scope.currentFunction)
      );
    };

    /**
     * Return flag indicating if the current user can duplicate function.
     * @returns {boolean|*}
     */
    $scope.canDuplicateFunction = function () {
      return $scope.isCurrentUserCrisisDirectoryHolder;
    };

    /**
     * Return flag indicating if the current user can notify function.
     * @returns {boolean|*}
     */
    $scope.canNotifyFunction = function (func) {
      return $scope.isCurrentUserCrisisDirectoryHolder && !func.notified;
    };

    /**
     * Return flag indicating if the current user can add a task.
     * @returns {*|boolean}
     */
    $scope.canAddTask = function () {
      return $scope.isCurrentUserCrisisDirectoryHolder || $scope.isHolder($scope.currentUserId, $scope.currentFunction);
    };

    /**
     * Return flag indicating if the current user can changer holder in functions.
     * @param userId
     * @returns {*|boolean}
     */
    $scope.canChangeHolder = function (userId) {
      return (
        $scope.isCurrentUserCrisisDirectoryHolder ||
        $scope.isHolder($scope.currentUserId, $scope.currentFunction) ||
        ($scope.isCurrentUserInCrisisDirectorTeam && userId === $scope.currentUserId)
      );
    };

    /**
     * Return flag indicating if the current user can change status of members.
     * @param user
     * (ie switch to available OFF-LINE...)
     */
    $scope.canChangeMembersStatus = function (user) {
      return (
        ($scope.isCurrentUserCrisisDirectoryHolder ||
          $scope.isCurrentUserHumanResourcesDirectoryHolder ||
          $scope.isHolder($scope.currentUserId, $scope.currentFunction) ||
          ($scope.isCurrentUserInCrisisDirectorTeam && user && user.userId === $scope.currentUserId)) &&
        $scope.isUserWrite(user)
      );
    };

    // user

    $scope.isActivated = function (user) {
      return new Date() - user.lastSeenAt < 1000 * env.userConnectedStatusMaxTime; // 5min
    };

    $scope.isActivatedOffline = function (user) {
      return user.isAvailableOffline && !$scope.isActivated(user);
    };

    $scope.isDeactivated = function (user) {
      return !user.isAvailableOffline && !$scope.isActivated(user);
    };

    $scope.hasNoAppointmentAndIsAvailable = function (user) {
      return !user.functions.find(f => f && f.isHolder) && ($scope.isActivated(user) || $scope.isActivatedOffline(user));
    };

    $scope.isUserWrite = function (user) {
      if (!user || !user.companies) {
        return false;
      }
      return $rootScope.companiesRolesFilter.find(c => user.companies.find(uc => uc.write && uc.name === c));
    };

    // functions

    $scope.isAppointed = function (f) {
      return f.holder;
    };

    $scope.isNotAppointed = function (f) {
      return !f.holder;
    };

    $scope.isFunctionDisabled = function (f) {
      return !f.tasks || f.tasks.length === 0;
    };

    // user functions

    $scope.isUserAffectedToCurrentFunction = function (userId) {
      return $scope.currentFunction.users.find(u => u.userId === userId);
    };

    $scope.isHolder = function (userId, func) {
      return userId && func && func.holder && func.holder.userId === userId;
    };

    $scope.showDuplicateFunction = function (ev, func) {
      $mdDialog.show({
        controller: 'DuplicateFunctionModalCtrl',
        templateUrl: 'views/team/duplicateFunctionModal.html',
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          originalFunction: func,
        },
      });
    };

    $scope.showAddMember = function (ev, func) {
      $mdDialog.show({
        controller: 'CreateMemberModalCtrl',
        templateUrl: 'views/team/createMemberModal.html',
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          user: null,
          func: func,
          crisis: $scope.crisis,
        },
      });
    };

    $scope.showAddTask = function (ev, func) {
      ModalsService.openCrisisTaskModalForCreation({
        crisis: $scope.crisis,
        forFunction: func.functionId,
        functionsUserForCrisis: [
          {
            functionId: func.functionId,
            title: func.title,
            shortTitle: func.shortTitle,
          },
        ],
      });
    };

    // ui helpers

    $scope.holderSince = function (date) {
      const m = moment.duration(moment().diff(moment(date)));
      return m.humanize();
    };

    $scope.displayFunctions = function (user) {
      return _.map(user.functions, 'shortTitle').join(', ');
    };

    /**
     * Destroy the scope.
     */
    $scope.$on('$destroy', function () {
      // Remove all listeners on $rootScope
      teamUsersByFunction$.unsubscribe();
      teamFunctionsByUser$.unsubscribe();
      currentUserCrisisRoles$.unsubscribe();
      crisis$.unsubscribe();
      curentUserSub.unsubscribe();
    });

    $scope.canEditUser = function (user) {
      return (
        $scope.isCurrentUserCrisisDirectoryHolder ||
        $scope.isCurrentUserHumanResourcesDirectoryHolder ||
        ($scope.isCreatedByMe(user) && $scope.isHolderOfOneFunction(user.functions))
      );
    };

    $scope.canEditStatus = function (user) {
      return (
        $scope.currentUserId !== user.userId &&
        ($scope.isCurrentUserCrisisDirectoryHolder ||
          $scope.isCurrentUserHumanResourcesDirectoryHolder ||
          ($scope.isCreatedByMe(user) && $scope.isHolderOfOneFunction(user.functions))) &&
        $scope.isUserWrite(user)
      );
    };

    $scope.canResetPassword = function (user) {
      return (
        $scope.isCurrentUserCrisisDirectoryHolder ||
        $scope.isCurrentUserHumanResourcesDirectoryHolder ||
        ($scope.isCreatedByMe(user) && $scope.isHolderOfOneFunction(user.functions))
      );
    };

    $scope.canBlacklist = function (user) {
      return (
        $scope.currentUserId !== user.userId &&
        !user.isBlacklisted &&
        ($scope.isCurrentUserCrisisDirectoryHolder ||
          $scope.isCurrentUserHumanResourcesDirectoryHolder ||
          ($scope.isCreatedByMe(user) && $scope.isHolderOfOneFunction(user.functions)))
      );
    };

    $scope.canWhitelist = function (user) {
      return (
        $scope.isHolderOfOneFunction(user.functions) &&
        $scope.currentUserId !== user.userId &&
        user.isBlacklisted &&
        ($scope.isCurrentUserCrisisDirectoryHolder ||
          $scope.isCurrentUserHumanResourcesDirectoryHolder ||
          ($scope.isCreatedByMe(user) && $scope.isHolderOfOneFunction(user.functions)))
      );
    };

    $scope.isCreatedByMe = function (user) {
      return angular.isDefined(user.createdBy) && user.createdBy.userId === $scope.currentUserId;
    };

    $scope.isHolderOfOneFunction = function (functions) {
      return !!functions.find(f => f.holder && f.holder.userId === $scope.currentUserId);
    };

    $scope.editUser = function (user, ev) {
      $mdDialog.show({
        controller: 'CreateMemberModalCtrl',
        templateUrl: 'views/team/createMemberModal.html',
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          func: null,
          crisis: $scope.crisis,
          user: user,
        },
      });
    };

    $scope.editStatus = function (user, ev) {
      $mdDialog.show({
        controller: 'ChangeUserStatusModalCtrl',
        templateUrl: 'views/team/changeuserstatusmodal.html',
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          user: _.find($scope.users, { userId: user.userId }),
          func: undefined,
          canChangeHolder: false,
          isCurrentUserCrisisDirectoryHolder: $scope.isCurrentUserCrisisDirectoryHolder,
          currentUserId: $scope.currentUserId,
        },
      });
    };

    $scope.resetPassword = function (user, ev) {
      $rootScope.showFullOverlay();
      ErpUsersService.resetPassword(user).then(
        function () {
          $rootScope.hideFullOverlay();
          $mdDialog.show(
            $mdDialog.holAlert({
              title: 'Access code reset',
              textContent: 'A mail has been sent to the user with his new credentials',
              targetEvent: ev,
              ok: 'Ok',
            })
          );
        },
        function (error) {
          $mdDialog.show(
            $mdDialog.holAlert({
              title: 'Error',
              textContent: error.message,
              targetEvent: ev,
              ok: 'Ok',
            })
          );
          $rootScope.hideFullOverlay();
        }
      );
    };

    $scope.blacklist = function (user, ev) {
      $rootScope.showFullOverlay();
      const holConfirm = $mdDialog.holConfirm({
        title: $translate.instant('TEAM.MODAL.BLACKLIST.TITLE'),
        htmlContent: $translate.instant('TEAM.MODAL.BLACKLIST.CONTENT', { user: user }),
        targetEvent: ev,
      });
      updateBlacklistFlag(user, true, holConfirm);
    };

    $scope.whitelist = function (user, ev) {
      $rootScope.showFullOverlay();
      const holConfirm = $mdDialog.holConfirm({
        title: $translate.instant('TEAM.MODAL.WHITELIST.TITLE'),
        htmlContent: $translate.instant('TEAM.MODAL.WHITELIST.CONTENT', { user: user }),
        targetEvent: ev,
      });
      updateBlacklistFlag(user, false, holConfirm);
    };

    const updateBlacklistFlag = (user, isBlackListed, holConfirm) => {
      return $mdDialog
        .show(holConfirm)
        .then(function (result) {
          if (result === true) {
            user.isBlacklisted = isBlackListed;
            return ErpUsersService.updateMember(user);
          }
        })
        .finally(() => {
          $rootScope.hideFullOverlay();
        });
    };

    $scope.notifyUsersOfFunction = function ($event, func) {
      $event.preventDefault();
      $event.stopPropagation();
      const confirm = $mdDialog.holConfirm({
        title: 'Notification',
        htmlContent: 'All users of function ' + func.title + ' will be notified.<br/>Do you confirm ?',
        targetEvent: $event,
      });

      $mdDialog.show(confirm).then(function (res) {
        if (res) {
          ErpMailService.sendNewCrisisEmails($scope.crisis, [func.functionId]);
          ErpSmsService.sendNewCrisisSms($scope.crisis, [func.functionId]);
        }
      });
    };
  });
