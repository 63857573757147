import { OclEvent } from './ocl-event.model';
import { HolNextInfo } from '../../common/models/hol-next-info.model';
import * as moment from 'moment';
import { OclGroup } from './ocl-group.model';

export class OclEventGroup extends OclGroup<OclEvent> {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.items =
      parseObject.get('events') && parseObject.get('events').length ? parseObject.get('events').map(event => new OclEvent(event)) : [];
  }

  getMostCriticalNextInfo(): HolNextInfo | null {
    let mostRecent: HolNextInfo = null;
    this.items.forEach(event => {
      if (event.infos) {
        event.infos.forEach(info => {
          if (!info.done) {
            if (mostRecent === null) {
              mostRecent = info;
            }

            if (moment(info.nextInfoTime).isBefore(mostRecent.nextInfoTime)) {
              mostRecent = info;
            }
          }
        });
      } else {
        mostRecent = null;
      }
    });

    return mostRecent;
  }
}
