'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.controller:BreakingNewsModalCtrl
 * @description
 * # BreakingNewsModalCtrl
 * Controller of the gestiondecriseApp
 */
angular
  .module('gestiondecriseApp')
  .controller('BreakingNewsModalCtrl', function ($scope, $rootScope, $timeout, $mdDialog, BreakingNewsService, $q, context) {
    $scope.context = {
      breakingNews: [],
      newsToAdd: undefined,
      canAddNews: false,
      loadingBreakingNews: true,
      addingBreakingNews: false,
    };

    var init = function () {
      BreakingNewsService.getAllBreakingNews(context).then(
        function (data) {
          $scope.context.breakingNews = _.cloneDeep(data);

          $scope.context.loadingBreakingNews = false;
        },
        function () {
          $scope.context.loadingBreakingNews = false;
        }
      );
    };

    init();

    $scope.cancel = $mdDialog.cancel;

    function _confirm(msg, title, $event) {
      var deferred = $q.defer();
      $mdDialog
        .show(
          $mdDialog.holConfirm({
            title: title,
            htmlContent: msg,
            targetEvent: $event,
            skipHide: true,
            hasBackdrop: false,
          })
        )
        .then(function (result) {
          if (result) {
            deferred.resolve();
          } else {
            deferred.reject();
          }
        }, deferred.reject);
      return deferred.promise;
    }

    $scope.validateBreakingNewsUpdate = function (breakingNew) {
      BreakingNewsService.updateBreakingNews(breakingNew).then(function () {
        $rootScope.$broadcast('breakingNewsEdited');
      });
      breakingNew.canEdit = false;
    };

    /**
     * Validate breaking news (add).
     */
    $scope.validateBreakingNews = function () {
      if ($scope.context.newsToAdd) {
        $scope.context.addingBreakingNews = true;
        BreakingNewsService.addBreakingNews($scope.context.newsToAdd, context).then(function (newBreakingNews) {
          $rootScope.$broadcast('breakingNewsEdited');
          $scope.context.addingBreakingNews = false;
          $scope.context.newsToAdd = '';
          $scope.context.breakingNews.push(newBreakingNews);
          $scope.context.canAddNews = false;
        });
      }
    };

    /**
     * Delete breaking news
     */
    $scope.deleteBreakingNews = function (breakingNews, $event) {
      _confirm('This news will be deleted. Confirm ?', 'Delete', $event).then(function () {
        BreakingNewsService.deleteBreakingNews(breakingNews).then(function () {
          $rootScope.$broadcast('breakingNewsEdited');
        });
        _.remove($scope.context.breakingNews, breakingNews);
      });
    };
  });
