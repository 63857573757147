import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclLogbook } from '../../models/ocl-logbook.model';

@Injectable({
  providedIn: 'root',
})
export abstract class OclLogbookTagService {
  // tslint:disable:variable-name
  protected ParseLogBookTag;
  protected ParseLogBook;
  protected ParseTag;
  // tslint:enable

  public constructor(private requestService: RequestService) {}

  public updateLogBookWithTags(logBook: OclLogbook): Promise<Parse.Object[]> {
    if (logBook.fromGOC) {
      return Promise.resolve([]);
    } else {
      return this.deleteLogbookTagsFor(logBook).then(() => {
        return this.setTagsForLogbook(logBook);
      });
    }
  }

  private setTagsForLogbook(logbook: OclLogbook): Promise<Parse.Object[]> {
    const parseLogbook = new this.ParseLogBook({ id: logbook.objectId });

    if (logbook.tags) {
      const currentParseTags = logbook.tags.map(tag => {
        return new this.ParseTag({ id: tag.objectId });
      });

      const savingList = currentParseTags.map(parseTag => {
        const joinTableRecord = new this.ParseLogBookTag();
        joinTableRecord.set('logbook', parseLogbook);
        joinTableRecord.set('tag', parseTag);
        return joinTableRecord;
      });

      return this.requestService.performSaveAllQuery(savingList);
    } else {
      return Promise.resolve([]);
    }
  }

  private deleteLogbookTagsFor(logbook: OclLogbook): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseLogBookTag);
    const parseLogbook = new this.ParseLogBook({ id: logbook.objectId });
    query.equalTo('logbook', parseLogbook);

    return this.requestService.performFindQuery(query).then(parseOccLogbookTags => {
      return this.requestService.performDestroyAllQuery(parseOccLogbookTags);
    });
  }
}
