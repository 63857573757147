import { RolesService } from 'src/app/common/services/roles.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { OclChecklistFunction } from '../../../ocl/store/ocl-tasks.selectors';
import { OclEvent } from '../../../ocl/models/ocl-event.model';

@Component({
  selector: 'app-checklist-event',
  templateUrl: './checklist-event.component.html',
  styleUrls: ['./checklist-event.component.scss'],
})
export class ChecklistEventComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public mod: string;
  @Input()
  public isReadOnly: boolean;
  @Input()
  public events: any;

  @Input()
  set checklistInput(value: OclChecklistFunction[]) {
    this._checklist.next(value);
  }

  get checklistInput(): OclChecklistFunction[] {
    return this._checklist.getValue();
  }

  public checklist: OclChecklistFunction[] = [];
  public checklistSub: Subscription;
  public filterStatus = [];
  public isFilterTodoActive = true;
  public isFilterCloseActive = true;
  public isFilterFrozenActive = true;
  public isFilterNotApplicableActive = true;
  public eventsList: OclEvent[];
  public selectedEventId: string = null;
  RolesService = RolesService;

  private _checklist = new BehaviorSubject<OclChecklistFunction[]>(undefined);

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.checklistSub = this._checklist.subscribe(result => {
      if (!result) {
        return;
      }
      this.checklist = result;
      this.eventsList = Object.values(
        result.reduce((acc, next) => {
          next.events.forEach(e => {
            if (e.event) {
              acc[e.event.objectId] = e.event;
            }
          });
          return acc;
        }, {})
      );
    });
    this.computeKindFilter();
  }

  public ngOnDestroy(): void {
    this.checklistSub.unsubscribe();
  }

  computeKindFilter() {
    this.filterStatus = [];
    if (this.isFilterTodoActive) {
      this.filterStatus.push('TODO');
    }
    if (this.isFilterCloseActive) {
      this.filterStatus.push('DONE');
    }
    if (this.isFilterFrozenActive) {
      this.filterStatus.push('FROZEN');
    }
    if (this.isFilterNotApplicableActive) {
      this.filterStatus.push('NOT_APPLICABLE');
    }
  }
}
