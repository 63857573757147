import { HolObject } from 'src/app/common/models/hol-object';
import { HolTag } from 'src/app/common/models/hol-tag';
import { HolUser } from 'src/app/common/models/hol-user.model';
import { ErpCrisis } from './erp-crisis';
import { ErpHistoryLog } from './erp-historyLog';

export class ErpCrisisAnnouncement extends HolObject {
  objectId?: string;
  message: string;
  crisisObjectId: string;
  tags: HolTag[];
  visibilityByFunctionId: string[];
  createdByFunctionId: string[];
  isArchived?: boolean;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.message = parseObject.get('message');
    const crisis = new ErpCrisis(parseObject.get('crisis'));
    this.crisisObjectId = crisis.objectId;
    this.tags = parseObject.get('tags') ? parseObject.get('tags').map(tag => new HolTag(tag)) : [];
    this.visibilityByFunctionId = parseObject.get('visibilityByFunctionId') || [];
    this.createdByFunctionId = parseObject.get('createdByFunctionId') || [];
    this.isArchived = parseObject.get('isArchived');
  }

  toLog?(action: string, parseObject: Parse.Object, crisisObjectId: string): ErpHistoryLog {
    const log = new ErpHistoryLog();
    log.type = 'crisisAnnouncement';
    log.subType = action;
    log.jsonObject = this;
    log.acl = this.acl;
    log.crisisAnnouncement = parseObject;
    log.crisis = new Parse.Object('GDCCrisis', { id: crisisObjectId });
    return log;
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('message');
  }
}
