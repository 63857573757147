<!-- ITEMS IN GROUP SECTION END -->
<mat-expansion-panel
  *ngIf="group.objectId && group.items | aclFilter: RolesService.companiesRolesFilter; let filteredItems"
  (closed)="isOpened = false"
  (opened)="isOpened = true"
  class="occ-event-group-item"
  [class.border-with-color]="type !== 'EVENT'"
>
  <mat-expansion-panel-header class="event-group-header" collapsedHeight="auto" expandedHeight="auto">
    <div class="event-item__header">
      <div class="first-line">
        <button (click)="onEditGroup()" *ngIf="!isReadOnly" class="group-btn" mat-raised-button>
          <img src="../../../../assets/images/group-attach-white.svg" />
        </button>
        <span class="event-item-title-big">{{ group.title }}</span>
        <span class="space"></span>
      </div>
      <div *ngIf="mostCriticalNextInfo && mostCriticalNextInfo.message && type === 'EVENT'" class="next-info-message-line">
        {{ mostCriticalNextInfo.message }}
      </div>
      <div *ngIf="decisionToDisplay && decisionToDisplay.message && type === 'DECISION'" class="next-info-message-line">
        {{ decisionToDisplay.message }}
      </div>
      <div *ngIf="mostRecentLogBook && mostRecentLogBook.text && type === 'LOGBOOK'" class="next-info-message-line">
        {{ mostRecentLogBook.text }}
      </div>
      <div
        *ngIf="globalInstructionToDisplay && globalInstructionToDisplay.description && type === 'GLOBAL_INSTRUCTION'"
        class="next-info-message-line"
      >
        {{ globalInstructionToDisplay.description }}
      </div>
      <div *ngIf="!mostCriticalNextInfo && type === 'EVENT'" class="next-info-message-line">
        No next info
      </div>
      <div
        *ngIf="decisionToDisplay && decisionToDisplay.nextInfoTime && type === 'DECISION'"
        [class.almost-elapsed]="isAlmostElapsed(decisionToDisplay.nextInfoTime)"
        [class.elapsed]="isElapsed(decisionToDisplay.nextInfoTime)"
        [class.hol-done]="decisionToDisplay.done"
        class="next-info-line"
      >
        <span>{{ decisionToDisplay.nextInfoTime | time: isUtc }}</span>
      </div>
      <div
        *ngIf="mostCriticalNextInfo && type === 'EVENT'"
        [class.almost-elapsed]="isAlmostElapsed(mostCriticalNextInfo.nextInfoTime)"
        [class.elapsed]="isElapsed(mostCriticalNextInfo.nextInfoTime)"
        [class.hol-affected]="mostCriticalNextInfo.done && !mostCriticalNextInfo.manual"
        [class.hol-done]="mostCriticalNextInfo.done && mostCriticalNextInfo.manual"
        class="next-info-line"
      >
        <span> {{ mostCriticalNextInfo.nextInfoTime | nextInfoTime: isUtc }}</span>
      </div>
      <div class="indicator">
        <span *ngIf="filteredItems.length > 1"
          >{{ filteredItems.length }} {{ moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.GROUP_TYPES' | translate }}</span
        >
        <span *ngIf="filteredItems.length === 1"
          >{{ filteredItems.length }} {{ moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.GROUP_TYPE' | translate }}</span
        >
        <i [class.opened]="isOpened" class="glyphicon glyphicon-menu-down indicator-icon"></i>
      </div>
    </div>
  </mat-expansion-panel-header>
  <ul class="hol-list">
    <ng-container *ngFor="let item of filteredItems; trackBy: trackByFunction">
      <li [ngClass]="{ 'not-seen': !isSeen(item) }" [@EnterLeave]="'flyIn'">
        <ng-container *ngIf="type === 'EVENT'" [ngTemplateOutlet]="eventItem" [ngTemplateOutletContext]="{ item: item }"></ng-container>
        <ng-container
          *ngIf="type === 'DECISION'"
          [ngTemplateOutlet]="decisionItem"
          [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
        <ng-container *ngIf="type === 'LOGBOOK'" [ngTemplateOutlet]="logBookItem" [ngTemplateOutletContext]="{ item: item }"></ng-container>
        <ng-container
          *ngIf="type === 'GLOBAL_INSTRUCTION'"
          [ngTemplateOutlet]="globalInstructionItem"
          [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
      </li>
    </ng-container>
  </ul>
</mat-expansion-panel>
<!-- ITEMS IN GROUP SECTION END -->

<!-- ITEMS ONLY SECTION -->
<ul class="hol-list" *ngIf="!group.objectId">
  <li [ngClass]="{ 'not-seen': !isSeen(group.items[0]) }">
    <ng-container *ngIf="type === 'EVENT'" [ngTemplateOutlet]="eventItem"></ng-container>
    <ng-container *ngIf="type === 'DECISION'" [ngTemplateOutlet]="decisionItem"></ng-container>
    <ng-container *ngIf="type === 'LOGBOOK'" [ngTemplateOutlet]="logBookItem"></ng-container>
    <ng-container *ngIf="type === 'GLOBAL_INSTRUCTION'" [ngTemplateOutlet]="globalInstructionItem"></ng-container>
  </li>
</ul>
<!-- ITEMS ONLY SECTION END-->

<!-- TEMPLATES ITEMS SECTION -->
<ng-template #eventItem let-item="item">
  <app-ocl-event-item
    [linkedIds]="linkedIds"
    [event]="group.objectId ? item : group.items[0]"
    [isReadOnly]="isReadOnly"
    [localHourOnly]="localHourOnly"
    (updatedEvent)="onEventUpdated($event)"
    [eclInfos]="eclInfos"
  >
  </app-ocl-event-item>
</ng-template>

<ng-template #decisionItem let-item="item">
  <app-ocl-decisions-list-item
    [logBookItems]="logBookItems"
    [modeLinkIsActivate]="modeLinkIsActivate"
    [linkedIds]="linkedIds"
    [events]="events"
    [localHourOnly]="localHourOnly"
    [isReadOnly]="isReadOnly"
    [item]="group.objectId ? item : group.items[0]"
    [airportsCode]="airportsCode"
  ></app-ocl-decisions-list-item>
</ng-template>

<ng-template #logBookItem let-item="item">
  <app-ocl-logbook-list-item
    [linkedIds]="linkedIds"
    [events]="events"
    [isReadOnly]="isReadOnly"
    [localHourOnly]="localHourOnly"
    [item]="group.objectId ? item : group.items[0]"
    [modeLinkIsActivate]="modeLinkIsActivate"
    [decisionItems]="decisionItems"
    [airportsCode]="airportsCode"
  >
  </app-ocl-logbook-list-item>
</ng-template>

<ng-template #globalInstructionItem let-item="item">
  <app-ocl-global-instruction-item
    [isReadOnly]="isReadOnly"
    [item]="group.objectId ? item : group.items[0]"
    [localHourOnly]="localHourOnly"
  ></app-ocl-global-instruction-item>
</ng-template>
<!-- TEMPLATES ITEMS SECTION END -->
