'use strict';

function BrowserStorage(_sessionStorage) {
  this.sessionStorage = _sessionStorage;
}

BrowserStorage.prototype.set = function (_key, _value) {
  this.sessionStorage.setItem(_key, JSON.stringify(_value));
};

BrowserStorage.prototype.get = function (_key) {
  const item = this.sessionStorage.getItem(_key);
  if (item) {
    return JSON.parse(item);
  } else {
    return undefined;
  }
};

BrowserStorage.prototype.del = function (_key) {
  this.sessionStorage.removeItem(_key);
};

function MemoryStorage() {
  this.sessionStorage = {};
}

MemoryStorage.prototype.set = function (_key, _value) {
  this.sessionStorage[_key] = _value;
};

MemoryStorage.prototype.get = function (_key) {
  return this.sessionStorage[_key];
};

MemoryStorage.prototype.del = function (_key) {
  this.sessionStorage[_key] = undefined;
};

/**
 * @ngdoc factory
 * @name gestiondecriseApp.LocalStorage
 * @description
 * # LocalStorage
 * Factory in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').factory('localStorage', function ($window) {
  let storage;

  try {
    $window.sessionStorage.setItem('test', '');
    $window.sessionStorage.removeItem('test');
    storage = new BrowserStorage($window.sessionStorage);
  } catch (err) {
    storage = new MemoryStorage();
  }

  function setUrlLocation(location) {
    storage.set('locationUrl', location);
  }

  function setUrlParams(params) {
    storage.set('locationParams', params);
  }

  function getUrlLocation() {
    return storage.get('locationUrl');
  }

  function getUrlParams() {
    return storage.get('locationParams');
  }

  function setOccDashboardLastDisplayTime(time) {
    storage.set('occDashboardLastDisplayTime', time);
  }

  function setEclDashboardLastDisplayTime(time) {
    storage.set('eclDashboardLastDisplayTime', time);
  }

  function setOpsDashboardLastDisplayTime(time) {
    storage.set('opsDashboardLastDisplayTime', time);
  }

  function setGocDashboardLastDisplayTime(time) {
    storage.set('gocDashboardLastDisplayTime', time);
  }

  function setErpDashboardLastDisplayTime(time) {
    storage.set('erpDashboardLastDisplayTime', time);
  }

  function getOccDashboardLastDisplayTime() {
    return moment(storage.get('occDashboardLastDisplayTime')).toDate();
  }

  function getEclDashboardLastDisplayTime() {
    return moment(storage.get('eclDashboardLastDisplayTime')).toDate();
  }

  function getOpsDashboardLastDisplayTime() {
    return moment(storage.get('opsDashboardLastDisplayTime')).toDate();
  }

  function getGocDashboardLastDisplayTime() {
    return moment(storage.get('gocDashboardLastDisplayTime')).toDate();
  }

  function getErpDashboardLastDisplayTime() {
    return moment(storage.get('erpDashboardLastDisplayTime')).toDate();
  }

  return {
    setUrlLocation: setUrlLocation,
    setUrlParams: setUrlParams,
    getUrlLocation: getUrlLocation,
    getUrlParams: getUrlParams,
    setOccDashboardLastDisplayTime: setOccDashboardLastDisplayTime,
    getOccDashboardLastDisplayTime: getOccDashboardLastDisplayTime,
    setEclDashboardLastDisplayTime: setEclDashboardLastDisplayTime,
    getEclDashboardLastDisplayTime: getEclDashboardLastDisplayTime,
    setOpsDashboardLastDisplayTime: setOpsDashboardLastDisplayTime,
    getOpsDashboardLastDisplayTime: getOpsDashboardLastDisplayTime,
    setGocDashboardLastDisplayTime: setGocDashboardLastDisplayTime,
    getGocDashboardLastDisplayTime: getGocDashboardLastDisplayTime,
    setErpDashboardLastDisplayTime: setErpDashboardLastDisplayTime,
    getErpDashboardLastDisplayTime: getErpDashboardLastDisplayTime,
  };
});
