import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolCrisis } from '../models/hol-crisis';
import { CommonStoreManager } from '../store/common.store-manager';
import { take } from 'rxjs/operators';
import { ErpFunctionUser } from '../../erp/models/erp-functionUser';
import { OptionsService } from './options.service';
import { ErpFunctionCrisis } from '../../erp/models/erp-functionCrisis';

export interface CrisisRolesRef {
  isInCrisisDirectorTeam: boolean;
  isInCrisisInitializerTeam: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  // tslint:disable:variable-name
  protected ParseUserFunction = Parse.Object.extend('GDCUserFunction_REF');
  protected ParseFunction = Parse.Object.extend('GDCFunction');

  constructor(
    protected requestService: RequestService,
    private commonStoreManager: CommonStoreManager,
    private optionsService: OptionsService
  ) {}

  async getCurrentUserCrisisRightsRef(): Promise<CrisisRolesRef> {
    const fQuery = new Parse.Query(this.ParseFunction);
    fQuery.containedIn(
      'shortTitle',
      this.optionsService.getCrisisDirectorShortTitlesList().concat(this.optionsService.getCrisisInitializerShortTitlesList())
    );

    const ufQuery = new Parse.Query(this.ParseUserFunction);
    ufQuery.equalTo('userId', Parse.User.current().get('userId'));

    return Promise.all([this.requestService.performFindAllQuery(fQuery), this.requestService.performFindAllQuery(ufQuery)]).then(
      ([pFunctions, pUserFunctions]) => {
        const functions = pFunctions.map(f => new ErpFunctionCrisis(f));
        const userFunctions = pUserFunctions.map(uf => new ErpFunctionUser(uf));
        const crisisDirectorFunctionsIds = functions
          .filter(f => this.optionsService.getCrisisDirectorShortTitlesList().includes(f.shortTitle))
          .map(f => f.functionId);
        const crisisInitializerFunctionIds = functions
          .filter(f => this.optionsService.getCrisisInitializerShortTitlesList().includes(f.shortTitle))
          .map(f => f.functionId);

        return {
          isInCrisisDirectorTeam: !!userFunctions.find(uf => crisisDirectorFunctionsIds.includes(uf.functionId)),
          isInCrisisInitializerTeam: !!userFunctions.find(uf => crisisInitializerFunctionIds.includes(uf.functionId)),
        };
      }
    );
  }
}
