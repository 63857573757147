'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewSituationsService
 * @description
 * # CrewSituationsService
 * Service in the gestiondecriseApp.
 */
angular
  .module('gestiondecriseApp')
  .service('CrewSituationsService', function (
    $q,
    $rootScope,
    RequestService,
    ParseMapper,
    MailService,
    SmsService,
    CrewUsersService,
    CrewEventsService,
    CrewOptionsService,
    CrewLogsService,
    CrewLogsTypes
  ) {
    var CrewSituations = Parse.Object.extend('CrewSituations');
    var CrewUsers = Parse.Object.extend('CrewUsers');
    var CrewEvent = Parse.Object.extend('CrewEvent');

    this.create = function (situation, user, sendMail, sendSms) {
      var deferred = $q.defer();
      if (!situation) {
        return $q.reject('CrewSituationsService::create: No situation provided');
      }
      if (!user) {
        return $q.reject('CrewSituationsService::create: No user provided');
      }
      if (situation.statusTime && !situation.statusDate) {
        return $q.reject('CrewSituationsService::create: Time without date');
      }

      var situationParse = new CrewSituations({
        user: new CrewUsers({
          id: user.objectId,
        }),
        text: situation.text,
        direction: situation.direction,
        status: situation.status,
        statusText: situation.statusText,
        createdBy: Parse.User.current(),
      });
      if (situation.statusDate) {
        situationParse.set('statusDate', moment(situation.statusDate).toDate());
      }

      var waitEventPromise = $q.defer();
      if (situation.event) {
        if (situation.event.objectId) {
          situationParse.set('event', new CrewEvent({ id: situation.event.objectId }));
          // add user to event
          CrewEventsService.addUser(situation.event, user).then(function () {
            waitEventPromise.resolve();
          }, waitEventPromise.reject);
        } else {
          situation.event.nbPersons = 1;
          situation.event.notes = [{ text: '**' + user.firstName + ' ' + user.lastName + '** : ' + situation.text }];
          CrewEventsService.create(situation.event).then(function (newEvent) {
            situationParse.set('event', new CrewEvent({ id: newEvent.objectId }));
            waitEventPromise.resolve();
          }, waitEventPromise.reject);
        }
      } else {
        situationParse.set('impactDate', situation.impactDate);
        situationParse.set('validityDate', situation.validityDate);
        waitEventPromise.resolve();
      }

      waitEventPromise.promise.then(function () {
        RequestService.performSaveQuery(
          situationParse,
          null,
          function (createdSituation) {
            var newSituation = ParseMapper.crewSituationToObject(createdSituation);
            if (sendMail) {
              MailService.sendNewSituationEmail(newSituation);
            }
            if (sendSms) {
              SmsService.sendNewSituationSms(newSituation);
            }

            if (newSituation.event) {
              CrewEventsService.getById(newSituation.event.objectId).then(function (event) {
                newSituation.event = event[0];
                CrewLogsService.postLog(CrewLogsTypes.SITUATION_CREATE, newSituation);
              });
            } else {
              CrewLogsService.postLog(CrewLogsTypes.SITUATION_CREATE, newSituation);
            }

            deferred.resolve(newSituation);
          },
          deferred.reject
        );
      }, deferred.reject);

      return deferred.promise;
    };

    this.updateStatus = function (situation, skipLog) {
      var deferred = $q.defer();

      var parseSituation = new CrewSituations({ id: situation.objectId });
      RequestService.performSaveQuery(
        parseSituation,
        {
          status: situation.status,
          statusDate: situation.statusDate,
          statusText: situation.statusText,
          isClosed: situation.isClosed,
          closeReasonText: situation.closeReasonText,
          closedBy: situation.closedBy,
        },
        function (updatedSituation) {
          var newSituation = ParseMapper.crewSituationToObject(updatedSituation);

          if (!skipLog) {
            if (newSituation.event) {
              CrewEventsService.getById(newSituation.event.objectId).then(function (event) {
                newSituation.event = event[0];
                CrewLogsService.postLog(CrewLogsTypes.SITUATION_UPDATE, newSituation);
              });
            } else {
              CrewLogsService.postLog(CrewLogsTypes.SITUATION_UPDATE, newSituation);
            }
          }
          deferred.resolve(newSituation);
        },
        deferred.reject
      );
      return deferred.promise;
    };

    this.close = function (situation, skipLog) {
      var deferred = $q.defer();

      var parseSituation = new CrewSituations({ id: situation.objectId });
      RequestService.performSaveQuery(
        parseSituation,
        {
          isClosed: true,
          closeReasonText: situation.closeReasonText,
          closedBy: Parse.User.current(),
          closedAt: moment().toDate(),
        },
        function (updatedSituation) {
          var newSituation = ParseMapper.crewSituationToObject(updatedSituation);

          if (!skipLog) {
            if (newSituation.event) {
              CrewEventsService.getById(newSituation.event.objectId).then(function (event) {
                newSituation.event = event[0];
                CrewLogsService.postLog(CrewLogsTypes.SITUATION_CLOSE, newSituation);
              });
            } else {
              CrewLogsService.postLog(CrewLogsTypes.SITUATION_CLOSE, newSituation);
            }
          }
          deferred.resolve(newSituation);
        },
        deferred.reject
      );
      return deferred.promise;
    };

    this.getForUser = function (user) {
      var deferred = $q.defer();
      var parseUser = new CrewUsers({ id: user.objectId });
      var situationQuery = new Parse.Query(CrewSituations);
      situationQuery.equalTo('user', parseUser);
      situationQuery.include('event');
      situationQuery.descending('createdAt');

      RequestService.performFindQuery(
        situationQuery,
        function (results) {
          deferred.resolve(_.map(results, r => ParseMapper.crewSituationToObject(r)));
        },
        deferred.reject
      );

      return deferred.promise;
    };

    this.isActiveSituation = function (situation) {
      if (situation.isClosed) {
        return false;
      }
      return (
        situation.status === 'WAITING' || // situation en attente
        (situation.event && situation.event.closeReason === undefined) || // ou liée à un évènement non clos
        (situation.validityDate && moment().isSameOrBefore(moment(situation.validityDate), 'day')) || // ou la date de validité n'est pas dépassée
        (situation.impactDate && !situation.validityDate && moment().isSameOrBefore(moment(situation.impactDate), 'day')) || // ou la date d'impact n'est pas dépassée (sans date de validité)
        false
      );
    };

    this.getAllLogs = function (dateFilter) {
      var deferred = $q.defer();

      var impactQuery = new Parse.Query(CrewSituations);
      if (dateFilter) {
        impactQuery.greaterThanOrEqualTo('impactDate', moment().startOf('day').toDate());
        impactQuery.lessThan('impactDate', moment().startOf('day').add(dateFilter.threshold, 'day').toDate());
      }

      var eventQuery = new Parse.Query(CrewSituations);
      if (dateFilter) {
        var eventDateQuery = new Parse.Query(CrewEvent);
        eventDateQuery.doesNotExist('closeReason');
        eventDateQuery.lessThan('date', moment().startOf('day').add(dateFilter.threshold, 'day').toDate());
        eventQuery.matchesQuery('event', eventDateQuery);
      }

      var mainQuery = Parse.Query.or(impactQuery, eventQuery);
      mainQuery.include('user');
      mainQuery.include('event');
      mainQuery.include('createdBy');
      mainQuery.descending('createdAt');

      RequestService.performFindQuery(
        mainQuery,
        function (situations) {
          situations = _.filter(situations, function (eu) {
            return !!eu.get('user');
          });
          CrewUsersService.getUsersFunctionsMap(
            _.map(situations, function (info) {
              return info.get('user');
            })
          ).then(function (usersFunctionsMap) {
            var mappedInfos = _.map(situations, function (sit) {
              var situation = ParseMapper.crewSituationToObject(sit);

              situation.user.functions = usersFunctionsMap[situation.user.userId];
              return situation;
            });

            deferred.resolve(mappedInfos);
          }, deferred.reject);
        },
        deferred.reject
      );
      return deferred.promise;
    };

    this.getAllTodos = function (dateFilter, dateLimits) {
      var deferred = $q.defer();
      var additionalDays = CrewOptionsService.getTodoAdditionalDaysToDisplay() || 0;
      var impactQuery = new Parse.Query(CrewSituations);
      //impactQuery.greaterThanOrEqualTo('impactDate', moment().startOf('day').toDate());
      impactQuery.exists('impactDate');
      if (dateFilter) {
        if (dateFilter.threshold === 1) {
          //just today events
          impactQuery.greaterThanOrEqualTo('impactDate', moment().startOf('day').toDate());
          impactQuery.lessThan('impactDate', moment().startOf('day').add(dateFilter.threshold, 'day').toDate());
        } else {
          //events for the threshold and events coming after the date of threshold
          impactQuery.greaterThanOrEqualTo(
            'impactDate',
            moment()
              .startOf('day')
              .add(dateFilter.threshold - 1, 'day')
              .toDate()
          );
        }
      }

      var eventQuery = new Parse.Query(CrewSituations);
      var eventDateQuery = new Parse.Query(CrewEvent);
      eventDateQuery.doesNotExist('closeReason');
      if (dateFilter) {
        if (dateFilter.threshold === 1) {
          //just today events
          eventDateQuery.greaterThanOrEqualTo('date', moment().startOf('day').toDate());
          eventDateQuery.lessThan('date', moment().startOf('day').add(dateFilter.threshold, 'day').toDate());
        } else {
          //events for the threshold and events coming after the date of threshold
          eventDateQuery.greaterThanOrEqualTo(
            'date',
            moment()
              .startOf('day')
              .add(dateFilter.threshold - 1, 'day')
              .toDate()
          );
        }
      }
      eventQuery.matchesQuery('event', eventDateQuery);

      var mainQuery = Parse.Query.or(impactQuery, eventQuery);
      mainQuery.include('user');
      mainQuery.include('event');
      mainQuery.include('createdBy');
      mainQuery.containedIn('status', ['WAITING', 'ACCEPTED']);
      mainQuery.ascending('statusDate');
      mainQuery.notEqualTo('isClosed', true);
      if (dateLimits) {
        mainQuery.lessThan('statusDate', moment(dateLimits.endDate).toDate());
        mainQuery.greaterThanOrEqualTo('statusDate', moment(dateLimits.startDate).toDate());
      } else {
        mainQuery.lessThan('statusDate', moment().endOf('day').add(additionalDays, 'day').toDate());
      }

      RequestService.performFindQuery(
        mainQuery,
        function (situations) {
          CrewUsersService.getUsersFunctionsMap(
            _.map(situations, function (info) {
              return info.get('user');
            })
          ).then(function (usersFunctionsMap) {
            var mappedInfos = _.map(situations, function (sit) {
              var situation = ParseMapper.crewSituationToObject(sit);

              if (situation.user) {
                situation.user.functions = usersFunctionsMap[situation.user.userId];
              }
              return situation;
            });

            deferred.resolve(mappedInfos);
          }, deferred.reject);
        },
        deferred.reject
      );
      return deferred.promise;
    };

    this.getAllWithImpactDate = function (dateLimits) {
      var deferred = $q.defer();

      var impactDateQuery = new Parse.Query(CrewSituations);
      impactDateQuery.greaterThanOrEqualTo('impactDate', moment(dateLimits.startDate).toDate());
      var validityDateQuery = new Parse.Query(CrewSituations);
      validityDateQuery.greaterThanOrEqualTo('validityDate', moment(dateLimits.startDate).toDate());

      var impactQuery = new Parse.Query.or(impactDateQuery, validityDateQuery);

      impactQuery.lessThan('impactDate', moment(dateLimits.endDate).toDate());

      impactQuery.include('user');
      impactQuery.include('createdBy');
      impactQuery.ascending('impactDate');
      impactQuery.notEqualTo('isClosed', true);
      RequestService.performFindQuery(
        impactQuery,
        function (situations) {
          CrewUsersService.getUsersFunctionsMap(
            _.map(situations, function (info) {
              return info.get('user');
            })
          ).then(function (usersFunctionsMap) {
            var mappedInfos = _.map(situations, function (sit) {
              var situation = ParseMapper.crewSituationToObject(sit);

              if (situation.user) {
                situation.user.functions = usersFunctionsMap[situation.user.userId];
              }
              return situation;
            });

            deferred.resolve(mappedInfos);
          }, deferred.reject);
        },
        deferred.reject
      );

      return deferred.promise;
    };

    //region Pool functions
    this.fetchAllLogs = function () {
      var deferred = $q.defer();
      this.getAllLogs($rootScope.crewEventFilter).then(function (logs) {
        $rootScope.$broadcast('crewPoolService-logs', logs);
        deferred.resolve(logs);
      }, deferred.reject);
      return deferred.promise;
    };

    this.fetchAllTodos = function () {
      var deferred = $q.defer();
      this.getAllTodos($rootScope.crewEventFilter, undefined).then(function (logs) {
        $rootScope.$broadcast('crewPoolService-todos', logs);
        deferred.resolve(logs);
      }, deferred.reject);
      return deferred.promise;
    };
    //endregion
  });
