<div
  [style.border-style]="item.linkedData ? 'solid' : 'none'"
  [style.border-color]="!item.linkedData ? '' : item.linkedData?.isParent ? item.linkedData?.color : (item.linkedData?.linkedColors)[0]"
  [style.border-width]="!item.linkedData ? '' : item.linkedData?.isParent ? '4px' : '2px'"
  [style.background-color]="
    !item.linkedData ? '' : (item.linkedData?.isParent ? item.linkedData?.color : (item.linkedData?.linkedColors)[0]) + '52'
  "
  class="hol-clickable occ-logbook-item"
  (click)="openDetailsModal($event, item)"
>
  <app-hol-list-item
    [item]="item"
    [isReadOnly]="isReadOnly"
    [localHourOnly]="localHourOnly"
    [fromModule]="fromModule"
    [toModule]="item.toGOC ? 'GOC' : item.toERP ? 'ERP' : null"
  >
    <button
      [ngClass]="{ selected: linkedUid }"
      class="occ-linked-button"
      (click)="showLinkedItems($event)"
      *ngIf="!isHistory && linkedIds[item.objectId]"
      [disabled]="linkedIds[item.objectId] === 'DISABLED_BUTTON'"
    >
      <img *ngIf="!linkedUid" class="occ-linked-icon" src="/assets/images/link-icon-blue.svg" />
      <img *ngIf="linkedUid" class="occ-linked-icon" src="/assets/images/link-icon-white.svg" />
    </button>

    <div class="header-addon">
      <div class="pinned" *ngIf="item.isPinned"><img class="pinned-item-icon-mini" src="/assets/images/pin--on.png" /></div>

      <div>
        <app-tag-chips *ngIf="!item.fromGOC && !item.fromCREW" [tags]="item.tags" [isShowName]="false"> </app-tag-chips>
      </div>
      <div *ngIf="item.flight && item.flight.flightNumber" class="item-dasboard-occ-flight-linked">
        <span>{{ item.flight.flightNumber }}/{{ item.flight.std | date: 'ddMMM' }}</span>
      </div>
    </div>

    <div class="hol-spaced-line addon">
      <app-file-and-image-icons-display [attachments]="item.attachments"></app-file-and-image-icons-display>
      <div
        [class.hol-done]="item.done"
        *ngIf="!isReadOnly && !item.readOnly && canCheckLogbook && !item.fromGOC && !item.fromCREW && !isHistory"
      >
        <button mat-icon-button (click)="toggleDone(item, $event)" aria-label="mark as done">
          <i class="icon icon-check"></i>
        </button>
        <strong (click)="toggleDone(item, $event)" *ngIf="item.done">
          {{ item.updatedAt | time: isUtc }}
        </strong>
      </div>
      <div class="hol-done" *ngIf="(isReadOnly || item.readOnly) && canCheckLogbook && item.done">
        <i class="icon icon-check"></i>
        <strong>
          {{ item.updatedAt | time: isUtc }}
        </strong>
      </div>
      <div
        *ngIf="item.nextInfoDate"
        [class.almost-elapsed]="isAlmostElapsed(item.nextInfoDate)"
        [class.elapsed]="isElapsed(item.nextInfoDate)"
        [class.hol-done]="item.done"
        class="col-next-info"
      >
        <span>{{ item.nextInfoDate | time: isUtc }}</span>
      </div>
    </div>
  </app-hol-list-item>
</div>
