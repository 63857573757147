import { HolNotifyFunction } from './../../../common/models/hol-notification.model';
import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MarkdownEditorComponent, MdEditorOption } from 'ngx-markdown-editor';
import { FunctionsStoreManager } from 'src/app/erp/store/functions/functions.store-manager';
import { FunctionUserForCrisis } from 'src/app/erp/models/erp-functionUser';
import { ErpCrisisTask } from 'src/app/erp/models/erp-crisisTask';
import { orderBy, cloneDeep } from 'lodash';
import { FormGroup } from '@angular/forms';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { ErpCrisis } from '../../models/erp-crisis';
import { nextInfoType } from 'src/app/common/components/hol-next-info/hol-next-info.component';
import { TagsService } from '../../../common/services/tags.service';
import { ErpTagsService } from '../../services/erp-tag.service';
import { ErpModuleConfigService } from '../../services/erp-module-config.service';
import { HistoryService } from '../../../common/services/history.service';
import { ErpHistoryService } from '../../services/erp-history.service';
import { TasksService } from '../../../common/services/tasks.service';

@Component({
  selector: 'app-erp-crisis-task-modal',
  templateUrl: './erp-crisis-task-modal.component.html',
  styleUrls: ['./erp-crisis-task-modal.component.scss'],
  providers: [
    {
      provide: TagsService,
      useExisting: ErpTagsService,
    },
    {
      provide: ModuleConfigService,
      useExisting: ErpModuleConfigService,
    },
    {
      provide: HistoryService,
      useExisting: ErpHistoryService,
    },
  ],
})
export class ErpCrisisTaskModalComponent implements OnInit {
  public options: MdEditorOption = { resizable: false, showBorder: false, hideIcons: ['FullScreen', 'Image'] };
  functionsUserForCrisis: FunctionUserForCrisis[];
  functionsUserForCrisisSelected: string;
  crisisTask: ErpCrisisTask;
  isVisibleByGroupChecked = false;
  form: FormGroup;
  crisis: ErpCrisis;
  defaultOutputDataLabelPlaceHolder: string;
  outputDataLabelPlaceHolder: string;
  defaultOutputTitlePlaceHolder: string;
  outputTitlePlaceHolder: string;
  defaultTitlePlaceHolder: string;
  titlePlaceHolder: string;
  temporarySave = false;
  notifyFunction: HolNotifyFunction = { sendByMail: false, sendBySms: false };
  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    public moduleConfig: ModuleConfigService,
    public dialogRef: MatDialogRef<ErpCrisisTaskModalComponent>,
    private functionsStoreManager: FunctionsStoreManager,
    private tasksService: TasksService,
    @Inject('$translate') private $translate,
    private datePipe: DatePipe
  ) {}

  @ViewChild('mdEditor') mdEditor: MarkdownEditorComponent;

  ngOnInit(): void {
    this.defaultOutputDataLabelPlaceHolder = this.$translate.instant(
      this.moduleConfig.config.translateKey + '.MODALS.TASK.OUTPUT_DATA_LABEL_PLACEHOLDER'
    );
    this.outputDataLabelPlaceHolder = this.defaultOutputDataLabelPlaceHolder;
    this.defaultOutputTitlePlaceHolder = this.$translate.instant(
      this.moduleConfig.config.translateKey + '.MODALS.TASK.OUTPUT_TITLE_PLACEHOLDER'
    );
    this.outputTitlePlaceHolder = this.defaultOutputTitlePlaceHolder;
    this.defaultTitlePlaceHolder = this.$translate.instant(this.moduleConfig.config.translateKey + '.MODALS.TASK.TASK_TITLE_PLACEHOLDER');
    this.titlePlaceHolder = this.defaultTitlePlaceHolder;
    this.form = new FormGroup({});

    this.functionsUserForCrisis = orderBy(this.data.functionsUserForCrisis, 'shortTitle');

    this.crisis = this.data.crisis;

    this.temporarySave = this.data.temporarySave ? this.data.temporarySave : false;

    this.crisisTask = {
      crisisTypeId: this.data.crisis.type.crisisTypeId,
      taskTitle: '',
      taskDescription: this.data.crisisNew
        ? this.data.crisisNew.content
        : this.data.crisisDescision
        ? this.data.crisisDescision.contentText +
          ' (' +
          this.$translate.instant('ERP.MODALS.DECISION.NEW_TASK_TITLE', {
            date: this.datePipe.transform(new Date(), 'dd/MM/yyyy').toString().toLocaleUpperCase(),
          }) +
          ')'
        : '',
      functionId: '',
      isOnDashboard: false,
      order: 1,
      status: 'TODO',
      outputDataLabel: '',
      outputTitle: '',
      isTemporary: true,
      code: '',
      attachments: this.data.crisisDescision ? this.data.crisisDescision.attachments : {},
      tags: [],
      visibleByGroup: false,
    };

    this.mdEditor.togglePreview();

    if (!this.functionsUserForCrisisSelected) {
      const defaultSelectedFunction = this.functionsUserForCrisis.find(fUser => fUser.functionId === this.data.forFunction);
      if (defaultSelectedFunction) {
        this.functionsUserForCrisisSelected = defaultSelectedFunction.functionId;
        this.crisisTask.code = defaultSelectedFunction.shortTitle;
        this.crisisTask.functionId = defaultSelectedFunction.functionId;
      } else if (this.functionsUserForCrisis.length) {
        this.functionsUserForCrisisSelected = this.functionsUserForCrisis[0].functionId;
        this.crisisTask.code = this.functionsUserForCrisis[0].shortTitle;
        this.crisisTask.functionId = this.functionsUserForCrisis[0].functionId;
      }
      if (this.functionsUserForCrisisSelected) {
        // tslint:disable-next-line: max-line-length
        const crisisTasksOrdered = orderBy(
          this.data.crisis.tasks.filter(el => el.functionId === this.functionsUserForCrisisSelected),
          'order',
          'desc'
        );
        if (crisisTasksOrdered.length) {
          this.crisisTask.order = crisisTasksOrdered[0].order + 1;
        }
      }
    }
  }

  selectTaskCode(event): void {
    const bufferSelectedfunctionUserForCrisis = this.functionsUserForCrisis.find(el => el.functionId === event);

    this.crisisTask.code = bufferSelectedfunctionUserForCrisis.shortTitle;
    this.crisisTask.functionId = bufferSelectedfunctionUserForCrisis.functionId;
    const crisisFunctionOrdered = orderBy(
      this.data.crisis.tasks.filter(el => el.functionId === bufferSelectedfunctionUserForCrisis.functionId),
      'order',
      'desc'
    );
    this.crisisTask.order = crisisFunctionOrdered[0] && crisisFunctionOrdered[0].order >= 0 ? crisisFunctionOrdered[0].order + 1 : 1;
  }

  saveCrisisTask(): void {
    this.crisisTask.isOnDashboard = this.crisisTask.outputDataLabel && this.crisisTask.outputDataLabel.trim().length > 0 ? true : false;
    if (this.notifyFunction.sendByMail || this.notifyFunction.sendBySms) {
      this.notifyFunction.functionTitle = this.functionsUserForCrisis.find(fn => fn.functionId === this.crisisTask.functionId).title;
    }
    if (this.temporarySave) {
      this.dialogRef.close({
        crisisTask: { ...this.crisisTask },
        notifications: [],
        notifyFunction: this.notifyFunction.sendByMail || this.notifyFunction.sendBySms ? this.notifyFunction : undefined,
        crisisObjectId: this.crisis.objectId,
        historyLogComment: 'CREATE_TASK',
      });
    } else {
      this.tasksService.save(
        { ...this.crisisTask },
        {
          notifications: [],
          functionToNotify: this.notifyFunction.sendByMail || this.notifyFunction.sendBySms ? this.notifyFunction : undefined,
        },
        'CREATE_TASK'
      );
      this.dialogRef.close({});
    }
  }

  saveNextInfo(nextInfo: nextInfoType) {
    this.crisisTask.nextInfoTime = nextInfo.nextInfoTime;
  }

  saveNotifyFunction(notifyFunction: { sendByMail: boolean; sendBySms: boolean }) {
    this.notifyFunction = cloneDeep(notifyFunction);
  }
}
