'use strict';

var env = require('../environments/environment').environment;

Raven.config(env.sentryDSN, {
  environment: 'prod',
  autoBreadcrumbs: {
    console: false,
  },
}).install();

angular
  .module('gestiondecriseApp')

  .run(function ($rootScope, CONSTANTS, OptionsService, Raven) {
    $rootScope.version = 'current';
    $rootScope.companyName = CONSTANTS.COMPANY_NAME;

    Raven.setTagsContext({
      company: $rootScope.companyName,
      env: OptionsService.getEnv(),
    });

    Raven.setRelease('GestionDeCrise@' + $rootScope.version);
  })

  .config(function ($compileProvider, msdElasticConfig) {
    $compileProvider.debugInfoEnabled(!env.production); // https://medium.com/swlh/improving-angular-performance-with-1-line-of-code-a1fb814a6476#.bdmv9cz4t
    msdElasticConfig.append = '\n';
  })

  .run(function ($rootScope, $window, AuthenticationService, localStorage, $state) {
    var md = new MobileDetect(window.navigator.userAgent);
    $rootScope.isPhone = !!md.phone();
    document.body.classList.add($rootScope.isPhone ? 'hol-mobile' : 'hol-desktop');
    var loginOut = false;

    function logout() {
      if (!loginOut) {
        loginOut = true;
        if ($state.includes('app.occ.dashboard')) {
          console.log('save time');
          localStorage.setOccDashboardLastDisplayTime(new Date());
        }
        if ($state.includes('app.ecl.dashboard')) {
          console.log('save time');
          localStorage.setEclDashboardLastDisplayTime(new Date());
        }
        if ($state.includes('app.ops.dashboard')) {
          console.log('save time');
          localStorage.setOpsDashboardLastDisplayTime(new Date());
        }
        if ($state.includes('app.goc.dashboard')) {
          console.log('save time');
          localStorage.setGocDashboardLastDisplayTime(new Date());
        }
      }
    }

    $window.addEventListener('beforeunload', logout, false);
    $window.addEventListener('pagehide', logout, false);
  });

// angular.element(function() {
//   angular.bootstrap(document, ['gestiondecriseApp']);
// });
