<div class="common-infos">
  <div class="create-infos">
    <app-acl-indicator [object]="item"></app-acl-indicator>
    <div *ngIf="displayUser">
      <div class="date text-muted">{{ item.createdAt | time: isUtc }}</div>
      <div class="text-muted" [matTooltip]="userTooltipContent">
        <strong *ngIf="item.createdBy && item.createdBy.userMonogram; else updateByLine">{{ item.createdBy.userMonogram }} &nbsp; </strong>
        <ng-template #updateByLine
          ><strong>{{ item.updatedBy && item.updatedBy.userMonogram }} &nbsp; </strong></ng-template
        >
      </div>
    </div>
    <div>
      <span *ngIf="fromModule" class="status-icons">
        <img class="icon" src="/assets/images/icon_paperplan.svg" />
        <span>&nbsp;{{ fromModule }}</span>
      </span>
      <span *ngIf="toModule" class="status-icons">
        <span>{{ toModule }}&nbsp;</span>
        <img class="icon" src="/assets/images/icon_paperplan.svg" />
      </span>
    </div>
    <div>
      <ng-content select=".create-infos-addon"></ng-content>
    </div>
  </div>
  <div class="content">
    <ng-content select=".header-addon"></ng-content>
    <div class="content-text font14" [class.ellipsis]="!displayAllTextInItem && !moduleConfig.displayAllTextInItem">
      {{ item.contentText }}
    </div>
    <ng-content></ng-content>
    <div class="footer">
      <div class="col-next-info">
        <app-hol-next-info-display
          *ngIf="nextInfo"
          [nextInfo]="nextInfo"
          [isReadOnly]="isReadOnly"
          [canUpdate]="canUpdateItem"
          [isUtc]="isUtc"
          (saved)="markAsDone()"
        >
        </app-hol-next-info-display>
      </div>
      <div class="col-attachments">
        <app-file-and-image-icons-display *ngIf="attachments" [attachments]="attachments"></app-file-and-image-icons-display>
      </div>
    </div>
  </div>
</div>
