import { Inject, Injectable } from '@angular/core';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { OclLogBookGroup } from '../../models/ocl-logbook-group.model';
import { OclEvent } from '../../models/ocl-event.model';
import { OclDecisionGroup } from '../../models/ocl-decision-group.model';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { RequestService } from '../../../common/services/request.service';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';
import { OclGroupsStoreManager } from '../../store/groups/ocl-groups.store-manager';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';

@Injectable({
  providedIn: 'root',
})
export abstract class OclGroupService {
  // tslint:disable:variable-name
  protected ParseEventGroup;
  protected ParseDecisionGroup;
  protected ParseLogbookGroup;
  protected ParseGlobalInstructionGroup;
  protected ParseEvent;
  protected ParseDecisions;
  protected ParseLogbook;
  protected ParseGlobalInstruction;
  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    protected groupStoreManager: OclGroupsStoreManager,
    protected moduleConfig: ModuleConfigService
  ) {}

  save(group, type): Promise<any> {
    let parseGroup;
    if (type === 'EVENT') {
      parseGroup = new this.ParseEventGroup();
    }
    if (type === 'LOGBOOK') {
      parseGroup = new this.ParseLogbookGroup();
    }
    if (type === 'DECISION') {
      parseGroup = new this.ParseDecisionGroup();
    }
    if (type === 'GLOBAL_INSTRUCTION') {
      parseGroup = new this.ParseGlobalInstructionGroup();
    }

    if (group.objectId) {
      // updateMode
      parseGroup.id = group.objectId;
    }

    parseGroup.set('createdBy', Parse.User.current());
    parseGroup.set('title', group.title);

    const parseObjects = group.items.map(el => {
      if (type === 'EVENT') {
        return new this.ParseEvent({ id: el.objectId });
      }
      if (type === 'LOGBOOK') {
        return new this.ParseLogbook({ id: el.objectId });
      }
      if (type === 'DECISION') {
        return new this.ParseDecisions({ id: el.objectId });
      }
      if (type === 'GLOBAL_INSTRUCTION') {
        return new this.ParseGlobalInstruction({ id: el.objectId });
      }
    });

    if (type === 'EVENT') {
      parseGroup.set('events', parseObjects);
    }
    if (type === 'LOGBOOK') {
      parseGroup.set('logBooks', parseObjects);
    }
    if (type === 'DECISION') {
      parseGroup.set('decisions', parseObjects);
    }
    if (type === 'GLOBAL_INSTRUCTION') {
      parseGroup.set('globalInstructions', parseObjects);
    }

    return new Promise((resolve, reject) => {
      this.requestService.performSaveQuery(
        parseGroup,
        null,
        parseData => {
          if (type === 'EVENT') {
            resolve(new OclEventGroup(parseData));
          }
          if (type === 'LOGBOOK') {
            resolve(new OclLogBookGroup(parseData));
          }
          if (type === 'DECISION') {
            resolve(new OclDecisionGroup(parseData));
          }
          if (type === 'GLOBAL_INSTRUCTION') {
            resolve(new OclGlobalInstructionGroup(parseData));
          }
        },
        error => reject(error)
      );
    });
  }

  delete(group, type): Promise<void> {
    let parseGroup;
    if (type === 'EVENT') {
      parseGroup = new this.ParseEventGroup();
    }
    if (type === 'LOGBOOK') {
      parseGroup = new this.ParseLogbookGroup();
    }
    if (type === 'DECISION') {
      parseGroup = new this.ParseDecisionGroup();
    }
    if (type === 'GLOBAL_INSTRUCTION') {
      parseGroup = new this.ParseGlobalInstructionGroup();
    }
    parseGroup.id = group.objectId;

    return new Promise((resolve, reject) => {
      this.requestService.performDestroyQuery(
        parseGroup,
        () => {
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getAll(type: 'EVENT' | 'LOGBOOK' | 'DECISION' | 'GLOBAL_INSTRUCTION', isFromPolling = false): Promise<any[]> {
    let query: Parse.Query;
    if (type === 'EVENT') {
      query = new Parse.Query(this.ParseEventGroup);
    } else if (type === 'LOGBOOK') {
      query = new Parse.Query(this.ParseLogbookGroup);
    } else if (type === 'DECISION') {
      query = new Parse.Query(this.ParseDecisionGroup);
    } else if (type === 'GLOBAL_INSTRUCTION') {
      query = new Parse.Query(this.ParseGlobalInstructionGroup);
    }
    query.descending('createdAt');

    return this.requestService.performFindQuery(query).then(results => {
      if (type === 'EVENT') {
        const groups = results.map(result => new OclEventGroup(result));
        if (isFromPolling) {
          this.groupStoreManager.updateGroupsFromPooling(groups, 'events', this.moduleConfig.config.moduleName);
        } else {
          this.groupStoreManager.initGroups(groups, 'events');
        }
        return groups;
      } else if (type === 'LOGBOOK') {
        const groups = results.map(result => new OclLogBookGroup(result));
        if (isFromPolling) {
          this.groupStoreManager.updateGroupsFromPooling(groups, 'logbooks', this.moduleConfig.config.moduleName);
        } else {
          this.groupStoreManager.initGroups(groups, 'logbooks');
        }
        return groups;
      } else if (type === 'DECISION') {
        const groups = results.map(result => new OclDecisionGroup(result));
        if (isFromPolling) {
          this.groupStoreManager.updateGroupsFromPooling(groups, 'decisions', this.moduleConfig.config.moduleName);
        } else {
          this.groupStoreManager.initGroups(groups, 'decisions');
        }
        return groups;
      } else if (type === 'GLOBAL_INSTRUCTION') {
        const groups = results.map(result => new OclGlobalInstructionGroup(result));
        if (isFromPolling) {
          this.groupStoreManager.updateGroupsFromPooling(groups, 'globalInstructions', this.moduleConfig.config.moduleName);
        } else {
          this.groupStoreManager.initGroups(groups, 'globalInstructions');
        }
        return groups;
      }
    });
  }

  generateGroupAvailable(group, data): any[] {
    // je retire les items non affichés sur le dashboard et je map le reste
    return group.filter(grp => {
      grp.items = grp.items
        .filter(itemInGroup => {
          return data.findIndex(el => el.objectId === itemInGroup.objectId) !== -1;
        })
        .map(itemInGroup => data.find((el: OclEvent | OclLogbook | OclDecision) => el.objectId === itemInGroup.objectId));
      return grp.items.length;
    });
  }

  generateGroupForDisplay(group, data, type): any[] {
    let dataGrouped: any[] = [];

    dataGrouped = [
      ...data
        .map(el => {
          // tslint:disable-next-line: max-line-length
          //  si un item n'est pas présent dans un group alors il sera taggé avec ALWAYS_VISIBLE car on pourra le voir dans les 2 vue (group et non group) et n'aura pas de group assigné
          // tslint:disable-next-line: max-line-length
          // si un item est présent dans un group alors il sera taggé avec NOT_VISIBLE_IN_GROUP_VIEW car on ne pourra le voir que si on est en vue non group et n'aura pas de group assigné
          const itemNotFound = group.every(grp => {
            return grp.items.findIndex(itemInGroup => itemInGroup.objectId === el.objectId) === -1;
          });
          let bufferGroup;
          if (type === 'EVENT') {
            bufferGroup = new OclEventGroup();
          }
          if (type === 'LOGBOOK') {
            bufferGroup = new OclLogBookGroup();
          }
          if (type === 'DECISION') {
            bufferGroup = new OclDecisionGroup();
          }
          if (type === 'GLOBAL_INSTRUCTION') {
            bufferGroup = new OclGlobalInstructionGroup();
          }

          bufferGroup.displayInGroupViewStatus = !itemNotFound ? 'NOT_VISIBLE_IN_GROUP_VIEW' : 'ALWAYS_VISIBLE';
          bufferGroup.items = [el];
          return bufferGroup;
        })
        .filter(el => el !== undefined),
      ...group,
    ];

    return dataGrouped;
  }

  fetchNewDataEventGroup() {
    return this.getAll('EVENT', true);
  }

  fetchNewDataLogBookGroup() {
    return this.getAll('LOGBOOK', true);
  }

  fetchNewDataDecisionGroup() {
    return this.getAll('DECISION', true);
  }

  fetchNewDataGlobalInstructionGroup() {
    return this.getAll('GLOBAL_INSTRUCTION', true);
  }
}
