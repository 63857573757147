import { Inject, Injectable } from '@angular/core';
import { FltApplicability } from './../models/flt-applicability';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class FltApplicabilityService {
  constructor(@Inject('$translate') protected $translate: any) {}

  public applicabilityHistoryTxt(applicability: FltApplicability, applicabilityDate: string) {
    const dateFormat = 'DD/MM/YY HH:mm[Z]';

    let direction: string;
    if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
      direction = this.$translate.instant('APPLICABILITY.FROM_DEPARTURE');
    } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
      direction = this.$translate.instant('APPLICABILITY.FROM_DESTINATION');
    } else {
      direction = this.$translate.instant('APPLICABILITY.FROM_DEPARTURE_AND_DESTINATION');
    }

    let validity = '';
    validity += applicability.validFrom
      ? ' ' + this.$translate.instant('APPLICABILITY.STARTING') + ' ' + moment.utc(applicability.validFrom).format(dateFormat)
      : '';
    validity += applicability.validTo
      ? ' ' + this.$translate.instant('APPLICABILITY.UNTIL') + ' ' + moment.utc(applicability.validTo).format(dateFormat)
      : ' ' + this.$translate.instant('APPLICABILITY.INDEFINITE_PERIODE');

    let txt: string;
    if (applicability.flights && applicability.flights.length > 0) {
      const flightSingPlur =
        applicability.flights.length === 1
          ? this.$translate.instant('APPLICABILITY.THIS_FLIGHT')
          : this.$translate.instant('APPLICABILITY.THOSE_FLIGHTS');
      txt = this.$translate.instant('APPLICABILITY.APPLICABLE') + ' ' + direction + ' ' + flightSingPlur;
      applicability.flights.forEach(flight => {
        txt +=
          ' ' +
          flight.flightNumber +
          '/' +
          moment(flight.std).format('DDMMM').toUpperCase() +
          ' ' +
          flight.departure +
          ' -> ' +
          flight.destination;
      });
    }
    if (applicability.flightsNumbers && applicability.flightsNumbers.length > 0) {
      txt =
        this.$translate.instant('APPLICABILITY.APPLICABLE') +
        ' ' +
        direction +
        ' ' +
        this.$translate.instant('APPLICABILITY.THOSE_FLIGHTS') +
        ' ' +
        applicability.flightsNumbers.toString() +
        ' ' +
        validity;
    }
    if (applicability.stations && applicability.stations.length > 0) {
      const stationSingPlur =
        applicability.stations.length === 1
          ? this.$translate.instant('APPLICABILITY.THIS_STATION')
          : this.$translate.instant('APPLICABILITY.THOSE_STATIONS');
      txt =
        this.$translate.instant('APPLICABILITY.APPLICABLE') +
        ' ' +
        this.$translate.instant('APPLICABILITY.TO_FLIGHTS') +
        ' ' +
        direction +
        ' ' +
        stationSingPlur +
        ' ' +
        applicability.stations.toString() +
        ' ' +
        validity;
    }
    if (applicability.inOutStations && applicability.inOutStations.length > 0) {
      txt = this.$translate.instant('APPLICABILITY.APPLICABLE') + ' ' + this.$translate.instant('APPLICABILITY.TO_FLIGHTS');
      applicability.inOutStations.forEach(inOutStation => {
        txt += ' ' + inOutStation[0] + ' -> ' + inOutStation[1];
      });
      txt += ' ' + validity;
    }

    if (!txt) {
      txt = this.$translate.instant('APPLICABILITY.CANCELLED_APPLICABILITY');
    }

    return ' ' + '\n' + '**' + this.$translate.instant('APPLICABILITY.TITLE') + ' ' + applicabilityDate + ':** ' + '\n' + txt + ' ' + '\n';
  }
}
