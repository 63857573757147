import { Store } from '@ngrx/store';
import { Inject, Injectable } from '@angular/core';
import { HolManagersSelectors } from '../../common/store/hol-managers.selectors';
import { AppState } from '../../store';
import { RolesService } from '../../common/services/roles.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { HolManagerUserService } from '../../common/services/hol-manager-user.service';
import { HolOptionsService } from '../../common/services/hol-options.service';
import { CrewModuleConfigService } from '../services/crew-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class CrewManagersSelectors extends HolManagersSelectors {
  protected constructor(
    protected store: Store<AppState>,
    @Inject('UserService') protected userService,
    protected holManagerUserService: HolManagerUserService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected holOptionsService: HolOptionsService,
    protected moduleConfig: CrewModuleConfigService
  ) {
    super(store, userService, holManagerUserService, rolesService, commonStoreManager, holOptionsService, moduleConfig);
  }
}
