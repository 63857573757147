<div class="hol-dialog-wrapper" style="position: relative;">
  <mat-toolbar>
    <div mat-dialog-title>
      {{ titleTranslateKey | translate }}
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <mat-form-field>
      <input type="text" matInput placeholder="{{ placeholderTranslateKey | translate }}" [(ngModel)]="tmpFile.title" />
    </mat-form-field>

    <!-- Link -->
    <div *ngIf="type === 'link'">
      <mat-form-field>
        <input
          type="url"
          pattern="^(https://|http://).*"
          matInput
          placeholder="{{ 'COMMON.LINK_PLACEHOLDER' | translate }}"
          [(ngModel)]="tmpFile.url"
          (ngModelChange)="saveButtonIsDisable = !(tmpFile.url?.length > 0)"
        />
        <span class="error"></span>
      </mat-form-field>
    </div>
    <!-- // Link -->

    <!-- File -->
    <div *ngIf="type === 'file'">
      <app-file-upload
        [column]="column"
        [disabled]="disabled"
        (onUploadSuccess)="onUploadFileSuccess($event)"
        [attachment]="tmpFile"
        [icon]="'hol-icon-file'"
        [label]="'COMMON.FILE' | translate"
      >
      </app-file-upload>
    </div>
    <!-- // Files -->
    <!-- File -->
    <div *ngIf="type === 'files'">
      <app-file-upload
        [column]="column"
        [disabled]="disabled"
        (onUploadSuccess)="onUploadFileSuccess($event)"
        [attachment]=""
        [icon]="'hol-icon-file'"
        [label]="'COMMON.FILE' | translate"
      >
      </app-file-upload>
    </div>
    <!-- // Files -->
    <!-- Image -->
    <div *ngIf="type === 'image'">
      <app-file-upload
        [column]="column"
        [disabled]="disabled"
        (onUploadSuccess)="onUploadFileSuccess($event)"
        [attachment]="tmpFile"
        [accept]="'image/x-png,image/gif,image/jpeg'"
        [icon]="'hol-icon-picture'"
        [label]="'COMMON.IMAGE' | translate"
      >
      </app-file-upload>
    </div>
    <!-- // Image -->
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button (click)="closeModal()" color="primary" mat-stroked-button>
      {{ 'COMMON.CANCEL' | translate | uppercase }}
    </button>
    <button [disabled]="saveButtonIsDisable" (click)="saveAttachment()" color="primary" mat-flat-button>
      <span *ngIf="isEdition">{{ 'COMMON.EDIT' | translate | uppercase }}</span>
      <span *ngIf="!isEdition">{{ 'COMMON.ADD' | translate | uppercase }}</span>
    </button>
  </mat-dialog-actions>
</div>
