import { ErpCrisisDecisionTaskToSave } from './../models/erp-crisisDecision';
import { ErpCrisis } from 'src/app/erp/models/erp-crisis';
import { ErpHistoryLog } from './../models/erp-historyLog';
import { ErpHistoryService } from './erp-history.service';
import { Injectable } from '@angular/core';
import { ErpCrisisTask } from '../models/erp-crisisTask';
import { ErpCrisisNew } from '../models/erp-crisisNew';
import { ErpCrisisType } from '../models/erp-crisisTypes';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { ErpCrisisData } from '../models/erp-crisisData';
import { ErpCrisisAnnouncement } from '../models/erp-crisisAnnouncement';
import { ErpCrisisNewsService } from './erp-crisis-news.service';
import { ErpCrisisService } from './erp-crisis.service';
import { ErpCrisisAnnouncementService } from './erp-crisis-announcement.service';
import { ErpCrisisDataService } from './erp-crisis-data.service';
import { ErpCrisisTaskService } from './erp-crisis-task.service';
import { ErpCrisisTypeService } from './erp-crisis-type.service';
import { ErpCrisisDecision } from '../models/erp-crisisDecision';
import { ErpCrisisDecisionService } from './erp-crisis-decision.service';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisApiService {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('GDCCrisis');
  ParseCrisisData = Parse.Object.extend('GDCCrisisData');
  ParseCrisisAnnouncement = Parse.Object.extend('GDCAnnouncement');
  ParseUser = Parse.Object.extend('_User');
  // tslint:enabled

  constructor(
    private erpCrisisNewsService: ErpCrisisNewsService,
    private erpCrisisService: ErpCrisisService,
    private erpCrisisDataService: ErpCrisisDataService,
    private erpCrisisTaskService: ErpCrisisTaskService,
    private erpCrisisTypeService: ErpCrisisTypeService,
    private erpCrisisAnnouncementService: ErpCrisisAnnouncementService,
    private erpCrisisDecisionService: ErpCrisisDecisionService,
    private erpHistoryService: ErpHistoryService
  ) {}

  getCurrentCrisis(): Promise<ErpCrisis> {
    return new Promise((resolve, reject) => {
      this.erpCrisisService.getCrisis().then(crisis => {
        if (crisis && crisis.objectId) {
          Promise.all([
            this.getCrisisNewsByCrisisObjectId(crisis.objectId),
            this.getCrisisTasksByCrisisTypeId(crisis.crisisTypeId),
            this.getCrisisTypebyCrisisTypeId(crisis.crisisTypeId),
            this.getCrisisDataByCrisisObjectId(crisis.objectId),
            this.getCrisisAnnouncementByCrisisObjectId(crisis.objectId),
            this.getCrisisDecisionByCrisisObjectId(crisis.objectId),
            this.getCrisisHistoryLogsByCrisisObjectId(crisis.objectId),
          ]).then(result => {
            crisis.news = result[0];
            crisis.tasks = result[1];
            crisis.type = result[2];
            crisis.data = result[3];
            crisis.announcements = result[4];
            crisis.decisions = result[5];
            crisis.historyLogs = result[6];
            resolve(crisis);
          });
        } else {
          reject('No crisis found');
        }
      }, reject);
    });
  }

  saveCrisis(crisis: Partial<ErpCrisis>): void {
    this.erpCrisisService.saveCrisis(crisis);
  }

  getCrisisNewsByCrisisObjectId(crisisObjectId): Promise<ErpCrisisNew[]> {
    return this.erpCrisisNewsService.getCrisisNewsByCrisisObjectId(crisisObjectId);
  }

  // tslint:disable-next-line: max-line-length
  saveCrisisNew(
    crisisNew: Partial<ErpCrisisNew>,
    crisisObjectId?: string,
    notifications: HolNotification[] = [],
    historyLogComment?: string,
    isArchiveAction: boolean = false,
    duplicateToOtherModule?: { newValue: boolean; oldValue: boolean }
  ): void {
    this.erpCrisisNewsService.saveCrisisNew(
      crisisNew,
      crisisObjectId,
      notifications,
      historyLogComment,
      isArchiveAction,
      duplicateToOtherModule
    );
  }

  getCrisisAnnouncementByCrisisObjectId(crisisObjectId): Promise<ErpCrisisAnnouncement[]> {
    return this.erpCrisisAnnouncementService.getCrisisAnnouncementByCrisisObjectId(crisisObjectId);
  }

  getCrisisDecisionByCrisisObjectId(crisisObjectId): Promise<ErpCrisisDecision[]> {
    return this.erpCrisisDecisionService.getCrisisDecisionByCrisisObjectId(crisisObjectId);
  }

  // tslint:disable-next-line: max-line-length
  saveCrisisAnnouncement(
    crisisAnnouncement: Partial<ErpCrisisAnnouncement>,
    crisisObjectId?: string,
    notifications: HolNotification[] = [],
    historyLogComment?: string,
    isArchiveAction: boolean = false
  ): void {
    this.erpCrisisAnnouncementService.saveCrisisAnnouncement(
      crisisAnnouncement,
      crisisObjectId,
      notifications,
      historyLogComment,
      isArchiveAction
    );
  }

  getCrisisDataByCrisisObjectId(crisisObjectId): Promise<ErpCrisisData[]> {
    return this.erpCrisisDataService.getCrisisDataByCrisisObjectId(crisisObjectId);
  }

  saveCrisisData(crisisData: Partial<ErpCrisisData>, crisisObjectId?): Promise<ErpCrisisData> {
    return this.erpCrisisDataService.saveCrisisData(crisisData, crisisObjectId);
  }

  // used for change the order in drag and drop or create data when a crisis is triggered, must be all the crisis data in the payload
  saveAllCrisisData(crisisDataArray: Partial<ErpCrisisData>[], crisisObjectId?, fromCrisisCreate = false): void {
    this.erpCrisisDataService.saveAllCrisisData(crisisDataArray, crisisObjectId, fromCrisisCreate);
  }

  deleteCrisisData(crisisDataObjectId: string): void {
    this.erpCrisisDataService.deleteCrisisData(crisisDataObjectId);
  }

  getCrisisTypebyCrisisTypeId(crisisTypeId): Promise<ErpCrisisType> {
    return this.erpCrisisTypeService.getCrisisTypebyCrisisTypeId(crisisTypeId);
  }

  getCrisisTasksByCrisisTypeId(crisisTypeId): Promise<ErpCrisisTask[]> {
    return this.erpCrisisTaskService.getCrisisTasksByCrisisTypeId(crisisTypeId);
  }

  saveCrisisDecision(
    crisisDecision: Partial<ErpCrisisDecision>,
    notifications: HolNotification[] = [],
    crisisObjectId: string,
    historyLogComment: string,
    decisionTasksToAdd: ErpCrisisDecisionTaskToSave[] = [],
    duplicateToOtherModule?: { newValue: boolean; oldValue: boolean }
  ): void {
    this.erpCrisisDecisionService.saveCrisisDecision(
      crisisDecision,
      notifications,
      crisisObjectId,
      historyLogComment,
      decisionTasksToAdd,
      duplicateToOtherModule
    );
  }

  removeCrisisTask(crisisTask: Partial<ErpCrisisTask>, crisisObjectId: string): void {
    this.erpCrisisTaskService.removeCrisisTask(crisisTask, crisisObjectId);
  }

  removeCrisisDecision(crisisDecision: Partial<ErpCrisisDecision>, crisisObjectId: string): void {
    this.erpCrisisDecisionService.removeCrisisDecision(crisisDecision, crisisObjectId);
  }

  getCrisisHistoryLogsByCrisisObjectId(crisisObjectId): Promise<ErpHistoryLog[]> {
    return this.erpHistoryService.getHistoryByCrisisObjectId(crisisObjectId);
  }

  deleteEclLogbook(crisisNew: ErpCrisisNew): void {
    this.erpCrisisNewsService.deleteEclLogbook(crisisNew);
  }

  deleteEclDecision(crisisDecision: ErpCrisisDecision): void {
    this.erpCrisisDecisionService.deleteEclDecision(crisisDecision);
  }
}
