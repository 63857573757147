import { Pipe, PipeTransform } from '@angular/core';
import { HolObject } from '../../models/hol-object';
import { OclGroup } from '../../../ocl/models/ocl-group.model';
import { GocStopover } from 'src/app/goc/models/goc-stopover.model';

@Pipe({
  name: 'aclGroupFilter',
})
export class AclGroupFilterPipe implements PipeTransform {
  transform(values: OclGroup<HolObject>[], companiesRolesFilter?: string[]): any {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      if (!companiesRolesFilter || !companiesRolesFilter.length) {
        return values;
      }
      return values.filter(o => {
        return o.items.find(item => {
          if (!item.companies || !item.companies.length) {
            return true;
          }
          return !!item.companies.find(c => {
            return companiesRolesFilter.indexOf(c) > -1;
          });
        });
      });
    }
    return null;
  }
}

@Pipe({
  name: 'aclStopoverFilter',
})
export class AclStopoverFilterPipe implements PipeTransform {
  transform(values: GocStopover[], companiesRolesFilter?: string[]): any {
    if (values && companiesRolesFilter && companiesRolesFilter.length) {
      if (!companiesRolesFilter || !companiesRolesFilter.length) {
        return values;
      }
      return values.filter(item => {
        if (!item.inFlight.companies || !item.inFlight.companies.length || !item.outFlight.companies || !item.outFlight.companies.length) {
          return true;
        }
        return (
          !!item.inFlight.companies.find(c => {
            return companiesRolesFilter.indexOf(c) > -1;
          }) &&
          !!item.outFlight.companies.find(c => {
            return companiesRolesFilter.indexOf(c) > -1;
          })
        );
      });
    }
    return null;
  }
}

@Pipe({
  name: 'aclFilter',
})
export class AclFilterPipe implements PipeTransform {
  transform(values: HolObject[], companiesRolesFilter?: string[]): any {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      if (!companiesRolesFilter || !companiesRolesFilter.length) {
        return values;
      }
      return values.filter(item => {
        if (!item.companies || !item.companies.length) {
          return true;
        }
        return !!item.companies.find(c => {
          return companiesRolesFilter.indexOf(c) > -1;
        });
      });
    }
    return null;
  }
}
