import { ModuleConfigService } from './../../../common/services/module-config/module-config.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ErpCrisis } from '../../models/erp-crisis';
import { ErpCrisisType } from '../../models/erp-crisisTypes';
import { ErpCrisisTypeService } from '../../services/erp-crisis-type.service';
import { ErpCrisisService } from '../../services/erp-crisis.service';
import { OptionsService } from '../../../common/services/options.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../../../common/modals/confirmation-modal/confirmation-modal.component';
import { AlertModalComponent } from '../../../common/modals/alert-modal/alert-modal.component';
import { UTC_ZONES } from '../../constants/utcZones.const';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { cloneDeep } from 'lodash';
import { ErpModuleConfigService } from '../../services/erp-module-config.service';

@Component({
  selector: 'app-erp-crisis-modal',
  templateUrl: './erp-crisis-modal.component.html',
  styleUrls: ['./erp-crisis-modal.component.scss'],
  providers: [
    {
      provide: ModuleConfigService,
      useExisting: ErpModuleConfigService,
    },
  ],
})
export class ErpCrisisModalComponent implements OnInit {
  crisis: ErpCrisis;
  crisisTypeList: ErpCrisisType[];
  form: FormGroup;
  utcZones = UTC_ZONES;
  canActivateCrisis: boolean;
  isCreate = true;

  constructor(
    @Inject('$translate') private $translate,
    @Inject('$state') private $state,
    @Inject(MAT_DIALOG_DATA) private data,
    public moduleConfig: ModuleConfigService,
    private dialogRef: MatDialogRef<ErpCrisisModalComponent>,
    private erpCrisisTypeService: ErpCrisisTypeService,
    private erpCrisisService: ErpCrisisService,
    private commonStoreManager: CommonStoreManager,
    private optionsService: OptionsService,
    private dialog: MatDialog
  ) {
    this.crisis = cloneDeep(data.crisis) || { ...new ErpCrisis(), contentText: '' };
    this.isCreate = !data.crisis;
  }

  ngOnInit() {
    this.form = new FormGroup({
      mainTitle: new FormControl(this.crisis.mainTitle || '', [Validators.required]),
      crisisType: new FormControl(null, [Validators.required]),
      exercice: new FormControl(this.crisis.isTraining !== undefined ? this.crisis.isTraining : null, [Validators.required]),
      info1: new FormControl('', [Validators.required, Validators.maxLength(12)]),
      info2: new FormControl('', [Validators.required, Validators.maxLength(12)]),
      info3: new FormControl('', [Validators.required, Validators.maxLength(12)]),
      info4: new FormControl('', [Validators.required, Validators.maxLength(12)]),
      subTitle: new FormControl(this.crisis.subTitle || '', [Validators.required]),
      clockDeparture: new FormControl(this.crisis.clockDeparture || ''),
      clockArrival: new FormControl(this.crisis.clockArrival || ''),
      clockOnSite: new FormControl(this.crisis.clockOnSite || ''),
    });
    this.erpCrisisTypeService.getAll().then(types => {
      if (!this.isCreate) {
        this.form.disable();
      }
      this.crisisTypeList = types;
      if (this.crisis.type && types) {
        const bufferType = types.find(t => t.crisisTypeId === this.crisis.type.crisisTypeId);
        if (bufferType) {
          this.form.get('crisisType').setValue(bufferType);
          this.setInfo(1, bufferType);
          this.setInfo(2, bufferType);
          this.setInfo(3, bufferType);
          this.setInfo(4, bufferType);
        }
      }
    });
    this.commonStoreManager.currentUserCrisisRolesRef.subscribe(roles => {
      this.canActivateCrisis =
        roles.isInCrisisDirectorTeam || (roles.isInCrisisInitializerTeam && this.optionsService.crisisInitializerCanActivate());
    });
  }

  saveCrisis(activate: boolean) {
    if (!this.isCreate) {
      this.erpCrisisService.updateCrisisAcl(this.crisis).then(() => {
        this.$state.go('app.crisis.dashboard', {}, { reload: true });
        this.dialogRef.close();
      });
      return;
    }
    let confirmTitle;
    let confirmContent;
    let confirmQuestion;
    this.crisis.data = [];
    this.crisis.mainTitle = this.form.get('mainTitle').value;
    this.crisis.subTitle = this.form.get('subTitle').value;
    this.crisis.isTraining = this.form.get('exercice').value;
    if (activate) {
      confirmTitle = this.$translate.instant(`ERP.MODALS.CREATE_${this.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.TITLE`);
      confirmContent = this.$translate.instant(`ERP.MODALS.CREATE_${this.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.CONTENT`);
      confirmQuestion = this.$translate.instant(`ERP.MODALS.CREATE_${this.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.QUESTION`);
      this.crisis.isInPreparation = false;
      this.crisis.inProgress = true;
    } else {
      confirmTitle = this.$translate.instant(`ERP.MODALS.PREPARE_${this.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.TITLE`);
      confirmContent = this.$translate.instant(`ERP.MODALS.PREPARE_${this.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.CONTENT`);
      confirmQuestion = this.$translate.instant(`ERP.MODALS.PREPARE_${this.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.QUESTION`);
      this.crisis.isInPreparation = true;
      this.crisis.inProgress = false;
    }

    this.crisis.crisisTypeId = this.form.get('crisisType').value.crisisTypeId;
    this.crisis.data = [
      {
        crisisObjectId: null,
        title: this.form.get('crisisType').value.info1Title,
        value: this.form.get('info1').value,
        order: 1,
        isDefault: true,
      },
      {
        crisisObjectId: null,
        title: this.form.get('crisisType').value.info2Title,
        value: this.form.get('info2').value,
        order: 2,
        isDefault: true,
      },
      {
        crisisObjectId: null,
        title: this.form.get('crisisType').value.info3Title,
        value: this.form.get('info3').value,
        order: 3,
        isDefault: true,
      },
      {
        crisisObjectId: null,
        title: this.form.get('crisisType').value.info4Title,
        value: this.form.get('info4').value,
        order: 4,
        isDefault: true,
      },
    ];
    this.crisis.clockDeparture = this.form.get('clockDeparture').value;
    this.crisis.clockArrival = this.form.get('clockArrival').value;
    this.crisis.clockOnSite = this.form.get('clockOnSite').value;
    this.crisis.type = this.form.get('crisisType').value;
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: confirmTitle,
          modalContent: confirmContent,
          modalQuestion: confirmQuestion,
        },
      })
      .afterClosed()
      .toPromise()
      .then(res => {
        if (res) {
          const progressModal = this.dialog.open(AlertModalComponent, {
            data: {
              modalTitle: this.$translate.instant('ERP.MODALS.CRISIS.CREATION_PROGRESS'),
              modalContent: this.$translate.instant('ERP.MODALS.CRISIS.CREATION_WARN') + '<br/>',
            },
          });
          return this.erpCrisisService.create(this.crisis).subscribe(
            s => {
              progressModal.componentInstance.modalContent += s;
            },
            e => {
              progressModal.componentInstance.modalContent += '❌ ' + JSON.stringify(e);
            },
            () => {
              this.$state.go('app.crisis.dashboard', {}, { reload: true });
              setTimeout(() => {
                this.dialogRef.close();
                progressModal.close();
              }, 1500);
            }
          );
        }
      });
  }

  private setInfo(infoIndex: number, crisisType: ErpCrisisType) {
    const data = this.crisis.data.find(d => d.title === crisisType[`info${infoIndex}Title`]);
    if (data) {
      this.form.get(`info${infoIndex}`).setValue(data.value);
    }
  }
}
