import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { OclScenarioService } from '../../services/ocl-scenario-service/ocl-scenario.service';
import { OclEventService } from '../../services/ocl-event-service/ocl-event.service';
import { OclHistoryService } from '../../services/ocl-history-service/ocl-history.service';

@Component({
  selector: 'app-ocl-timeline-search-modal',
  templateUrl: './ocl-timeline-search-modal.component.html',
  styleUrls: ['./ocl-timeline-search-modal.component.scss'],
  providers: [TranslatePipe],
})
export class OclTimelineSearchModalComponent implements OnInit {
  form: FormGroup;
  archivedEvents: boolean;
  isReadOnly: boolean;
  searchLaunched: boolean;
  events = [];
  scenarios = [];
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('UserService') private userService,
    @Inject('$mdDialog') private $mdDialog,
    private notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<OclTimelineSearchModalComponent>,
    private scenarioService: OclScenarioService,
    private fb: FormBuilder,
    private historyService: OclHistoryService,
    private eventService: OclEventService
  ) {
    this.archivedEvents = false;
    this.searchLaunched = false;
    this.createForm();
  }

  get description() {
    return this.form.get('description') as FormControl;
  }

  get scenario() {
    return this.form.get('scenario') as FormControl;
  }

  createForm() {
    this.form = this.fb.group({
      scenario: [undefined, undefined],
      description: [undefined],
    });
  }

  ngOnInit(): void {
    this.scenarioService.getAll().then(scenarios => {
      this.scenarios = scenarios;
    });
  }

  onEventUpdated(event) {
    this.search();
    this.historyService.fetchNewData();
  }

  search() {
    this.events = [];
    this.searchLaunched = true;
    this.loading = true;
    const searchedData = {
      scenario: this.form.value.scenario,
      description: this.form.value.description,
      archived: this.archivedEvents,
    };
    this.eventService.getByData(searchedData).then(results => {
      this.loading = false;
      this.events = results;
    });
  }
}
