import { ErpHistoryLog } from './erp-historyLog';
import { HolUser, HolUserWithCompanies } from 'src/app/common/models/hol-user.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ErpFunctionCategory } from './erp-functionCategory';
import { ErpCrisisDecision } from './erp-crisisDecision';
import { HolTask } from '../../common/models/hol-task';

export class ErpCrisisTask extends HolTask {
  outputDataLabel: string;
  outputDataHint?: string;
  customOutputDataLabel?: string;
  keywords?: string[];
  isOnDashboard?: boolean;
  defaultIsOnDashboard?: boolean;
  customIsOnDashboard?: boolean;
  crisisTypeId: string;
  isTemporary?: boolean;
  isDocumentOnly?: boolean;
  visibleByGroup?: boolean;
  customVisibleByGroup?: boolean;
  categoryTagId?: string;
  isArchived?: boolean;
  decision?: ErpCrisisDecision;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]) {
    super(parseObject, tags, defaultTags);
    if (!parseObject) {
      return;
    }

    this.outputDataLabel = parseObject.get('outputDataLabel') || parseObject.get('customOutputDataLabel'); // 'Libellé de la valeur de sortie de la tâche. Ex : "Nom du CEO :"',
    this.customOutputDataLabel = parseObject.get('customOutputDataLabel');
    this.isTemporary = parseObject.get('isTemporary');
    this.outputDataHint = parseObject.get('outputDataHint'); // " Texte utilisé dans le placeholder lorsqu'un outputDataLabel est requis. ",
    this.keywords = parseObject.get('keywords');
    this.isOnDashboard = parseObject.get('isOnDashboard') || parseObject.get('customIsOnDashboard');
    this.customIsOnDashboard = parseObject.get('customIsOnDashboard');
    this.defaultIsOnDashboard = parseObject.get('isOnDashboard');
    this.crisisTypeId = parseObject.get('crisisTypeId');
    this.updatedBy = new HolUser(parseObject.get('updatedBy'));
    this.isDocumentOnly = parseObject.get('isDocumentOnly');
    // this.visibilityByFunctionId = parseObject.get('visibilityByFunctionId') || [];
    // this.createdByFunctionId = parseObject.get('createdByFunctionId') || [];
    this.isArchived = parseObject.get('isArchived');
    this.visibleByGroup = parseObject.get('visibleByGroup');
    this.customVisibleByGroup = parseObject.get('customVisibleByGroup');
    if (parseObject.get('decision')) {
      this.decision = new ErpCrisisDecision(parseObject.get('decision'));
    }
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('isDocumentOnly')
      ? parseObject.get('outputDataLabel') || parseObject.get('customOutputDataLabel')
      : parseObject.get('taskTitle');
  }

  toLog?(action: string, parseObject: Parse.Object, crisisObjectId: string): ErpHistoryLog {
    const log = new ErpHistoryLog();
    log.type = this.isDocumentOnly ? 'crisisDocument' : 'crisisTask';
    log.subType = action;
    log.jsonObject = this;
    log.acl = this.acl;
    log.crisisTask = parseObject;
    log.crisis = new Parse.Object('GDCCrisis', { id: crisisObjectId });
    return log;
  }
}

export class ErpCrisisDocument extends ErpCrisisTask {
  constructor(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]) {
    super(parseObject, tags, defaultTags);
  }
  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('outputDataLabel') || parseObject.get('customOutputDataLabel');
  }
}

export interface ErpCrisisTaskStream {
  shortTitle: string;
  title: string;
  hasHolder: boolean;
  holder: HolUserWithCompanies;
  tasksDetails: {
    total: number;
    done: number;
    frozen: number;
    notApplicable: number;
    todo: number;
    completionRate: number;
  };
}

export interface ErpCrisisUserTask {
  functionInfos: FunctionInfos[];
  tasks: ErpCrisisTask[];
}

interface FunctionInfos {
  shortTitle: string;
  title: string;
  isHolder: boolean;
  functionId: string;
}

export interface ErpCrisisDocumentByCategory {
  category: ErpFunctionCategory;
  documents: ErpCrisisDocument[];
}
