<app-loading *ngIf="loading" class="full-loader"></app-loading>

<button
  class="md-raised hol-invert md-button md-ink-ripple add-button"
  type="button"
  aria-label="Add event"
  *ngIf="!isReadOnly && !isClosedEvents"
  (click)="openProperEventModal($event, null)"
>
  <i class="fa fa-plus"></i> <strong>{{ 'CREW.DASHBOARD.EVENTS.ADD' | translate }}</strong>
</button>

<label *ngIf="!isClosedEvents" class="current-label">{{ 'CREW.DASHBOARD.EVENTS.ACTIVE_EVENTS_TITLE' | translate }}</label>
<label *ngIf="isClosedEvents" class="closed-label">{{ 'CREW.DASHBOARD.EVENTS.CLOSED_EVENTS_TITLE' | translate }}</label>

<div class="hol-box__content">
  <div
    class="text-muted hol-centered-line hol-margin"
    *ngIf="!loading && !(events | callback: isInFunction | callback: matchIsClosed).length"
  >
    <span>Aucun évènement ne correspond aux filtres actuels</span>
  </div>

  <div class="iscroll-wrapper" iscroll>
    <div class="iscroll-scroller">
      <ul class="hol-list">
        <li
          *ngFor="let group of groupedEvents | callback: matchSearchFilter; trackBy: trackByFunction"
          class="hol-clickable hol-animate-enter-left hol-animate-leave-right"
        >
          <app-crew-event-group-item [groupedEvents]="group"></app-crew-event-group-item>
        </li>
      </ul>
    </div>
  </div>
</div>
