import { OptionsService } from '../../common/services/options.service';
import { Inject, Injectable } from '@angular/core';
import { HolUser, HolUserWithCompanies } from '../../common/models/hol-user.model';
import { RequestService } from '../../common/services/request.service';
import { FunctionsStoreManager } from '../store/functions/functions.store-manager';
import { RolesService } from '../../common/services/roles.service';
import { environment } from '@env/environment';
import { HolRole } from '../../common/models/hol-role';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CrisisStoreManager } from '../store/crisis/crisis.store-manager';
import { ErpFunctionUser } from '../models/erp-functionUser';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { ErpFunctionCrisis } from '../models/erp-functionCrisis';
import { HelperService } from '../../common/services/helper.service';
import { ErpCrisis } from '../models/erp-crisis';

export interface HolUserWithFunctions {
  fullName: string;
  email: string;
  phone: string;
  userId: string;
  functions: {
    company: string;
    functionId: string;
    title: string;
    shortTitle: string;
    tasksSummary: string;
    otherUsers: string[];
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class ErpUsersService {
  // tslint:disable:variable-name
  ParseFunctionUser = Parse.Object.extend('GDCUserFunction');

  // tslint:enabled
  constructor(
    private requestService: RequestService,
    private functionsStoreManager: FunctionsStoreManager,
    private crisisStoreManager: CrisisStoreManager,
    private commonStoreManager: CommonStoreManager,
    private rolesService: RolesService,
    private optionsService: OptionsService,
    private helperService: HelperService,
    @Inject('MailSenderService') private mailSenderService,
    @Inject('SmsSenderService') private smsSenderService,
    @Inject('CONSTANTS') private CONSTANTS
  ) {}

  public static getUsersWithFunctions(
    usersToNotify: HolUserWithCompanies[],
    allUsers: HolUserWithCompanies[],
    allUserFunctions: ErpFunctionUser[],
    allFunctions: ErpFunctionCrisis[]
  ): HolUserWithFunctions[] {
    return usersToNotify.map(user => {
      const newUser: HolUserWithFunctions = {
        userId: user.userId,
        fullName: user.fullName,
        email: user.email,
        phone: user.phone,
        functions: [],
      };
      const userFunctions = allUserFunctions.filter(uf => uf.userId === user.userId);
      userFunctions.forEach(userFunction => {
        const ufCompany = userFunction.companies[0] || '';
        const func = allFunctions.find(f => f.functionId === userFunction.functionId && f.companies.includes(ufCompany));
        if (func) {
          const ufsForFunction = allUserFunctions.filter(uf => uf.functionId === func.functionId && uf.companies[0] === ufCompany);
          newUser.functions.push({
            company: ufCompany,
            functionId: func.functionId,
            title: func.title,
            shortTitle: func.shortTitle,
            tasksSummary: func.tasksSummary,
            otherUsers: allUsers
              .filter(
                u =>
                  u.userId !== user.userId &&
                  u.companies.find(c => c.name === ufCompany) &&
                  ufsForFunction.find(uf => uf.userId === u.userId)
              )
              .map(u => u.fullName),
          });
        }
      });
      return newUser;
    });
  }

  getUsersWithFunctionsForCrisis(
    crisis: ErpCrisis,
    functionsIdsToNotify?: string[],
    usersToNotify?: HolUser[],
    companies?: string[]
  ): Promise<{ users: HolUserWithFunctions[]; functionsIdsNotified: string[] }> {
    return combineLatest([
      this.functionsStoreManager.functionsCrisisErpState.pipe(filter(f => f && !!f.length)).pipe(take(1)),
      this.functionsStoreManager.functionsAllUserErpState.pipe(filter(fU => fU && !!fU.length)).pipe(take(1)),
      this.getUsersToSendMail(),
    ])
      .toPromise()
      .then(([functionsCrisis, functionsUser, allUsers]) => {
        companies = companies || this.helperService.parseACL(crisis.acl);
        functionsCrisis = functionsCrisis.filter(f => !!f.companies.find(c => companies.includes(c)));
        allUsers = allUsers.filter(user => !!user.companies.find(c => companies.includes(c.name)));
        functionsUser = functionsUser.filter(fu => !!fu.companies.find(c => companies.includes(c)));

        if (!functionsIdsToNotify && crisis.type) {
          functionsIdsToNotify = crisis.type.functionIdToNotify;
        }
        if (!usersToNotify) {
          if (functionsIdsToNotify && functionsIdsToNotify.length) {
            const usersIdToNotify = functionsUser.filter(f => functionsIdsToNotify.includes(f.functionId)).map(el => el.userId);
            usersToNotify = allUsers.filter(user => usersIdToNotify.findIndex(userId => userId === user.userId) !== -1);
          } else {
            functionsIdsToNotify = functionsCrisis.map(f => f.functionId);
            usersToNotify = allUsers;
          }
        }

        return {
          users: ErpUsersService.getUsersWithFunctions(usersToNotify, allUsers, functionsUser, functionsCrisis),
          functionsIdsNotified: functionsIdsToNotify,
        };
      });
  }

  getAllCrisisDirectorsForCrisis(crisis: ErpCrisis): Promise<HolUserWithCompanies[]> {
    return combineLatest([
      this.functionsStoreManager.functionsCrisisErpState.pipe(filter(f => f && !!f.length)).pipe(take(1)),
      this.functionsStoreManager.functionsAllUserErpState.pipe(filter(fU => fU && !!fU.length)).pipe(take(1)),
      this.functionsStoreManager.allUsers.pipe(filter(fU => fU && !!fU.length)).pipe(take(1)),
    ])
      .toPromise()
      .then(([functionsCrisis, allUsersFunctions, allUsers]) => {
        const companies = this.helperService.parseACL(crisis.acl);
        const crisisDirectorFunctionsIds = functionsCrisis
          .filter(f => this.optionsService.getCrisisDirectorShortTitlesList().includes(f.shortTitle))
          .map(f => f.functionId);
        const crisisDirectorsUserId = allUsersFunctions
          .filter(uf => crisisDirectorFunctionsIds.includes(uf.functionId))
          .map(uf => uf.userId);
        return allUsers.filter(u => !!u.companies.find(c => companies.includes(c.name)) && crisisDirectorsUserId.includes(u.userId));
      });
  }

  updateMember(user: HolUser): Promise<HolUser> {
    return this.requestService.performCloudCode('updateUser', user).then((parseUser: any) => {
      const newUser = new HolUser(parseUser);
      this.functionsStoreManager.updateOneUser(newUser);

      this.mailSenderService.sendMail(
        {
          recipients: [{ email: newUser.email }],
          subject: 'Account updated',
          contentHtml: [
            '<strong>Your account has been updated.</strong>',
            'Username : ' +
              newUser.email +
              '<br/>First name : ' +
              newUser.firstName +
              '<br/>Last name : ' +
              newUser.lastName +
              '<br/>Mail : ' +
              newUser.email +
              '<br/>Tel : ' +
              newUser.phone,
            'You can login at ' + location.origin,
          ].join('<br/><br/>'),
        },
        true
      );

      this.smsSenderService.sendSms(
        newUser.phone,
        [
          'Your account has been updated.',
          'Username : ' +
            newUser.email +
            '\nFirst name : ' +
            newUser.firstName +
            '\nLast name : ' +
            newUser.lastName +
            '\nMail : ' +
            newUser.email +
            '\nTel : ' +
            newUser.phone,
          'You can login at ' + location.origin,
        ].join('\n'),
        true
      );
      return newUser;
    });
  }

  createMember(userToCreate: Partial<HolUser>): Promise<HolUserWithCompanies> {
    const generatedPassword = (Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000).toString();

    const user = new Parse.User();
    user.set('username', userToCreate.email);
    user.set('password', generatedPassword);
    user.set('email', userToCreate.email);
    user.set('userEmail', userToCreate.email);
    user.set('firstName', userToCreate.firstName);
    user.set('lastName', userToCreate.lastName);
    user.set('phone', userToCreate.phone);
    user.set('userId', userToCreate.email);
    user.set('createdBy', Parse.User.current());

    return this.requestService
      .performSaveQuery(user)
      .then(parseUser => {
        const newUser = new HolUserWithCompanies(parseUser);
        newUser.companies = [];
        return this.tryAddUserToErpRole(newUser).then(roles => {
          roles.forEach(role => {
            const bufferUserComp = newUser.companies.find(el => role.company === el.name);
            if (!bufferUserComp) {
              newUser.companies.push({ name: role.company, read: role.read, write: role.write });
            } else {
              bufferUserComp.write = bufferUserComp.write || role.write;
            }
          });
          this.functionsStoreManager.addOneUser(newUser);
          return newUser;
        });
      })
      .then(newUser => {
        this.mailSenderService.sendMail(
          {
            recipients: [{ email: newUser.email }],
            subject: 'Account created',
            contentHtml: [
              '<strong>A new account has been created for you.</strong>',
              'Here are you credentials :<br/>' + 'Username : ' + newUser.email + '<br/>' + 'Access code : ' + generatedPassword,
              'You can login at ' + location.origin,
            ].join('<br/><br/>'),
          },
          true
        );
        this.smsSenderService.sendSms(
          newUser.phone,
          [
            'A new account has been created for you. Here are you credentials :',
            'Username : ' + newUser.email + '\n' + 'Access code : ' + generatedPassword,
            'You can login at ' + location.origin,
          ].join('\n'),
          true
        );
        return newUser;
      });
  }

  resetPassword(user: HolUser): Promise<HolUser> {
    return this.requestService.performCloudCode('resetPassword', {
      username: user.username,
      location: location.origin,
      sender: this.CONSTANTS.COMPANY_NAME,
    });
  }

  /**
   * Get all connected users with at least one ERP role
   */
  getAllConnectedUsers(): Promise<HolUserWithCompanies[]> {
    const date = new Date();
    date.setSeconds(date.getSeconds() - environment.userConnectedStatusMaxTime);
    return this.getErpRoles()
      .then(roles => {
        return Promise.all(
          roles.map(r => {
            return this.requestService.performFindAllQuery<Parse.User>(r.getUsers().query().greaterThan('lastSeenAt', date)).then(users => {
              return {
                role: r,
                users,
              };
            });
          })
        );
      })
      .then(rolesWithUsers => {
        return this.reduceRolesWithUsers(rolesWithUsers);
      });
  }

  /**
   * Get all users with at least one ERP role
   */
  getAll(): Promise<HolUserWithCompanies[]> {
    return this.getErpRoles()
      .then(roles => {
        return Promise.all(
          roles.map(r => {
            return this.requestService.performFindAllQuery<Parse.User>(r.getUsers().query()).then(users => {
              return {
                role: r,
                users,
              };
            });
          })
        );
      })
      .then(rolesWithUsers => {
        return this.reduceRolesWithUsers(rolesWithUsers);
      });
  }

  setOnDutyForAllFunctions(): Promise<ErpFunctionUser[]> {
    return combineLatest([
      this.functionsStoreManager.functionsAllUserErpState.pipe(take(1)),
      this.crisisStoreManager.crisisErpState.pipe(take(1)),
      this.commonStoreManager.currentUser.pipe(take(1)),
    ])
      .toPromise()
      .then(([allUserFunctions, crisis, currentUser]) => {
        if (crisis.companies) {
          const toUpdate = [];
          crisis.companies.forEach(c => {
            const userFunctionsForCompany = allUserFunctions.filter(uf => uf.companies.includes(c));
            const userFunctionsByFunction: { [key: string]: ErpFunctionUser[] } = userFunctionsForCompany.reduce((accu, next) => {
              if (accu[next.functionId]) {
                accu[next.functionId].push(next);
              } else {
                accu[next.functionId] = [next];
              }
              return accu;
            }, {});
            Object.values(userFunctionsByFunction).forEach(ufs => {
              const hasHolder = ufs.find(uf => uf.isHolder);
              if (!hasHolder) {
                const myUF = ufs.find(uf => uf.userId === currentUser.userId);
                if (myUF && !myUF.readOnly) {
                  toUpdate.push(new this.ParseFunctionUser({ id: myUF.objectId, isHolder: true }));
                }
              }
            });
          });
          return this.requestService.performSaveAllQuery(toUpdate).then(ufs => {
            const newUfs = ufs.map(uf => new ErpFunctionUser(uf));
            newUfs.forEach(uf => {
              this.functionsStoreManager.updateOneFunctionAllUser(uf);
            });
            return newUfs;
          });
        }
      });
  }

  resetOnDutyForAllFunctions(): Promise<ErpFunctionUser[]> {
    return combineLatest([
      this.functionsStoreManager.functionsAllUserErpState.pipe(take(1)),
      this.commonStoreManager.currentUser.pipe(take(1)),
    ])
      .toPromise()
      .then(([allUserFunctions, currentUser]) => {
        if (allUserFunctions && currentUser) {
          const toUpdate = allUserFunctions
            .filter(uf => uf.isHolder && uf.userId === currentUser.userId)
            .map(uf => {
              return new this.ParseFunctionUser({ id: uf.objectId, isHolder: false });
            });
          return this.requestService.performSaveAllQuery(toUpdate).then(ufs => {
            const newUfs = ufs.map(uf => new ErpFunctionUser(uf));
            newUfs.forEach(uf => {
              this.functionsStoreManager.updateOneFunctionAllUser(uf);
            });
            return newUfs;
          });
        }
      });
  }

  getUsersToSendMail() {
    if (this.optionsService.getEnv() === 'sandbox') {
      return this.getAllConnectedUsers();
    } else {
      return this.getAll();
    }
  }

  private getErpRoles() {
    const rolesQuery = new Parse.Query(Parse.Role);
    rolesQuery.startsWith('name', 'ERP_');
    return this.requestService.performFindQuery<Parse.Role>(rolesQuery);
  }

  private reduceRolesWithUsers(rolesWithUsers: { role: Parse.Role; users: Parse.User[] }[]): HolUserWithCompanies[] {
    const userMap: { [id: string]: HolUserWithCompanies } = {};
    rolesWithUsers.forEach(rwu => {
      const role = new HolRole(rwu.role);
      rwu.users.forEach(u => {
        if (userMap[u.id]) {
          const bufferUserComp = userMap[u.id].companies.find(el => role.company === el.name);
          if (!bufferUserComp) {
            userMap[u.id].companies.push({ name: role.company, read: role.read, write: role.write });
          } else {
            bufferUserComp.write = bufferUserComp.write || role.write;
          }
        } else {
          const user = new HolUserWithCompanies(u);
          user.companies = [{ name: role.company, read: role.read, write: role.write }];
          userMap[u.id] = user;
        }
      });
    });
    return Object.values(userMap);
  }

  private tryAddUserToErpRole(user: HolUser): Promise<HolRole[]> {
    let roles: HolRole[];
    return this.rolesService
      .getAll()
      .then(allRoles => {
        roles = allRoles.filter(r => this.rolesService.$companiesRolesFilter.value.includes(r.company) && r.universe === 'ERP' && r.write);
        return roles.map(r => {
          return this.rolesService.addUsers([user], r);
        });
      })
      .then(() => {
        return roles;
      })
      .catch(err => {
        console.warn('can not create roles', err);
        return [];
      });
  }
}
