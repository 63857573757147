import { HolFilterPanelComponent } from './components/hol-filter-panel/hol-filter-panel.component';
import { HolNextInfoDisplayComponent } from './components/hol-next-info-display/hol-next-info-display.component';
import { HolNextInfoComponent } from './components/hol-next-info/hol-next-info.component';
import { HolModalComponent } from './modals/hol-modal/hol-modal.component';
import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { TimePipe } from './pipes/time/time.pipe';
import { FileAndImageDisplayComponent } from './components/file-and-image-display/file-and-image-display.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CallbackPipe } from './pipes/callback/callback.pipe';
import { GroupByPipe } from './pipes/group-by/group-by.pipe';
import { TranslatePipe } from './pipes/translate/translate.pipe';
import { IndicatorTimelineComponent } from './components/indicator/indicator-timeline/indicator-timeline.component';
import { IndicatorStatusComponent } from './components/indicator/indicator-status/indicator-status.component';
import { IndicatorsPanelComponent } from './components/indicator/indicators-panel/indicators-panel.component';
import { IndicatorStatusItemComponent } from './components/indicator/indicator-status/indicator-status-item/indicator-status-item.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatCardModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatRadioModule,
  MatToolbarModule,
  MatTabsModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatDividerModule,
} from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClientModule } from '@angular/common/http';
import { IndicatorTimelineColorDirective } from './directives/indicator-timeline-color.directive';
import { SampleModalComponent } from './components/sample-modal/sample-modal.component';
import { FileAndImageUploadComponent } from './components/file-and-image-upload/file-and-image-upload.component';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { MarkdownEditorComponent } from './components/markdown-editor/markdown-editor.component';
import { MarkdownEditorModalComponent } from './components/markdown-editor/markdown-editor-modal.component';

import { NotificationTableComponent } from './components/notification-table/notification-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxMdModule } from 'ngx-md';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ArchiveModalComponent } from './components/archive-modal/archive-modal.component';
import { FileAndImageDisplayFullComponent } from './components/file-and-image-display-full/file-and-image-display-full.component';
import { NextInfoTimePipe } from './pipes/nextInfoTime/next-info-time.pipe';
import { ClockComponent } from './components/clock/clock.component';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { TimeOrDayPipe } from './pipes/time-or-day/time-or-day.pipe';
import { FilterFlightListPipe } from './pipes/filterFlightList/filter-flight-list.pipe';
import { TimeOnlyDayPipe } from './pipes/time-only-day/time-only-day.pipe';
import { TagSelectorComponent } from './components/tag-selector/tag-selector.component';
import { TagChipsComponent } from './components/tag-chips/tag-chips.component';
import { TagChangeService } from './services/tag-change.service';
import { TagsFilterPipe } from './pipes/tags-filter/tags-filter.pipe';
import { AddNextInfoModalComponent } from './modals/add-next-info-modal/add-next-info-modal.component';
import { DetailNextInfoModalComponent } from './modals/detail-next-info-modal/detail-next-info-modal.component';
import { HorizontalScrollDirective } from './directives/horizontal-scroll/horizontal-scroll.directive';
import { HolsterStopoverComponent } from './components/holster-stopover/holster-stopover.component';
import { OccSearchDashboardPipe } from './pipes/occ-search-dashboard/occ-search-dashboard.pipe';
import { HolNextInfoItemComponent } from './components/hol-next-info-item/hol-next-info-item.component';
import { CustomTabComponent } from './components/custom-tabs/custom-tab.component';
import { CustomTabsComponent } from './components/custom-tabs/custom-tabs.component';
import { ChecklistVacationComponent } from './components/checklist-vacation/checklist-vacation.component';
import { VacationChecklistSortPipe } from './pipes/vacation-checklist-sort/vacation-checklist-sort.pipe';
import { ChecklistComponent } from './components/checklist-vacation/checklist/checklist.component';
import { ChecklistItemComponent } from './components/checklist-vacation/checklist-item/checklist-item.component';
import { VacationComponent } from './components/checklist-vacation/vacation/vacation.component';
import { ChecklistsItemEditComponent } from './components/checklist-vacation/checklists-item-edit/checklists-item-edit.component';
import { NoteTemplateService } from './services/note-template.service';
import { ChecklistEventComponent } from './components/checklist-event/checklist-event.component';
import { ChecklistByFunctionComponent } from './components/checklist-event/checklist-by-function/checklist-by-function.component';
import { ChecklistByScenarioComponent } from './components/checklist-event/checklist-by-scenario/checklist-by-scenario.component';
import { ChecklistTaskComponent } from './components/checklist-event/checklist-item/checklist-task.component';
import { FilterChecklistEventPipe } from './pipes/filter-checklist-event/filter-checklist-event.pipe';
import { FileAndImageIconsDisplayComponent } from './components/file-and-image-icons-display/file-and-image-icons-display.component';
import { FileUploadAttachmentModalComponent } from './components/file-upload-attachment-modal/file-upload-attachment-modal.component';
import { AclFilterComponent } from './components/acl-filter/acl-filter.component';
import { AclSelectorComponent } from './components/acl-selector/acl-selector.component';
import { AclIndicatorComponent } from './components/acl-indicator/acl-indicator.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AclFilterPipe, AclGroupFilterPipe, AclStopoverFilterPipe } from './pipes/acl-filter/acl-filter.pipe';
import { HolListItemComponent } from './components/hol-list-item/hol-list-item.component';
import { HeaderComponent } from './components/header/header.component';
import { NgMarqueeModule } from 'ng-marquee';
import { ChatComponentDirective } from './angularJSComponents/chatComponent.directive';
import { ChatChannelComponentDirective } from './angularJSComponents/chatChannelComponent.directive';
import { ChatModalComponent } from './modals/chat-modal/chat-modal.component';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { IndicatorStopoverTimelineComponent } from './components/indicator/indicator-stopover-timeline/indicator-stopover-timeline.component';
import { HolSwitchToggleComponent } from './components/ui-kit/hol-switch-toggle/hol-switch-toggle.component';
import { TeamComponentDirective } from './angularJSComponents/teamComponent.directive';
import { HolHistoryItemsModalComponent } from './modals/hol-history-items-modal/hol-history-items-modal.component';
import { DynamicModule } from 'ng-dynamic-component';
import { TaskFormIoComponent } from './components/task-details/task-form-io/task-form-io.component';
import { TaskDetailsComponent } from './components/task-details/task-details.component';
import { TaskHistoryComponent } from './components/task-details/task-history/task-history.component';
import { FormioModule } from 'angular-formio';
import { TaskItemComponent } from './components/task-item/task-item.component';
import { ToasterComponent } from './components/toaster/toaster.component';
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    ChatComponentDirective,
    TeamComponentDirective,
    ChatChannelComponentDirective,
    IndicatorStatusComponent,
    IndicatorTimelineComponent,
    TimePipe,
    LoadingComponent,
    CallbackPipe,
    GroupByPipe,
    TranslatePipe,
    FileUploadComponent,
    MarkdownEditorComponent,
    MarkdownEditorModalComponent,
    FileAndImageDisplayComponent,
    FileAndImageDisplayFullComponent,
    FileAndImageUploadComponent,
    IndicatorsPanelComponent,
    IndicatorStatusItemComponent,
    IndicatorTimelineColorDirective,
    SampleModalComponent,
    NotificationTableComponent,
    ArchiveModalComponent,
    NextInfoTimePipe,
    ClockComponent,
    TimeOrDayPipe,
    FilterFlightListPipe,
    TimeOnlyDayPipe,
    TagSelectorComponent,
    TagChipsComponent,
    TagsFilterPipe,
    AddNextInfoModalComponent,
    DetailNextInfoModalComponent,
    HorizontalScrollDirective,
    HolsterStopoverComponent,
    HolNextInfoItemComponent,
    OccSearchDashboardPipe,
    CustomTabComponent,
    CustomTabsComponent,
    ChecklistEventComponent,
    ChecklistVacationComponent,
    VacationChecklistSortPipe,
    ChecklistComponent,
    ChecklistItemComponent,
    VacationComponent,
    ChecklistsItemEditComponent,
    ChecklistEventComponent,
    ChecklistByFunctionComponent,
    ChecklistByScenarioComponent,
    ChecklistTaskComponent,
    FilterChecklistEventPipe,
    FileAndImageIconsDisplayComponent,
    FileUploadAttachmentModalComponent,
    AclFilterComponent,
    AclSelectorComponent,
    AclIndicatorComponent,
    AclFilterPipe,
    AclGroupFilterPipe,
    AclStopoverFilterPipe,
    HolListItemComponent,
    HeaderComponent,
    ChatModalComponent,
    AlertModalComponent,
    ConfirmationModalComponent,
    DisableControlDirective,
    IndicatorStopoverTimelineComponent,
    HolSwitchToggleComponent,
    HolModalComponent,
    HolHistoryItemsModalComponent,
    HolNextInfoComponent,
    HolNextInfoDisplayComponent,
    TaskFormIoComponent,
    TaskDetailsComponent,
    TaskHistoryComponent,
    TaskItemComponent,
    ToasterComponent,
    HolFilterPanelComponent,
  ],
  imports: [
    CommonModule,
    LMarkdownEditorModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatSnackBarModule,
    MatExpansionModule,
    NgxHmCarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMdModule.forRoot(),
    MatTooltipModule,
    ColorPickerModule,
    NgMarqueeModule,
    MatTabsModule,
    MatSlideToggleModule,
    DynamicModule,
    MatDividerModule,
    FormioModule,
  ],
  entryComponents: [
    IndicatorsPanelComponent,
    SampleModalComponent,
    MarkdownEditorModalComponent,
    ArchiveModalComponent,
    TagSelectorComponent,
    TagChipsComponent,
    ToasterComponent,
    FileAndImageUploadComponent,
    FileAndImageDisplayFullComponent,
    FileAndImageDisplayComponent,
    AddNextInfoModalComponent,
    DetailNextInfoModalComponent,
    HolNextInfoItemComponent,
    CustomTabComponent,
    CustomTabsComponent,
    ChecklistVacationComponent,
    ChecklistEventComponent,
    ChecklistsItemEditComponent,
    FileAndImageIconsDisplayComponent,
    FileUploadAttachmentModalComponent,
    AclFilterComponent,
    AclSelectorComponent,
    HeaderComponent,
    ChatModalComponent,
    AlertModalComponent,
    ConfirmationModalComponent,
    HolHistoryItemsModalComponent,
    LoadingComponent,
  ],
  exports: [
    CommonModule,
    LMarkdownEditorModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMdModule,
    NotificationTableComponent,
    FileAndImageDisplayComponent,
    FileAndImageDisplayFullComponent,
    FileAndImageUploadComponent,
    FileUploadComponent,
    MarkdownEditorComponent,
    MarkdownEditorModalComponent,
    LoadingComponent,
    IndicatorsPanelComponent,
    IndicatorStatusComponent,
    IndicatorTimelineComponent,
    ArchiveModalComponent,
    ChatComponentDirective,
    TeamComponentDirective,
    ChatChannelComponentDirective,
    IndicatorTimelineColorDirective,
    TimePipe,
    TimeOrDayPipe,
    NextInfoTimePipe,
    CallbackPipe,
    GroupByPipe,
    TranslatePipe,
    HttpClientModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatRadioModule,
    MatCardModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatExpansionModule,
    NgxHmCarouselModule,
    ClockComponent,
    FilterFlightListPipe,
    TimeOnlyDayPipe,
    TagSelectorComponent,
    TagChipsComponent,
    ToasterComponent,
    TagsFilterPipe,
    HorizontalScrollDirective,
    AddNextInfoModalComponent,
    DetailNextInfoModalComponent,
    HolsterStopoverComponent,
    HolNextInfoItemComponent,
    OccSearchDashboardPipe,
    CustomTabComponent,
    CustomTabsComponent,
    ChecklistVacationComponent,
    ChecklistEventComponent,
    VacationChecklistSortPipe,
    ChecklistsItemEditComponent,
    FilterChecklistEventPipe,
    FileAndImageIconsDisplayComponent,
    AclSelectorComponent,
    AclIndicatorComponent,
    AclFilterComponent,
    AclFilterPipe,
    AclGroupFilterPipe,
    AclStopoverFilterPipe,
    HolListItemComponent,
    MatTooltipModule,
    HeaderComponent,
    AlertModalComponent,
    MatTabsModule,
    DisableControlDirective,
    MatSlideToggleModule,
    IndicatorStopoverTimelineComponent,
    HolSwitchToggleComponent,
    HolModalComponent,
    HolHistoryItemsModalComponent,
    MatDividerModule,
    HolNextInfoComponent,
    HolNextInfoDisplayComponent,
    TaskFormIoComponent,
    TaskDetailsComponent,
    TaskHistoryComponent,
    TaskItemComponent,
    HolFilterPanelComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    TagChangeService,
    ToasterComponent,
    TranslatePipe,
    DatePipe,
    TimeOrDayPipe,
    NoteTemplateService,
  ],
})
export class CommonComponentsModule {
  constructor() {}
}
