import { OclDecisionService } from 'src/app/ocl/services/ocl-decision-service/ocl-decision.service';
import { FltDecision } from './../../../flt/models/flt-decision';
import { FltApplicability } from './../../../flt/models/flt-applicability';
import { Subscription } from 'rxjs';
import { CommonStoreManager } from './../../../common/store/common.store-manager';
import { OCL_EVENT_MODAL_TOKEN } from './../ocl-event-modal/ocl-event-modal-tokens';
import { HolContext } from './../../../common/models/hol-context.model';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';
import * as moment from 'moment';
import { OclEvent } from '../../models/ocl-event.model';
import { OCL_LOGBOOK_MODAL_TOKEN } from '../ocl-logbook-modal/ocl-logbook-modal.token';
import { OclLinkedItemsStoreManager } from '../../store/linked-items/ocl-linked-items-store-manager.service';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclNotificationsService } from '../../services/ocl-notifications-service/ocl-notifications.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclLinksService } from '../../services/ocl-links-service/ocl-links.service';
import { FltFlightService } from '../../../flt/services/flt-flight.service';
import { nextInfoType } from '../../../common/components/hol-next-info/hol-next-info.component';
import { ConfirmationModalComponent } from '../../../common/modals/confirmation-modal/confirmation-modal.component';
import { intersectionWith } from 'lodash';

@Component({
  selector: 'app-ocl-decisions-modal',
  templateUrl: './ocl-decisions-modal.component.html',
  styleUrls: ['./ocl-decisions-modal.component.scss'],
})
export class OclDecisionsModalComponent implements OnInit, OnDestroy {
  public decision: OclDecision = new OclDecision();
  public isCreate: boolean;
  public isReadOnly: boolean;
  public hasErpAccess: boolean;
  public hasToSendSMS = false;
  public events: OclEvent[] = null;
  public selectedItem = undefined;
  public selectedTags = [];
  public module: string;
  public action: string;
  public isUtc: boolean;
  public isPinned = false;
  public loading = false;
  public loadingFlight = false;
  public selectedFlight = undefined;
  public searchText: string;
  public notifications: HolNotification[];
  public logBookItems: any[] = [];
  public linkedLogBooks: string[];
  public linkedLogBooksDisplay: any[] = [];
  public linkedLogBooksCopy: string[] = [];
  public linkedEvents: string[];
  public linkedEventsDisplay: any[] = [];
  public linkedEventsCopy: string[] = [];
  public linkedEventsArchived = [];
  public linkedLogBookArchived = [];
  public isFromLogBook = false;
  public searchData = {
    date: undefined,
    flights: [],
    flightsFiltered: [],
    flightNumber: null,
  };
  public autocompletePlaceHolder: string;
  public disabledLinks = {};
  modeLinkIsActivate: boolean;
  public airportsCode: Array<string>;
  public applicabilityIsReadOnly: boolean;
  public context: HolContext;
  isHistory;
  nextInfo: nextInfoType = {
    nextInfoTime: undefined,
    nextInfoDone: false,
  };
  public fromERP = false;
  public hasCrisisInProgress = false;
  public toERP = false;
  public hasCrisisInProgressSub: Subscription;
  public duplicateToOtherModule = false;
  public oldDuplicateToOtherModuleValue = null;
  public newDuplicateToOtherModuleValue = null;
  public updateDuplicateToOtherModuleFlightValue = false;
  public isTodoConfigActivated = false;
  private initialNIvalue = null;
  private initialNIdone = null;

  constructor(
    protected dialog: MatDialog,
    protected translatePipe: TranslatePipe,
    public moduleConfig: ModuleConfigService,
    public flightService: FltFlightService,
    protected notificationsService: OclNotificationsService,
    protected dialogRef: MatDialogRef<OclDecisionsModalComponent>,
    protected linksService: OclLinksService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected decisionsService: OclDecisionService,
    @Inject('$mdDialog') protected $mdDialog,
    @Inject('$translate') protected $translate,
    @Inject(OCL_LOGBOOK_MODAL_TOKEN) protected occLogbookModal,
    protected occLinkItemsStoreManager: OclLinkedItemsStoreManager,
    protected occDecisionsStoreManager: OclDecisionsStoreManager,
    @Inject(OCL_EVENT_MODAL_TOKEN) protected eventModal,
    protected commonStoreManager: CommonStoreManager,
    @Inject('$rootScope') public $rootScope
  ) {
    this.notificationsService.getAll().then(notif => (this.notifications = notif));
    this.hasErpAccess = $rootScope.accessRights.crisis !== 'UNAUTHORIZED';
    this.isTodoConfigActivated = this.moduleConfig.config.activateTODO;
  }

  ngOnInit(): void {
    this.module = this.translatePipe.transform('PDF_TITLE.OCC');
    this.action = this.translatePipe.transform('PDF_TITLE.DECISION');
    this.autocompletePlaceHolder = this.translatePipe.transform(
      this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_CHOOSE_DATE'
    );

    this.isUtc = this.data.isUtc;
    this.modeLinkIsActivate = this.data.modeLinkIsActivate;

    this.events = _.cloneDeep(this.data.events) || [];
    this.logBookItems = _.cloneDeep(this.data.logBookItems) || [];

    this.airportsCode = this.data.airportsCode;

    this.isHistory = this.data.isHistory ? this.data.isHistory : false;

    this.hasCrisisInProgressSub = this.commonStoreManager.crisis.subscribe(crisis => {
      this.hasCrisisInProgress = crisis.inProgress;
    });

    if (this.data.decision) {
      this.initLinks();
      this.isReadOnly = this.data.isReadOnly || this.isHistory || this.data.decision.fromERP;
      this.isCreate = false;
      this.applicabilityIsReadOnly = true;
      this.decision = this.data.decision;
      if (this.decision.flight) {
        this.selectedFlight = this.decision.flight;
        this.initSearchDataFlight();
      }
      if (this.isTodoConfigActivated && this.data.decision.isTodo) {
        this.initialNIvalue = this.decision.nextInfoTime;
        this.initialNIdone = this.decision.done;
      }
      this.nextInfo = {
        nextInfoTime: this.decision.nextInfoTime,
        nextInfoDone: this.decision.done,
      };
    } else {
      this.decision.isPinned = false;
      this.decision.attachments = {};
      this.decision.contentText = '';
      this.isCreate = true;
      this.applicabilityIsReadOnly = false;
      this.linkedEvents = [];
      this.linkedLogBooks = [];
      if (this.data.fromLogBook) {
        this.isFromLogBook = true;
        this.decision.contentText = this.data.fromLogBook.message;
        this.decision.tags = _.cloneDeep(this.data.fromLogBook.tags);
        this.decision.attachments = _.cloneDeep(this.data.fromLogBook.attachments);
        this.decision.acl = this.data.fromLogBook.acl;
        if (this.data.fromLogBook.flight && this.data.fromLogBook.flight.flightNumber) {
          this.selectedFlight = this.data.fromLogBook.flight;
          this.initSearchDataFlight();
        }
      }
    }

    if (this.decision.fromERP) {
      this.fromERP = this.decision.fromERP ? true : false;
    }

    if (this.moduleConfig.config.displayToERP) {
      this.toERP = this.decision.toERP ? true : false;
    }

    this.context = {
      module: this.moduleConfig.config.translateKey,
      category: 'DECISION',
      htmlTitle: this.decision.contentText,
      htmlDate: this.decision.createdAt ? this.decision.createdAt : '',
      htmlTemplate: 'A',
    };
  }

  public initSearchDataFlight(): void {
    this.searchData.flightNumber = this.selectedFlight.flightNumber;
    this.searchData.date = moment.utc(this.selectedFlight.sta).toDate();
    // tslint:disable-next-line: max-line-length
    const date = moment.utc(
      `${this.searchData.date.getFullYear()}/${this.searchData.date.getMonth() + 1}/${this.searchData.date.getDate()}`,
      'YYYY/MM/DD'
    );
    this.flightService.getBySlot(date).then(res => {
      this.searchData.flights = res;
      this.searchData.flightsFiltered = res;
    });
  }

  public initLinks(): void {
    this.linksService.getLinkDecisionEventById(this.data.decision.objectId).then(evts => {
      this.linkedEvents = [];
      if (!evts) {
        return;
      }
      evts.forEach(evt => {
        if (this.events.findIndex(el => el.objectId === evt.objectId) === -1) {
          this.disabledLinks[evt.objectId] = true;
          this.linkedEventsArchived.push(evt);
        } else {
          this.linkedEvents.push(evt.objectId);
          this.linkedEventsCopy.push(evt.objectId);
        }
        this.linkedEventsDisplay.push(evt);
      });
      if (this.data.decision.event && evts.findIndex(el => el === this.data.decision.event.objectId) === -1) {
        this.linkedEvents.push(this.data.decision.event.objectId);
        this.linkedEventsCopy.push(this.data.decision.event.objectId);
        this.linkedEventsDisplay.push(this.data.decision.event);
      }
    });
    this.linksService.getLinkLogBookForDecisionById(this.data.decision.objectId).then(lgks => {
      if (!lgks) {
        this.linkedLogBooks = [];
        return;
      }
      this.linkedLogBooks = [];
      lgks.forEach(lgk => {
        if (this.logBookItems.findIndex(el => el.objectId === lgk.objectId) === -1) {
          this.disabledLinks[lgk.objectId] = true;
          this.linkedLogBookArchived.push(lgk);
        } else {
          this.linkedLogBooks.push(lgk.objectId);
          this.linkedLogBooksCopy.push(lgk.objectId);
        }
        this.linkedLogBooksDisplay.push(lgk);
      });
    });
  }

  public changeDate(event): void {
    this.searchData.date = new Date(event);
    if (!this.searchData.date) {
      this.selectedFlight = undefined;
      this.searchText = undefined;
      this.selectedFlight = undefined;
      this.searchData.flights = [];
      this.autocompletePlaceHolder = this.translatePipe.transform(
        this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_CHOOSE_DATE'
      );
      return;
    }
    this.searchText = undefined;
    this.selectedFlight = undefined;
    this.searchData.flights = [];
    this.autocompletePlaceHolder = this.translatePipe.transform(
      this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_FLIGHT_LOADING'
    );
    // tslint:disable-next-line: max-line-length
    const date = moment.utc(
      `${this.searchData.date.getFullYear()}/${this.searchData.date.getMonth() + 1}/${this.searchData.date.getDate()}`,
      'YYYY/MM/DD'
    );
    this.flightService.getBySlot(date).then(res => {
      this.searchData.flights = res;
      this.searchData.flightsFiltered = res;
      if (!res.length) {
        this.autocompletePlaceHolder = this.translatePipe.transform(
          this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_NO_FLIGHT_FOUND'
        );
      } else {
        this.autocompletePlaceHolder = this.translatePipe.transform(
          this.moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.PLACEHOLDER_EXAMPLE'
        );
      }
    });
    this.querySearch('');
  }

  public querySearch(query): void {
    this.selectedFlight = null;
    this.searchData.flightNumber = null;
    this.searchData.flightsFiltered = _.cloneDeep(this.searchData.flights);
    if (!query || query.trim().length === 0) {
      this.searchData.flightsFiltered = _.cloneDeep(this.searchData.flights);
    } else {
      query = query.toLowerCase();
      this.searchData.flightsFiltered = _.cloneDeep(
        this.searchData.flights.filter(flight => {
          const dataToFind = flight.flightNumber.toLowerCase();
          if (dataToFind.includes(query)) {
            return flight;
          }
        })
      );
    }
  }

  public resetFlight(): void {
    this.selectedFlight = null;
    this.searchData = {
      date: undefined,
      flights: [],
      flightsFiltered: [],
      flightNumber: null,
    };
  }

  public selectedItemChange(value: { option: { value: string } }): void {
    this.selectedFlight = this.searchData.flights.find(f => f.objectId === value.option.value);
    this.searchData.flightNumber = this.selectedFlight.flightNumber;
  }

  public eventOnChanged(): void {
    if (this.selectedItem && this.selectedItem.tags) {
      this.selectedTags = this.selectedItem.tags;
    }
  }

  public cancel(): void {
    this.loading = false;
    this.dialogRef.close();
  }

  addEvent(event, applicability = null): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.eventModal, {
      data: {
        notifications: this.notifications,
        fromDecision: {
          attachments: this.decision.attachments,
          tags: this.decision.tags,
          message: this.decision.contentText,
          applicability: applicability,
          acl: this.decision.acl,
        },
        fromLogbook: undefined,
        isUtc: this.isUtc,
        airportsCode: this.airportsCode,
      },
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then(newEvent => {
        if (newEvent) {
          const bufferEvent = _.cloneDeep(newEvent);
          this.events.push(bufferEvent);
          this.linkedEvents.push(bufferEvent.objectId);
          this.linkedEventsDisplay.push(bufferEvent);
        }
      });
  }

  public changelinkedEvent(selectedValues) {
    this.linkedEventsDisplay = [...intersectionWith(this.events, selectedValues, (i, j) => i.objectId === j), ...this.linkedEventsArchived];
  }

  public removeEvent(ev): void {
    this.linkedEvents = this.linkedEvents.filter(event => event !== ev.objectId);
    this.linkedEventsDisplay = this.linkedEventsDisplay.filter(event => event.objectId !== ev.objectId);
  }

  public addLogbook(event, applicability = null): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.occLogbookModal, {
      data: {
        events: this.events,
        isUtc: !this.moduleConfig.config.localHours,
        logbook: null,
        fromDecision: {
          attachments: this.decision.attachments,
          tags: this.decision.tags,
          message: this.decision.contentText,
          flight: this.selectedFlight,
          applicability: applicability,
          acl: this.decision.acl,
        },
        airportsCode: this.airportsCode,
      },
    });
    dialogRef.afterClosed().subscribe(newLogbook => {
      if (newLogbook) {
        const bufferLogbook = _.cloneDeep(newLogbook);
        this.logBookItems.push(bufferLogbook);
        this.linkedLogBooks.push(bufferLogbook.objectId);
        this.linkedLogBooksDisplay.push(bufferLogbook);
      }
    });
  }

  public removeLogBook(lgb): void {
    this.linkedLogBooks = this.linkedLogBooks.filter(logBook => logBook !== lgb.objectId);
    this.linkedLogBooksDisplay = this.linkedLogBooksDisplay.filter(logBook => logBook.objectId !== lgb.objectId);
  }

  public changelinkedLogBook(selectedValues) {
    this.linkedLogBooksDisplay = [
      ..._.intersectionWith(this.logBookItems, selectedValues, (i, j) => i.objectId === j),
      ...this.linkedLogBookArchived,
    ];
  }

  public saveDecision(
    event: { contentTextChanged: boolean; notifications: HolNotification[]; nextInfo: nextInfoType },
    closeModal: boolean = true
  ): void {
    if (this.decision.erpDecision && !this.fromERP) {
      this.deleteErpDecision();
    }
    if (event.nextInfo && event.nextInfo.nextInfoTime) {
      this.decision.nextInfoTime = event.nextInfo.nextInfoTime;
      this.decision.done = event.nextInfo.nextInfoDone;
    }
    if (this.decision.isTodo) {
      if (event && event.nextInfo) {
        this.decision.done =
          !!this.initialNIdone && !moment(this.initialNIvalue).isSame(this.decision.nextInfoTime) ? false : event.nextInfo.nextInfoDone;
        this.saveEntity(event, closeModal);
      }
    } else {
      this.saveEntity(event, closeModal);
    }
  }

  deleteErpDecision(): void {
    this.decisionsService.deleteErpDecision(this.decision);
    this.dialogRef.close();
  }

  public toggleIsTodo($event): void {
    if (!this.isCreate) {
      // transform Decision to Todo entity (and clear applicabilities)
      if (!this.decision.isTodo) {
        const mod = this.dialog
          .open(ConfirmationModalComponent, {
            data: {
              modalTitle: this.$translate.instant(
                this.moduleConfig.config.translateKey + '.MODALS.DECISION.CONVERT_DECISION_TO_TODO.TITLE'
              ),
              modalContent: this.$translate.instant(
                this.moduleConfig.config.translateKey + '.MODALS.DECISION.CONVERT_DECISION_TO_TODO.CONTENT'
              ),
              modalQuestion: this.$translate.instant(
                this.moduleConfig.config.translateKey + '.MODALS.DECISION.CONVERT_DECISION_TO_TODO.QUESTION'
              ),
            },
          })
          .afterClosed()
          .toPromise()
          .then(confirm => {
            if (confirm) {
              if (this.decision instanceof FltDecision) {
                this.decision.applicability = new FltApplicability();
              } else {
                this.resetFlight();
              }
              this.loading = true;
              this.decision.isTodo = true;
              this.nextInfo = {
                nextInfoTime: this.decision.nextInfoTime,
                nextInfoDone: this.decision.done,
              };
              this.saveDecision({ contentTextChanged: false, notifications: null, nextInfo: this.nextInfo }, false);
            } else {
              this.decision.isTodo = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.decision.isTodo = false;
        this.nextInfo = undefined;
        this.saveDecision(null, false);
      }
    } else {
      // creation of Todo entity (but entity created by save button)
      this.decision.isTodo = true;
    }
  }
  private saveEntity(
    event: { contentTextChanged: boolean; notifications: HolNotification[]; nextInfo: nextInfoType },
    closeModal: boolean = true
  ) {
    this.loading = true;
    if (this.isCreate) {
      this.decision.message = this.decision.contentText;
      this.decision.event = this.selectedItem;
      this.decision.flight = this.selectedFlight;

      if (this.moduleConfig.config.displayToERP) {
        this.duplicateToOtherModule = this.toERP;
        this.decision.toERP = this.toERP;
      }

      this.decisionsService
        .create(this.decision, event.notifications, this.context, this.duplicateToOtherModule)
        .then(newDecision => {
          Promise.all([
            this.linksService.saveLinkDecisionEvent(newDecision.objectId, this.linkedEvents),
            this.linksService.saveLinkLogBookDecision(newDecision.objectId, 'decision', this.linkedLogBooks),
          ])
            .then(() => {
              this.linksService
                .checkLinks([newDecision.objectId], this.linkedLogBooks, this.linkedEvents, true)
                .then(linkIds => {
                  this.occLinkItemsStoreManager.updateLinkedItems(linkIds);
                  this.dialogRef.close(newDecision);
                })
                .catch(error => {
                  this.dialogRef.close();
                  console.error('Error creating Decision link', error);
                })
                .finally(() => {
                  this.loading = false;
                });
            })
            .catch(error => {
              this.dialogRef.close();
              console.error('Error creating Decision link', error);
            });
        })
        .catch(error => {
          this.dialogRef.close();
          console.error('Error creating OCCStatusDecision', error);
        });
    } else {
      this.decision.flight = this.selectedFlight;
      if (event && event.contentTextChanged) {
        this.decision.message = this.decision.contentText;
        this.decision.customCreatedAt = new Date();
      }
      if (this.moduleConfig.config.displayToERP) {
        this.oldDuplicateToOtherModuleValue = this.decision.toERP;
        this.newDuplicateToOtherModuleValue = this.toERP;
        this.duplicateToOtherModule = this.toERP;
        this.decision.toERP = this.toERP;
      }
      const linkedEventsToUpdate = _.difference(this.linkedEvents, this.linkedEventsCopy);
      const linkedEventsToDelete = _.difference(this.linkedEventsCopy, this.linkedEvents);
      const linkedLogBooksToUpdate = _.difference(this.linkedLogBooks, this.linkedLogBooksCopy);
      const linkedLogBooksToDelete = _.difference(this.linkedLogBooksCopy, this.linkedLogBooks);

      this.decisionsService
        .update(this.decision, this.context, this.oldDuplicateToOtherModuleValue, this.newDuplicateToOtherModuleValue)
        .then(newDecision => {
          // STORE
          // const decisonTemp = new OclDecision(newDecision);
          const decisonTemp = _.cloneDeep(newDecision);
          decisonTemp.tags = this.decision.tags;
          this.occDecisionsStoreManager.updateOneDecision(decisonTemp);
          Promise.all([
            this.linksService.saveLinkDecisionEvent(this.decision.objectId, linkedEventsToUpdate),
            this.linksService.saveLinkLogBookDecision(this.decision.objectId, 'decision', linkedLogBooksToUpdate),
            this.linksService.deleteLinkDecisionEventById(this.decision.objectId, linkedEventsToDelete),
            this.linksService.deleteLinkLogBookForDecisionById(this.decision.objectId, linkedLogBooksToDelete),
          ])
            .then(() => {
              this.linksService
                .checkLinks([], [], [], true)
                .then(linkIds => {
                  this.occLinkItemsStoreManager.updateLinkedItems(linkIds);
                  if (closeModal) {
                    this.dialogRef.close(newDecision);
                  }
                })
                .catch(error => {
                  if (closeModal) {
                    this.dialogRef.close();
                  }
                  console.error('Error creating Decision link', error);
                })
                .finally(() => {
                  this.loading = false;
                });
            })
            .catch(error => {
              console.error(error);
              if (closeModal) {
                this.dialogRef.close();
              }
            });
        })
        .catch(error => {
          console.error(error);
          if (closeModal) {
            this.dialogRef.close();
          }
        });
    }
  }

  ngOnDestroy() {
    this.hasCrisisInProgressSub.unsubscribe();
  }
}
