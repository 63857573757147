import { Inject, Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionsStoreManager } from 'src/app/ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { GocGlobalInstructionTagService } from '../goc-global-instruction-tag-service/goc-global-instruction-tag.service';
import { GocModuleConfigService } from '../goc-module-config/goc-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class GocGlobalInstructionService extends OclGlobalInstructionService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('GOCGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('GOCGlobalInstruction');
  protected ParseTag = Parse.Object.extend('GOCTag');
  // tslint:enable
  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    @Inject('UserService') protected userService,
    protected globalInstructionTagService: GocGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    public moduleConfig: GocModuleConfigService
  ) {
    super($rootScope, requestService, userService, globalInstructionTagService, globalInstructionsStoreManager, moduleConfig);
  }
}
