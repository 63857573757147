import { Inject, Injectable } from '@angular/core';

import * as _ from 'lodash';

import { TirednessIndicator } from '../models/tiredness-indicator.model';

const XLSX = require('xlsx');

@Injectable({
  providedIn: 'root',
})
export class TirednessIndicatorService {
  constructor(@Inject('CrewUsersService') private crewUsersService) {}

  public parseXLSFile(workbook): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!workbook.SheetNames.length) {
        reject('Fichier mal formé: Aucune feuille');
      }
      // Here is your object
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      let start = null;
      let end = sheet['!ref'].split(':')[1];
      // tslint:disable-next-line:forin
      for (const key in sheet) {
        if (!start && sheet[key].w === 'Tlc') {
          start = key;
        }
        if (start && sheet[key].w === '') {
          end = key.replace('A', /[A-Z]+/gi.exec(end)[0]);
          break;
        }
      }
      if (!start) {
        reject('Fichier mal formé: Entrée AIRCRAFT introuvable');
      }
      sheet['!ref'] = start + ':' + end;
      let jsonXLS = XLSX.utils.sheet_to_json(sheet);
      // REMOVE HEADER LINES AND BLANK LINES
      jsonXLS = _.filter(jsonXLS, row => row.Tlc && row.Tlc !== 'Tlc' && row['Note globale']);

      /*var setMelReference = function (row, res) {
        var reg = /(\d{2})-(\d{2})(-(\d{2})-?(\w)?)?/gi;
        var parsedRef = reg.exec(row['REFERENCE']);
        if (parsedRef) {
          var newRef = parsedRef[1] + '-' + parsedRef[2];
          if (parsedRef[4]) {
            newRef += '-' + parsedRef[4];
            if (parsedRef[5]) {
              newRef += parsedRef[5];
            } else {
              res.warnings.push('REFERENCE::missing letter: ' + row['REFERENCE']);
            }
          } else {
            res.warnings.push('REFERENCE::missing 3rd value: ' + row['REFERENCE']);
          }
          if (newRef !== row['REFERENCE']) {
            res.infos.push('REFERENCE::transformed from "' + row['REFERENCE'] + '" to "' + newRef + '"');
          }
          res.item.reference = newRef;
        } else {
          res.item.reference = row['REFERENCE'];
          res.warnings.push('REFERENCE::Invalid format: ' + row['REFERENCE']);
        }
      };*/
      /*var setFaultName = function (row, res) {
         var regFaultName = /([^\/]*\/)?(.*)/gi;
         var parsedFaultName = regFaultName.exec(row['FAULT NAME']);
         if (parsedFaultName) {
           res.item.faultName = parsedFaultName[2].trim();
         } else {
           res.item.faultName = row['FAULT NAME'];
           res.warnings.push('FAULT NAME::Invalid format: ' + row['REFERENCE']);
         }
       };*/
      // tslint:disable-next-line
      const tirednessItems = _.map(jsonXLS, row => new TirednessIndicator(row['Tlc'], row['Note globale']));
      this.addAllToCrewUsers(tirednessItems)
        .then(crewUsers => {
          return this.crewUsersService.updateAll(crewUsers);
        })
        .finally(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public addAllToCrewUsers(tirednessItems: TirednessIndicator[]) {
    return new Promise((resolve, reject) => {
      this.crewUsersService
        .getAll()
        .then(crewUsers => {
          const newOnes = _.map(crewUsers, crewUser => {
            const foundItem = _.find(tirednessItems, ['tlc', crewUser.lastName]);
            if (foundItem) {
              crewUser.noteGlobal = foundItem.noteGlobal;
              // just return crewUsers that need to be updated
              return crewUser;
            }
          }).filter(item => {
            return item !== undefined;
          });
          resolve(newOnes);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
