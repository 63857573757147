import { HolVacationChecklist, HolVacationChecklistItem } from 'src/app/common/models/hol-vacation.model';
import { GocStopover } from 'src/app/goc/models/goc-stopover.model';

import { Action } from '@ngrx/store';

import { HolCrisis } from '../models/hol-crisis';
import { HolFlight } from '../models/hol-flight.model';
import { HolFunction } from '../models/hol-function';
import { HolUserFunction } from '../models/hol-user-function';
import { HolUser } from '../models/hol-user.model';
import { HolVacation } from '../models/hol-vacation.model';
import { CrisisRolesRef } from '../services/functions.service';
import { EclInfos, OpsInfos, OPSLayoutGroups, OPSLayoutInfos } from './common.model';

export enum CommonActionTypes {
  INIT_COMMON = '[COMMON] Init Common',
  UPDATE_ONE_FLIGHT = '[COMMON] Update One Flight',
  UPDATE_CURRENT_FLIGHT = '[COMMON] Update Current Flight',
  UPDATE_CURRENT_STOPOVER = '[COMMON] Update Current Stopover',
  UPDATE_FLIGHTS = '[COMMON] Update Flights',
  UPDATE_STOPOVERS = '[COMMON] Update Stopovers',
  UPDATE_CURRENT_OCL_MODULE = '[COMMON] Update Current OCL Module',
  UPDATE_ECL_INFOS = '[COMMON] Update ECL Infos',
  UPDATE_OPS_LAYOUT_INFOS = '[COMMON] Update OPS Layout Infos',
  UPDATE_OPS_INFOS = '[COMMON] Update OPS Infos',
  UPDATE_LAYOUT_GROUPS = '[COMMON] Update layout Groups',
  UPDATE_CURRENT_USER = '[COMMON] Update Current User ',
  INIT_CRISIS = '[COMMON] Init crisis ',
  INIT_CRISIS_ROLES_REF = '[COMMON] Init crisis roles ref ',
  INIT_HOL_FUNCTIONS = '[COMMON] Init hol functions ',
  INIT_HOL_VACATIONS = '[COMMON] Init hol vacations ',
  UPDATE_HOL_VACATION = '[COMMON] Update one hol vacation ',
  REMOVE_HOL_VACATION = '[COMMON] Remove one hol vacation ',
  INIT_USER_FUNCTIONS = '[HOL USER FUNCTION] Init User Functions',
  UPDATE_MANY_USER_FUNCTIONS = '[HOL USER FUNCTION] Update many User Functions',
}

export class InitCommon implements Action {
  readonly type = CommonActionTypes.INIT_COMMON;
  constructor(public payload: any) {}
}
export class UpdateCurrentOclModule implements Action {
  readonly type = CommonActionTypes.UPDATE_CURRENT_OCL_MODULE;
  constructor(public payload: string) {}
}
export class UpdateEclInfos implements Action {
  readonly type = CommonActionTypes.UPDATE_ECL_INFOS;
  constructor(public payload: EclInfos) {}
}

export class UpdateOpsLayoutInfos implements Action {
  readonly type = CommonActionTypes.UPDATE_OPS_LAYOUT_INFOS;
  constructor(public payload: Partial<OPSLayoutInfos>) {}
}
export class UpdateOpsInfos implements Action {
  readonly type = CommonActionTypes.UPDATE_OPS_INFOS;
  constructor(public payload: OpsInfos) {}
}

export class UpdateLayoutGroups implements Action {
  readonly type = CommonActionTypes.UPDATE_LAYOUT_GROUPS;
  constructor(public payload: OPSLayoutGroups) {}
}

export class UpdateOneFlight implements Action {
  readonly type = CommonActionTypes.UPDATE_ONE_FLIGHT;
  constructor(public payload: HolFlight) {}
}
export class UpdateCurrentFlight implements Action {
  readonly type = CommonActionTypes.UPDATE_CURRENT_FLIGHT;
  constructor(public payload: HolFlight) {}
}

export class UpdateCurrentStopover implements Action {
  readonly type = CommonActionTypes.UPDATE_CURRENT_STOPOVER;
  constructor(public payload: GocStopover) {}
}
export class UpdateFlights implements Action {
  readonly type = CommonActionTypes.UPDATE_FLIGHTS;
  constructor(public payload: HolFlight[]) {}
}

export class UpdateStopovers implements Action {
  readonly type = CommonActionTypes.UPDATE_STOPOVERS;
  constructor(public payload: GocStopover[]) {}
}

export class UpdateCurrentUser implements Action {
  readonly type = CommonActionTypes.UPDATE_CURRENT_USER;
  constructor(public payload: HolUser) {}
}

export class InitCrisis implements Action {
  readonly type = CommonActionTypes.INIT_CRISIS;
  constructor(public crisis: Partial<HolCrisis>) {}
}

export class InitCrisisRolesRef implements Action {
  readonly type = CommonActionTypes.INIT_CRISIS_ROLES_REF;
  constructor(public roles: CrisisRolesRef) {}
}

export class InitHolFunctions implements Action {
  readonly type = CommonActionTypes.INIT_HOL_FUNCTIONS;
  constructor(public holFunctions: HolFunction[]) {}
}

export class InitHolVacations implements Action {
  readonly type = CommonActionTypes.INIT_HOL_VACATIONS;
  constructor(public holVacations: HolVacation[]) {}
}

export class UpdateHolVacation implements Action {
  readonly type = CommonActionTypes.UPDATE_HOL_VACATION;
  constructor(
    public vacationId: string,
    public checklistId: string,
    public checklistItemId: string,
    public partial: Partial<HolVacation | HolVacationChecklist | HolVacationChecklistItem>
  ) {}
}

export class RemoveHolVacations implements Action {
  readonly type = CommonActionTypes.REMOVE_HOL_VACATION;
  constructor(public holVacationId: string) {}
}

export class InitUserFunctions implements Action {
  readonly type = CommonActionTypes.INIT_USER_FUNCTIONS;
  constructor(public payload: HolUserFunction[]) {}
}

export class UpdateManyUserFunctions implements Action {
  readonly type = CommonActionTypes.UPDATE_MANY_USER_FUNCTIONS;
  constructor(public userFunctions: HolUserFunction[]) {}
}

export type CommonActions =
  | InitCommon
  | UpdateCurrentOclModule
  | UpdateEclInfos
  | UpdateOpsInfos
  | UpdateFlights
  | UpdateOneFlight
  | UpdateCurrentFlight
  | UpdateOpsLayoutInfos
  | UpdateStopovers
  | UpdateCurrentStopover
  | UpdateCurrentUser
  | InitCrisis
  | InitCrisisRolesRef
  | InitHolFunctions
  | InitHolVacations
  | UpdateHolVacation
  | RemoveHolVacations
  | InitUserFunctions
  | UpdateManyUserFunctions
  | UpdateLayoutGroups;
