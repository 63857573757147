import { HolUser } from './hol-user.model';

export class HolRole {
  public static readonly ROLE_PARTS_REGEXP = /^([A-Z]+)_([A-Z]+)(?:-([A-Z]+))?_(READ|WRITE)/;

  name: string;
  users: HolUser[];
  parseRole: Parse.Role;
  read: boolean;
  write: boolean;
  company: string;
  subCompany: string;
  universe: string;
  color: string;

  constructor(role: Parse.Role, users?: Parse.User[]) {
    this.name = role.getName();
    if (HolRole.ROLE_PARTS_REGEXP.test(role.getName())) {
      const parts = HolRole.ROLE_PARTS_REGEXP.exec(role.getName());
      this.universe = parts[1];
      this.company = parts[2];
      this.subCompany = parts[3];
      this.write = parts[4] === 'WRITE';
      this.read = parts[4] === 'READ';
    }
    this.users =
      users &&
      users
        .map(u => new HolUser(u))
        .sort((a, b) => {
          return a.userId < b.userId ? -1 : 1;
        });
    this.color = role.get('color');
    this.parseRole = role;
  }
}
