import { Inject, Injectable } from '@angular/core';
import { OclSmsService } from '../../../ocl/services/ocl-sms-service/ocl-sms.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class EclSmsService extends OclSmsService {
  constructor(
    @Inject('SmsSenderService') protected smsSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    @Inject('$translate') protected $translate,
    protected moduleConfig: EclModuleConfigService
  ) {
    super(smsSenderService, CONSTANTS, $translate, moduleConfig);

    this.$translate.onReady(() => {
      this.SENDER_SUFFIX = this.$translate.instant('SMS.ECL.SENDER_SUFFIX') || 'ECL';
    });
  }
}
