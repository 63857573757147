'use strict';
angular.module('gestiondecriseApp').directive('crewEventsBox', function () {
  return {
    restrict: 'E',
    templateUrl: 'scripts/directives/crew/crewEventsBox/crewEventsBox.html',
    scope: {
      currentFilter: '=',
      isReadOnly: '=',
    },
    controller: function ($scope, $mdDialog, $timeout, CrewFunctionsService, CrewEventsService, $rootScope, $translate) {
      $scope.events = [];
      $scope.loading = false;
      $rootScope.eventFiter = '';
      $scope.isPhone = $rootScope.isPhone;

      $scope.collapseTextEvent = $translate.instant('CREW.DASHBOARD.EVENTS.COLLAPSED');
      $scope.isEventOpened = false;

      init();

      var unregisterWatchFilters = $scope.$watch('currentFilter', function () {
        init();
      });

      function init() {
        $scope.loading = true;

        CrewEventsService.getAll($scope.currentFilter)
          .then(function (events) {
            $scope.events = events;
          })
          .finally(function () {
            $scope.loading = false;
          });
      }

      var unregisterPoolService = $scope.$on('crewPoolService-events', function (e, events) {
        $scope.events = events;
      });

      var unregisterSituationEvent = $scope.$on('crew-situation-updated', function () {
        init();
      });

      $scope.$on('$destroy', function () {
        unregisterPoolService();
        unregisterSituationEvent();
      });

      $scope.isInFunction = function (event) {
        return !$rootScope.crewSelectedFunction || (event.function && event.function.code === $rootScope.crewSelectedFunction);
      };

      $scope.matchSearchFilter = function (event) {
        return event.impact.toUpperCase().indexOf($rootScope.eventFiter.toUpperCase()) > -1;
      };

      function openEventModal($event, event) {
        $mdDialog
          .show({
            controller: 'CrewEventModalCtrl',
            templateUrl: 'views/crew/modals/event.modal.html',
            clickOutsideToClose: false,
            targetEvent: $event,
            locals: {
              functions: CrewFunctionsService.getAll,
              event: event,
              isReadOnly: $scope.isReadOnly,
            },
          })
          .then(function (retVal) {
            if (retVal) {
              init();
            }
          });
      }

      function openEventModalPhone($event, event) {
        $mdDialog
          .show({
            controller: 'CrewEventModalCtrl',
            templateUrl: 'views/crew/modals/event.responsive.modal.html',
            clickOutsideToClose: false,
            targetEvent: $event,
            locals: {
              functions: CrewFunctionsService.getAll,
              event: event,
              isReadOnly: $scope.isReadOnly,
            },
          })
          .then(function (retVal) {
            if (retVal) {
              init();
            }
          });
      }

      $scope.openProperEventModal = function ($event, event) {
        if ($rootScope.isPhone) {
          openEventModalPhone($event, event);
        } else {
          openEventModal($event, event);
        }
      };

      $scope.$on('$destroy', function () {
        unregisterWatchFilters();
      });

      $scope.changeCollapseText = function () {
        if ($scope.collapseTextEvent === $translate.instant('CREW.DASHBOARD.EVENTS.COLLAPSED')) {
          $scope.collapseTextEvent = $translate.instant('CREW.DASHBOARD.EVENTS.OPENED');
        } else {
          $scope.collapseTextEvent = $translate.instant('CREW.DASHBOARD.EVENTS.COLLAPSED');
        }
      };

      $scope.toggleEventCollapse = function () {
        $scope.isEventOpened = !$scope.isEventOpened;
        $scope.changeCollapseText();
      };
    },
  };
});
