'use strict';

/**
 * @ngdoc directive
 * @name gestiondecriseApp.directive:clock
 * @description
 * # clock
 */
angular.module('gestiondecriseApp').directive('clock', function ($timeout) {
  return {
    restrict: 'E',
    scope: {
      utcoffset: '=',
      icon: '@',
    },
    template:
      '<div>' +
      '<i class="icon {{icon}}"></i> ' +
      '<span class="clock-display">{{clockDisplay.hours}}<span class="clock-blink">:</span>{{clockDisplay.minutes}}</span>' +
      '</div>',
    link: function postLink($scope, element, attrs) {
      var getClockDisplay = function () {
        var d = moment(new Date()).utcOffset($scope.utcoffset.replace('−', '-'));
        return {
          hours: d.format('HH'),
          minutes: d.format('mm'),
        };
      };

      $scope.clockDisplay = getClockDisplay();

      var tick = function () {
        var newDisplay = getClockDisplay();
        if ($scope.clockDisplay.hours != newDisplay.hours || $scope.clockDisplay.minutes != newDisplay.minutes) {
          $scope.$apply(function () {
            $scope.clockDisplay = newDisplay;
          });
        }
        requestAnimFrame(tick);
      };

      tick();
    },
  };
});

window.requestAnimFrame = (function () {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (/* function */ callback, /* DOMElement */ element) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();
