<div class="hol-dialog-wrapper hol-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <span class="title" *ngIf="isCreate">
        {{ moduleConfig.config.translateKey + '.MODALS.' + type + '.NEW_TITLE' | translate }}
      </span>
      <span class="title" *ngIf="!isCreate">
        {{
          moduleConfig.config.translateKey + '.MODALS.' + type + '.EDIT_TITLE'
            | translate
              : {
                  title: item.contentText,
                  date: item.createdAt | time: isUTC
                }
        }}
      </span>
    </div>
    <button
      class="history-button"
      *ngIf="historyItemComponent && item.customCreatedAt && !isHistory"
      mat-raised-button
      (click)="openHistory()"
    >
      <span>{{ 'COMMON.BUTTON.HISTORY' | translate | uppercase }}</span>
    </button>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form" class="hol-modal__content">
      <div class="hol-modal__content__column">
        <div class="hol-dialog-section" *ngIf="hasContentText">
          <div class="hol-modal__content__column--title">
            <!-- TITLE -->
            <mat-form-field>
              <mat-label>{{ moduleConfig.config.translateKey + '.MODALS.' + type + '.TITLE_PLACEHOLDER' | translate }}</mat-label>
              <textarea
                *ngIf="!canEditTitle"
                matInput
                class="hol-modal-title"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"
                [(ngModel)]="item.contentText"
                [maxlength]="contentTextLength"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="isReadOnly || !isCreate"
              ></textarea>
              <textarea
                *ngIf="canEditTitle"
                matInput
                class="hol-modal-title"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"
                [(ngModel)]="item.contentText"
                [maxlength]="contentTextLength"
                [ngModelOptions]="{ standalone: true }"
                [disabled]="isReadOnly || !canUpdate"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <!-- CONTENT -->
        <ng-content></ng-content>
        <!-- NOTE -->
        <ng-container *ngIf="hasAttachments">
          <div class="hol-dialog-section" *ngIf="!isReadOnly && hasAttachmentsMandatory">
            <label>{{ moduleConfig.config.translateKey + '.MODALS.' + type + '.ATTACHMENTS_LABEL' | translate }}</label>
          </div>
        </ng-container>

        <div class="hol-dialog-section" *ngIf="hasAttachments">
          <app-file-and-image-upload
            [hideLink]="true"
            [hideFile]="true"
            [hideImage]="true"
            [attachments]="item.attachments"
            [disabled]="isReadOnly || (!isCreate && !canUpdate)"
            [context]="context"
            [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
          >
          </app-file-and-image-upload>
        </div>
      </div>
      <div class="separator" *ngIf="!hideSecondaryColumn"></div>
      <div class="hol-modal__content__column" *ngIf="!hideSecondaryColumn">
        <!-- CONTENT TOP RIGHT -->
        <ng-content select=".content-right-top-addon"></ng-content>
        <!-- OTHER ATTACHMENTS -->
        <div class="hol-dialog-section" *ngIf="hasAttachments">
          <app-file-and-image-upload
            [hideNote]="true"
            [attachments]="item.attachments"
            [disabled]="isReadOnly || (!isCreate && !canUpdate)"
            [context]="context"
            [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
          >
          </app-file-and-image-upload>
        </div>
        <!-- NOTIFS -->
        <div
          *ngIf="(isCreate || (!isReadOnly && canUpdate)) && ((notifications && notifications.length !== 0) || !!notifyFunction)"
          class="hol-dialog-section"
        >
          <div class="modal-column">
            <label>{{ 'DASHBOARD.MODAL.NOTIFICATION_LABEL' | translate }}</label>
            <app-notification-table
              [notifications]="notifications"
              [notifyFunction]="notifyFunction"
              [acl]="item.acl"
              [type]="type.toLowerCase()"
              (saveNotifications)="saveNotifications($event)"
              (saveNotifyFunction)="saveNotifyFunction($event)"
            ></app-notification-table>
          </div>
        </div>
        <!-- ECHEANCE -->
        <div *ngIf="nextInfo">
          <label>{{ 'DASHBOARD.MODAL.NEXT_INFO' | translate }}</label>
          <app-hol-next-info
            [nextInfo]="nextInfo"
            [isReadOnly]="isReadOnly || (!isCreate && !canUpdate)"
            [isUTC]="isUTC"
            [hasDoneBtn]="true"
            (saveNextInfo)="saveNextInfo($event)"
          >
          </app-hol-next-info>
        </div>
        <!-- CONTENT RIGHT -->
        <ng-content select=".content-right-addon"></ng-content>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-acl-selector
      class="acl-selector"
      *ngIf="!isReadOnly && ((!isCreate && canUpdate) || isCreate)"
      [moduleName]="moduleConfig.config.moduleName"
      [limitTo]="limitTo"
      [object]="item"
      [formGroup]="form"
      [extendACLOnly]="extendACLOnly"
      [applicability]="applicability"
    ></app-acl-selector>
    <div class="hol-modal__actions">
      <div>
        <ng-content select=".actions-right-addon"></ng-content>
        <button *ngIf="!isReadOnly && !isCreate && canArchive" class="hol-archive-btn-secondary" mat-raised-button (click)="archive()">
          {{ 'DASHBOARD.MODAL.ARCHIVE_BUTTON' | translate }}
        </button>
      </div>
      <div>
        <ng-content select=".actions-addon"></ng-content>
        <button
          *ngIf="!isReadOnly && ((!isCreate && canUpdate) || isCreate) && !disabledValidateActions"
          class="hol-validate-btn-secondary"
          mat-raised-button
          [disabled]="form.invalid || (hasContentText && item.contentText?.trim().length === 0) || !canSave"
          (click)="save()"
        >
          {{ (isCreate ? addButtonTrad : updateButtonTrad) | translate }}
        </button>
        <button
          *ngIf="(isReadOnly || (!isCreate && !canUpdate)) && !disabledValidateActions"
          class="hol-validate-btn-secondary"
          mat-dialog-close
          mat-raised-button
        >
          {{ 'DASHBOARD.MODAL.OK_BUTTON' | translate }}
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</div>
