import { MarkdownService } from './../../../common/components/markdown-editor/markdown.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { Inject, Injectable } from '@angular/core';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { OccDecisionTagService } from '../occ-decision-tag-service/occ-decision-tag.service';
import { OccHistoryService } from '../occ-history-service/occ-history.service';
import { OccOptionsService } from '../occ-options-service/occ-options.service';
import { OclDecisionService } from '../../../ocl/services/ocl-decision-service/ocl-decision.service';
import { OclDecisionsStoreManager } from '../../../ocl/store/decisions/ocl-decisions.store-manager';
import { OccMailService } from '../occ-mail-service/occ-mail.service';
import { OccSmsService } from '../occ-sms-service/occ-sms.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class OccDecisionService extends OclDecisionService {
  // tslint:disable:variable-name
  protected ParseDecision = Parse.Object.extend('OCCDecisions');
  protected ParseEvent = Parse.Object.extend('OCCEvents');
  protected ParseDecisionTag = Parse.Object.extend('OCCDecisionTag');
  protected ParseTag = Parse.Object.extend('OCCTag');
  // tslint:enabled

  public constructor(
    @Inject('$filter') protected $filter,
    @Inject('FilesService') protected filesService,
    @Inject('$translate') protected $translate,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected parseMapper: ParseMapperService,
    protected requestService: RequestService,
    protected optionsService: OccOptionsService,
    protected notificationsService: NotificationsService,
    protected smsService: OccSmsService,
    protected mailService: OccMailService,
    protected historyService: OccHistoryService,
    protected decisionTagService: OccDecisionTagService,
    protected moduleConfig: OccModuleConfigService,
    protected commonStoreManager: CommonStoreManager,
    protected markdownService: MarkdownService,

  ) {
    super(
      $filter,
      filesService,
      $translate,
      decisionsStoreManager,
      parseMapper,
      requestService,
      optionsService,
      notificationsService,
      smsService,
      mailService,
      historyService,
      decisionTagService,
      moduleConfig,
      markdownService,
      commonStoreManager
    )
  }
}
