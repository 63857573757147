<app-loading *ngIf="loading" class="full-loader"></app-loading>
<app-hol-modal
  [item]="logbook"
  [isReadOnly]="isReadOnly || isHistory"
  [isCreate]="isCreate"
  [type]="'LOGBOOK'"
  [context]="context"
  [contentTextLength]="200"
  [notifications]="isCreate ? notifications : []"
  [canArchive]="false"
  [historyItemComponent]="data.itemComponent"
  [historyItemListMode]="true"
  [historyItemObjectId]="logbook.objectId"
  [historyItemType]="'logbook'"
  [historyTitle]="moduleConfig.config.translateKey + '.MODALS.HISTORY.LOGBOOK'"
  [canEditTitle]="!isHistory"
  [isHistory]="isHistory"
  [disabledValidateActions]="!logbook.readOnly && (logbook.fromGOC || logbook.fromCREW)"
  (saveItem)="saveLogbook($event)"
>
  <div class="logbook-modal">
    <!-- TAGS -->
    <div class="hol-dialog-section">
      <app-tag-selector *ngIf="!logbook.fromGOC" [(selectedTags)]="logbook.tags" [readOnly]="isReadOnly" class="tag-selector">
      </app-tag-selector>
    </div>
    <!-- FROM OTHER MODULE -->
    <div *ngIf="logbook.fromGOC" class="hol-dialog-section">
      <mat-checkbox [(ngModel)]="fromGOC" [labelPosition]="'after'" color="primary" [disabled]="isHistory || logbook.readOnly">
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        {{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.MODALS.FROM_GOC' | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="logbook.fromCREW" class="hol-dialog-section">
      <mat-checkbox [(ngModel)]="fromCREW" [labelPosition]="'after'" color="primary" [disabled]="isHistory || logbook.readOnly">
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        {{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.MODALS.FROM_CREW' | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="logbook.fromERP && hasCrisisInProgress" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="fromERP"
        [labelPosition]="'after'"
        color="primary"
        [disabled]="isHistory || logbook.readOnly || isReadOnly"
      >
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        {{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.MODALS.FROM_ERP' | translate }}
      </mat-checkbox>
    </div>
    <!-- TO OTHER MODULE -->
    <div *ngIf="moduleConfig.config.displayToERP && !logbook.fromERP" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="toERP"
        [labelPosition]="'after'"
        color="primary"
        [disabled]="isHistory || logbook.readOnly || !hasCrisisInProgress || !hasErpAccess"
      >
        {{ moduleConfig.config.translateKey + '.DASHBOARD.LOGBOOK.MODALS.TO_ERP' | translate }}
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
      </mat-checkbox>
    </div>
    <!-- LINKED EVENTS -->
    <div *ngIf="!isFromDecision" class="hol-dialog-section">
      <div *ngIf="linkedEvents; else linkedEventLoadingTemplate" class="linked-section">
        <div class="linked-section__top">
          <div class="left">
            <span class="title-read-only" *ngIf="(isReadOnly || modeLinkIsActivate) && linkedEventsDisplay.length">{{
              moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LABEL' | translate
            }}</span>
            <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
              <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LABEL' | translate }}</mat-label>
              <mat-select
                (ngModelChange)="changelinkedEvent($event)"
                [(ngModel)]="linkedEvents"
                [ngModelOptions]="{ standalone: true }"
                multiple
                ngModel
                [disabled]="events.length === 0"
              >
                <mat-select-trigger>
                  <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_SEARCH' | translate }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let event of events" [value]="event.objectId">{{
                  event.scenario.code + event.order + ' - ' + event.description
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="right">
            <button
              *ngIf="!isReadOnly && isCreate && !modeLinkIsActivate && moduleConfig.config.canCreateItemsFromLogbook"
              class="add-button mat-icon-button"
              color="primary"
              (click)="addEvent()"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="linked-section__bottom">
          <div *ngFor="let event of linkedEventsDisplay" class="linked-section__bottom__row">
            <div class="left">
              {{ event.scenario.code + event.order + ' - ' + event.description }}
              <span *ngIf="disabledLinks[event.objectId]">{{
                moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
              }}</span>
            </div>
            <div class="right">
              <button
                *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[event.objectId]"
                (click)="removeEvent(event)"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #linkedEventLoadingTemplate>
        <div class="linked-section">
          <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LOADING' | translate }}</span>
        </div>
      </ng-template>
    </div>
    <!-- LINKED DECISIONS -->
    <div *ngIf="!isFromDecision" class="hol-dialog-section">
      <div *ngIf="linkedDecisions; else linkedDecisionsLoadingTemplate" class="linked-section">
        <div class="linked-section__top">
          <div class="left">
            <span class="title-read-only" *ngIf="(isReadOnly || modeLinkIsActivate) && linkedDecisionsDisplay.length">{{
              moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.DECISION_LABEL' | translate
            }}</span>
            <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
              <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.DECISION_LABEL' | translate }}</mat-label>
              <mat-select
                (ngModelChange)="changelinkedDecision($event)"
                [(ngModel)]="linkedDecisions"
                [ngModelOptions]="{ standalone: true }"
                multiple
                ngModel
                [disabled]="decisionItems.length === 0"
              >
                <mat-select-trigger>
                  <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.DECISION_SEARCH' | translate }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let decision of decisionItems" [value]="decision.objectId">{{ decision.message }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="right">
            <button
              *ngIf="!isReadOnly && isCreate && !modeLinkIsActivate && moduleConfig.config.canCreateItemsFromLogbook"
              class="add-button mat-icon-button"
              color="primary"
              (click)="addDecision($event)"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="linked-section__bottom">
          <div *ngFor="let decision of linkedDecisionsDisplay" class="linked-section__bottom__row">
            <div class="left">
              {{ decision.message }}
              <span *ngIf="disabledLinks[decision.objectId]">{{
                moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
              }}</span>
            </div>
            <div class="right">
              <button
                *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[decision.objectId]"
                (click)="removeDecision(decision)"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #linkedDecisionsLoadingTemplate>
        <div class="linked-section">
          <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.DECISION_LOADING' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- COLUMN RIGHT -->
  <div class="content-right-top-addon">
    <!-- LINKED OCC FLIGHT -->
    <div *ngIf="moduleConfig.config.showLinkToFlightOcc" class="hol-dialog-section">
      <div class="linked-flight">
        <label id="favoriteStateLabel">{{
          moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.FLIGHT' | translate
        }}</label>
        <div class="date input-group">
          <mat-form-field class="full-width no-label">
            <input
              [disabled]="isReadOnly"
              tabindex="-1"
              [matDatepicker]="flightPicker"
              [ngModelOptions]="{ timezone: 'utc', standalone: true }"
              (ngModelChange)="changeDate($event)"
              [ngModel]="searchData.date"
              matInput
              placeholder="DD/MM/YYYY"
            />
            <mat-datepicker-toggle [for]="flightPicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #flightPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flight">
          <mat-form-field>
            <span matPrefix class="icon-container">
              <i class="hol-icon-airplane-mode-active"></i>
            </span>
            <input
              type="text"
              matInput
              [matAutocomplete]="auto"
              name="searchDataFight"
              [disabled]="isReadOnly || !searchData.date || searchData.flights.length === 0"
              placeholder="{{ autocompletePlaceHolder }}"
              [ngModelOptions]="{ standalone: true }"
              [value]="searchData.flightNumber"
              [ngModel]="searchData.flightNumber"
              (ngModelChange)="querySearch($event)"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedItemChange($event)">
              <mat-option *ngFor="let item of searchData.flightsFiltered" [value]="item.objectId">
                {{ item.flightNumber }} {{ item.std | date: 'HH:mm ddMMM':'UTC' }}
              </mat-option>
              <mat-option *ngIf="searchData.flightsFiltered.length === 0 && searchData.date"
                >No results found with this search.
              </mat-option>
            </mat-autocomplete>
            <button
              *ngIf="selectedFlight && !isReadOnly"
              mat-icon-button
              matSuffix
              (click)="resetFlight()"
              mat-icon-button
              aria-label="Clear"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- PINNED BTN -->
  <div class="actions-right-addon">
    <div *ngIf="!isReadOnly" class="pinned-item-btn" [ngClass]="{ isPinned: logbook.isPinned }">
      <mat-checkbox [(ngModel)]="logbook.isPinned" aria-label="is Pinned" color="primary">
        <i class="hol-icon-pin"></i>
      </mat-checkbox>
    </div>
    <div *ngIf="isReadOnly && logbook.isPinned" class="pinned-item-btn isPinned">
      <i class="hol-icon-pin"></i>
    </div>
  </div>

  <div class="actions-addon" *ngIf="!logbook.readOnly && (logbook.fromGOC || logbook.fromCREW)">
    <button class="hol-validate-btn-secondary" mat-raised-button (click)="saveLogbook()">
      <span>{{ 'DASHBOARD.MODAL.UPDATE_BUTTON' | translate }}</span>
    </button>
  </div>
</app-hol-modal>
