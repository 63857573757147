import { Inject, Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { RolesService } from './roles.service';
import * as moment from 'moment';
import { HolManagerFunction, HolUserFunctionWithUser } from '../store/hol-managers.selectors';
import { OclHistoryLog } from '../../ocl/models/ocl-history-log.model';
import { HolManagerUser } from '../models/hol-manager-user';
import { HolOptionsService } from './hol-options.service';
import { OccHistoryService } from '../../occ/services/occ-history-service/occ-history.service';
import { EclHistoryService } from '../../ecl/services/ecl-history-service/ecl-history.service';
import { GocHistoryService } from '../../goc/services/goc-history-service/goc-history.service';
import { OpsHistoryService } from '../../ops/services/ops-history-service/ops-history.service';
import { OclHistoryService } from '../../ocl/services/ocl-history-service/ocl-history.service';
import { HolUserFunction } from '../models/hol-user-function';
import { CommonStoreManager } from '../store/common.store-manager';

@Injectable({
  providedIn: 'root',
})
export class HolManagersService {
  // tslint:disable:variable-name
  protected ParseHolUserFunction = Parse.Object.extend('HOLUserFunction');

  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    @Inject('FunctionService') protected functionService,
    @Inject('UserService') protected userService,
    protected holOptionsService: HolOptionsService,
    protected occHistoryService: OccHistoryService,
    protected eclHistoryService: EclHistoryService,
    protected gocHistoryService: GocHistoryService,
    protected opsHistoryService: OpsHistoryService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager
  ) {}

  //
  // ---
  //

  public updateManagerFunction(managerFunction: HolManagerFunction, prevManagerFunction: HolManagerFunction): Promise<HolUserFunction[]> {
    const ufsToUpdate = [];
    managerFunction.companies.forEach((company, i) => {
      const prevCompany = prevManagerFunction.companies[i];
      // Si on a un nouveau holder,
      // et (qu'il n'y avait pas d'ancien, ou qu'il a changé ou qu'on a changé le expiresAt)
      // on met a jour la uf
      if (
        company.holder &&
        (!prevCompany.holder ||
          company.holder.objectId !== prevCompany.holder.objectId ||
          !moment(company.holder.expiredAtNextInfo.nextInfoTime).isSame(prevCompany.holder.expiredAtNextInfo.nextInfoTime))
      ) {
        const uf = new this.ParseHolUserFunction({ id: company.holder.objectId });
        uf.set('isHolder', true);
        if (company.holder.expiredAtNextInfo.nextInfoTime) {
          uf.set('expiredAt', company.holder.expiredAtNextInfo.nextInfoTime);
        } else {
          uf.unset('expiredAt');
        }
        ufsToUpdate.push(uf);

        if (!prevCompany.holder || company.holder.objectId !== prevCompany.holder.objectId) {
          // changements de titulaire => log
          this.logManagerChange(managerFunction, company);
        }
      }
      // Si on a un ancien holder,
      // et (qu'il n'y plus de holder, ou qu'il a changé)
      // on met a jour la uf
      if (prevCompany.holder && (!company.holder || company.holder.objectId !== prevCompany.holder.objectId)) {
        const uf = new this.ParseHolUserFunction({ id: prevCompany.holder.objectId });
        uf.set('isHolder', false);
        uf.unset('expiredAt');
        ufsToUpdate.push(uf);
      }
    });
    return this.requestService.performSaveAllQuery(ufsToUpdate).then(parseUfs => {
      const ufs = parseUfs.map(uf => new HolUserFunction(uf));
      this.commonStoreManager.updateManyUserFunctions(ufs);
      return ufs;
    });
  }

  private logManagerChange(
    managerFunction: HolManagerFunction,
    company: { name: string; users: HolUserFunctionWithUser[]; holder: HolUserFunctionWithUser }
  ) {
    managerFunction.modules.forEach(module => {
      const historyService = this.getHistoryService(module);
      if (historyService) {
        if (this.holOptionsService.getFunctionsIdsForManagerByModule(module).includes(managerFunction.functionId)) {
          historyService
            .postShiftSupervisorLog(this._getLogMessage(managerFunction.title, company.holder.user), company.holder.user)
            .then();
        } else {
          historyService.postLog(OclHistoryLog.create(this._getLogMessage(managerFunction.title, company.holder.user), 'managers')).then();
        }
      }
    });
  }

  private getHistoryService(module: string): OclHistoryService {
    switch (module) {
      case 'OCC':
        return this.occHistoryService;
      case 'ECL':
        return this.eclHistoryService;
      case 'GOC':
        return this.gocHistoryService;
      case 'OPS':
        return this.opsHistoryService;
    }
  }

  private _getLogMessage(functionTitle: string, selectedUser: HolManagerUser) {
    if (selectedUser) {
      return selectedUser.fullName + ' has been appointed has on duty manager for ' + functionTitle;
    } else {
      return 'The on duty manager has been removed for function ' + functionTitle + ' (nobody is affected)';
    }
  }
}
