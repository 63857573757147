import { cloneDeep } from 'lodash';
import { OclGroupsActions, OclGroupsActionTypes } from './ocl-groups.actions';
import { OclGroupsStoreState } from '../../ocl.model';

const groupsInitialState: OclGroupsStoreState = {
  events: [],
  logbooks: [],
  decisions: [],
  globalInstructions: [],
};

export function oclGroupsReducer(
  state: OclGroupsStoreState = cloneDeep(groupsInitialState),
  action: OclGroupsActions
): OclGroupsStoreState {
  switch (action.type) {
    case OclGroupsActionTypes.INIT_GROUPS:
      return {
        ...state,
        [action.typeKey]: action.groups,
      };
    case OclGroupsActionTypes.UPDATE_GROUPS_FROM_POOLING:
      return {
        ...state,
        [action.typeKey]: action.groups,
      };

    default:
      return state;
  }
}
