import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppState } from 'src/app/store';

import { Store } from '@ngrx/store';

import { AddOrUpdateLinkedDiplayedItems, RemoveLinkedDiplayedItems } from './ocl-linked-displayed-items.actions';

@Injectable({
  providedIn: 'root',
})
export class OclLinkedDisplayedItemsStoreManager {
  private _linkedDisplayedItemsState: Observable<any>;

  constructor(private store: Store<AppState>) {
    this._linkedDisplayedItemsState = this.store.select('ocl', 'linkedDisplayedItems');
  }

  get linkedDisplayedItemsState(): Observable<any> {
    return this._linkedDisplayedItemsState;
  }

  addOrUpdateLinkedDiplayedItems(linkedUid: string, color: string, itemsToUpdate: any[]): void {
    this.store.dispatch(new AddOrUpdateLinkedDiplayedItems(linkedUid, color, itemsToUpdate));
  }

  removeLinkedDiplayedItems(linkedUid: string, color: string): void {
    this.store.dispatch(new RemoveLinkedDiplayedItems(linkedUid, color));
  }
}
