import { OclTask } from './../../models/ocl-task';
import { Inject, Injectable } from '@angular/core';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export abstract class OclMailService {
  protected SENDER_SUFFIX = '';

  protected constructor(
    @Inject('MailSenderService') protected mailSenderService,
    @Inject('$translate') protected $translate,
    @Inject('CONSTANTS') protected CONSTANTS,
    @Inject('MailTemplateService') protected mailTemplateService,
    protected moduleConfig: ModuleConfigService
  ) {}

  public sendNewDecisionMail(decision, mails) {
    if (!decision) {
      console.warn('MailService::sendNewDecisionMail: decision empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewDecisionMail: no mailing list found');
    }
    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_DECISION.SUBJECT'),
        contentHtml: this.mailTemplateService.getDecisionMessage(decision),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  public sendEclActivatedMail(event, infos, mails) {
    if (!event) {
      console.warn('MailService::sendEclActivatedMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendEclActivatedMail: no mailing list found');
    }
    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.ECL_ACTIVATED_SUBJECT', {
          code: event.scenario ? event.scenario.code : undefined,
          order: event.order,
          scenario: event.scenario ? '- ' + event.scenario.title : undefined,
        }),
        contentHtml: this.mailTemplateService.getEclActivatedMessage(event, infos, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  public sendNewEventMail(event, infos, mails) {
    if (!event) {
      console.warn('MailService::sendNewEventMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewEventMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_EVENT.SUBJECT', {
          code: event.scenario ? event.scenario.code : undefined,
          order: event.order,
          scenario: event.scenario ? '- ' + event.scenario.title : undefined,
        }),
        contentHtml: this.mailTemplateService.getNewEventMessage(event, infos, false, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  public sendNewEventInfoMail(event, eventInfo, mails) {
    if (!event) {
      console.warn('MailService::sendNewEventInfoMail: event empty');
    }
    if (!eventInfo) {
      console.warn('MailService::sendNewEventInfoMail: eventInfo empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewEventInfoMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_INFO.SUBJECT', {
          code: event.scenario ? event.scenario.code : undefined,
          order: event.order,
          scenario: event.scenario ? ' - ' + event.scenario.title : undefined,
          nbInfos: event.infos.length,
        }),
        contentHtml: this.mailTemplateService.getNewEventInfoMessage(event, eventInfo, false, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  public sendCloseEventMail(event, mails, closeDate) {
    if (!event) {
      console.warn('MailService::sendCloseEventMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendCloseEventMail: no mailing list found');
    }
    let closeReason;
    if (event.closeReason === 0) {
      closeReason = this.$translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_1');
    } else if (event.closeReason === 1) {
      closeReason = this.$translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_2');
    } else {
      closeReason = event.closeReasonText;
    }

    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.CLOSE_EVENT.SUBJECT', {
          code: event.scenario ? event.scenario.code : undefined,
          order: event.order,
          scenario: event.scenario ? ' - ' + event.scenario.title : undefined,
        }),
        contentHtml: this.mailTemplateService.getCloseEventMessage(event, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
        variables: {
          subtitle: closeReason,
        },
      },
      true,
      true
    );
  }

  public sendNewEventChecklistActivatedMail(event, infos, mails) {
    if (!event) {
      console.warn('MailService::sendNewEventChecklistActivatedMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewEventChecklistActivatedMail: no mailing list found');
    }

    const subject = this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_EVENT.SUBJECT', {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      scenario: event.scenario ? '- ' + event.scenario.title : undefined,
    });

    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject,
        contentHtml: this.mailTemplateService.getNewEventMessage(event, infos, true, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  public sendNewEventInfoChecklistActivatedMail(event, infos, mails) {
    if (!event) {
      console.warn('MailService::sendNewEventInfoChecklistActivatedMail: event empty');
    }
    if (!infos) {
      console.warn('MailService::sendNewEventInfoChecklistActivatedMail: eventInfo empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewEventInfoChecklistActivatedMail: no mailing list found');
    }

    const subject = this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_INFO.SUBJECT', {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      scenario: event.scenario ? ' - ' + event.scenario.title : undefined,
      nbInfos: event.infos.length,
    });

    infos.forEach(eventInfo => {
      this.mailSenderService.sendMail(
        {
          recipients: mails.map(m => {
            return { email: m };
          }),
          subject,
          contentHtml: this.mailTemplateService.getNewEventInfoMessage(event, eventInfo, true, this.moduleConfig.config.translateKey),
          sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
        },
        true,
        true
      );
    });
  }

  public sendNewLogbookMail(logbook, mails) {
    if (!logbook) {
      console.warn('MailService::sendNewLogbookMail: logbook empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewLogbookMail: no mailing list found');
    }
    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_LOGBOOK.SUBJECT'),
        contentHtml: this.mailTemplateService.getLogbookMessage(logbook),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  sendTaskDoneMail(task: OclTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!task) {
      console.warn('OclMailService::sendTaskDoneMail: task empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('OclMailService::sendTaskDoneMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_DONE.SUBJECT'),
        contentHtml: this.getTaskDoneMail(task, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  protected getTaskDoneMail(task: OclTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(task.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(task.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_DONE.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskResult: task.outputTitle,
      functionTitle,
    });
    if (comment) {
      msg += '&nbsp;';
      msg +=
        this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_DONE.SECOND_PART', {
          comment,
        }) + '&nbsp;';
    }
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  sendTaskFrozenMail(task: OclTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!task) {
      console.warn('OclMailService::sendTaskFrozenMail: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('OclMailService::sendTaskFrozenMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_FROZEN.SUBJECT'),
        contentHtml: this.getTaskFrozenMail(task, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  protected getTaskFrozenMail(task: OclTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(task.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(task.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_FROZEN.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskResult: task.outputTitle,
      functionTitle,
      comment,
    });
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  sendTaskBackTodoMail(task: OclTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!task) {
      console.warn('OclMailService::sendTaskBackTodoMail: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('OclMailService::sendTaskBackTodoMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.SUBJECT'),
        contentHtml: this.getTaskBackTodoMail(task, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true
    );
  }

  protected getTaskBackTodoMail(task: OclTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(task.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(task.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskResult: task.outputTitle,
      functionTitle,
    });
    if (comment) {
      msg += '&nbsp;';
      msg +=
        this.$translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.SECOND_PART', {
          comment,
        }) + '&nbsp;';
    }
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }
}
