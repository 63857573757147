(function () {
  'use strict';
  angular.module('gestiondecriseApp').factory('MailTemplateService', mailTemplateService);

  mailTemplateService.$inject = ['$translate', 'UserService', '$log'];

  function mailTemplateService($translate, UserService, $log) {
    var P_SEPARATOR_HTML = '<br/><br/>';

    return {
      getDecisionMessage: getDecisionMessage,
      getNewEventMessage: getEventMessage,
      getEclActivatedMessage: getEclActivatedMessage,
      getNewEventInfoMessage: getNewEventInfoMessage,
      getNewMELInfoMessage: getNewMELInfoMessage,
      getCloseEventMessage: getCloseEventMessage,
      getSituationMessage: getSituationMessage,
      getNewMCCEventMessage: getMCCEventMessage,
      getNewMCCEventInfoMessage: getNewMCCEventInfoMessage,
      getLogbookMessage: getLogbookMessage,
    };

    function getDecisionMessage(decision) {
      var msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
      msg +=
        '<td width="1px" style="white-space: nowrap">' +
        moment(decision.createdAt).utc().format('HH:mm[UTC]') +
        '<br/>' +
        moment(decision.createdAt).utc().format('ddd[&nbsp;]DD') +
        '</td>';
      if (decision.event) {
        var eventName = (decision.event.scenario ? decision.event.scenario.code : '') + decision.event.order;
        msg +=
          '<td width="1px" align="center"><span style="padding:5px 10px; background-color: #F1F5F5; color: #3F6185; border-radius: 4px;">' +
          eventName +
          '</span></td>';
      }
      msg += '<td>' + decision.message + '</td>';
      if (
        decision.attachments &&
        (decision.attachments.note || decision.attachments.noteFile || decision.attachments.file || decision.attachments.image)
      ) {
        msg += '<td width="1" style="white-space: nowrap">';
        if (decision.attachments.noteFile) {
          msg +=
            '<a href="' +
            decision.attachments.noteFile.url +
            '" target="_blank" title="' +
            decision.attachments.noteFile.fileName +
            '">📝</a>&nbsp;';
        } else if (decision.attachments.note) {
          msg += '<span title="' + decision.attachments.note + '">📝</span>&nbsp;';
        }
        if (decision.attachments.file) {
          msg += '<a title="' + decision.attachments.file.fileName + '" href="' + decision.attachments.file.url + '">📎</a>&nbsp;';
        }
        if (decision.attachments.image) {
          msg += '<a title="' + decision.attachments.image.fileName + '" href="' + decision.attachments.image.url + '">🖼</a>&nbsp;';
        }
        msg += '</td>';
      }

      if (decision.nextInfoTime) {
        msg +=
          '<td width="1px" align="right">►' +
          moment(decision.nextInfoTime).utc().format('HH:mm[UTC]') +
          '<br/>' +
          moment(decision.nextInfoTime).utc().format('ddd[&nbsp;]DD') +
          '</td>';
      }
      msg += '</tr></table>';

      return msg;
    }

    function getEclActivatedMessage(event, infos, translateKey) {
      if (!translateKey) {
        translateKey = 'OCC';
      }
      var msg = '';

      msg +=
        '<tr><td bgcolor="#ffffff"><table cellpadding="0" cellspacing="0" width="100%" style="width:100%!important" align="center"><tbody><tr><td width="35px"></td><td style="padding-top:0px;padding-bottom:30px;border-bottom:1px solid #e0e0e0"><table align="center" cellpadding="0" cellspacing="0" width="100%" style="width:100%!important"><tbody><tr>';
      msg += $translate.instant('MAIL.' + translateKey + '.ECL_ACTIVATED', { eclUrl: location.origin + '/#/ecl/dashboard' });
      msg += '</td></tr><tr><td align="center"></td></tr></tbody></table></td><td width="35px"></td></tr></tbody></table></td></tr>';
      msg += '<tr><td style="padding-bottom: 20px;"></td></tr>';
      msg +=
        '<table style="border: 1px solid #E7E7E7; background-color: #F1F5F5; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%">';

      msg += '<tr>';
      msg += '<td style="border-bottom: 1px solid #E7E7E7">';
      msg += '<table width="100%" style="width:100%!important;">';

      msg += '<tr style="color: #3F6185">';
      msg += '<td width="1px"><strong>' + (event.scenario ? event.scenario.code : '') + event.order + '</strong></td>';
      msg += '<td style="padding-left: 5px;"><strong>' + (event.scenario ? event.scenario.title : '') + '</strong></td>';
      msg += '</tr>';

      msg += '<tr>';
      msg +=
        '<td  width="1px" style="white-space: nowrap">' +
        moment(event.createdAt).utc().format('HH:mm[UTC]') +
        '<br/>' +
        moment(event.createdAt).utc().format('ddd[&nbsp;]DD') +
        '</td>';
      msg += '<td style="padding-left: 5px;"><strong>' + event.description + '</strong><br/>';

      if (event.attachments) {
        if (event.attachments.noteFile) {
          msg +=
            '<a href="' +
            event.attachments.noteFile.url +
            '" target="_blank" title="' +
            event.attachments.noteFile.fileName +
            '">📝</a>&nbsp;';
        } else if (event.attachments.note) {
          msg += '<span title="' + event.attachments.note + '">📝</span>&nbsp;';
        }
        if (event.attachments.file) {
          msg += '<a title="' + event.attachments.file.fileName + '" href="' + event.attachments.file.url + '">📎</a>&nbsp;';
        }
        if (event.attachments.image) {
          msg += '<a title="' + event.attachments.image.fileName + '" href="' + event.attachments.image.url + '">🖼</a>&nbsp;';
        }
      }

      msg += '</td>';
      msg += '</tr>';

      msg += '</table>';
      msg += '</td>';
      msg += '</tr>';

      if (infos && infos.length) {
        var info = infos[0].info;
        msg += '<tr>';
        msg += '<td>';
        msg += '<table width="100%" style="width:100%!important;">';

        msg += '<tr>';
        msg += '<td>';
        msg += info.message + '<br/>';

        if (info.attachments) {
          if (info.attachments.noteFile) {
            msg +=
              '<a href="' +
              info.attachments.noteFile.url +
              '" target="_blank" title="' +
              info.attachments.noteFile.fileName +
              '">📝</a>&nbsp;';
          } else if (info.attachments.note) {
            msg += '<span title="' + info.attachments.note + '">📝</span>&nbsp;';
          }
          if (info.attachments.file) {
            msg += '<a title="' + info.attachments.file.fileName + '" href="' + info.attachments.file.url + '">📎</a>&nbsp;';
          }
          if (info.attachments.image) {
            msg += '<a title="' + info.attachments.image.fileName + '" href="' + info.attachments.image.url + '">🖼</a>&nbsp;';
          }
        }
        msg += '</td>';
        msg += '</tr>';

        msg += '<tr>';
        msg += '<td align="right">' + moment(info.nextInfoTime).utc().format('[Next info] ddd DD [at] HH:mm[UTC]') + '</td>';
        msg += '</tr>';

        msg += '</table>';
      }

      msg += '</td></tr></table>';
      msg +=
        '<p style="color: black">' +
        $translate.instant('MAIL.' + translateKey + '.NEW_EVENT.BODY_END', { appUrl: location.origin }) +
        '</p>';

      if (event.infos.length) {
        msg += $translate.instant('MAIL.' + translateKey + '.NEW_EVENT_INFO.BODY_PREVIOUS_INFO');

        for (var i = 1; i < event.infos.length; i++) {
          var info = event.infos[i];
          msg += getEventInfoMessage(info);
        }
      }

      return msg;
    }

    function getEventMessage(event, infos, isFromChecklistActivate, translateKey) {
      if (!translateKey) {
        translateKey = 'OCC';
      }
      var msg =
        '<table style="border: 1px solid #E7E7E7; background-color: #F1F5F5; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%">';

      msg += '<tr>';
      msg += '<td style="border-bottom: 1px solid #E7E7E7">';
      msg += '<table width="100%" style="width:100%!important;">';

      msg += '<tr style="color: #3F6185">';
      msg += '<td width="1px"><strong>' + (event.scenario ? event.scenario.code : '') + event.order + '</strong></td>';
      msg += '<td style="padding-left: 5px;"><strong>' + (event.scenario ? event.scenario.title : '') + '</strong></td>';
      msg += '</tr>';

      msg += '<tr>';
      msg +=
        '<td  width="1px" style="white-space: nowrap">' +
        moment(event.createdAt).utc().format('HH:mm[UTC]') +
        '<br/>' +
        moment(event.createdAt).utc().format('ddd[&nbsp;]DD') +
        '</td>';
      msg += '<td style="padding-left: 5px;"><strong>' + event.description + '</strong><br/>';

      if (event.attachments) {
        if (event.attachments.noteFile) {
          msg +=
            '<a href="' +
            event.attachments.noteFile.url +
            '" target="_blank" title="' +
            event.attachments.noteFile.fileName +
            '">📝</a>&nbsp;';
        } else if (event.attachments.note) {
          msg += '<span title="' + event.attachments.note + '">📝</span>&nbsp;';
        }
        if (event.attachments.file) {
          msg += '<a title="' + event.attachments.file.fileName + '" href="' + event.attachments.file.url + '">📎</a>&nbsp;';
        }
        if (event.attachments.image) {
          msg += '<a title="' + event.attachments.image.fileName + '" href="' + event.attachments.image.url + '">🖼</a>&nbsp;';
        }
      }

      msg += '</td>';
      msg += '</tr>';

      if (isFromChecklistActivate) {
        msg +=
          '<td> <td style="padding-left: 5px;"><strong>' +
          $translate.instant('MAIL.' + translateKey + '.NEW_EVENT.CHECK_LIST_INFOS') +
          '</strong><br/></td></tr>';
      }

      msg += '</table>';
      msg += '</td>';
      msg += '</tr>';

      if (infos && infos.length) {
        var info = infos[0].info;
        msg += '<tr>';
        msg += '<td>';
        msg += '<table width="100%" style="width:100%!important;">';

        msg += '<tr>';
        msg += '<td>';
        msg += info.message + '<br/>';

        if (info.attachments) {
          if (info.attachments.noteFile) {
            msg +=
              '<a href="' +
              info.attachments.noteFile.url +
              '" target="_blank" title="' +
              info.attachments.noteFile.fileName +
              '">📝</a>&nbsp;';
          } else if (info.attachments.note) {
            msg += '<span title="' + info.attachments.note + '">📝</span>&nbsp;';
          }
          if (info.attachments.file) {
            msg += '<a title="' + info.attachments.file.fileName + '" href="' + info.attachments.file.url + '">📎</a>&nbsp;';
          }
          if (info.attachments.image) {
            msg += '<a title="' + info.attachments.image.fileName + '" href="' + info.attachments.image.url + '">🖼</a>&nbsp;';
          }
        }
        msg += '</td>';
        msg += '</tr>';

        msg += '<tr>';
        msg += '<td align="right">' + moment(info.nextInfoTime).utc().format('[Next info] ddd DD [at] HH:mm[UTC]') + '</td>';
        msg += '</tr>';

        msg += '</table>';
      }

      msg += '</td></tr></table>';
      msg +=
        '<p style="color: black">' +
        $translate.instant('MAIL.' + translateKey + '.NEW_EVENT.BODY_END', { appUrl: location.origin }) +
        '</p>';

      return msg;
    }

    function getMELMessage(mel) {
      var msg =
        '<table style="border: 1px solid #E7E7E7; background-color: #F1F5F5; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%">';

      msg += '<tr>';
      msg += '<td style="border-bottom: 1px solid #E7E7E7">';
      msg += '<table width="100%" style="width:100%!important;">';

      msg += '<tr>';
      msg +=
        '<td  width="1px" style="white-space: nowrap">' +
        moment(mel.foundDate).utc().format('ddd DD/MM') +
        ' > ' +
        moment(mel.dueDate).utc().format('ddd DD/MM') +
        '</td>';
      msg += '<td style="padding-left: 5px;"><strong>' + mel.reference + ' (' + mel.faultType + ') ' + mel.faultName + '</strong><br/>';

      msg += '</td>';
      msg += '</tr>';

      msg += '</table>';
      msg += '</td>';
      msg += '</tr>';

      msg += '</table>';

      return msg;
    }

    function getNewEventInfoMessage(event, eventInfo, isFromChecklistActivate, translateKey) {
      if (!translateKey) {
        translateKey = 'OCC';
      }
      var msg = 'A new information has been added to this event: ' + P_SEPARATOR_HTML;

      msg += getEventMessage(event, [], isFromChecklistActivate, translateKey);

      msg += $translate.instant('MAIL.' + translateKey + '.NEW_EVENT_INFO.BODY_NEW_INFO');
      msg += getEventInfoMessage(eventInfo);

      if (event.infos.length) {
        msg += $translate.instant('MAIL.' + translateKey + '.NEW_EVENT_INFO.BODY_PREVIOUS_INFO');

        for (var i = 1; i < event.infos.length; i++) {
          var info = event.infos[i];
          msg += getEventInfoMessage(info);
        }
      }

      return msg;
    }

    function getNewMCCEventInfoMessage(event, eventInfo) {
      var msg = 'A new information has been added to this event: ' + P_SEPARATOR_HTML;

      msg += getMCCEventMessage(event);

      msg += $translate.instant('MAIL.OCC.NEW_EVENT_INFO.BODY_NEW_INFO');
      msg += getEventInfoMessage(eventInfo);

      if (event.infos.length) {
        msg += $translate.instant('MAIL.OCC.NEW_EVENT_INFO.BODY_PREVIOUS_INFO');

        for (var i = 1; i < event.infos.length; i++) {
          var info = event.infos[i];
          msg += getEventInfoMessage(info);
        }
      }

      return msg;
    }

    function getNewMELInfoMessage(mel, melInfo) {
      var msg = 'A new information has been added to this MEL: ' + P_SEPARATOR_HTML;

      msg += getMELMessage(mel);

      msg += $translate.instant('MAIL.MCC.NEW_MEL_INFO.BODY_NEW_INFO');
      msg += getEventInfoMessage(melInfo);

      if (mel.infos.length) {
        msg += $translate.instant('MAIL.MCC.NEW_MEL_INFO.BODY_PREVIOUS_INFO');

        for (var i = 1; i < mel.infos.length; i++) {
          var info = mel.infos[i];
          msg += getEventInfoMessage(info);
        }
      }

      return msg;
    }

    function getCloseEventMessage(event, translateKey) {
      if (!translateKey) {
        translateKey = 'OCC';
      }
      var closeReason;
      if (event.closeReason === 0) {
        closeReason = $translate.instant(translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_1');
      } else if (event.closeReason === 1) {
        closeReason = $translate.instant(translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_2');
      } else {
        closeReason = event.closeReasonText;
      }
      var msg = $translate.instant('MAIL.' + translateKey + '.CLOSE_EVENT.BODY_CLOSE_REASON', {
        closeReason: closeReason,
      });

      msg += getEventMessage(event, null, null, translateKey);

      if (event.infos.length) {
        msg += $translate.instant('MAIL.' + translateKey + '.CLOSE_EVENT.BODY_LAST_INFOS');
      }

      for (var i = 0; i < event.infos.length; i++) {
        var info = event.infos[i];
        msg += getEventInfoMessage(info);
      }

      return msg;
    }

    function getEventInfoMessage(info) {
      var msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
      msg +=
        '<td width="1px"  style="white-space: nowrap">' +
        moment(info.createdAt).utc().format('HH:mm[UTC]') +
        '<br/>' +
        moment(info.createdAt).utc().format('ddd[&nbsp;]DD') +
        '</td>';
      msg += '<td>' + info.message + '</td>';
      if (info.attachments && (info.attachments.note || info.attachments.noteFile || info.attachments.file || info.attachments.image)) {
        msg += '<td width="1" style="white-space: nowrap">';
        if (info.attachments.noteFile) {
          msg +=
            '<a href="' +
            info.attachments.noteFile.url +
            '" target="_blank" title="' +
            info.attachments.noteFile.fileName +
            '">📝</a>&nbsp;';
        } else if (info.attachments.note) {
          msg += '<span title="' + info.attachments.note + '">📝</span>&nbsp;';
        }
        if (info.attachments.file) {
          msg += '<a title="' + info.attachments.file.fileName + '" href="' + info.attachments.file.url + '">📎</a>&nbsp;';
        }
        if (info.attachments.image) {
          msg += '<a title="' + info.attachments.image.fileName + '" href="' + info.attachments.image.url + '">🖼</a>&nbsp;';
        }
        msg += '</td>';
      }

      if (info.nextInfoTime) {
        msg +=
          '<td width="1px" align="right">►' +
          moment(info.nextInfoTime).utc().format('HH:mm[UTC]') +
          '<br/>' +
          moment(info.nextInfoTime).utc().format('ddd[&nbsp;]DD') +
          '</td>';
      }
      msg += '</tr></table>';

      return msg;
    }

    function getSituationMessage(situation) {
      var translateKey = 'MAIL.CREW.NEW_SITUATION_' + situation.direction;

      return $translate.instant(translateKey, situation);
    }

    function getMCCEventMessage(event, infos) {
      var msg =
        '<table style="border: 1px solid #E7E7E7; background-color: #F1F5F5; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%">';

      msg += '<tr>';
      msg += '<td style="border-bottom: 1px solid #E7E7E7">';
      msg += '<table width="100%" style="width:100%!important;">';

      msg += '<tr style="color: #3F6185">';
      msg +=
        '<td><strong>' +
        (event.ataCode ? event.ataCode.code : '') +
        ' - ' +
        (event.ataCode ? event.ataCode.description : '') +
        '</strong></td>';
      msg +=
        '<td width="1px" style="padding-left: 5px; white-space: nowrap"><strong>' +
        (event.aircraft ? event.aircraft.registration : '') +
        '</strong></td>';
      msg += '</tr>';

      msg += '<tr>';
      msg += '<td><strong>' + event.description + '</strong></td>';
      msg += '<td width="1px" style="padding-left: 5px; white-space: nowrap"><strong>' + event.localisation + '</strong><br/>';

      if (event.attachments) {
        if (event.attachments.noteFile) {
          msg +=
            '<a href="' +
            event.attachments.noteFile.url +
            '" target="_blank" title="' +
            event.attachments.noteFile.fileName +
            '">📝</a>&nbsp;';
        } else if (event.attachments.note) {
          msg += '<span title="' + event.attachments.note + '">📝</span>&nbsp;';
        }
        if (event.attachments.file) {
          msg += '<a title="' + event.attachments.file.fileName + '" href="' + event.attachments.file.url + '">📎</a>&nbsp;';
        }
        if (event.attachments.image) {
          msg += '<a title="' + event.attachments.image.fileName + '" href="' + event.attachments.image.url + '">🖼</a>&nbsp;';
        }
      }

      msg += '</td>';
      msg += '</tr>';

      msg += '</table>';
      msg += '</td>';
      msg += '</tr>';

      if (infos) {
        var info = infos[0];
        msg += '<tr>';
        msg += '<td>';
        msg += '<table width="100%" style="width:100%!important;">';

        msg += '<tr>';
        msg += '<td>';
        msg += info.message + '<br/>';

        if (info.attachments) {
          if (info.attachments.noteFile) {
            msg +=
              '<a href="' +
              info.attachments.noteFile.url +
              '" target="_blank" title="' +
              info.attachments.noteFile.fileName +
              '">📝</a>&nbsp;';
          } else if (info.attachments.note) {
            msg += '<span title="' + info.attachments.note + '">📝</span>&nbsp;';
          }
          if (info.attachments.file) {
            msg += '<a title="' + info.attachments.file.fileName + '" href="' + info.attachments.file.url + '">📎</a>&nbsp;';
          }
          if (info.attachments.image) {
            msg += '<a title="' + info.attachments.image.fileName + '" href="' + info.attachments.image.url + '">🖼</a>&nbsp;';
          }
        }
        msg += '</td>';
        msg += '</tr>';

        msg += '<tr>';
        msg += '<td align="right">' + moment(info.nextInfoTime).utc().format('[Next info] ddd DD [at] HH:mm[UTC]') + '</td>';
        msg += '</tr>';

        msg += '</table></td></tr>';
      }

      msg += '</table>';

      return msg;
    }

    function getLogbookMessage(logbook) {
      var msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
      msg +=
        '<td width="1px" style="white-space: nowrap">' +
        moment(logbook.createdAt).utc().format('HH:mm[UTC]') +
        '<br/>' +
        moment(logbook.createdAt).utc().format('ddd[&nbsp;]DD') +
        '</td>';
      msg += '<td>' + logbook.text + '</td>';
      if (
        logbook.attachments &&
        (logbook.attachments.note || logbook.attachments.noteFile || logbook.attachments.file || logbook.attachments.image)
      ) {
        msg += '<td width="1" style="white-space: nowrap">';
        if (logbook.attachments.noteFile) {
          msg +=
            '<a href="' +
            logbook.attachments.noteFile.url +
            '" target="_blank" title="' +
            logbook.attachments.noteFile.fileName +
            '">📝</a>&nbsp;';
        } else if (logbook.attachments.note) {
          msg += '<span title="' + logbook.attachments.note + '">📝</span>&nbsp;';
        }
        if (logbook.attachments.file) {
          msg += '<a title="' + logbook.attachments.file.fileName + '" href="' + logbook.attachments.file.url + '">📎</a>&nbsp;';
        }
        if (logbook.attachments.image) {
          msg += '<a title="' + logbook.attachments.image.fileName + '" href="' + logbook.attachments.image.url + '">🖼</a>&nbsp;';
        }
        msg += '</td>';
      }
      msg += '</tr></table>';

      return msg;
    }
  }
})();
