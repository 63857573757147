import { AclFilterComponent } from './common/components/acl-filter/acl-filter.component';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionBoxComponent } from './ocl/components/ocl-global-instruction-box/ocl-global-instruction-box.component';
import { CrisisService } from './common/services/crisis.service';
import { ErpSmsService } from './erp/services/erp-sms.service';
import { ErpMailService } from './erp/services/erp-mail.service';
import { ErpHistoryService } from './erp/services/erp-history.service';
import { OptionsService } from 'src/app/common/services/options.service';
import { ErrorHandler, Injectable, NgModule, NgModuleFactoryLoader, SystemJsNgModuleLoader } from '@angular/core';
import { downgradeComponent, downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';
import { NgHybridStateDeclaration, UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import * as Sentry from '@sentry/browser';
import { TirednessIndicatorService } from './crew/services/tiredness-indicator.service';
import { ParseMapperService } from './common/services/parse-mapper.service';
import * as angular from 'angular';
import { CrewEventsBoxComponent } from './crew/components/crew-events-box/crew-events-box.component';
import { TirednessIndicatorComponent } from './crew/common/tiredness-indicator/tiredness-indicator.component';
import { NotificationsService } from './common/services/notifications/notifications.service';
import { FileAndImageUploadComponent } from './common/components/file-and-image-upload/file-and-image-upload.component';
import { HelperService } from './common/services/helper.service';
import { DialogService } from './common/services/dialog/dialog.service';
import { HolNextInfoItemComponent } from './common/components/hol-next-info-item/hol-next-info-item.component';
import { VacationService } from './common/services/vacation.service';
import { CustomTabComponent } from './common/components/custom-tabs/custom-tab.component';
import { CustomTabsComponent } from './common/components/custom-tabs/custom-tabs.component';
import { ChecklistVacationComponent } from './common/components/checklist-vacation/checklist-vacation.component';
import { AuthenticationService } from './login/services/authentication.service';
import { ReferentialService } from './admin/services/referential.service';
import { FileAndImageIconsDisplayComponent } from './common/components/file-and-image-icons-display/file-and-image-icons-display.component';
import { StoreModule } from '@ngrx/store';
import { getReducers, metaReducers, REDUCER_TOKEN } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ErpCrisisApiService } from './erp/services/erp-crisis-api.service';
import { RolesService } from './common/services/roles.service';
import { RequestService } from './common/services/request.service';
import { OccOptionsService } from './occ/services/occ-options-service/occ-options.service';
import { OccEventService } from './occ/services/occ-event-service/occ-event.service';
import { EclOptionsService } from './ecl/services/ecl-options-service/ecl-options.service';
import { HeaderComponent } from './common/components/header/header.component';
import { OclEventItemComponent } from './ocl/components/ocl-event-item/ocl-event-item.component';
import { OclModule } from './ocl/ocl.module';
import { EclEventService } from './ecl/services/ecl-event-service/ecl-event.service';
import { CommonStoreManager } from './common/store/common.store-manager';
import { PollingService } from './common/services/polling/polling.service';
import { MccEventService } from './mcc/services/mcc-event.service';
import { CrewModule } from './crew/crew.module';
import { MccAtaCodeService } from './mcc/services/mcc-ata-code.service';
import { OpsOptionsService } from './ops/services/ops-options-service/ops-options.service';
import { FltFlightService } from './flt/services/flt-flight.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { FunctionsStoreManager } from './erp/store/functions/functions.store-manager';
import { ErpFunctionsService } from './erp/services/erp-functions.service';
import { CrisisStoreManager } from './erp/store/crisis/crisis.store-manager';
import { ModalsService } from './erp/services/modals.service';
import { AclSelectorComponent } from './common/components/acl-selector/acl-selector.component';
import { ErpCrisisTypeService } from './erp/services/erp-crisis-type.service';
import { ErpCrisisService } from './erp/services/erp-crisis.service';
import { ErpCrisisNewsService } from './erp/services/erp-crisis-news.service';
import { ErpCrisisAnnouncementService } from './erp/services/erp-crisis-announcement.service';
import { ErpCrisisDataService } from './erp/services/erp-crisis-data.service';
import { CurrentUserService } from './common/services/current-user.service';
import { ErpUsersService } from './erp/services/erp-users.service';
import { ErpCrisisTaskService } from './erp/services/erp-crisis-task.service';
import { CommonComponentsModule } from './common/common.components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErpCrisisTaskModalComponent } from './erp/modales/erp-crisis-task-modal/erp-crisis-task-modal.component';
import { ErpCrisisModalComponent } from './erp/modales/erp-crisis-modal/erp-crisis-modal.component';
import { CrewManagersSelectors } from './crew/store/crew-managers.selectors';
import { LoadingComponent } from './common/components/loading/loading.component';

export const gocRootStates: NgHybridStateDeclaration = {
  name: 'app.goc.**',
  url: '/goc',
  loadChildren: './goc/goc.module#GocModule',
};

export const erpRootStates: NgHybridStateDeclaration = {
  name: 'app.crisis.**',
  url: '/crisis',
  loadChildren: './erp/erp.module#ERPModule',
};

export const crewRootStates: NgHybridStateDeclaration = {
  name: 'app.crew.**',
  url: '/crew',
  loadChildren: './crew/crew.module#CrewModule',
};
export const occRootStates: NgHybridStateDeclaration = {
  name: 'app.occ.**',
  url: '/occ',
  loadChildren: './occ/occ.module#OccModule',
};

export const eclRootStates: NgHybridStateDeclaration = {
  name: 'app.ecl.**',
  url: '/ecl',
  loadChildren: './ecl/ecl.module#EclModule',
};

export const opsRootStates: NgHybridStateDeclaration = {
  name: 'app.ops.**',
  url: '/ops',
  loadChildren: './ops/ops.module#OpsModule',
};

export const mccRootStates: NgHybridStateDeclaration = {
  name: 'app.mcc.**',
  url: '/mcc',
  loadChildren: './mcc/mcc.module#MccModule',
};

export const adminRootStates: NgHybridStateDeclaration = {
  name: 'app.admin.**',
  url: '/admin',
  loadChildren: './admin/admin.module#AdminModule',
};

export const loginRootStates: NgHybridStateDeclaration = {
  name: 'login.**',
  url: '/login',
  loadChildren: './login/login.module#LoginModule',
};

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

const myGestionDeCriseApp = angular.module('gestiondecriseApp');

export const enum USER_RIGHTS {
  READ = 'READ',
  WRITE = 'WRITE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  EXTERNAL_READ = 'EXTERNAL_READ',
  EXTERNAL_WRITE = 'EXTERNAL_WRITE',
}

@NgModule({
  declarations: [ErpCrisisTaskModalComponent, ErpCrisisModalComponent],
  imports: [
    UpgradeModule,
    BrowserAnimationsModule,
    CrewModule, // TODO remove this import once crew module migrated to angular
    CommonComponentsModule,
    UIRouterUpgradeModule.forRoot({
      states: [
        loginRootStates,
        gocRootStates,
        adminRootStates,
        crewRootStates,
        erpRootStates,
        occRootStates,
        eclRootStates,
        opsRootStates,
        mccRootStates,
      ],
    }),
    StoreModule.forRoot(REDUCER_TOKEN, { metaReducers }),
    StoreDevtoolsModule.instrument({
      name: '[HOLIS]',
      maxAge: 50, // Retains last 25 states
    }),
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useFactory: getReducers,
    },
    { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
    {
      provide: 'CrewLogsService',
      useFactory: ($injector: any) => $injector.get('CrewLogsService'),
      deps: ['$injector'],
    },
    {
      provide: '$filter',
      useFactory: ($injector: any) => $injector.get('$filter'),
      deps: ['$injector'],
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: '$mdDialog',
      useFactory: ($injector: any) => $injector.get('$mdDialog'),
      deps: ['$injector'],
    },
    {
      provide: '$event',
      useFactory: ($injector: any) => $injector.get('$event'),
      deps: ['$injector'],
    },
    {
      provide: 'RequestService',
      useFactory: ($injector: any) => $injector.get('RequestService'),
      deps: ['$injector'],
    },
    {
      provide: 'UserService',
      useFactory: ($injector: any) => $injector.get('UserService'),
      deps: ['$injector'],
    },
    {
      provide: '$event',
      useFactory: ($injector: any) => $injector.get('$event'),
      deps: ['$injector'],
    },
    {
      provide: '$rootScope',
      useFactory: ($injector: any) => $injector.get('$rootScope'),
      deps: ['$injector'],
    },
    {
      provide: '$q',
      useFactory: ($injector: any) => $injector.get('$q'),
      deps: ['$injector'],
    },
    {
      provide: 'ParseMapper',
      useFactory: ($injector: any) => $injector.get('ParseMapper'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewUsersService',
      useFactory: ($injector: any) => $injector.get('CrewUsersService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewOptionsService',
      useFactory: ($injector: any) => $injector.get('CrewOptionsService'),
      deps: ['$injector'],
    },
    {
      provide: '$translate',
      useFactory: ($injector: any) => $injector.get('$translate'),
      deps: ['$injector'],
    },
    {
      provide: '$timeout',
      useFactory: ($injector: any) => $injector.get('$timeout'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewFunctionsService',
      useFactory: ($injector: any) => $injector.get('CrewFunctionsService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewEventsService',
      useFactory: ($injector: any) => $injector.get('CrewEventsService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewSituationsService',
      useFactory: ($injector: any) => $injector.get('CrewSituationsService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewTeamService',
      useFactory: ($injector: any) => $injector.get('CrewTeamService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewHistoryService',
      useFactory: ($injector: any) => $injector.get('CrewHistoryService'),
      deps: ['$injector'],
    },
    {
      provide: '$root',
      useFactory: ($injector: any) => $injector.get('$root'),
      deps: ['$injector'],
    },
    {
      provide: 'FilesService',
      useFactory: ($injector: any) => $injector.get('FilesService'),
      deps: ['$injector'],
    },
    {
      provide: 'CONSTANTS',
      useFactory: ($injector: any) => $injector.get('CONSTANTS'),
      deps: ['$injector'],
    },
    {
      provide: 'MailSenderService',
      useFactory: ($injector: any) => $injector.get('MailSenderService'),
      deps: ['$injector'],
    },
    {
      provide: 'SmsSenderService',
      useFactory: ($injector: any) => $injector.get('SmsSenderService'),
      deps: ['$injector'],
    },
    {
      provide: 'CommonService',
      useFactory: ($injector: any) => $injector.get('CommonService'),
      deps: ['$injector'],
    },
    {
      provide: 'localStorage',
      useFactory: ($injector: any) => $injector.get('localStorage'),
      deps: ['$injector'],
    },
    {
      provide: 'ChatService',
      useFactory: ($injector: any) => $injector.get('ChatService'),
      deps: ['$injector'],
    },
    {
      provide: 'FunctionService',
      useFactory: ($injector: any) => $injector.get('FunctionService'),
      deps: ['$injector'],
    },
    {
      provide: '$state',
      useFactory: ($injector: any) => $injector.get('$state'),
      deps: ['$injector'],
    },
    {
      provide: 'MailService',
      useFactory: ($injector: any) => $injector.get('MailService'),
      deps: ['$injector'],
    },
    {
      provide: 'SmsService',
      useFactory: ($injector: any) => $injector.get('SmsService'),
      deps: ['$injector'],
    },
    {
      provide: 'BreakingNewsService',
      useFactory: ($injector: any) => $injector.get('BreakingNewsService'),
      deps: ['$injector'],
    },
    {
      provide: 'MailTemplateService',
      useFactory: ($injector: any) => $injector.get('MailTemplateService'),
      deps: ['$injector'],
    },
  ],
  entryComponents: [ErpCrisisTaskModalComponent, ErpCrisisModalComponent],
})
export class AppModule {
  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.iconRegistry.addSvgIcon(
      'flight_departure',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/flight_departure.svg')
    );
    this.iconRegistry.addSvgIcon('flight_arrival', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/flight_arrival.svg'));
  }

  ngDoBootstrap() {}
}

myGestionDeCriseApp.directive(
  'appOclGlobalInstructionBox',
  downgradeComponent({ component: OclGlobalInstructionBoxComponent }) as angular.IDirectiveFactory
);
myGestionDeCriseApp.directive('appLoading', downgradeComponent({ component: LoadingComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive(
  'appFileAndImageUpload',
  downgradeComponent({ component: FileAndImageUploadComponent }) as angular.IDirectiveFactory
);
myGestionDeCriseApp.directive('appCustomTab', downgradeComponent({ component: CustomTabComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appCustomTabs', downgradeComponent({ component: CustomTabsComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive(
  'appChecklistVacation',
  downgradeComponent({ component: ChecklistVacationComponent }) as angular.IDirectiveFactory
);
myGestionDeCriseApp.directive(
  'appFileAndImageIconsDisplay',
  downgradeComponent({ component: FileAndImageIconsDisplayComponent }) as angular.IDirectiveFactory
);
myGestionDeCriseApp.directive('appCrewEventsBox', downgradeComponent({ component: CrewEventsBoxComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive(
  'appTirednessIndicator',
  downgradeComponent({ component: TirednessIndicatorComponent }) as angular.IDirectiveFactory
);
myGestionDeCriseApp.directive(
  'appHolNextInfoItem',
  downgradeComponent({ component: HolNextInfoItemComponent }) as angular.IDirectiveFactory
);
myGestionDeCriseApp.directive('appHeader', downgradeComponent({ component: HeaderComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appOclEventItem', downgradeComponent({ component: OclEventItemComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appAclSelector', downgradeComponent({ component: AclSelectorComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appAclFilter', downgradeComponent({ component: AclFilterComponent }) as angular.IDirectiveFactory);

myGestionDeCriseApp.factory('AuthenticationService', downgradeInjectable(AuthenticationService) as any);
myGestionDeCriseApp.factory('ErpCrisisApiService', downgradeInjectable(ErpCrisisApiService) as any);
myGestionDeCriseApp.factory('CommonStoreManager', downgradeInjectable(CommonStoreManager) as any);
myGestionDeCriseApp.factory('DialogService', downgradeInjectable(DialogService) as any);
myGestionDeCriseApp.factory('EclEventService', downgradeInjectable(EclEventService) as any);
myGestionDeCriseApp.factory('ECLOptionsService', downgradeInjectable(EclOptionsService) as any);
myGestionDeCriseApp.factory('HelperService', downgradeInjectable(HelperService) as any);
myGestionDeCriseApp.factory('NotificationsService', downgradeInjectable(NotificationsService) as any);
myGestionDeCriseApp.factory('OccEventService', downgradeInjectable(OccEventService) as any);
myGestionDeCriseApp.factory('OCCOptionsService', downgradeInjectable(OccOptionsService) as any);
myGestionDeCriseApp.factory('OPSOptionsService', downgradeInjectable(OpsOptionsService) as any);
myGestionDeCriseApp.factory('ParseMapper', downgradeInjectable(ParseMapperService) as any);
myGestionDeCriseApp.factory('PollingService', downgradeInjectable(PollingService) as any);
myGestionDeCriseApp.factory('ReferentialServiceNew', downgradeInjectable(ReferentialService) as any);
myGestionDeCriseApp.factory('RequestService', downgradeInjectable(RequestService) as any);
myGestionDeCriseApp.factory('RolesService', downgradeInjectable(RolesService) as any);
myGestionDeCriseApp.factory('TirednessIndicatorService', downgradeInjectable(TirednessIndicatorService) as any);
myGestionDeCriseApp.factory('VacationService', downgradeInjectable(VacationService) as any);
myGestionDeCriseApp.factory('OclGlobalInstructionService', downgradeInjectable(OclGlobalInstructionService) as any);

myGestionDeCriseApp.factory('MCCATACodeService', downgradeInjectable(MccAtaCodeService) as any);
myGestionDeCriseApp.factory('MCCEventsService', downgradeInjectable(MccEventService) as any);
myGestionDeCriseApp.factory('FunctionsStoreManager', downgradeInjectable(FunctionsStoreManager) as any);
myGestionDeCriseApp.factory('ErpFunctionsService', downgradeInjectable(ErpFunctionsService) as any);
myGestionDeCriseApp.factory('CrisisStoreManager', downgradeInjectable(CrisisStoreManager) as any);
myGestionDeCriseApp.factory('OptionsService', downgradeInjectable(OptionsService) as any);
myGestionDeCriseApp.factory('ModalsService', downgradeInjectable(ModalsService) as any);
myGestionDeCriseApp.factory('ErpCrisisTypeService', downgradeInjectable(ErpCrisisTypeService) as any);
myGestionDeCriseApp.factory('ErpCrisisService', downgradeInjectable(ErpCrisisService) as any);
myGestionDeCriseApp.factory('ErpCrisisNewsService', downgradeInjectable(ErpCrisisNewsService) as any);
myGestionDeCriseApp.factory('ErpCrisisAnnouncementService', downgradeInjectable(ErpCrisisAnnouncementService) as any);
myGestionDeCriseApp.factory('ErpCrisisDataService', downgradeInjectable(ErpCrisisDataService) as any);
myGestionDeCriseApp.factory('ErpHistoryService', downgradeInjectable(ErpHistoryService) as any);
myGestionDeCriseApp.factory('CurrentUserService', downgradeInjectable(CurrentUserService) as any);
myGestionDeCriseApp.factory('ErpUsersService', downgradeInjectable(ErpUsersService) as any);
myGestionDeCriseApp.factory('ErpCrisisTaskService', downgradeInjectable(ErpCrisisTaskService) as any);
myGestionDeCriseApp.factory('ErpMailService', downgradeInjectable(ErpMailService) as any);
myGestionDeCriseApp.factory('ErpSmsService', downgradeInjectable(ErpSmsService) as any);
myGestionDeCriseApp.factory('CrisisService', downgradeInjectable(CrisisService) as any);
myGestionDeCriseApp.factory('CrewManagersSelectors', downgradeInjectable(CrewManagersSelectors) as any);
