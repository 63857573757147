import * as _ from 'lodash';
import { Component, Inject, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { OclLogbook } from '../../models/ocl-logbook.model';
import * as moment from 'moment';
import { OclLinkedDisplayedItemsStoreManager } from '../../store/linked-display-items/ocl-linked-displayed-items-store-manager.service';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { MatDialog } from '@angular/material';
import { OclLogbookService } from '../../services/ocl-logbook-service/ocl-logbook.service';
import { OclEventService } from '../../services/ocl-event-service/ocl-event.service';
import { OclNotificationsService } from '../../services/ocl-notifications-service/ocl-notifications.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { OclFlightStatusService } from '../../services/ocl-flight-status-service/ocl-flight-status.service';
import { OclOptionsService } from '../../services/ocl-options-service/ocl-options.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclLinksHelperService } from '../../services/ocl-links-helper-service/ocl-links-helper.service';
import { OCL_LOGBOOK_MODAL_TOKEN } from '../../modals/ocl-logbook-modal/ocl-logbook-modal.token';

@Component({
  selector: 'app-ocl-logbook-list-item',
  templateUrl: './ocl-logbook-list-item.component.html',
  styleUrls: ['./ocl-logbook-list-item.component.scss'],
})
export class OclLogbookListItemComponent implements OnInit, OnChanges {
  @Input()
  public item: OclLogbook = null;
  @Input()
  public isReadOnly = false;
  @Input()
  public events = [];
  @Input()
  public localHourOnly;
  @Input()
  public isHistory = false;

  @Input() linkedIds;
  @Input() modeLinkIsActivate;
  @Input() decisionItems;
  @Input() airportsCode;

  public canCheckLogbook = false;
  public isUtc: boolean;

  public linkedUid: string;
  public randomColor: string;

  public fromModule = null;

  constructor(
    @Inject('localStorage') protected localStorage,
    protected flightsStatusService: OclFlightStatusService,
    protected eventService: OclEventService,
    @Inject('$mdDialog') protected $mdDialog,
    protected optionsService: OclOptionsService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected notificationsService: OclNotificationsService,
    protected logbookService: OclLogbookService,
    public moduleConfig: ModuleConfigService,
    protected linksHelperService: OclLinksHelperService,
    protected occLinkedDisplayedItemsStoreManager: OclLinkedDisplayedItemsStoreManager,
    protected occDecisionsStoreManager: OclDecisionsStoreManager,
    protected occEventsStoreManager: OclEventsStoreManager,
    protected dialog: MatDialog,
    @Inject(OCL_LOGBOOK_MODAL_TOKEN) protected logbookModal
  ) {}
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.item && changes.item.currentValue && changes.item.currentValue.linkedData && changes.item.currentValue.linkedData) {
      this.linkedUid = changes.item.currentValue.linkedData.linkedUid;
    } else if (
      changes.item &&
      changes.item.previousValue &&
      changes.item.previousValue.linkedData &&
      changes.item.previousValue.linkedData &&
      changes.item.currentValue &&
      !changes.item.currentValue.linkedData
    ) {
      this.linkedUid = undefined;
    }
  }

  public ngOnInit() {
    this.isUtc = this.localHourOnly ? false : !this.isReadOnly;
    this.canCheckLogbook = this.optionsService.getCanCheckLogbook();
    this.fromModule = this.item.fromGOC ? 'GOC' : this.item.fromCREW ? 'CREW' : this.item.fromERP ? 'ERP' : null;
  }

  public openDetailsModal(event, item): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(this.logbookModal, {
      data: {
        events: this.events,
        isUtc: !this.localHourOnly,
        logbook: _.cloneDeep(item),
        decisionItems: this.decisionItems,
        isReadOnly: _.cloneDeep(item).fromGOC || _.cloneDeep(item).fromCREW || this.isHistory ? true : this.isReadOnly,
        modeLinkIsActivate: this.modeLinkIsActivate,
        airportsCode: this.airportsCode,
        itemComponent: OclLogbookListItemComponent,
        isHistory: this.isHistory,
      },
    });
    dialogRef.afterClosed().subscribe(logbook => {
      if (logbook) {
        this.flightsStatusService.setNeedsUpdate(true);
      }
    });
  }

  public openEventModal($event, event): void {
    $event.stopPropagation();
    if (event && event.objectId) {
      const fullEvent = this.events.find(e => (e ? e.objectId === event.objectId : false));
      if (fullEvent) {
        this.$mdDialog
          .show({
            controller: 'EventsEditModalCtrl',
            templateUrl: 'views/occ/dashboard/events-edit.modal.html',
            clickOutsideToClose: true,
            targetEvent: $event,
            locals: {
              event: fullEvent,
              isReadOnly: this.isReadOnly,
              notifications: this.notificationsService.getAll(),
              isUtc: this.isUtc,
            },
          })
          .then(() => {
            this.eventService.fetchNewData();
            this.logbookService.fetchNewData();
          }, this.eventService.fetchNewData);
      }
    }
  }

  public showLinkedItems($event): void {
    $event.stopPropagation();
    this.moduleConfig.setLoadingItemsLinked();
    const bufferLinkedUid = 'LinkFrom_' + this.item.objectId;

    if (!this.item.linkedData || !this.item.linkedData.linkedUid) {
      this.linksHelperService.getLinkFromLogBook(this.item.objectId).then(data => {
        this.randomColor = data[0];
        const linkedItemsToDisplay = data[1];
        const decisionsToUpdate = data[2];
        const eventsToUpdate = data[3];
        this.occLinkedDisplayedItemsStoreManager.addOrUpdateLinkedDiplayedItems(bufferLinkedUid, this.randomColor, linkedItemsToDisplay);
        if (eventsToUpdate.length) {
          this.occEventsStoreManager.updateEventsFromLinkedValue(eventsToUpdate, bufferLinkedUid);
        }
        if (decisionsToUpdate.length) {
          this.occDecisionsStoreManager.updateDecisionsFromLinkedValue(decisionsToUpdate, bufferLinkedUid);
        }
        this.moduleConfig.unsetLoadingItemsLinked();
      });
    } else {
      this.occLinkedDisplayedItemsStoreManager.removeLinkedDiplayedItems(bufferLinkedUid, this.randomColor);
      this.occEventsStoreManager.removeEventsFromLinkedValue(bufferLinkedUid);
      this.occDecisionsStoreManager.removeDecisionsFromLinkedValue(bufferLinkedUid);
      this.linkedUid = undefined;
      this.moduleConfig.unsetLoadingItemsLinked();
    }
  }

  public toggleDone(item: OclLogbook, $event): void {
    $event.stopPropagation();
    // canCheckLogbook is global for each user
    if (this.canCheckLogbook && !this.isReadOnly) {
      this.logbookService.toggleDone(item).then(newLogBook => _.extend(item, newLogBook));
    }
  }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }
}
