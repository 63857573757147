import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErpCrisis } from '../models/erp-crisis';
import { ErpCrisisTaskModalComponent } from '../modales/erp-crisis-task-modal/erp-crisis-task-modal.component';
import { ErpCrisisModalComponent } from '../modales/erp-crisis-modal/erp-crisis-modal.component';
import { AlertModalComponent } from '../../common/modals/alert-modal/alert-modal.component';
import { ConfirmationModalComponent } from '../../common/modals/confirmation-modal/confirmation-modal.component';
import { FunctionUserForCrisis } from '../models/erp-functionUser';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  constructor(private dialog: MatDialog) {}

  openCrisisTaskModalForCreation(data: { crisis: ErpCrisis; forFunction: string; functionsUserForCrisis?: FunctionUserForCrisis[] }): void {
    this.dialog.open(ErpCrisisTaskModalComponent, {
      data: {
        crisis: data.crisis,
        functionsUserForCrisis: data.functionsUserForCrisis,
        forFunction: data.forFunction,
      },
    });
  }

  openCrisisModal(data: { crisis: ErpCrisis }) {
    this.dialog.open(ErpCrisisModalComponent, {
      disableClose: true,
      data: {
        crisis: data.crisis,
      },
    });
  }

  openConfirmModal(data): Promise<any> {
    return this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: data.title,
          modalContent: data.content,
          modalQuestion: data.question,
        },
      })
      .afterClosed()
      .toPromise();
  }

  openAlertModal(data): Promise<any> {
    return this.dialog
      .open(AlertModalComponent, {
        data: {
          modalTitle: data.title,
          modalContent: data.content,
          modalType: data.modalType,
        },
      })
      .afterClosed()
      .toPromise();
  }
}
