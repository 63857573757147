'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.BreakingNews
 * @description
 * # BreakingNews
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('BreakingNewsService', function ($q, $timeout, $rootScope, RequestService) {
  var BreakingNews = Parse.Object.extend('GDCBreakingNews');

  /**
   * Get all breaking news.
   * @param forceToRefresh
   */
  this.getAllBreakingNews = function (context) {
    var deferred = $q.defer();

    var query = new Parse.Query(BreakingNews);
    query.ascending('createdAt');
    if (context) {
      query.equalTo('context', context);
    }
    RequestService.performFindQuery(
      query,
      function (results) {
        var news = [];
        for (var i = 0; i < results.length; i++) {
          news.push(transformParseToObject(results[i]));
        }
        deferred.resolve(news);
      },
      function (error) {
        deferred.reject(error);
      }
    );

    return deferred.promise;
  };

  /**
   * Add new breaking news.
   * @param news
   */
  this.addBreakingNews = function (news, context) {
    return _addBreakingNews(news, context);
  };

  /**
   * Delete breaking news.
   * @param news
   */
  this.deleteBreakingNews = function (news) {
    return _deleteBreakingNews(news);
  };

  this.updateBreakingNews = function (news) {
    return _updateBreakingNews(news);
  };

  /**
   * Delete all breaking news.
   */
  this.deleteAllBreakingNews = function () {
    var deferred = $q.defer();

    var query = new Parse.Query(BreakingNews);
    query.limit(1000);
    RequestService.performFindQuery(
      query,
      function (results) {
        RequestService.performDestroyAllQuery(
          results,
          function () {
            // All the objects were deleted
            console.debug('All breaking news deleted');
            deferred.resolve();
          },
          function (error) {
            console.error('Oops! Something went wrong: ' + error.message + ' (' + error.code + ')');
            deferred.reject(error);
          }
        );
      },
      function (error) {
        deferred.reject(error);
      }
    );

    return deferred.promise;
  };

  this.fetchNewData = function (context) {
    return this.getAllBreakingNews(context).then(function (data) {
      $rootScope.$broadcast('poolService-breaking-news', data);
    });
  };

  /**
   * Add new breaking news.
   * @param news
   */
  var _addBreakingNews = function (news, context) {
    var deferred = $q.defer();

    if (!news) {
      deferred.resolve();
    } else {
      var breakingNews = new BreakingNews();
      breakingNews.set('news', news);
      breakingNews.set('context', context);
      RequestService.performSaveQuery(
        breakingNews,
        null,
        function (newBreakingNews) {
          deferred.resolve(transformParseToObject(newBreakingNews));
        },
        function () {
          deferred.reject("Can't create a new breaking news");
        }
      );
    }

    return deferred.promise;
  };

  /**
   * Update breaking news.
   * @param news
   */
  var _updateBreakingNews = function (news) {
    var deferred = $q.defer();

    if (!news) {
      deferred.resolve();
    } else {
      // Check if the news has really been edited
      var query = new Parse.Query(BreakingNews);
      RequestService.performGetQuery(
        query,
        news.objectId,
        function (breakingNewsObject) {
          breakingNewsObject.set('news', news.news);
          RequestService.performSaveQuery(
            breakingNewsObject,
            null,
            function () {},
            function () {}
          );
          deferred.resolve(breakingNewsObject);
        },
        function () {
          deferred.reject("Can't update a breaking news");
        }
      );
    }

    return deferred.promise;
  };

  /**
   * Delete breaking news.
   * @param news
   */
  var _deleteBreakingNews = function (news) {
    var deferred = $q.defer();

    if (!news) {
      deferred.resolve();
    } else {
      var query = new Parse.Query(BreakingNews);
      RequestService.performGetQuery(
        query,
        news.objectId,
        function (newsObject) {
          RequestService.performDestroyQuery(
            newsObject,
            function () {},
            function () {}
          );
          deferred.resolve(newsObject);
        },
        function () {
          deferred.reject("Can't delete the breaking news");
        }
      );
    }

    return deferred.promise;
  };

  var transformParseToObject = function (parseObject) {
    return {
      news: parseObject.get('news'),
      context: parseObject.get('context'),
      createdAt: parseObject.get('createdAt'),
      objectId: parseObject.id,
    };
  };
});
