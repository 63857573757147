export class HolOptions {
  shortTitleForCrisisObservor: string;
  shortTitleForCrisisDirector: string;
  hasToSendMails: boolean;
  hasToSendSms: boolean;
  crisisInitializerCanActivate: boolean;
  shortTitleForHumanResources: string;
  shortTitleForOCC: string;
  shortTitleForCrewManager: string;
  shortTitleForChat: string;
  shortTitleForMCC: string;
  env: string;
  nextInfoLimit: number;
  shortTitleForGOC: string;
  shortTitleForCrisisInitializer: string;
  crisisTimeRemainingOpen: number;
  defaultCrisisImageUrl: string;
  companyLogoUrl: string;
  defaultCrisisImageName: string;
  companyLogoName: string;
  formIoAdmin: string;
  formIoPwd: string;
  formIoBackEnd: string;
}
