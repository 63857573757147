import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
export class HOLOptions {
  functionIdsForOCCManagers: string[];
  functionIdsForECLManagers: string[];
  functionIdsForGOCManagers: string[];
  functionIdsForOPSManagers: string[];
  functionIdsForCREWManagers: string[];
  functionIdsForMCCManagers: string[];
}

@Injectable({
  providedIn: 'root',
})
export class HolOptionsService {
  // tslint:disable-next-line:variable-name
  protected ParseOptions = Parse.Object.extend('HOLOptions');

  public constructor(private requestService: RequestService) {}

  private options: HOLOptions; // @cache

  // @hasCache options
  get(forceRefresh?): Promise<HOLOptions> {
    if (this.options && !forceRefresh) {
      return Promise.resolve(this.options);
    } else {
      const query = new Parse.Query(this.ParseOptions);
      return this.requestService.performFirstQuery(query).then(result => {
        if (result) {
          this.options = {
            functionIdsForOCCManagers: result.get('functionIdsForOCCManagers') ? result.get('functionIdsForOCCManagers').split('|') : [],
            functionIdsForECLManagers: result.get('functionIdsForECLManagers') ? result.get('functionIdsForECLManagers').split('|') : [],
            functionIdsForGOCManagers: result.get('functionIdsForGOCManagers') ? result.get('functionIdsForGOCManagers').split('|') : [],
            functionIdsForOPSManagers: result.get('functionIdsForOPSManagers') ? result.get('functionIdsForOPSManagers').split('|') : [],
            functionIdsForCREWManagers: result.get('functionIdsForCREWManagers') ? result.get('functionIdsForCREWManagers').split('|') : [],
            functionIdsForMCCManagers: result.get('functionIdsForMCCManagers') ? result.get('functionIdsForMCCManagers').split('|') : [],
          };
        } else {
          this.options = new HOLOptions();
        }
        return this.options;
      });
    }
  }

  getFunctionsIdsForManagerByModule(module: string): string[] {
    if (this.options) {
      switch (module.toUpperCase()) {
        case 'OCC':
          return this.options.functionIdsForOCCManagers;
        case 'ECL':
          return this.options.functionIdsForECLManagers;
        case 'GOC':
          return this.options.functionIdsForGOCManagers;
        case 'OPS':
          return this.options.functionIdsForOPSManagers;
        case 'MCC':
          return this.options.functionIdsForMCCManagers;
        case 'CREW':
          return this.options.functionIdsForCREWManagers;
      }
    }
    return [];
  }
}
