import { MarkdownService } from './../../../common/components/markdown-editor/markdown.service';
import { HelperService } from './../../../common/services/helper.service';
import { RolesService } from './../../../common/services/roles.service';
import { take } from 'rxjs/operators';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { Inject, Injectable } from '@angular/core';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { OclDecisionService } from '../../../ocl/services/ocl-decision-service/ocl-decision.service';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { EclDecisionTagService } from '../ecl-decision-tag-service/ecl-decision-tag.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';
import { OclDecisionsStoreManager } from '../../../ocl/store/decisions/ocl-decisions.store-manager';
import { EclMailService } from '../ecl-mail-service/ecl-mail.service';
import { EclSmsService } from '../ecl-sms-service/ecl-sms.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { OclDecision } from 'src/app/ocl/models/ocl-decision.model';

@Injectable({
  providedIn: 'root',
})
export class EclDecisionService extends OclDecisionService {
  // tslint:disable:variable-name
  protected ParseDecision = Parse.Object.extend('ECLDecisions');
  protected ParseEvent = Parse.Object.extend('ECLEvents');
  protected ParseDecisionTag = Parse.Object.extend('ECLDecisionTag');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseErpDecision;
  private ParseCrisis = Parse.Object.extend('GDCCrisis');
  // tslint:enabled

  public constructor(
    @Inject('$filter') protected $filter,
    @Inject('FilesService') protected filesService,
    @Inject('$translate') protected $translate,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected parseMapper: ParseMapperService,
    protected requestService: RequestService,
    protected optionsService: EclOptionsService,
    protected notificationsService: NotificationsService,
    protected smsService: EclSmsService,
    protected mailService: EclMailService,
    protected historyService: EclHistoryService,
    protected decisionTagService: EclDecisionTagService,
    public moduleConfig: EclModuleConfigService,
    public commonStoreManager: CommonStoreManager,
    private helperService: HelperService,
    public markdownService: MarkdownService,
    private rolesService: RolesService
  ) {
    super(
      $filter,
      filesService,
      $translate,
      decisionsStoreManager,
      parseMapper,
      requestService,
      optionsService,
      notificationsService,
      smsService,
      mailService,
      historyService,
      decisionTagService,
      moduleConfig,
      markdownService,
      commonStoreManager
    );
  }

  protected async duplicateDecisionToOtherModule(decision: OclDecision) {
    const crisis = await this.commonStoreManager.crisis.pipe(take(1)).toPromise();
    const parseErpDecision = new this.ParseErpDecision();
    parseErpDecision.set('eclDecision', new this.ParseDecision({ id: decision.objectId }));
    parseErpDecision.set('crisis', new this.ParseCrisis({ id: crisis.objectId }));
    const companies = this.helperService.parseACL(decision.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['ERP'], companies);
    parseErpDecision.setACL(acl);
    this.requestService.performSaveAllQuery(parseErpDecision).then();
  }

  protected deleteDuplicateDecisionFromModule(decision: OclDecision) {
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    const query = new Parse.Query(this.ParseErpDecision);
    query.equalTo('eclDecision', parseDecision);

    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const parseErpDecision = new this.ParseErpDecision({ id: result.id });
        this.requestService.performDestroyQuery(parseErpDecision).then();
      }
    });
  }
}
