'use strict';

/**
 * @ngdoc directive
 * @name gestiondecriseApp.directive:autofocus
 * @description
 * # autofocus
 */
angular.module('gestiondecriseApp').directive('autofocus', function ($timeout) {
  return {
    restrict: 'A',
    link: function postLink(scope, element) {
      $(element).focus();
      var promise = $timeout(function () {
        $(element).focus();
      }, 600);
      scope.$on('destroy', function () {
        $timeout.cancel(promise);
      });
    },
  };
});
