'use strict';

/**
 * @ngdoc directive
 * @name gestiondecriseApp.directive:dateValidator
 * @description
 * # dateValidator
 */
angular.module('gestiondecriseApp').directive('dateValidator', function () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attr, ctrl) {
      var minVal;

      ctrl.$validators.minDate = function (value) {
        return !value || !minVal || moment(value).isSameOrAfter(minVal);
      };

      attr.$observe('min', function (val) {
        minVal = val && moment(val);
        ctrl.$validate();
      });
    },
  };
});
