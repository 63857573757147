import { GocEvent } from './goc-event.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import * as _ from 'lodash';
import { GocLog } from './goc-log.model';
import { FltFlightEvent } from 'src/app/flt/models/flt-flight-event';

export class GocFlightEvent extends FltFlightEvent {
  event: GocEvent;
  isPrivate: boolean;
  toOCC?: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    if (parseObject && parseObject.get('event')) {
      this.event = new GocEvent(parseObject.get('event'));
    }
    this.isPrivate = parseObject.get('isPrivate');
    this.toOCC = parseObject.get('toOCC');
  }

  // toLog(): GocLog {
  //   const log = new GocLog();
  //   log.attachments = this.attachments;
  //   log.comment = this.description;
  //   log.type = 'event';
  //   log.jsonObject = this;

  //   return log;
  // }
}
