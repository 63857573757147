import { HolUser } from 'src/app/common/models/hol-user.model';
import { ErpHistoryLog } from '../../erp/models/erp-historyLog';
import { ErpHistoryService } from '../../erp/services/erp-history.service';
import { OptionsService } from 'src/app/common/services/options.service';
import { Inject, Injectable } from '@angular/core';
import * as Raven from 'raven-js';
import { USER_RIGHTS } from '../../app.module';
import { RequestService } from '../../common/services/request.service';
import { RolesService } from '../../common/services/roles.service';
import { forkJoin } from 'rxjs';
import { PollingService } from '../../common/services/polling/polling.service';
import { CurrentUserService } from '../../common/services/current-user.service';
import { CrisisService } from '../../common/services/crisis.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { HolCrisis } from '../../common/models/hol-crisis';
import { CrisisRolesRef, FunctionsService } from '../../common/services/functions.service';
import { ErpUsersService } from '../../erp/services/erp-users.service';
import { HolFunctionService } from '../../common/services/hol-function.service';
import { HolFunction } from '../../common/models/hol-function';
import { HolOptionsService } from '../../common/services/hol-options.service';
import { HolUserFunctionService } from '../../common/services/hol-user-function.service';
import { OpsOptionsService } from '../../ops/services/ops-options-service/ops-options.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    @Inject('$state') private $state,
    @Inject('$translate') private $translate,
    @Inject('UserService') private userService,
    private currentUserService: CurrentUserService,
    private optionsService: OptionsService,
    private holOptionsService: HolOptionsService,
    private opsOptionsService: OpsOptionsService,
    @Inject('ChatService') private chatService,
    private poolService: PollingService,
    @Inject('CONSTANTS') private CONSTANTS,
    @Inject('$rootScope') private $rootScope,
    @Inject('localStorage') private localStorage,
    private requestService: RequestService,
    @Inject('$mdDialog') private $mdDialog,
    private rolesService: RolesService,
    private crisisService: CrisisService,
    private erpHistoryService: ErpHistoryService,
    private functionsService: FunctionsService,
    private erpUsersService: ErpUsersService,
    private commonStoreManager: CommonStoreManager,
    private holFunctionService: HolFunctionService,
    private holUserFunctionService: HolUserFunctionService
  ) {}

  public tryLogin(): Promise<Parse.User> {
    const currentUser = Parse.User.current();
    if (currentUser) {
      // Check if session still valid
      return Parse.User.become(currentUser.getSessionToken()).then((user: Parse.User) => {
        return user;
      });
    } else {
      return Promise.reject(null);
    }
  }

  public login(username, password): Promise<Parse.User> {
    return Parse.User.logIn(username.toLowerCase(), password).then(u => {
      const log = new ErpHistoryLog();
      log.type = 'Login';
      log.hideInHistory = true;
      this.erpHistoryService.postLog(log);
      return u;
    });
  }

  public initApp(parseUser: Parse.User): Promise<void> {
    // tslint:disable-next-line:no-console
    console.time('*** APP INITIALIZATION ***');
    // Init raven
    Raven.setUserContext({
      email: parseUser.get('email'),
      id: parseUser.get('userId'),
    });

    return this.currentUserService
      .initCurrentUser()
      .then(user => {
        // check blacklist
        if (user && user.isBlacklisted) {
          throw this.$translate.instant('LOGIN.ERROR.BLACKLISTED_USER');
        }
      })
      .then(() => {
        return this.$translate.onReady();
      })
      .then(() => {
        return Promise.all([this.holOptionsService.get(), this.opsOptionsService.get(true)]);
      })
      .then(() => {
        // Init options and access rights and display Sandbox alert
        return this.optionsService.get().then(options => {
          if (options.env === 'sandbox') {
            document.title = 'HOLIS ' + this.CONSTANTS.COMPANY_NAME + ' SANDBOX';
            const holAlert = this.$mdDialog.holAlert({
              title: 'Attention',
              skipHide: true,
              htmlContent: `<p>Vous êtes connecté sur un site sandbox.</p>
                <p>Il s'agit d'un site d'entraînement qui ne reflète pas la situation réelle de l'exploitation.</p>`,
            });
            this.$mdDialog.show(holAlert);
          }
          return this.userService.getAccessRights(true).then(accessRights => {
            return (this.$rootScope.accessRights = accessRights);
          });
        });
      })
      .then(() => {
        return forkJoin([this.rolesService.getCurrentUserRoles(), this.rolesService.getAll()]);
      })
      .then(() => {
        // Connect to chat
        this.userService.isCurrentUserInTheChatTeam().then(isInTeam => {
          if (isInTeam) {
            this.chatService.connect();
          }
        });
      })
      .then(() => {
        return Promise.all([
          this.functionsService.getCurrentUserCrisisRightsRef(),
          this.crisisService.get().catch(() => null),
          this.holFunctionService.getAll(),
          this.holUserFunctionService.getAll(),
        ]);
      })
      .then(([crisisRights, crisis, functions, userFunctions]: [CrisisRolesRef, HolCrisis, HolFunction[], any]) => {
        this.commonStoreManager.initCrisisRolesRef(crisisRights);
        this.commonStoreManager.initCrisis(crisis);
        this.commonStoreManager.initHolFunctions(functions);
        this.commonStoreManager.initHolUserFunctions(userFunctions);
      })
      .then(() => {
        return this.currentUserService.justLoggedIn();
      })
      .then(() => {
        // tslint:disable-next-line:no-console
        console.timeEnd('*** APP INITIALIZATION ***');
        this.poolService.startLongPooling();
      });
  }

  public enterApp(): Promise<any> {
    // TODO Check isExternal ?
    return this.optionsService
      .get()
      .then(() => {
        return Promise.all([
          this.userService.getAccessRights(true),
          this.crisisService.get().catch(() => {
            return null;
          }),
        ]);
      })
      .then(([accessRights, crisis]: [any, HolCrisis]) => {
        // If a crisis is in progress, got to crisis dashboard
        if (crisis && crisis.inProgress && accessRights.crisis !== USER_RIGHTS.UNAUTHORIZED) {
          return this.$state.go('app.crisis.dashboard');
        } else {
          // Get last location from storage and go to it
          const urlLocation = this.localStorage.getUrlLocation();
          const urlParams = this.localStorage.getUrlParams();
          if (urlLocation) {
            return this.$state.go(urlLocation, urlParams).catch(() => {
              this.localStorage.setUrlLocation(null);
              this.localStorage.setUrlParams(null);
              return this.$state.go(this.getDefaultPage(accessRights));
            });
          } else {
            // Go to default page depending of the accessRights
            return this.$state.go(this.getDefaultPage(accessRights));
          }
        }
      })
      .catch(() => this.isAdmin().then(() => this.$state.go('app.admin.dashboard')));
  }

  public logout(resetHolderFlags) {
    const user = Parse.User.current();
    this.poolService.stopIt();
    const log = new ErpHistoryLog();
    log.type = 'Logout';
    log.hideInHistory = true;
    this.erpHistoryService.postLog(log);
    this.userService.clearCache();
    return new Promise<any>((resolve, reject) => {
      if (user && resetHolderFlags) {
        return this.erpUsersService.resetOnDutyForAllFunctions().then(resolve, reject);
      } else {
        return resolve();
      }
    })
      .then(() => {
        return new Promise<void>((resolve, reject) => {
          if (user) {
            return this.requestService.performCloudCode('disconnectUser', { objectId: user.id }, resolve, reject);
          } else {
            return resolve();
          }
        });
      })
      .then(() => {
        return Parse.User.logOut().then(() => {
          this.$state.go('login');
        });
      })
      .catch(() => {
        this.$state.go('login');
      });
  }

  public resetPassword(username): Promise<{ sendByMail: boolean; sendBySms: boolean }> {
    // Call logout to force cleaning saved session tokens
    Parse.User.logOut();
    return this.requestService.performCloudCode('resetPassword', {
      username,
      location: location.origin,
      sender: this.CONSTANTS.COMPANY_NAME,
    });
  }

  public isAdmin(): Promise<boolean> {
    const query = new Parse.Query(Parse.Role).equalTo('users', Parse.User.current()).equalTo('name', 'Admin');
    return this.requestService
      .performCountQuery(query)
      .then(count => {
        if (count >= 1) {
          return true;
        }
        throw new Error('Unauthorized');
      })
      .catch(() => {
        throw new Error('Unauthorized');
      });
  }

  private getDefaultPage(accessRights) {
    console.log('accessRights', accessRights);
    if (accessRights.occ !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.occ.dashboard';
    } else if (accessRights.ecl !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.ecl.dashboard';
    } else if (accessRights.crew !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.crew.dashboard';
    } else if (accessRights.mcc !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.mcc.dashboard';
    } else if (accessRights.goc !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.goc.dashboard';
    } else if (accessRights.crisis !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.crisis.dashboard';
    } else if (this.isAdmin()) {
      return 'app.admin.dashboard';
    } else {
      return 'app.crisis.dashboard';
    }
  }
}
