(function () {
  'use strict';

  angular.module('gestiondecriseApp').directive('scrollIntoView', [
    '$timeout',
    function ($timeout) {
      return {
        restrict: 'A',
        scope: {
          justCreated: '=',
        },
        link: function (scope, element) {
          $timeout(function () {
            $timeout(function () {
              if (scope.justCreated) {
                // Add temporary scale to scroll a little more
                element.css('transform', 'scale(2)');
                element.scrollintoview();
                element.css('transform', '');
              }
            }, 0);
          }, 0);
        },
      };
    },
  ]);
})();
