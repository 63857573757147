export class HolTag {
  objectId: string;
  name: string;
  color: string;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }

    this.objectId = parseObject.id;
    this.name = parseObject.get('name');
    this.color = parseObject.get('color');
  }
}
