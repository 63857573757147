<!-- <app-loading *ngIf="loading" class="full-loader"></app-loading> -->
<div class="hol-dialog-wrapper crisis-task-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <span>
        {{ moduleConfig.config.translateKey + '.MODALS.TASK.ADD_TASK' | translate }}
      </span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form" class="cols-container">
      <div class="crisis-task-modal__content">
        <div class="task-modal-row task-modal-row--functions">
          <span>
            {{ moduleConfig.config.translateKey + '.MODALS.TASK.FUNCTION' | translate }}
          </span>
          <mat-form-field *ngIf="functionsUserForCrisis && functionsUserForCrisis.length">
            <mat-select
              (ngModelChange)="selectTaskCode($event)"
              [ngModelOptions]="{ standalone: true }"
              [ngModel]="functionsUserForCrisisSelected"
              matNativeControl
            >
              <mat-option *ngFor="let fUser of functionsUserForCrisis" [value]="fUser.functionId">
                {{ fUser.shortTitle }} - {{ fUser.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="task-modal-column task-modal-column--title">
          <span class="task-label">
            {{ moduleConfig.config.translateKey + '.MODALS.TASK.TITLE' | translate }}
            *</span
          >
          <input
            maxlength="70"
            [placeholder]="titlePlaceHolder"
            type="text"
            [(ngModel)]="crisisTask.taskTitle"
            [ngModelOptions]="{ standalone: true }"
            (focusout)="titlePlaceHolder = defaultTitlePlaceHolder"
            (focusin)="titlePlaceHolder = ''"
          />
        </div>

        <div class="task-modal-column">
          <span class="task-label">{{ moduleConfig.config.translateKey + '.MODALS.TASK.DESCRIPTION' | translate }} *</span>
          <form name="markdownForm">
            <md-editor
              #mdEditor
              [(ngModel)]="crisisTask.taskDescription"
              [ngModelOptions]="{ standalone: true }"
              [options]="options"
              mode="editor"
              name="Content"
            >
            </md-editor>
          </form>
        </div>
        <hr />
        <div class="task-modal-column task-modal-column--output-title">
          <span class="task-label">{{ moduleConfig.config.translateKey + '.MODALS.TASK.OUTPUT_TITLE' | translate }} *</span>
          <input
            maxlength="70"
            [placeholder]="outputTitlePlaceHolder"
            type="text"
            [(ngModel)]="crisisTask.outputTitle"
            [ngModelOptions]="{ standalone: true }"
            (focusout)="outputTitlePlaceHolder = defaultOutputTitlePlaceHolder"
            (focusin)="outputTitlePlaceHolder = ''"
          />
        </div>
        <div class="task-modal-column task-modal-column--output-datalabel">
          <span class="task-label">
            {{ moduleConfig.config.translateKey + '.MODALS.TASK.OUTPUT_DATA_LABEL' | translate }}
          </span>
          <input
            maxlength="70"
            [placeholder]="outputDataLabelPlaceHolder"
            type="text"
            [(ngModel)]="crisisTask.outputDataLabel"
            [ngModelOptions]="{ standalone: true }"
            (focusout)="outputDataLabelPlaceHolder = defaultOutputDataLabelPlaceHolder"
            (focusin)="outputDataLabelPlaceHolder = ''"
          />
        </div>
        <hr />

        <div class="task-modal-row task-modal-row-additional-info">
          <div class="task-modal-column task-modal-column-notification">
            <label>
              {{ 'DASHBOARD.MODAL.NOTIFICATION_LABEL' | translate }}
            </label>
            <app-notification-table
              [notifications]="[]"
              [notifyFunction]="notifyFunction"
              (saveNotifyFunction)="saveNotifyFunction($event)"
            ></app-notification-table>
          </div>
          <div class="task-modal-column task-modal-column-next-info">
            <div class="next-info">
              <label>
                {{ 'DASHBOARD.MODAL.NEXT_INFO' | translate }}
              </label>
              <app-hol-next-info
                [nextInfo]="{ nextInfoTime: crisisTask.nextInfoTime }"
                [isReadOnly]="false"
                [hasDoneBtn]="false"
                (saveNextInfo)="saveNextInfo($event)"
              >
              </app-hol-next-info>
            </div>
          </div>
          <div class="task-modal-column task-modal-column-tag">
            <app-tag-selector class="tag-selector" [(selectedTags)]="crisisTask.tags"></app-tag-selector>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <app-acl-selector
      class="acl-selector"
      [limitTo]="crisis?.acl"
      [moduleName]="moduleConfig.config.moduleName"
      [object]="crisisTask"
      [formGroup]="form"
    ></app-acl-selector>
    <div class="task-actions">
      <button
        class="hol-validate-btn-secondary"
        mat-raised-button
        [disabled]="
          !form.valid ||
          (crisisTask.taskTitle &&
            crisisTask.taskDescription &&
            crisisTask.outputTitle &&
            crisisTask.taskTitle.trim().length === 0 &&
            crisisTask.taskDescription.trim().length === 0 &&
            crisisTask.outputTitle.trim().length === 0)
        "
        (click)="saveCrisisTask()"
      >
        {{ 'COMMON.ADD' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
