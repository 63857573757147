<div class="filters">
  <mat-form-field appearance="fill" class="mat-form-field scenario">
    <mat-label>{{ 'TASKS.EVENT_FILTER' | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedEventId" panelClass="panel-class" disableOptionCentering>
      <mat-option class="custom-option" [value]="null">All</mat-option>
      <mat-option *ngFor="let event of eventsList" class="custom-option" [value]="event.objectId"
        >{{ event.scenario.code }}{{ event.order }} - {{ event.scenario.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [(ngModel)]="isFilterTodoActive" (change)="computeKindFilter()">{{ 'TASKS.FILTERS.TODO' | translate }}</mat-checkbox>
  <mat-checkbox [(ngModel)]="isFilterCloseActive" (change)="computeKindFilter()">{{ 'TASKS.FILTERS.CLOSED' | translate }}</mat-checkbox>
  <mat-checkbox [(ngModel)]="isFilterFrozenActive" (change)="computeKindFilter()">{{ 'TASKS.FILTERS.FROZEN' | translate }}</mat-checkbox>
  <mat-checkbox [(ngModel)]="isFilterNotApplicableActive" (change)="computeKindFilter()">{{
    'TASKS.FILTERS.NOT_APPLICABLE' | translate
  }}</mat-checkbox>
</div>
<div class="checklist-event">
  <ng-container
    *ngFor="
      let checklistGroupByFunction of checklist
        | aclFilter: RolesService.companiesRolesFilter
        | filterChecklistEvent: selectedEventId:filterStatus;
      trackBy: trackByFunction
    "
  >
    <app-checklist-by-function [isReadOnly]="isReadOnly" [mod]="mod" [checklistGroupByFunction]="checklistGroupByFunction">
    </app-checklist-by-function>
  </ng-container>
</div>
