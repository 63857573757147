import { CrewTagsService } from './services/crew-tag.service';
import { CrewModuleConfigService } from './services/crew-module-config.service';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { CrewGroupService } from './services/crew-group.service';
import { OclGroupService } from 'src/app/ocl/services/ocl-group-service/ocl-group.service';
import { CrewGlobalInstructionTagService } from './services/crew-global-instruction-tag.service';
import { OclGlobalInstructionTagService } from './../ocl/services/ocl-global-instruction-tag-service/ocl-global-instruction-tag.service';
import { CrewGlobalInstructionService } from './services/crew-global-instruction.service';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { CrewChecklistComponentDirective } from './angularJSComponents/crewChecklistComponent.directive';
import { CrewTeamComponentDirective } from './angularJSComponents/crewTeamComponent.directive';
import { CrewHistoryComponentDirective } from './angularJSComponents/crewHistoryComponent.directive';
import { CrewDashboardComponentDirective } from './angularJSComponents/crewDashboardComponent.directive';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { CREW_ROOT_STATE, CREW_DASHBOARD_STATE, CREW_CHECKLIST_STATE, CREW_TEAM_STATE, CREW_HISTORY_STATE } from './crew.states';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { TirednessIndicatorComponent } from './common/tiredness-indicator/tiredness-indicator.component';
import { CrewEventsListComponent } from './components/crew-events-box/crew-events-list/crew-events-list.component';
import { CrewEventItemComponent } from './components/crew-events-box/crew-event-item/crew-event-item.component';
import { FilterColorDirective } from './common/directives/filter-color.directive';
import { CrewEventGroupItemComponent } from './components/crew-events-box/crew-event-group-item/crew-event-group-item.component';
import { CrewEventsBoxComponent } from './components/crew-events-box/crew-events-box.component';
import { CrewDashboardComponent } from './pages/crew-dashboard/crew-dashboard.component';
import { CrewComponent } from './crew.component';
import { CrewChecklistComponent } from './pages/crew-checklist/crew-checklist.component';
import { CrewTeamComponent } from './pages/crew-team/crew-team.component';
import { CrewHistoryComponent } from './pages/crew-history/crew-history.component';
import { OclModule } from '../ocl/ocl.module';
import { OclGlobalInstructionBoxComponent } from '../ocl/components/ocl-global-instruction-box/ocl-global-instruction-box.component';
import { TagsService } from '../common/services/tags.service';

const CREW_STATES: Ng2StateDeclaration[] = [
  CREW_ROOT_STATE,
  CREW_DASHBOARD_STATE,
  CREW_CHECKLIST_STATE,
  CREW_TEAM_STATE,
  CREW_HISTORY_STATE,
];
@NgModule({
  declarations: [
    TirednessIndicatorComponent,
    CrewEventsListComponent,
    CrewEventItemComponent,
    FilterColorDirective,
    CrewEventGroupItemComponent,
    CrewEventsBoxComponent,
    CrewDashboardComponent,
    CrewComponent,
    CrewChecklistComponent,
    CrewTeamComponent,
    CrewHistoryComponent,
    CrewDashboardComponentDirective,
    CrewHistoryComponentDirective,
    CrewChecklistComponentDirective,
    CrewTeamComponentDirective,
  ],
  imports: [OclModule, UIRouterUpgradeModule.forChild({ states: CREW_STATES })],
  entryComponents: [TirednessIndicatorComponent, CrewEventsListComponent, CrewEventsBoxComponent, OclGlobalInstructionBoxComponent],
  exports: [
    TirednessIndicatorComponent,
    CrewEventsListComponent,
    CrewDashboardComponentDirective,
    CrewHistoryComponentDirective,
    CrewTeamComponentDirective,
    CrewChecklistComponentDirective,
  ],
  providers: [
    {
      provide: ModuleConfigService,
      useExisting: CrewModuleConfigService,
    },
    {
      provide: OclGlobalInstructionService,
      useExisting: CrewGlobalInstructionService,
    },
    {
      provide: OclGlobalInstructionTagService,
      useExisting: CrewGlobalInstructionTagService,
    },
    {
      provide: OclGroupService,
      useExisting: CrewGroupService,
    },
    {
      provide: TagsService,
      useExisting: CrewTagsService,
    },
  ],
})
export class CrewModule {}
