import { CommonStoreState } from 'src/app/common/store/common.model';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { OclSmsService } from './../ocl-sms-service/ocl-sms.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { HolNotification } from 'src/app/common/models/hol-notification.model';
import { GocFlightLogbook } from '../../../goc/models/goc-flight-logbook.model';
import { HolContext } from './../../../common/models/hol-context.model';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { GocFlightLogbookService } from '../../../goc/services/goc-flight-logbook-service/goc-flight-logbook.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import * as moment from 'moment';
import { OclLogbookTagService } from '../ocl-logbook-tag-service/ocl-logbook-tag.service';
import { OclOptionsService } from '../ocl-options-service/ocl-options.service';
import { OclHistoryService } from '../ocl-history-service/ocl-history.service';
import { HolTag } from '../../../common/models/hol-tag';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { OclLogBooksStoreManager } from '../../store/logbooks/ocl-log-books-store-manager.service';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { OclMailService } from '../ocl-mail-service/ocl-mail.service';
import { OclHistoryLog } from '../../models/ocl-history-log.model';

@Injectable({
  providedIn: 'root',
})
export abstract class OclLogbookService<T extends OclLogbook = OclLogbook, U extends HolContext = HolContext> {
  // tslint:disable:variable-name
  protected abstract ParseLogbook;
  protected abstract ParseEvents;
  protected ParseFlight = Parse.Object.extend('GOCFlight');
  protected abstract ParseLogbookTag;
  protected abstract ParseTag;
  private ParseUser = Parse.Object.extend('_User');
  protected ParseGocFlightLogBook = Parse.Object.extend('GOCFlightLogbook');
  private ParseGocFlightLogbookTag = Parse.Object.extend('GOCFlightLogbookTag');
  private ParseCrewEventNote = Parse.Object.extend('CrewEventNote');
  protected ParseErpLogbook = Parse.Object.extend('GDCCrisisNews');
  // tslint:enabled
  public _data = []; // @cache
  protected loadMoreCount = 1;
  public dateTo: moment.Moment;
  public totalLogbooks: number;

  protected constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    @Inject('UserService') protected userService,
    protected optionsService: OclOptionsService,
    protected historyService: OclHistoryService,
    protected parseMapper: ParseMapperService,
    protected logBookTagService: OclLogbookTagService,
    protected gocLogbookService: GocFlightLogbookService,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    public moduleConfig: ModuleConfigService,
    protected notificationsService: NotificationsService,
    protected smsService: OclSmsService,
    protected mailService: OclMailService,
    public commonStoreManager: CommonStoreManager
  ) {}

  static _getLogMessage(log: OclLogbook): string {
    return log.text;
  }

  /**
   * get all the logbooks
   * @param forceRefresh if refresh is forced, to nut use cash
   */
  public getAll(forceRefresh: boolean, loadMore: boolean = false, isFromPooling?, filterDataStartDate?: Date): Promise<T[]> {
    if (loadMore) {
      this.loadMoreCount = this.loadMoreCount + 1;
    } else if (!isFromPooling) {
      this.loadMoreCount = 1;
    }
    return new Promise((resolve, reject) => {
      if (this._data !== undefined && this._data.length && !forceRefresh) {
        resolve(this._data);
      } else {
        this.getLogbooksToDisplay(isFromPooling, filterDataStartDate).then(
          logbooks => {
            resolve(_.orderBy(logbooks, 'createdAt', 'desc'));
          },
          error => reject(error)
        );
        // const occLogbookPromise = this.getLogbooksToDisplay(isFromPooling);
        // const gocLogbooksPromise = this.gocLogbookService.getAllToOcc();
        // Promise.all([occLogbookPromise, gocLogbooksPromise]).then(
        //   data => {
        //     const occLogbooks: any[] = data[0];
        //     const gocLogbooks: GocLogbook[] = data[1];
        //     const fromGoc = _.map(gocLogbooks, gocL => this.parseMapper.gocLogbookToOCCLogbook(gocL));
        //     const merged = occLogbooks.concat(fromGoc);
        //     resolve(_.orderBy(merged, 'createdAt', 'desc'));
        //   },
        //   error => reject(error)
        // );
      }
    });
  }

  public create(logbook: T, notifications: HolNotification[], context?: U, duplicateToOtherModule?: boolean): Promise<T> {
    const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
    const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);

    const createLogBook = new this.ParseLogbook();
    createLogBook.setACL(logbook.acl);
    createLogBook.set('text', logbook.text);
    createLogBook.set('isPinned', logbook.isPinned ? logbook.isPinned : false);
    createLogBook.set('createdBy', new this.ParseUser({ id: this.userService.getCurrentUserObject().objectId }));
    if (logbook.attachments) {
      createLogBook.set('attachments', JSON.stringify(logbook.attachments));
    }
    if (logbook.event && logbook.event.objectId) {
      createLogBook.set('event', new this.ParseEvents({ id: logbook.event.objectId }));
    }
    if (logbook.flight && logbook.flight.objectId) {
      createLogBook.set('flight', new this.ParseFlight({ id: logbook.flight.objectId }));
    }
    if (logbook.toGOC) {
      createLogBook.set('toGOC', logbook.toGOC);
    }
    if (logbook.toERP) {
      createLogBook.set('toERP', logbook.toERP);
    }
    this.setAdditionalFields(logbook, createLogBook);
    return new Promise((resolve, reject) => {
      this.requestService.performSaveQuery(
        createLogBook,
        null,
        parseData => {
          this.createLogbookFromParseData(logbook, parseData).then(
            logbookTags => {
              const tags = this.getTagsForLogbook(logbookTags, parseData);
              const newLogbook = this.newLogbook(parseData, tags);

              if (addressMailToSend.length) {
                this.mailService.sendNewLogbookMail(newLogbook, addressMailToSend);
              }
              if (phoneNumbersToSend.length) {
                this.smsService.sendNewLogbookSMS(newLogbook, phoneNumbersToSend);
              }

              this.historyService.postLog(
                OclHistoryLog.create(
                  OclLogbookService._getLogMessage(newLogbook),
                  'logbook',
                  'create',
                  newLogbook.attachments,
                  newLogbook,
                  newLogbook.acl,
                  parseData
                )
              );

              if (duplicateToOtherModule) {
                this.duplicateLogbookToOtherModule(newLogbook);
              }

              // STORE
              this.occLogBooksStoreManager.addOneLogBook(newLogbook);
              resolve(newLogbook);
            },
            error => reject(error)
          );
        },
        error => reject(error)
      );
    });
  }

  public update(
    logbook: T,
    context?: U,
    oldDuplicateToOtherModuleValue?: boolean,
    newDuplicateToOtherModuleValue?: boolean,
    updateDuplicateToOtherModuleFlightValue?: boolean
  ): Promise<T> {
    const parseLogbook = new this.ParseLogbook();
    parseLogbook.id = logbook.objectId;
    parseLogbook.setACL(logbook.acl);
    parseLogbook.set('attachments', JSON.stringify(logbook.attachments));
    parseLogbook.set('done', logbook.done);
    parseLogbook.set('isPinned', logbook.isPinned ? logbook.isPinned : false);
    return new Promise((resolve, reject) => {
      if (logbook.event && logbook.event.objectId) {
        parseLogbook.set('event', new this.ParseEvents({ id: logbook.event.objectId }));
      } else {
        parseLogbook.set('event', null);
      }
      if (logbook.flight && logbook.flight.objectId) {
        parseLogbook.set('flight', new this.ParseFlight({ id: logbook.flight.objectId }));
      } else {
        parseLogbook.set('flight', null);
      }
      parseLogbook.set('toGOC', logbook.toGOC);
      parseLogbook.set('toERP', logbook.toERP);
      if (logbook.text) {
        parseLogbook.set('text', logbook.text);
      }
      if (logbook.customCreatedAt) {
        parseLogbook.set('customCreatedAt', logbook.customCreatedAt);
      }
      this.setAdditionalFields(logbook, parseLogbook);
      this.requestService.performSaveQuery(
        parseLogbook,
        null,
        pSavedLogbook => {
          this.logBookTagService.updateLogBookWithTags(logbook).then(
            logbookTags => {
              const tags = this.getTagsForLogbook(logbookTags, pSavedLogbook);
              const updatedLogbook = this.newLogbook(pSavedLogbook, tags);
              this.historyService.postLog(
                OclHistoryLog.create(
                  updatedLogbook.text,
                  'logbook',
                  'update',
                  updatedLogbook.attachments,
                  updatedLogbook,
                  updatedLogbook.acl,
                  pSavedLogbook
                )
              );

              if (!_.isEqual(newDuplicateToOtherModuleValue, oldDuplicateToOtherModuleValue)) {
                // Supprimer
                if (!newDuplicateToOtherModuleValue && oldDuplicateToOtherModuleValue) {
                  this.deleteDuplicateLogbookFromModule(updatedLogbook);
                }
                // Ajouter
                if (newDuplicateToOtherModuleValue && !oldDuplicateToOtherModuleValue) {
                  this.duplicateLogbookToOtherModule(updatedLogbook);
                }
              }

              // STORE
              this.occLogBooksStoreManager.updateOneLogBook(updatedLogbook);
              resolve(updatedLogbook);
            },
            error => reject(error)
          );
        },
        error => reject(error)
      );
    });
  }

  public toggleDone(logbook: T): Promise<T> {
    const l = _.cloneDeep(logbook);
    l.done = !l.done;
    return this.update(l);
  }

  public fetchNewData(isFromPooling: boolean = false, filterDataStartDate?: Date): Promise<void | never> {
    return this.getAll(true, false, isFromPooling, filterDataStartDate).then(logbook => {
      // STORE
      this.occLogBooksStoreManager.updateLogBooksFromPooling(logbook, this.moduleConfig.config.moduleName);
    });
  }

  protected getTagsForLogbook(logbooksTags: Parse.Object[], logbook: Parse.Object): Parse.Object[] {
    return logbooksTags
      ? logbooksTags.filter(dt => {
          return dt.get('logbook').id === logbook.id;
        })
      : [];
  }

  protected getLogbooksToDisplay(isFromPooling: boolean, filterDataStartDate?: Date): Promise<T[]> {
    const query = new Parse.Query(this.ParseLogbook);
    const logbooksToDisplay = this.optionsService.getLogbooksToDisplay();

    let today: Date;
    if (this.moduleConfig.config.canChooseDataStartDate) {
      if (filterDataStartDate instanceof Date && filterDataStartDate !== undefined) {
        today = filterDataStartDate;
      } else {
        today = new Date();
      }
    }

    if (logbooksToDisplay) {
      if (this.moduleConfig.config.canChooseDataStartDate && filterDataStartDate instanceof Date && filterDataStartDate !== undefined) {
        const dateFrom = moment.utc(filterDataStartDate).endOf('day');
        const dateTo = moment.utc(filterDataStartDate).subtract(logbooksToDisplay, 'hours');
        this.dateTo = dateTo;
        query.lessThanOrEqualTo('createdAt', dateFrom.toDate());
        query.greaterThanOrEqualTo('createdAt', dateTo.toDate());
      } else {
        const dateFrom = isFromPooling ? moment.utc() : moment.utc().subtract(logbooksToDisplay * (this.loadMoreCount - 1), 'hours');
        const dateTo = moment.utc().subtract(logbooksToDisplay * this.loadMoreCount, 'hours');
        this.dateTo = dateTo;
        query.lessThanOrEqualTo('createdAt', dateFrom.toDate());
        query.greaterThanOrEqualTo('createdAt', dateTo.toDate());
      }
    }

    const queryPinned = new Parse.Query(this.ParseLogbook);
    queryPinned.descending('createdAt');
    queryPinned.equalTo('isPinned', true);

    const logBookQuery = this.getAdditionnalQueries(query, queryPinned, today);

    logBookQuery.descending('createdAt');
    logBookQuery.include('event.scenario');
    logBookQuery.include('flight');
    logBookQuery.include('applFlights');
    logBookQuery.include('createdBy');
    logBookQuery.include('gocLogbook');
    logBookQuery.include('crewLogbook');
    logBookQuery.include('erpLogbook');
    logBookQuery.doesNotExist('isFromFlight');

    const totalQuery = new Parse.Query(this.ParseLogbook);
    return Promise.all([this.requestService.performFindAllQuery(logBookQuery), this.requestService.performCountQuery(totalQuery)]).then(
      ([logbooks, totalCount]) => {
        this.totalLogbooks = totalCount;
        return this.getLogbooksByTag(logBookQuery, logbooks);
      }
    );
  }

  private getLogbooksByTag(query: any, results: any): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const logbookTagQuery = new Parse.Query(this.ParseLogbookTag);
      logbookTagQuery.include('tag');
      // logbookTagQuery.matchesQuery('logbook', query);
      logbookTagQuery.containedIn('logbook', results);
      logbookTagQuery.descending('createdAt');
      this.requestService.performFindQuery(
        logbookTagQuery,
        logbookTags => {
          const logBook = [];
          results.map(result => {
            const tags = this.getTagsForLogbook(logbookTags, result);
            const logbookItem = this.newLogbook(result, tags);
            logBook.push(logbookItem);
          });
          this._data = logBook;
          resolve(logBook);
        },
        error => reject(error)
      );
    });
  }

  private createLogbookFromParseData(logbook: T, parseData): Promise<Parse.Object[]> {
    return new Promise((resolve, reject) => {
      if (logbook.tags && logbook.tags.length) {
        const logbookTags = _.map(logbook.tags, tag => {
          return new this.ParseLogbookTag({
            logbook: parseData,
            tag: new this.ParseTag({ id: tag && tag.objectId }),
          });
        });
        this.requestService.performSaveAllQuery(
          logbookTags,
          res => resolve(res),
          error => reject(error)
        );
      } else {
        resolve([]);
      }
    });
  }

  protected newLogbook(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return new OclLogbook(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }
  protected setAdditionalFields(logbook: T, parseLogbook: Parse.Object) {}

  getGOCFlightLogbook(parseGocFlightLogbook: Parse.Object): Promise<GocFlightLogbook> {
    const query = new Parse.Query(this.ParseGocFlightLogBook);
    query.equalTo('objectId', parseGocFlightLogbook.id);
    return this.requestService.performFindQuery(query).then(parseFlightLogbook => {
      const flightLogbookTagsQuery = new Parse.Query(this.ParseGocFlightLogbookTag);
      flightLogbookTagsQuery.containedIn('flightLogbook', parseFlightLogbook);
      flightLogbookTagsQuery.include('tag');
      flightLogbookTagsQuery.descending('createdAt');
      return this.requestService.performFindQuery(flightLogbookTagsQuery).then(tags => {
        const gocLogbook = new GocFlightLogbook(parseGocFlightLogbook);
        gocLogbook.tags = tags.filter(flt => flt.get('flightLogbook').id === gocLogbook.objectId).map(flt => new HolTag(flt.get('tag')));
        return gocLogbook;
      });
    });
  }

  deleteGocLogbook(occLogbook: T): void {
    const parseOccLogbook = new this.ParseLogbook({ id: occLogbook.objectId });
    const parseGocLogbook = new this.ParseGocFlightLogBook({ id: occLogbook.gocLogbook.objectId });
    parseGocLogbook.set('toOCC', false);
    this.requestService.performSaveQuery(parseGocLogbook).then(() => {
      this.requestService.performDestroyQuery(
        parseOccLogbook,
        () => {
          // STORE
          this.occLogBooksStoreManager.deleteOneLogBook(occLogbook.objectId);
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  deleteCrewLogbook(occLogbook: T): void {
    const parseOccLogbook = new this.ParseLogbook({ id: occLogbook.objectId });
    const parseEventNote = new this.ParseCrewEventNote({ id: occLogbook.crewLogbook.objectId });
    parseEventNote.set('toOCC', false);
    this.requestService.performSaveQuery(parseEventNote).then(() => {
      this.requestService.performDestroyQuery(
        parseOccLogbook,
        () => {
          // STORE
          this.occLogBooksStoreManager.deleteOneLogBook(occLogbook.objectId);
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  deleteErpLogbook(oclLogbook: T): void {
    const parseOclLogbook = new this.ParseLogbook({ id: oclLogbook.objectId });
    const parseErpLogbook = new this.ParseErpLogbook({ id: oclLogbook.erpLogbook.objectId });
    parseErpLogbook.set('toECL', false);
    this.requestService.performSaveQuery(parseErpLogbook).then(() => {
      this.requestService.performDestroyQuery(
        parseOclLogbook,
        () => {
          // STORE
          this.occLogBooksStoreManager.deleteOneLogBook(oclLogbook.objectId);
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  protected getAdditionnalQueries(query, queryPinned, today) {
    return Parse.Query.or(query, queryPinned);
  }

  protected duplicateLogbookToOtherModule(logbook: T) {}

  protected deleteDuplicateLogbookFromModule(logbook: T) {}
}
