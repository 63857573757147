'use strict';

angular
  .module('gestiondecriseApp')

  /**
   * @ngdoc Mailservice
   * @name MailService
   * @description
   * Service in the gestiondecriseApp.
   */
  .service('MailService', function (
    $q,
    $translate,
    UserService,
    OptionsService,
    FunctionService,
    MailSenderService,
    RequestService,
    CONSTANTS,
    $log,
    MailTemplateService,
    ErpUsersService
  ) {
    this.sendNewMELInfoMail = function (mel, melInfo, mails) {
      if (!mel) {
        $log.warn('MailService::sendNewMELInfoMail: MEL empty');
      }
      if (!melInfo) {
        $log.warn('MailService::sendNewMELInfoMail: MELInfo empty');
      }
      if (!mails || !mails.length) {
        $log.warn('MailService::sendNewMELInfoMail: no mailing list found');
      }

      MailSenderService.sendMail(
        {
          recipients: _.map(mails, function (m) {
            return { email: m };
          }),
          subject: $translate.instant('MAIL.MCC.NEW_MEL_INFO.SUBJECT', {
            faultName: mel.faultName,
          }),
          contentHtml: MailTemplateService.getNewMELInfoMessage(mel, melInfo),
          sender: CONSTANTS.COMPANY_NAME + CONSTANTS.MCC_SUFFIX,
        },
        true,
        true
      );
    };

    //this is a crew event, not occ
    this.sendNewSituationEmail = function (situation) {
      if (!situation.user || !situation.user.email) {
        $log.warn('MailService::sendNewSituationEmail: no user mail');
        return;
      }

      MailSenderService.sendMail(
        {
          recipients: [{ email: situation.user.email }],
          subject: 'Contact with ' + situation.createdBy.firstName + ' ' + situation.createdBy.lastName,
          contentHtml: MailTemplateService.getSituationMessage(situation),
          sender: CONSTANTS.COMPANY_NAME + CONSTANTS.CREW_SUFFIX,
        },
        true,
        true
      );
    };

    //MCC
    this.sendNewMCCEventMail = function (event, mails) {
      if (!event) {
        $log.warn('MailService::sendNewMCCEventMail: event empty');
      }
      if (!mails || !mails.length) {
        $log.warn('MailService::sendNewMCCEventMail: no mailing list found');
      }

      MailSenderService.sendMail(
        {
          recipients: _.map(mails, function (m) {
            return { email: m };
          }),
          subject: $translate.instant('MAIL.MCC.NEW_EVENT.SUBJECT', {
            aircraft: event.aircraft && event.aircraft.registration,
          }),
          contentHtml: MailTemplateService.getNewMCCEventMessage(event, event.infos),
          sender: CONSTANTS.COMPANY_NAME + CONSTANTS.MCC_SUFFIX,
        },
        true,
        true
      );
    };

    this.sendNewMCCEventInfoMail = function (event, eventInfo, mails) {
      if (!event) {
        $log.warn('MailService::sendNewMCCEventInfoMail: MCCEvent empty');
      }
      if (!eventInfo) {
        $log.warn('MailService::sendNewMCCEventInfoMail: MCCEventInfo empty');
      }
      if (!mails || !mails.length) {
        $log.warn('MailService::sendNewMCCEventInfoMail: no mailing list found');
      }

      MailSenderService.sendMail(
        {
          recipients: _.map(mails, function (m) {
            return { email: m };
          }),
          subject: $translate.instant('MAIL.MCC.NEW_EVENT_INFO.SUBJECT', {}),
          contentHtml: MailTemplateService.getNewMCCEventInfoMessage(event, eventInfo),
          sender: CONSTANTS.COMPANY_NAME + CONSTANTS.MCC_SUFFIX,
        },
        true,
        true
      );
    };
  })

  /**
   * @ngdoc MailSenderService
   * @name MailSenderService
   * @description
   * Service in the gestiondecriseApp.
   */
  .service('MailSenderService', function ($q, $http, CONSTANTS, OptionsService, CommonService, RequestService) {
    /**
     * @description Sends an email.
     * @methodOf MailSenderService
     * @param {Object} data must contain: to, subject, contentText, contentHtml, attachements
     * @param {boolean?} forceToSendMail Set this flag to true to bypass hasToSendMail flag (default to false)
     * @param {boolean?} doNotSendCC Set this flag to true if you don't want to add crisis directors in CC field (default to false)
     * @return {Promise}
     */
    this.sendMail = function (data, forceToSendMail, doNotSendCC) {
      var deferred = $q.defer();

      // If we don't force to send mail
      // And if hasToSendMails flag is false,
      // ==> We don't send any mail
      if (forceToSendMail !== true && !OptionsService.hasToSendMails()) {
        return $q.resolve();
      }

      data.sender = data.sender || CONSTANTS.COMPANY_NAME;

      if (doNotSendCC) {
        RequestService.performCloudCode('sendMail', data, deferred.resolve, deferred.reject);
      } else {
        data.ccRecipients = data.ccRecipients || [];
        // add current user in copy
        data.ccRecipients.push({ email: Parse.User.current().get('email') || Parse.User.current().get('userEmail') });

        // add erd in copy (if he is not the current user)
        CommonService.getCrisisDirectors().then(function (crisisDirectors) {
          for (var i = 0; i < crisisDirectors.length; i++) {
            var crisisDirector = crisisDirectors[i];
            if (angular.isDefined(crisisDirector) && crisisDirector.id !== Parse.User.current().id) {
              data.ccRecipients.push({ email: crisisDirector.mail });
            }
          }
          RequestService.performCloudCode('sendMail', data, deferred.resolve, deferred.reject);
        });
      }
      return deferred.promise;
    };
  });
