<app-loading *ngIf="loading" class="full-loader"></app-loading>
<div class="hol-dialog-wrapper">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <span *ngIf="isCreate" class="title">{{
        moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.ADD_INSTRUCTION' | translate
      }}</span>
      <span *ngIf="!isCreate" class="title">{{ globalInstuction.createdAt | time: !isReadOnly }} : {{ globalInstuction.description }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="hol-dialog-section" *ngIf="isCreate">
        <mat-form-field *ngIf="isCreate" class="full-width description-form-field">
          <textarea
            formControlName="description"
            maxlength="200"
            placeholder="{{ 'GOC.MODALS.COMMON.DESCRIPTION' | translate }}"
            type="text"
            mat-autosize
            matInput
          >
          </textarea>
        </mat-form-field>
      </div>
      <div class="hol-dialog-section">
        <app-tag-selector [(selectedTags)]="tags" [readOnly]="isReadOnly" class="tag-selector" mode="occ"> </app-tag-selector>
      </div>
      <div class="hol-dialog-section">
        <app-file-and-image-upload
          [attachments]="attachments"
          [disabled]="isReadOnly"
          [context]="{
            module: moduleConfig.config.translateKey,
            category: 'GLOBALCONSIGNE',
            htmlTitle: form.value.description,
            htmlDate: globalInstuction?.createdAt,
            htmlTemplate: 'A'
          }"
        >
        </app-file-and-image-upload>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <app-acl-selector
      class="acl-selector"
      *ngIf="!isReadOnly"
      [moduleName]="moduleConfig.config.moduleName"
      [object]="globalInstuction"
      [formGroup]="form"
    ></app-acl-selector>
    <button mat-stroked-button color="warn" *ngIf="!isReadOnly && !isCreate && !globalInstuction.archived" (click)="archive()">
      <i class="icon fa fa-archive"></i>
      {{ 'COMMON.BUTTON.ARCHIVE' | translate }}
    </button>
    <div class="fill-space"></div>
    <button class="hol-validate-btn" mat-raised-button (click)="save()" [disabled]="form.invalid">
      <mat-icon>done</mat-icon>
      <span *ngIf="isCreate">{{ 'COMMON.BUTTON.ADD' | translate | uppercase }}</span>
      <span *ngIf="!isCreate">{{ 'COMMON.BUTTON.OK' | translate | uppercase }}</span>
    </button>
    <button class="hol-validate-btn" mat-raised-button *ngIf="isReadOnly" mat-dialog-close>
      <mat-icon>done</mat-icon>
    </button>
  </mat-dialog-actions>
</div>
