import { Action } from '@ngrx/store';
import { OclEvent } from '../../models/ocl-event.model';

export enum OclEventsActionTypes {
  INIT_EVENTS = '[OCL EVENTS] Init Events',
  RESET_EVENTS = '[OCL EVENTS] Reset Events',
  UPDATE_ONE_EVENT = '[OCL EVENTS] Update One Event',
  UPDATE_MANY_EVENTS = '[OCL EVENTS] Update Many Events',
  UPDATE_EVENTS_FROM_POOLING = '[OCL EVENTS] Update Events From Pooling',
  UPDATE_EVENTS_FROM_LINKED_VALUE = '[OCL EVENTS] Update Events From Linked Value',
  REMOVE_EVENTS_FROM_LINKED_VALUE = '[OCL EVENTS] Remove Events From Linked Value',
  ADD_ONE_EVENT = '[OCL EVENTS] Add One Event',
  DELETE_ONE_EVENT = '[OCL EVENTS] Delete One Event',
}

export class InitEvents implements Action {
  readonly type = OclEventsActionTypes.INIT_EVENTS;
  constructor(public payload: Array<OclEvent>) {}
}

export class UpdateOneEvent implements Action {
  readonly type = OclEventsActionTypes.UPDATE_ONE_EVENT;
  constructor(public payload: OclEvent) {}
}
export class AddOneEvent implements Action {
  readonly type = OclEventsActionTypes.ADD_ONE_EVENT;
  constructor(public payload: OclEvent) {}
}

export class UpdateManyEvents implements Action {
  readonly type = OclEventsActionTypes.UPDATE_MANY_EVENTS;
  constructor(public payload: Array<OclEvent>) {}
}

export class UpdateEventsFromPooling implements Action {
  readonly type = OclEventsActionTypes.UPDATE_EVENTS_FROM_POOLING;
  constructor(public payload: Array<OclEvent>) {}
}

export class UpdateEventsFromLinkedValue implements Action {
  readonly type = OclEventsActionTypes.UPDATE_EVENTS_FROM_LINKED_VALUE;
  constructor(public payload: Array<OclEvent>, public linkedUid: string) {}
}

export class RemoveEventsFromLinkedValue implements Action {
  readonly type = OclEventsActionTypes.REMOVE_EVENTS_FROM_LINKED_VALUE;
  constructor(public linkedUid: string) {}
}

export class ResetEvents implements Action {
  readonly type = OclEventsActionTypes.RESET_EVENTS;
}

export class DeleteOneEvent implements Action {
  readonly type = OclEventsActionTypes.DELETE_ONE_EVENT;
  constructor(public eventObjectId: string) {}
}

export type EventsActions =
  | InitEvents
  | ResetEvents
  | UpdateOneEvent
  | AddOneEvent
  | UpdateManyEvents
  | UpdateEventsFromPooling
  | UpdateEventsFromLinkedValue
  | RemoveEventsFromLinkedValue
  | DeleteOneEvent;
