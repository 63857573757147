'use strict';

angular.module('gestiondecriseApp').service('MarkdownService', function ($translate, OptionsService) {
  this.createHtmlContent = function (content, context) {
    const logoUrl = OptionsService.getCompanyLogoUrl();

    const i18nUnivers = $translate.instant('HTML_FILE.UNIVERS.' + context.module.toLocaleUpperCase());
    const i18nTitle = $translate.instant(
      'HTML_FILE.TITLE.' + context.module.toLocaleUpperCase() + '.' + context.category.toLocaleUpperCase()
    );
    const i18nSubTitle = $translate.instant(
      'HTML_FILE.SUBTITLE.' + context.module.toLocaleUpperCase() + '.' + context.category.toLocaleUpperCase()
    );
    var nextInfoHtmlSource;

    if (context.htmlNextInfo) {
      nextInfoHtmlSource = `<div class="bottom">
          <div class="left">
            <span>NEXT INFO</span>
            <span class="htmlNextInfoDate date">${context.htmlNextInfo.createdAt}</span>
          </div>
          <div class="right">
            <span class="htmlNextInfoTitle">${context.htmlNextInfo.nextInfoTime} - ${context.htmlNextInfo.message}</span>
          </div>
        </div>`;
    }

    var htmlTemplate;
    htmlTemplate = `<html>
      <head>
        <meta charset="utf-8"/>
        <link href="https://fonts.googleapis.com/css2?family=Nunito&display=swap" rel="stylesheet">
        <style type="text/css">
        body {
          font-family: Nunito, Helvetica, Arial, sans-serif;
          padding:1em;
          margin:auto;
          max-width:64em;
          background:#fefefe;
        }
        
        h1, h2, h3, h4, h5, h6 {
          font-weight: bold;
        }
        
        h1 {
          border-bottom: 1px solid #CCCCCC;
          color: #000000;
          font-size: 24px;
        }
        
        h2 {
          font-size: 18px;
        }
        
        h3 {
          font-size: 16px;
        }
        
        h4, h5, h6 {
          color: #777777;
          background-color: inherit;
          font-size: 14px;
        }
        
        hr {
          height: 0.2em;
          border: 0;
          color: #CCCCCC;
          background-color: #CCCCCC;
        }
        
        p, ul, ol, dl, li, table, pre {
          margin: 15px 0;
        }
        
        blockquote{
        color:#666666;
        margin:0;
        padding-left: 3em;
        border-left: 0.5em #EEE solid;
        }
        
        img {
          max-width: 100%;
        }
        
        table {
          border-collapse: collapse;
          width: 100%;
        }
        
        table, th, td {
          border: 1px solid #EAEAEA;
          border-radius: 3px;
          padding: 5px;
        }
        
        th {
          background-color: #F8F8F8;
        }
        
        tr:nth-child(even) {
          background-color: #F8F8F8;
        }
        
        a, a:visited {
          color: #4183C4;
          background-color: inherit;
          text-decoration: none;
        }
        
        #message {
          border-radius: 6px;
          border: 1px solid #ccc;
          display:block;
          width:100%;
          height:60px;
          margin:6px 0px;
        }
        
        button, #ws {
          font-size: 10pt;
          padding: 4px 6px;
          border-radius: 5px;
          border: 1px solid #bbb;
          background-color: #eee;
        }
        
        code, pre, #ws, #message {
          font-family: Monaco, monospace !important;
          font-size: 10pt;
          border-radius: 3px;
          background-color: #F8F8F8;
          color: inherit;
        }
        
        code {
          border: 1px solid #EAEAEA;
          margin: 0 2px;
          padding: 0 5px;
        }
        
        pre {
          border: 1px solid #CCCCCC;
          overflow: auto;
          padding: 4px 8px;
        }
        
        pre > code {
          border: 0;
          margin: 0;
          padding: 0;
        }
        
        #ws { background-color: #f8f8f8; }
        
        .send { color:#77bb77; }
        .server { color:#7799bb; }
        .error { color:#AA0000; }
        pre {
          display: block;
          padding: 9.5px!important;
          margin: 0 0 10px;
          font-size: 13px;
          line-height: 1.42857143;
          color: #333333;
          word-break: break-all;
          word-wrap: break-word;
          background-color: #f5f5f5;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
          pre code {
            padding: 0;
            font-size: inherit;
            color: inherit;
            white-space: pre-wrap;
            background-color: transparent;
            border-radius: 0;
          }
    
        code, kbd, pre, samp {
          font-family: Menlo, Monaco, Consolas, "Courier New", monospace !important;
        }
      
        body {
          padding: 20px;
        }
          body * {
            font-family: 'Nunito', sans-serif, helvetica;
          }
        .date {
          white-space: break-spaces;
          word-break: normal;
          width: 80px;
          display: block;
          text-align: center;
        }
          .header {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: solid 1px #bfbcbc;
          }
            .header .top  {
              display: flex;
              width: 100%;
              font-size: 20px;
            }
              .header .top .left {
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: solid 1px #bfbcbc
              }
                .header .top .left img{
                    width: 150px;
                }
            .header .top .center{
              width: 70%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .header .top .right {
              width: 15%;
              display: flex;
              justify-content: center;
              align-items: center;
              border-left: solid 1px #bfbcbc;
            }
              .header .top .right .today.date{ 
                width: 80px;
              }
            .header .middle {
              border-top: solid 1px #bfbcbc;
              padding: 10px;
              background: #7f7f7f;
              color: white;
            }
            .bottom {
              display: flex;
              width: 100%;
            }
              .bottom .left {
                background: #d3d3d3;
                width: 15%;
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              .bottom .right {
                width: 85%;
                padding: 10px;
                text-align: center;
              }
            .htmlScenario {
              font-weight: bold;
              text-align: center;
            }
            .markdownData {
              padding: 10px 20px;
            }
        </style>
      </head>
      <body>
        <div class="header">
          <div class="top">
            <div class="left">`;

    if (logoUrl) {
      htmlTemplate += `<img
          class="logo"
          src="${logoUrl}"/>`;
    }

    htmlTemplate += `</div>
            <div class="center">
              <span class="i18nTitle">${i18nUnivers} - ${i18nTitle}</span>
              ${context.htmlScenario ? '<span class="htmlScenario">' + context.htmlScenario + '</span>' : ''}
              <span class="i18nSubTitle">${i18nSubTitle}</span>
            </div>
            <div class="right">
              <span class="today date">${moment().format('DD/MM/YY HH:mm')}</span>
            </div>
            
          </div>
          <div class="middle">
            <span class="htmlTitle">${context.htmlDate} ---- ${context.htmlTitle}</span>
            
          </div>
        ${nextInfoHtmlSource ? nextInfoHtmlSource : ''}
        </div>
        <div class="markdownData">
           ${content}
        </div>
      </body>
      </html>`;

    return htmlTemplate;
  };

  this.parseMdToHtml = function (markdown) {
    return marked(markdown, { breaks: true });
  };
});
