'use strict';

angular
  .module('gestiondecriseApp')
  .directive('fileAndImageDisplay', function ($timeout) {
    return {
      template:
        '<a class="external-attachment"' + '   target="_blank" ' + '   href="{{url}}"> ' + '  <i class="icon icon-{{icon}}"></i>' + '</a>',
      restrict: 'E',
      scope: {
        url: '=',
        icon: '@',
      },
      link: function (scope, element, attrs) {},
    };
  })
  .directive('fileAndImageDisplayFull', function () {
    return {
      template:
        '' +
        '<div class="file-image-display-full"> ' +
        ' <a target="_blank" ' +
        '    class="external-attachment" ' +
        '     href="{{ url }}">' +
        '   <figure ng-if="icon === \'picture\'">' +
        '     <img ng-src="{{ url }}" />' +
        '     <figcaption><i class="icon icon-{{icon}}"></i> {{ name }}</figcaption>' +
        '   </figure>' +
        '   <span ng-if="icon !== \'picture\'">' +
        '     <i class="icon icon-clip"></i>' +
        '     <span>{{ name }}</span>' +
        '   </span>' +
        ' </a>' +
        '</div>',
      restrict: 'E',
      replace: true,
      scope: {
        url: '=',
        icon: '@',
        name: '=',
      },
      link: function (scope, element, attrs) {},
    };
  });
