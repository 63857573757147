import { HelperService } from './../../common/services/helper.service';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { Inject, Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { ParseMapperService } from '../../common/services/parse-mapper.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { ENDED_STATUS, HolFlight, TAKEN_OFF_STATUS } from '../../common/models/hol-flight.model';
import { HolTag } from '../../common/models/hol-tag';
import { FltFlightLogbookService } from './flt-flight-logbook.service';
import { FltFlightInstructionService } from './flt-flight-instruction.service';
import { FltApplicability } from '../models/flt-applicability';
import { FltFlightEventService } from './flt-flight-event.service';
import { FltUserAirportService } from './flt-user-airport.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as _ from 'lodash';
import { GocOptionsService } from '../../goc/services/goc-options-service/goc-options.service';
import { RolesService } from 'src/app/common/services/roles.service';

@Injectable({
  providedIn: 'root',
})
export class FltFlightService<T extends HolFlight = HolFlight> {
  // tslint:disable:variable-name
  protected ParseFlight = Parse.Object.extend('GOCFlight');
  protected ParseEvent = Parse.Object.extend('GOCEvent');
  protected ParseFlightLogbook = Parse.Object.extend('GOCInstruction');
  protected ParseFlightInstruction = Parse.Object.extend('GOCLogbook');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseFlightTag = Parse.Object.extend('GOCFlightTag');
  protected ParseOPSOfficer = Parse.Object.extend('OPSOfficer');
  protected ParseGOCOfficer = Parse.Object.extend('GOCOfficer');
  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    @Inject('UserService') protected userService,
    protected parseMapper: ParseMapperService,
    protected userAirportService: FltUserAirportService,
    protected gocOptionService: GocOptionsService,
    protected commonStoreManager: CommonStoreManager,
    protected flightLogbookService: FltFlightLogbookService,
    protected flightInstructionService: FltFlightInstructionService,
    protected flightEventService: FltFlightEventService,
    protected moduleConfig: ModuleConfigService,
    protected helperService: HelperService,
    protected rolesService: RolesService
  ) {}

  public static hasTakenOff(status: string): boolean {
    return TAKEN_OFF_STATUS.indexOf(status) > -1;
  }

  public static hasEnded(status: string): boolean {
    return ENDED_STATUS.indexOf(status) > -1;
  }

  protected newFlight(parseObject: Parse.Object, tags?: HolTag[]): T {
    return new HolFlight(parseObject, tags) as T;
  }

  public getById(id: string, addToStore?: boolean): Promise<T> {
    if (!id) {
      return Promise.reject('no flight id');
    }
    const flightQuery = new Parse.Query(this.ParseFlight);
    flightQuery.include('opsOfficer');
    return Promise.all([this.userService.getAccessRights(), this.requestService.performGetQuery(flightQuery, id)]).then(
      ([accessRights, parseFlight]) => {
        const flight = this.newFlight(parseFlight);
        return this.retrieveDetails([flight], accessRights).then(async () => {
          if (addToStore) {
            this.commonStoreManager.updateCurrentFlight(flight);
          }
          return flight;
        });
      }
    );
  }

  public getByTimeSlots(forceToRefresh: boolean, fromDate?: Moment, toDate?: Moment): Promise<any[]> {
    let from = fromDate; // more far in the past
    let to = toDate; // closer in the past or today
    to = (to && to.clone().endOf('day')) || moment.utc();
    if (to.isAfter(moment().utc())) {
      to = moment.utc();
    }
    from = (from && from.clone().startOf('day')) || to.clone().subtract(1, 'days').startOf('day');
    const flightQuery = new Parse.Query(this.ParseFlight);
    flightQuery.greaterThanOrEqualTo('std', from.toDate());
    flightQuery.lessThanOrEqualTo('std', to.toDate());
    flightQuery.notEqualTo('status', 'C');
    flightQuery.include('opsOfficer');
    flightQuery.include('tags');
    flightQuery.ascending('std');
    return this.requestService.performFindQuery(flightQuery).then(async parseFlights => {
      const flights = parseFlights.map(f => this.newFlight(f));
      await this.retrieveTags(flights);
      return flights;
    });
  }

  public getBySlot(daySelected?, withDetails?: boolean, fromNow?: boolean): Promise<Array<HolFlight>> {
    const stopoverThreshold = this.gocOptionService.getStopoverThreshold();
    daySelected = daySelected ? moment(daySelected).utc() : moment().utc();
    let minDate;
    if (!fromNow) {
      minDate = moment(daySelected).startOf('day').utc().subtract(stopoverThreshold, 'hour');
    } else {
      minDate = daySelected;
    }
    const maxDate = moment(daySelected).endOf('day').utc().clone().add(stopoverThreshold, 'hour');
    return Promise.all([this.userService.getAccessRights(), this.userAirportService.getUserAirports()]).then(([accessRights, airports]) => {
      let flightQuery;
      if (airports && airports.length) {
        const depQuery = new Parse.Query(this.ParseFlight);
        depQuery.containedIn('departure', airports);
        const arrQuery = new Parse.Query(this.ParseFlight);
        arrQuery.containedIn('destination', airports);
        flightQuery = Parse.Query.or(depQuery, arrQuery);
      } else {
        flightQuery = new Parse.Query(this.ParseFlight);
      }
      flightQuery.greaterThanOrEqualTo('sta', minDate.toDate());
      flightQuery.lessThanOrEqualTo('std', maxDate.toDate());
      // flightQuery.notContainedIn('status', ENDED_STATUS);
      flightQuery.notEqualTo('status', 'C');
      flightQuery.ascending('std');

      return this.requestService.performFindQuery(flightQuery).then(parseFlights => {
        const flights = parseFlights.map(f => this.newFlight(f));
        const detailsPromise: Promise<any> = withDetails ? this.retrieveDetails(flights, accessRights) : Promise.resolve();
        return detailsPromise.then(async () => {
          return flights;
        });
      });
    });
  }

  public setFlightIndicatorStatus(flight: any, universe: string, indicatorStatus: string): Promise<T> {
    const parseObject = new this.ParseFlight({ id: flight.objectId });
    parseObject.set(universe, indicatorStatus);

    return this.requestService.performSaveQuery(parseObject).then(data => {
      flight[universe] = indicatorStatus;
      const f = this.newFlight(data);
      this.commonStoreManager.updateOneFlight(flight);
      return f;
    });
  }

  public getAllFromYesterday(filterDataStartDate?: Date): Promise<T[]> {
    let today: Date;
    if (this.moduleConfig.config.canChooseDataStartDate && filterDataStartDate instanceof Date && filterDataStartDate !== null) {
      today = filterDataStartDate;
    } else {
      today = new Date();
    }

    const query = new Parse.Query(this.ParseFlight);

    query.greaterThanOrEqualTo('std', moment.utc(today).startOf('day').subtract(1, 'day').toDate());
    query.lessThanOrEqualTo('std', moment.utc(today).endOf('day').add(1, 'day').toDate());
    // query.greaterThanOrEqualTo('std', moment('2020-07-03T12:00:00.000Z').utc().startOf('day').subtract(1, 'day').toDate());
    // query.lessThanOrEqualTo('std', moment('2020-07-03T12:00:00.000Z').utc().endOf('day').add(1, 'day').toDate());
    query.ascending('std');
    query.notEqualTo('status', 'C');
    query.include('opsOfficer');
    query.limit(1000);

    return this.requestService.performFindQuery(query).then(async results => {
      const flights = results.map(f => this.newFlight(f));
      await this.retrieveTags(flights);
      await this.retrieveSummary(flights);
      this.commonStoreManager.updateFlights(flights);
      return flights;
    });
  }

  public updateFlight(flight: T, key: string, value: any) {
    const parseFlight = new this.ParseFlight({ id: flight.objectId });
    parseFlight.set(key, value);
    return this.requestService.performSaveQuery(parseFlight).then(() => {
      this.commonStoreManager.updateOneFlight(flight);
      return this.newFlight(parseFlight);
    });
  }

  public async saveTags(flight: T, tags: HolTag[]): Promise<HolTag[]> {
    const parseFlight = new this.ParseFlight({ id: flight.objectId });

    // remove old tags
    const oldTagQuery = new Parse.Query(this.ParseFlightTag);
    oldTagQuery.equalTo('flight', parseFlight);
    const oldTags = await this.requestService.performFindQuery(oldTagQuery);
    await this.requestService.performDestroyAllQuery(oldTags);

    // add new tags
    const parseTags = tags.map(tag => {
      return new this.ParseFlightTag({
        flight: parseFlight,
        tag: new this.ParseTag({ id: tag && tag.objectId }),
      });
    });
    return this.requestService.performSaveAllQuery(parseTags).then(pfts => {
      const newTags = pfts.map(pft => {
        return new HolTag(pft.get('tag'));
      });
      flight.tags = newTags;
      this.commonStoreManager.updateCurrentFlight(flight);
      this.commonStoreManager.updateOneFlight(flight);
      return newTags;
    });
  }

  private retrieveDetails(flights: T[], accessRights?): Promise<T[]> {
    // tslint:disable-next-line:no-console
    return Promise.all([
      this.retrieveTags(flights),
      this.retrieveFlightsLogbooks(flights, accessRights),
      this.retrieveFlightsInstructions(flights, accessRights),
      this.retrieveFlightsEvents(flights, accessRights),
    ]).then(() => {
      return this.retrieveSummary(flights);
    });
  }

  protected retrieveTags(flights: T[]): Promise<T[]> {
    const parseFLights = flights.map(f => new this.ParseFlight({ id: f.objectId }));
    const flightTagsQuery = new Parse.Query(this.ParseFlightTag);
    flightTagsQuery.containedIn('flight', parseFLights);
    flightTagsQuery.include('tag');
    flightTagsQuery.descending('createdAt');
    return this.requestService.performFindQuery(flightTagsQuery).then(pfts => {
      flights.forEach(flight => {
        flight.tags = pfts.filter(pft => pft.get('flight').id === flight.objectId).map(pft => new HolTag(pft.get('tag')));
      });
      return flights;
    });
  }

  protected retrieveFlightsLogbooks(flights: T[], accessRights): Promise<T[]> {
    return this.flightLogbookService.getForFlights(flights).then(dico => {
      flights.forEach(flight => {
        if (dico[flight.objectId]) {
          flight.logbooks = dico[flight.objectId];
        } else {
          flight.logbooks = [];
        }
      });
      return flights;
    });
  }

  protected retrieveFlightsEvents(flights: T[], accessRights): Promise<T[]> {
    return this.flightEventService.getForFlights(flights).then(dico => {
      flights.forEach(flight => {
        if (dico[flight.objectId]) {
          flight.events = dico[flight.objectId];
        } else {
          flight.events = [];
        }
      });
      return flights;
    });
  }

  protected retrieveFlightsInstructions(flights: T[], accessRights): Promise<T[]> {
    return this.flightInstructionService.getForFlights(flights).then(dico => {
      flights.forEach(flight => {
        if (dico[flight.objectId]) {
          flight.instructions = dico[flight.objectId];
        } else {
          flight.instructions = [];
        }
      });
      return flights;
    });
  }

  public retrieveSummary(flights: T[]): Promise<T[]> {
    return Promise.resolve(flights);
  }

  public getFlightsForApplicability(applicability: FltApplicability, acl: Parse.ACL): Promise<T[]> {
    let applicabilityFlights = [];
    const promises = [];
    if (applicability.flights) {
      promises.push(Promise.resolve(applicability.flights.filter(f => ENDED_STATUS.indexOf(f.status) === -1) as T[]));
    }
    if (applicability.flightsNumbers) {
      promises.push(this.getByFlightNumbers(applicability));
    }
    if (applicability.stations) {
      promises.push(this.getByStations(applicability, acl));
    }
    if (applicability.inOutStations) {
      promises.push(this.getByInOutStations(applicability));
    }
    return Promise.all(promises).then((flightsList: HolFlight[][]) => {
      if (flightsList && flightsList.length) {
        flightsList.forEach(flights => {
          applicabilityFlights = applicabilityFlights.concat(flights);
        });
      }
      return _.uniqBy(applicabilityFlights, 'objectId');
    });
  }

  private getByFlightNumbers(applicability: FltApplicability): Promise<T[]> {
    const flightNumbers = applicability.flightsNumbers;
    const today = moment().utc().startOf('day');
    const from: Moment = moment(applicability.validFrom);
    const fromDate = from.isBefore(today) ? today : from; // Today or in the futur

    const query = new Parse.Query(this.ParseFlight);
    query.containedIn('flightNumber', flightNumbers);
    query.notContainedIn('status', ENDED_STATUS);
    query.greaterThanOrEqualTo('std', fromDate.toDate());
    if (applicability.validTo) {
      query.lessThan('std', applicability.validTo);
    }

    return this.requestService.performFindAllQuery(query).then(results => {
      return results.map(f => this.newFlight(f));
    });
  }

  private getByStations(applicability: FltApplicability, acl: Parse.ACL): Promise<T[]> {
    const stations: string[] = applicability.stations;
    const stationsDirection: 'IN' | 'OUT' | null = applicability.stationsDirection;
    const today = moment().utc().startOf('day');
    const from: Moment = moment(applicability.validFrom);
    const fromDate = from.isBefore(today) ? today : from; // Today or in the futur

    let query = new Parse.Query(this.ParseFlight);

    if (stationsDirection === 'IN') {
      query.containedIn('destination', stations);
    } else if (stationsDirection === 'OUT') {
      query.containedIn('departure', stations);
    } else {
      const q1 = new Parse.Query(this.ParseFlight);
      const q2 = new Parse.Query(this.ParseFlight);
      q1.containedIn('destination', stations);
      q2.containedIn('departure', stations);
      query = Parse.Query.or(q1, q2);
    }
    query.notContainedIn('status', ENDED_STATUS);
    query.greaterThanOrEqualTo('std', fromDate.toDate());
    if (applicability.validTo) {
      query.lessThan('std', applicability.validTo);
    }
    const companies = this.helperService.parseACL(acl);

    return this.requestService.performFindAllQuery(query).then(results => {
      return results.map((f) => {
        const flight = this.newFlight(f);
        const flightParsedACL = this.helperService.parseACL(flight.acl);
        if(!!companies.filter(aclCompany => flightParsedACL.includes(aclCompany))){
          return flight;
        }
      });
    });
  }

  private getByInOutStations(applicability: FltApplicability): Promise<T[]> {
    const inOutStations: [string, string][] = applicability.inOutStations;
    const today = moment().utc().startOf('day');
    const from: Moment = moment(applicability.validFrom);
    const fromDate = from.isBefore(today) ? today : from; // Today or in the futur

    const query = new Parse.Query(this.ParseFlight);

    query.containedIn(
      'departure',
      inOutStations.map(s => s[0])
    );
    query.containedIn(
      'destination',
      inOutStations.map(s => s[1])
    );

    query.notContainedIn('status', ENDED_STATUS);
    query.greaterThanOrEqualTo('std', fromDate.toDate());
    if (applicability.validTo) {
      query.lessThan('std', applicability.validTo);
    }

    return this.requestService.performFindAllQuery(query).then(results => {
      return results.map(f => this.newFlight(f));
    });
  }
}
