import { cloneDeep, values, merge, keyBy, isEqual, extend, find, uniqBy, intersectionWith, difference } from 'lodash';
import { OclEventsActionTypes, EventsActions } from './ocl-events.actions';
import { OclEvent } from '../../models/ocl-event.model';

const eventInitialState: Array<OclEvent> = [];

export function oclEventsReducer(state: OclEvent[] = cloneDeep(eventInitialState), action: EventsActions): OclEvent[] {
  switch (action.type) {
    case OclEventsActionTypes.INIT_EVENTS:
      return action.payload;
    case OclEventsActionTypes.UPDATE_MANY_EVENTS:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        // tslint:disable-next-line: max-line-length
        const bufferStateIntersection = intersectionWith(cloneDeep(state), action.payload, (i, j) => i.objectId === j.objectId);
        const bufferdisplayFromLinkedValues = cloneDeep(state).filter(el => el.displayFromLinkedValues !== undefined);
        return [
          ...values(merge(keyBy(bufferStateIntersection, 'objectId'), keyBy(action.payload, 'objectId'))),
          ...bufferdisplayFromLinkedValues,
        ];
      }
    case OclEventsActionTypes.UPDATE_EVENTS_FROM_LINKED_VALUE:
      const bufferStateForAddLinkedValue = cloneDeep(state);
      action.payload.forEach(item => {
        const bufferIndex = bufferStateForAddLinkedValue.findIndex(el => el.objectId === item.objectId);
        if (bufferIndex === -1) {
          if (!item.displayFromLinkedValues) {
            item.displayFromLinkedValues = [];
          }
          item.displayFromLinkedValues.push(action.linkedUid);
          bufferStateForAddLinkedValue.push(item);
        } else {
          // tslint:disable-next-line: max-line-length
          if (
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues &&
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues.length
          ) {
            bufferStateForAddLinkedValue[bufferIndex].displayFromLinkedValues.push(action.linkedUid);
          }
        }
      });
      return bufferStateForAddLinkedValue;
    case OclEventsActionTypes.REMOVE_EVENTS_FROM_LINKED_VALUE:
      const bufferStateForRemoveLinkedValue = cloneDeep(state);
      bufferStateForRemoveLinkedValue.map(el => {
        if (el.displayFromLinkedValues && el.displayFromLinkedValues.includes(action.linkedUid)) {
          el.displayFromLinkedValues.splice(el.displayFromLinkedValues.indexOf(action.linkedUid), 1);
        }
        return el;
      });
      return bufferStateForRemoveLinkedValue.filter(el => !el.displayFromLinkedValues || el.displayFromLinkedValues.length !== 0);
    case OclEventsActionTypes.UPDATE_EVENTS_FROM_POOLING:
      if (isEqual(state, action.payload)) {
        return state;
      } else {
        // tslint:disable-next-line: max-line-length
        const bufferStateIntersection = intersectionWith(cloneDeep(state), action.payload, (i, j) => i.objectId === j.objectId);
        // console.log('UPDATE NEEDED : POOL AND STORE ARE DIFFERENT');
        // console.log('state', state);
        // console.log('payload', action.payload);
        const bufferdisplayFromLinkedValues = cloneDeep(state).filter(el => el.displayFromLinkedValues !== undefined);
        return [
          ...values(merge(keyBy(bufferStateIntersection, 'objectId'), keyBy(action.payload, 'objectId'))),
          ...bufferdisplayFromLinkedValues,
        ];
      }
    case OclEventsActionTypes.ADD_ONE_EVENT:
      const bufferState = cloneDeep(state);
      bufferState.unshift(action.payload);
      return bufferState;
    case OclEventsActionTypes.UPDATE_ONE_EVENT:
      return cloneDeep(state).map(event => (event.objectId === action.payload.objectId ? action.payload : event));
    case OclEventsActionTypes.RESET_EVENTS:
      return cloneDeep(eventInitialState);
    case OclEventsActionTypes.DELETE_ONE_EVENT:
      if (action.eventObjectId && cloneDeep(state).findIndex(event => event.objectId === action.eventObjectId) !== -1) {
        return cloneDeep(state).filter(el => el.objectId !== action.eventObjectId);
      } else {
        return cloneDeep(state);
      }
    default:
      return state;
  }
}
