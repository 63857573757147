import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../common/services/request.service';
import { MccMel } from '../models/mcc-mel.model';
import { HolNextInfo } from '../../common/models/hol-next-info.model';
import { NotificationsService } from '../../common/services/notifications/notifications.service';
import * as moment from 'moment';
import { HolNotification } from '../../common/models/hol-notification.model';
import { HolAttachments } from '../../common/models/hol-attachments.model';
import * as XLSX from 'xlsx';
import { remove } from 'lodash';
import { MccAircraftsStoreManager } from '../store/aircrafts/mcc-aircrafts.store-manager';
import { MccAircraft } from '../models/mcc-aircraft.model';
import { MccHistoryService } from './mcc-history.service';

@Injectable({
  providedIn: 'root',
})
export class MccMelService {
  // tslint:disable:variable-name
  ParseMccMel = Parse.Object.extend('MCCMEL');
  ParseMccMelInfo = Parse.Object.extend('MCCMELInfo');
  // tslint:enable

  constructor(
    private requestService: RequestService,
    private notificationsService: NotificationsService,
    private mccHistoryService: MccHistoryService,
    @Inject('MailService') private mailService,
    @Inject('SmsService') private smsService,
    private aircraftsStoreManager: MccAircraftsStoreManager
  ) {}

  createAll(melItems: MccMel[], aircrafts: MccAircraft[]): Promise<MccMel[]> {
    const pMELs = melItems.map(melItem => {
      const parseMccMel = new this.ParseMccMel({
        aircraft: melItem.aircraft,
        barcode: melItem.barcode,
        reference: melItem.reference,
        faultName: melItem.faultName,
        faultType: melItem.faultType,
        dueDate: melItem.dueDate,
        foundDate: melItem.foundDate,
      });
      if (melItem.acl) {
        parseMccMel.setACL(melItem.acl);
      }
      return parseMccMel;
    });

    return this.requestService.performSaveAllQuery(pMELs).then(parseMels => {
      const updatedMels = parseMels.map(pm => new MccMel(pm));
      this.mccHistoryService.postImportMelLog('mel', 'create', aircrafts, updatedMels, false);
      return updatedMels;
    });
  }

  removeAll(mels: MccMel[], aircrafts: MccAircraft[]): Promise<any[]> {
    const parseObjectsToRemove = mels.map(mel => {
      return new this.ParseMccMel({ id: mel.objectId });
    });
    this.mccHistoryService.postImportMelLog('mel', 'remove', aircrafts, mels, true);

    return this.requestService.performDestroyAllQuery(parseObjectsToRemove);
  }

  updateAll(mels: MccMel[], aircrafts: MccAircraft[]): Promise<MccMel[]> {
    const parseObjectsToUpdate = mels.map(melItem => {
      const parseMccMel = new this.ParseMccMel({
        id: melItem.objectId,
        aircraft: melItem.aircraft,
        barcode: melItem.barcode,
        reference: melItem.reference,
        faultName: melItem.faultName,
        faultType: melItem.faultType,
        dueDate: melItem.dueDate,
        foundDate: melItem.foundDate,
      });

      if (melItem.acl) {
        parseMccMel.setACL(melItem.acl);
      }
      return parseMccMel;
    });

    return this.requestService.performSaveAllQuery(parseObjectsToUpdate).then(parseMels => {
      const updatedMels = parseMels.map(pm => new MccMel(pm));
      this.mccHistoryService.postImportMelLog('mel', 'update', aircrafts, updatedMels, false);
      return updatedMels;
    });
  }

  getMEL(id: string): Promise<MccMel> {
    const query = new Parse.Query(this.ParseMccMel);
    query.equalTo('objectId', id);

    return this.requestService.performFirstQuery(query).then(parseMel => {
      const melInfoQuery = new Parse.Query(this.ParseMccMelInfo);
      melInfoQuery.equalTo('mel', parseMel);
      melInfoQuery.descending('createdAt');
      return this.requestService.performFindQuery(melInfoQuery).then(infos => {
        return new MccMel(
          parseMel,
          infos.map(i => new HolNextInfo(i))
        );
      });
    });
  }

  getAll(): Promise<MccMel[]> {
    const query = new Parse.Query(this.ParseMccMel);
    query.ascending('dueDate');

    return this.requestService.performFindAllQuery(query).then(parseMCCMELs => {
      const melInfoQuery = new Parse.Query(this.ParseMccMelInfo);
      melInfoQuery.descending('createdAt');
      return this.requestService.performFindAllQuery(melInfoQuery).then(melInfos => {
        return parseMCCMELs.map(mel => {
          const infos = melInfos.filter(mItem => {
            return mItem.get('mel').id === mel.id;
          });
          return new MccMel(
            mel,
            infos.map(i => new HolNextInfo(i))
          );
        });
      });
    });
  }

  update(mel: MccMel, acl: Parse.ACL, aircraft: MccAircraft): Promise<MccMel> {
    const parseMel = new this.ParseMccMel();
    parseMel.id = mel.objectId;
    if (acl) {
      parseMel.setACL(acl);
    }
    parseMel.set('faultName', mel.faultName);
    parseMel.set('attachments', JSON.stringify(mel.attachments));

    return this.requestService.performSaveQuery(parseMel).then(m => {
      const newMel = new MccMel(m);
      this.mccHistoryService.postMccLog(newMel.reference, 'mel', 'info', newMel.acl, aircraft, null, null, newMel, newMel.attachments);
      return newMel;
    });
  }

  addInfo(
    mel: MccMel,
    message: string,
    nextInfoTime: Date,
    notifications: HolNotification[],
    attachments: HolAttachments,
    acl: Parse.ACL,
    aircraft: MccAircraft
  ): Promise<HolNextInfo> {
    const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
    const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);
    const melInfo = new this.ParseMccMelInfo();
    const melParse = new this.ParseMccMel();
    melParse.id = mel.objectId;
    if (acl) {
      melInfo.setACL(acl);
    }
    melInfo.set('mel', melParse);
    melInfo.set('message', message);
    melInfo.set('nextInfoTime', nextInfoTime);
    melInfo.set('createdBy', Parse.User.current());
    if (attachments) {
      melInfo.set('attachments', JSON.stringify(attachments));
    }

    return this.requestService.performSaveQuery(melInfo).then(parseData => {
      const infosToMarkAsDone = mel.infos.filter(info => {
        return !info.done && moment(info.nextInfoTime).isBefore(nextInfoTime);
      });

      const promises = infosToMarkAsDone.map(info => {
        return this.markInfoAsDone(info, mel, true, false, aircraft);
      });

      return Promise.all(promises).then(() => {
        const newInfo = new HolNextInfo(parseData);
        if (!mel.infos) {
          mel.infos = [];
        }
        mel.infos.unshift(newInfo);

        if (addressMailToSend.length) {
          this.mailService.sendNewMELInfoMail(mel, newInfo, addressMailToSend);
        }

        if (phoneNumbersToSend.length) {
          this.smsService.sendNewMELInfoSMS(mel, newInfo, phoneNumbersToSend);
        }

        this.aircraftsStoreManager.updateMel(mel);

        this.mccHistoryService.postMccLog(mel.reference, 'mel', 'info', mel.acl, aircraft, null, null, mel, mel.attachments);

        return newInfo;
      });
    });
  }

  updateInfo(info: HolNextInfo, acl: Parse.ACL): Promise<HolNextInfo> {
    const parseMELInfo = new this.ParseMccMelInfo();
    parseMELInfo.id = info.objectId;
    if (acl) {
      parseMELInfo.setACL(acl);
    }
    parseMELInfo.set('attachments', JSON.stringify(info.attachments));
    return this.requestService.performSaveQuery(parseMELInfo).then(res => {
      const newInfo = new HolNextInfo(res);
      this.aircraftsStoreManager.updateMelInfo(newInfo);
      return newInfo;
    });
  }

  markInfoAsDone(info: HolNextInfo, mel: MccMel, done: boolean, manual: boolean, aircraft: MccAircraft): Promise<HolNextInfo> {
    const melInfo = new this.ParseMccMelInfo();
    melInfo.id = info.objectId;
    melInfo.set('done', done);
    melInfo.set('manual', manual);

    return this.requestService.performSaveQuery(melInfo).then(parseData => {
      const newInfo = new HolNextInfo(parseData);

      Object.assign(info, newInfo);
      this.aircraftsStoreManager.updateMelInfo(info);

      mel.infos = mel.infos.map(inf => {
        if (inf.objectId === newInfo.objectId) {
          return newInfo;
        }
        return inf;
      });

      this.mccHistoryService.postMccLog(
        mel.reference,
        'mel',
        done ? 'info-done' : 'info-backtodo',
        mel.acl,
        aircraft,
        null,
        null,
        mel,
        mel.attachments
      );
      return info;
    });
  }

  parseXLSFile(
    workbook: XLSX.WorkBook,
    aircrafts: MccAircraft[]
  ): Promise<
    {
      item: MccMel;
      warnings: any[];
      infos: any[];
    }[]
  > {
    if (!workbook.SheetNames.length) {
      return Promise.reject('Fichier mal formé: Aucune feuille');
    }
    // Here is your object
    const sheet = workbook.Sheets[workbook.SheetNames[0]];

    let start = null;
    const end = sheet['!ref'].split(':')[1];
    for (const key in sheet) {
      if (sheet[key].w === 'AIRCRAFT') {
        start = key;
        break;
      }
    }
    if (!start) {
      return Promise.reject('Fichier mal formé: Entrée AIRCRAFT introuvable');
    }
    sheet['!ref'] = start + ':' + end;
    let jsonXLS = XLSX.utils.sheet_to_json<any>(sheet, { raw: false });

    // REMOVE HEADER LINES AND BLANK LINES
    jsonXLS = jsonXLS.filter(row => {
      return row.AIRCRAFT !== 'AIRCRAFT' && row.BARCODE;
    });

    const foundInvalidDate = jsonXLS.find(row => {
      return !moment(row['DUE DATE'], 'DD/MM/YYYY').isValid() || !moment(row['FOUND DATE'], 'DD/MM/YYYY').isValid();
    });

    if (foundInvalidDate) {
      return Promise.reject('Invalid dateformat');
    }

    const setMelReference = (row, res) => {
      const reg = /(\d{2})-(\d{2})(-(\d{2})-?(\w)?)?/gi;
      const parsedRef = reg.exec(row.REFERENCE);
      if (parsedRef) {
        let newRef = parsedRef[1] + '-' + parsedRef[2];
        if (parsedRef[4]) {
          newRef += '-' + parsedRef[4];
          if (parsedRef[5]) {
            newRef += parsedRef[5];
          } else {
            res.warnings.push('REFERENCE::missing letter: ' + row.REFERENCE);
          }
        } else {
          res.warnings.push('REFERENCE::missing 3rd value: ' + row.REFERENCE);
        }
        if (newRef !== row.REFERENCE) {
          res.infos.push('REFERENCE::transformed from "' + row.REFERENCE + '" to "' + newRef + '"');
        }
        res.item.reference = newRef;
      } else {
        res.item.reference = row.REFERENCE;
        res.warnings.push('REFERENCE::Invalid format: ' + row.REFERENCE);
      }
    };
    const setFaultName = (row, res) => {
      const regFaultName = /([^\/]*\/)?(.*)/gi;
      const parsedFaultName = regFaultName.exec(row['FAULT NAME']);
      if (parsedFaultName) {
        res.item.faultName = parsedFaultName[2].trim();
      } else {
        res.item.faultName = row['FAULT NAME'];
        res.warnings.push('FAULT NAME::Invalid format: ' + row.REFERENCE);
      }
    };

    const melItems = jsonXLS.map(row => {
      const res = {
        item: new MccMel(),
        warnings: [],
        infos: [],
      };

      setMelReference(row, res);
      setFaultName(row, res);
      res.item.aircraft = row.AIRCRAFT;
      res.item.dueDate = moment(row['DUE DATE'], 'DD/MM/YYYY').toDate();
      res.item.foundDate = moment(row['FOUND DATE'], 'DD/MM/YYYY').toDate();
      res.item.faultType = row['FAULT TYPE'] && row['FAULT TYPE'].replace('MEL ', '');
      res.item.barcode = row.BARCODE;
      const bufferAircraftIndex = aircrafts.findIndex(el => el.registration === row.AIRCRAFT);
      if (bufferAircraftIndex !== -1 && aircrafts[bufferAircraftIndex].acl) {
        res.item.acl = aircrafts[bufferAircraftIndex].acl;
      }
      return res;
    });

    return Promise.resolve(melItems);
  }

  importMels(
    melsFromXLS: {
      item: MccMel;
      warnings: any[];
      infos: any[];
    }[],
    aircrafts: MccAircraft[]
  ) {
    const availableAircraftsRegistration = aircrafts.filter(aircraft => aircraft.readOnly === false).map(aircraft => aircraft.registration);
    return this.getAll().then(allMels => {
      melsFromXLS = melsFromXLS.filter(mel => availableAircraftsRegistration.some(el => el === mel.item.aircraft));
      allMels = allMels.filter(mel => availableAircraftsRegistration.some(el => el === mel.aircraft));
      const toAdd = melsFromXLS
        .filter(melFromXLS => {
          return !allMels.find(m => m.barcode === melFromXLS.item.barcode);
        })
        .map(m => m.item);

      const toUpdate = remove(allMels, mel => {
        return melsFromXLS.find(melFromXLS => {
          if (melFromXLS.item.barcode === mel.barcode) {
            Object.keys(melFromXLS.item).forEach(itemKey => {
              mel[itemKey] = melFromXLS.item[itemKey];
            });
            return true;
          }
          return false;
        });
      });
      const toRemove = allMels;

      return Promise.all([this.createAll(toAdd, aircrafts), this.removeAll(toRemove, aircrafts), this.updateAll(toUpdate, aircrafts)]).then(
        () => {
          return {
            toAdd,
            toRemove,
            toUpdate,
          };
        }
      );
    });
  }

  getPercentage(date1: Date, date2: Date): number {
    const currentMillis = moment().valueOf();
    const date1Millis = moment(date1).valueOf();
    const date2Millis = moment(date2).valueOf();

    const diffMillis = date2Millis - date1Millis;
    const ellapsedMillis = currentMillis - date1Millis;

    return ellapsedMillis / diffMillis;
  }
}
