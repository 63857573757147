import { Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
class OCLOptions {
  objectId: string;
  decisionsToDisplay: number;
  logbooksToDisplay: number;
  flightsStatusHoursToDisplay: number;
  mailingList: string;
  historyTimeSlot: number;
  statusXAxis1: string;
  statusXAxis2: string;
  statusXAxis3: string;
  statusYAxis1: string;
  statusYAxis2: string;
  statusYAxis3: string;
  defaultScenario: string;
  decisionsTitle: string;
  logbooksTitle: string;
  eventsTitle: string;
  managersTitle: string;
  isNextInfoMandatory: boolean;
  canCheckLogbook: boolean;
  defaultNextInfoDelay: number;
  hiddenButtons: string;
  moduleName: string;
  moduleIsActivated: boolean;
  occEvent: Parse.Object;
  crewBriefingFormData: any;
  isGlobalInstructionActivated: boolean;
  isCrewBriefingActivated: boolean;
  stopoverThreshold: number;
}

@Injectable({
  providedIn: 'root',
})
export abstract class OclOptionsService {
  // tslint:disable-next-line:variable-name
  protected abstract ParseOptions;

  public constructor(private requestService: RequestService, private httpclient: HttpClient) {}

  private options: OCLOptions; // @cache : options occ

  // @hasCache options
  get(forceRefresh): Promise<OCLOptions> {
    return new Promise((resolve, reject) => {
      if (this.options !== undefined && !forceRefresh) {
        resolve(this.options);
      } else {
        const query = new Parse.Query(this.ParseOptions);
        this.requestService
          .performFirstQuery(query)
          .then(result => {
            if (result) {
              const crewBriefing = result.get('crewBriefingFormData');
              this.options = {
                objectId: result.id,
                decisionsToDisplay: result.get('decisionsToDisplay'),
                logbooksToDisplay: result.get('logbooksToDisplay'),
                flightsStatusHoursToDisplay: result.get('flightsStatusHoursToDisplay'),
                mailingList: result.get('mailingList'),
                historyTimeSlot: result.get('historyTimeSlot'),
                statusXAxis1: result.get('statusXAxis1'),
                statusXAxis2: result.get('statusXAxis2'),
                statusXAxis3: result.get('statusXAxis3'),
                statusYAxis1: result.get('statusYAxis1'),
                statusYAxis2: result.get('statusYAxis2'),
                statusYAxis3: result.get('statusYAxis3'),
                defaultScenario: result.get('defaultScenario'),
                decisionsTitle: result.get('decisionsTitle'),
                logbooksTitle: result.get('logbooksTitle'),
                eventsTitle: result.get('eventsTitle'),
                managersTitle: result.get('managersTitle'),
                isNextInfoMandatory: result.get('isNextInfoMandatory'),
                canCheckLogbook: result.get('canCheckLogbook'),
                defaultNextInfoDelay: result.get('defaultNextInfoDelay'),
                hiddenButtons: result.get('hiddenButtons'),
                moduleName: result.get('moduleName'), // only ECL
                moduleIsActivated: result.get('moduleIsActivated'), // only ECL
                occEvent: result.get('occEvent'), // only ECL,
                crewBriefingFormData: crewBriefing ? this.httpclient.get(crewBriefing.url()).pipe(map(res => res)) : of(null),
                isGlobalInstructionActivated: result.get('isGlobalInstructionActivated'),
                isCrewBriefingActivated: result.get('isCrewBriefingActivated'),
                stopoverThreshold: result.get('stopoverThreshold'),
              };
            } else {
              this.options = new OCLOptions();
            }
            resolve(this.options);
          })
          .catch(reject);
      }
    });
  }

  getDecisionsToDisplay() {
    return this.options && this.options.decisionsToDisplay;
  }

  getLogbooksToDisplay() {
    return this.options && this.options.logbooksToDisplay;
  }

  getFlightsStatusHoursToDisplay() {
    return this.options && this.options.flightsStatusHoursToDisplay;
  }

  getMailingLists() {
    return (this.options && this.options.mailingList && this.options.mailingList.split('|')) || [];
  }

  getHistoryTimeSlot() {
    return this.options && this.options.historyTimeSlot;
  }

  getXAxis1() {
    return this.options && this.options.statusXAxis1;
  }

  getXAxis2() {
    return this.options && this.options.statusXAxis2;
  }

  getXAxis3() {
    return this.options && this.options.statusXAxis3;
  }

  getYAxis1() {
    return this.options && this.options.statusYAxis1;
  }

  getYAxis2() {
    return this.options && this.options.statusYAxis2;
  }

  getYAxis3() {
    return this.options && this.options.statusYAxis3;
  }

  getDefaultScenario() {
    return this.options && this.options.defaultScenario;
  }

  getDecisionsTitle() {
    return this.options && this.options.decisionsTitle;
  }

  getLogbooksTitle() {
    return this.options && this.options.logbooksTitle;
  }

  getEventsTitle() {
    return this.options && this.options.eventsTitle;
  }

  getManagersTitle() {
    return this.options && this.options.managersTitle;
  }

  getIsNextInfoMandatory() {
    return this.options && this.options.isNextInfoMandatory;
  }

  getCanCheckLogbook() {
    return this.options && this.options.canCheckLogbook;
  }

  getDefaultNextInfoDelay() {
    return this.options && this.options.defaultNextInfoDelay;
  }

  getEclModuleInfos() {
    return {
      moduleName: this.options && this.options.moduleName,
      moduleIsActivated: this.options && this.options.moduleIsActivated,
      occEvent: this.options && this.options.occEvent,
    };
  }

  getCrewBriefingFormData(): Observable<any> {
    if (this.options && this.options.crewBriefingFormData) {
      return this.options.crewBriefingFormData;
    }
  }

  getIsGlobalInstructionActivated() {
    return this.options && this.options.isGlobalInstructionActivated;
  }

  getIsCrewBriefingActivated() {
    return this.options && this.options.isCrewBriefingActivated;
  }

  getStopoverThreshold() {
    return this.options && this.options.stopoverThreshold;
  }
}
