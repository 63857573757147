import { Injectable } from '@angular/core';
import { FltFlightInstructionService } from '../../../flt/services/flt-flight-instruction.service';
import { RequestService } from '../../../common/services/request.service';
import { OpsFlightService } from '../ops-flight-service/ops-flight.service';
import { OpsFlight } from '../../models/ops-flight.model';
import { Dictionary, groupBy } from 'lodash';
import { FltFlightLogbook } from '../../../flt/models/flt-flight-logbook';
import { HolTag } from '../../../common/models/hol-tag';
import { FltFlightInstruction } from '../../../flt/models/flt-flight-instruction';

@Injectable({
  providedIn: 'root',
})
export class OpsFlightInstructionService extends FltFlightInstructionService {
  // tslint:disable:variable-name
  protected ParseFlightInstruction = Parse.Object.extend('OPSFlightInstruction');
  protected ParseDecision = Parse.Object.extend('OPSDecisions');
  protected ParseFlightInstructionTag = Parse.Object.extend('OPSFlightInstructionTag');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseDecisionTag = Parse.Object.extend('OPSDecisionTag');
  // tslint:enable
}
