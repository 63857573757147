import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MatDialog } from '@angular/material';

import * as _ from 'lodash';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { FlyIn } from 'src/app/common/models/hol-animations';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { OPSLayoutInfos } from 'src/app/common/store/common.model';
import { OclGroupService } from 'src/app/ocl/services/ocl-group-service/ocl-group.service';
import { RolesService } from 'src/app/common/services/roles.service';
import { TagChangeService } from 'src/app/common/services/tag-change.service';

import { OclGlobalInstructionModalComponent } from '../../modals/ocl-global-instruction-modal/ocl-global-instruction-modal.component';
import { OclGroupModalComponent } from '../../modals/ocl-group-modal/ocl-group-modal.component';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';

@Component({
  selector: 'app-ocl-global-instruction-box',
  templateUrl: './ocl-global-instruction-box.component.html',
  styleUrls: ['./ocl-global-instruction-box.component.scss'],
  animations: [FlyIn],
})
export class OclGlobalInstructionBoxComponent implements OnInit, OnDestroy {
  @Input() isReadOnly: boolean;
  @Input() localHourOnly: boolean;

  @Input()
  set globalInstructionsInput(value) {
    this._globalInstructionsItems.next(value);
  }

  get globalInstructionsInput() {
    return this._globalInstructionsItems.getValue();
  }

  @Input()
  set globalInstructionsGroupsInput(value) {
    this._globalInstructionsGroups.next(value);
  }

  get globalInstructionsGroupsInput() {
    return this._globalInstructionsGroups.getValue();
  }

  @Input()
  set searchCriterias(value) {
    this._searchCriterias.next(value);
  }

  get searchCriteriasdata() {
    return this._searchCriterias.getValue();
  }

  private _searchCriterias = new BehaviorSubject<string>('');
  private _globalInstructionsItems = new BehaviorSubject<any>([]);
  private _globalInstructionsGroups = new BehaviorSubject<any>([]);

  private ngUnsubscribe: Subject<void> = new Subject();
  private storeLayoutInfos: OPSLayoutInfos;

  public globalInstructionGrouped: OclGlobalInstructionGroup[] = [];
  public globalInstructionItems: OclGlobalInstruction[] = [];
  public groupsFromApi: OclGlobalInstructionGroup[] = [];
  public groupsAvailable: OclGlobalInstructionGroup[] = [];

  public loading = false;
  public searchCriteriasForPipe: string;
  private currentTags: HolTag[];

  public RolesService = RolesService;

  public context = 'global_instruction';

  constructor(
    private dialog: MatDialog,
    public moduleConfig: ModuleConfigService,
    protected groupService: OclGroupService,
    protected commonStoreManager: CommonStoreManager,
    protected tagChangeService: TagChangeService
  ) {}

  ngOnInit() {
    combineLatest(this._globalInstructionsItems, this._globalInstructionsGroups)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([globalInstructionItems, globalInstructionsGroups]) => {
        this.globalInstructionItems = globalInstructionItems ? _.cloneDeep(globalInstructionItems) : [];
        (this.groupsFromApi = globalInstructionsGroups ? globalInstructionsGroups : []),
          (this.groupsAvailable =
            globalInstructionsGroups && globalInstructionsGroups.length
              ? this.groupService.generateGroupAvailable(_.cloneDeep(globalInstructionsGroups), this.globalInstructionItems)
              : []);
        this.globalInstructionGrouped = this.generateGlobalInstructionGrouped(this.groupsAvailable, this.globalInstructionItems);
      });

    this.tagChangeService.tagsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe(tags => (this.currentTags = _.cloneDeep(tags)));

    this._searchCriterias.pipe(takeUntil(this.ngUnsubscribe)).subscribe(cristerias => {
      this.searchCriteriasForPipe = _.cloneDeep(cristerias);
    });

    this.commonStoreManager.layoutInfos
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(layoutInfosData => (this.storeLayoutInfos = layoutInfosData));
  }

  get enableViewGroup(): boolean {
    return (
      (!this.currentTags || this.currentTags.length === 0) && this.searchCriteriasForPipe.length === 0 && !!this.groupsAvailable.length
    );
  }

  get isSetGroups(): boolean {
    return this.enableViewGroup ? this.storeLayoutInfos.groups.consigneGroupEnabled : false;
  }

  set isSetGroups(value: boolean) {
    this.commonStoreManager.updateLayoutGroups({
      consigneGroupEnabled: value,
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public openAddModal(event): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(OclGlobalInstructionModalComponent, {
      data: {
        isReadOnly: this.isReadOnly,
        localHourOnly: this.localHourOnly,
      },
    });
  }

  trackByFn(index, item) {
    return item.objectId || item.items[0].objectId;
  }

  private generateGlobalInstructionGrouped(group, globalInstructions): OclGlobalInstructionGroup[] {
    return _.orderBy(
      this.groupService.generateGroupForDisplay(group, globalInstructions, 'GLOBAL_INSTRUCTION'),
      item => {
        if ((item as OclGlobalInstructionGroup).getMostRecentGlobalInstruction()) {
          return (item as OclGlobalInstructionGroup).getMostRecentGlobalInstruction().createdAt;
        }
        return null;
      },
      'desc'
    );
  }

  public openGroupModal(): void {
    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        globalInstructionItems: this.globalInstructionItems,
        isReadOnly: this.isReadOnly,
        group: null,
        groupsFromApi: this.groupsFromApi,
        type: 'GLOBAL_INSTRUCTION',
      },
    });
    dialogRef.afterClosed().subscribe(group => {
      this.groupService.fetchNewDataGlobalInstructionGroup();
    });
  }
}
