import { Injectable } from '@angular/core';
import { OclGroupService } from '../../../ocl/services/ocl-group-service/ocl-group.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { RequestService } from '../../../common/services/request.service';
import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';

@Injectable({
  providedIn: 'root',
})
export class EclGroupService extends OclGroupService {
  // tslint:disable:variable-name
  ParseEventGroup = Parse.Object.extend('ECLEventGroup');
  ParseDecisionGroup = Parse.Object.extend('ECLDecisionGroup');
  ParseLogbookGroup = Parse.Object.extend('ECLLogBookGroup');
  ParseGlobalInstructionGroup = Parse.Object.extend('ECLGlobalInstructionGroup');
  ParseEvent = Parse.Object.extend('ECLEvent');
  ParseDecisions = Parse.Object.extend('ECLDecisions');
  ParseLogbook = Parse.Object.extend('ECLLogBook');
  ParseGlobalInstruction = Parse.Object.extend('ECLGlobalInstruction');
  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    protected groupStoreManager: OclGroupsStoreManager,
    protected moduleConfig: EclModuleConfigService
  ) {
    super(requestService, groupStoreManager, moduleConfig);
  }
}
