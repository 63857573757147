import { take } from 'rxjs/operators';
import { RolesService } from './../../../common/services/roles.service';
import { HelperService } from './../../../common/services/helper.service';
import { OclLogbook } from 'src/app/ocl/models/ocl-logbook.model';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { EclSmsService } from './../ecl-sms-service/ecl-sms.service';
import { EclMailService } from './../ecl-mail-service/ecl-mail.service';
import { Inject, Injectable } from '@angular/core';
import { OclLogbookService } from '../../../ocl/services/ocl-logbook-service/ocl-logbook.service';
import { RequestService } from '../../../common/services/request.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { GocFlightLogbookService } from '../../../goc/services/goc-flight-logbook-service/goc-flight-logbook.service';
import { OclLogBooksStoreManager } from '../../../ocl/store/logbooks/ocl-log-books-store-manager.service';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';
import { EclLogbookTagService } from '../ecl-logbook-tag-service/ecl-logbook-tag.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class EclLogbookService extends OclLogbookService {
  // tslint:disable:variable-name
  protected ParseLogbook = Parse.Object.extend('ECLLogBook');
  protected ParseOccEvents = Parse.Object.extend('ECLEvents');
  protected ParseLogbookTag = Parse.Object.extend('ECLLogBookTag');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseEvents = Parse.Object.extend('ECLEvents');
  protected ParseErpLogbook;
  private ParseCrisis = Parse.Object.extend('GDCCrisis');
  // tslint:enabled

  public constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    @Inject('UserService') protected userService,
    protected optionsService: EclOptionsService,
    protected historyService: EclHistoryService,
    protected parseMapper: ParseMapperService,
    protected logBookTagService: EclLogbookTagService,
    protected gocLogbookService: GocFlightLogbookService,
    protected occLogBooksStoreManager: OclLogBooksStoreManager,
    public moduleConfig: EclModuleConfigService,
    protected notificationsService: NotificationsService,
    protected smsService: EclSmsService,
    protected mailService: EclMailService,
    public commonStoreManager: CommonStoreManager,
    private helperService: HelperService,
    private rolesService: RolesService
  ) {
    super(
      $rootScope,
      requestService,
      userService,
      optionsService,
      historyService,
      parseMapper,
      logBookTagService,
      gocLogbookService,
      occLogBooksStoreManager,
      moduleConfig,
      notificationsService,
      smsService,
      mailService,
      commonStoreManager
    );
  }

  protected async duplicateLogbookToOtherModule(logbook: OclLogbook) {
    const crisis = await this.commonStoreManager.crisis.pipe(take(1)).toPromise();
    const parseErpLogbook = new this.ParseErpLogbook();
    parseErpLogbook.set('eclLogbook', new this.ParseLogbook({ id: logbook.objectId }));
    parseErpLogbook.set('crisis', new this.ParseCrisis({ id: crisis.objectId }));
    const companies = this.helperService.parseACL(logbook.acl);
    const acl: Parse.ACL = await this.rolesService.getACLForCompanies(['ERP'], companies);
    parseErpLogbook.setACL(acl);
    this.requestService.performSaveAllQuery(parseErpLogbook).then();
  }

  protected deleteDuplicateLogbookFromModule(logbook: OclLogbook) {
    const parseLogbook = new this.ParseLogbook({ id: logbook.objectId });
    const query = new Parse.Query(this.ParseErpLogbook);
    query.equalTo('eclLogbook', parseLogbook);

    this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        const parseErpLogbook = new this.ParseErpLogbook({ id: result.id });
        this.requestService.performDestroyQuery(parseErpLogbook).then();
      }
    });
  }
}
