import { OclLogbook } from '../../ocl/models/ocl-logbook.model';
import { HolFlight } from '../../common/models/hol-flight.model';
import { HolTag } from '../../common/models/hol-tag';
import { HolAttachments } from '../../common/models/hol-attachments.model';
import { HolUser } from '../../common/models/hol-user.model';
import { FltLogbook } from './flt-logbook';
import { FltDecision } from './flt-decision';

export class FltFlightLogbook extends OclLogbook {
  logbook: FltLogbook;
  decision: FltDecision;
  flight: HolFlight;
  archived: boolean;
  station: string;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject, tags);
    if (!parseObject) {
      return;
    }
    if (parseObject && parseObject.get('decision')) {
      this.decision = new FltDecision(parseObject.get('decision'));
      this.createdBy = new HolUser(parseObject.get('decision').get('createdBy'));
      const jsonAttachments = parseObject.get('decision').get('attachments');
      this.setAttachments(jsonAttachments);
    } else if (parseObject && parseObject.get('logbook')) {
      this.logbook = new FltLogbook(parseObject.get('logbook'));
      this.createdBy = new HolUser(parseObject.get('logbook').get('createdBy'));
      const jsonAttachments = parseObject.get('logbook').get('attachments');
      this.setAttachments(jsonAttachments);
    } else if (parseObject && parseObject.get('occLogbook')) {
      this.occLogbook = new OclLogbook(parseObject.get('occLogbook'));
      this.createdBy = new HolUser(parseObject.get('occLogbook').get('createdBy'));
      const jsonAttachments = parseObject.get('occLogbook').get('attachments');
      this.setAttachments(jsonAttachments);
      this.fromOCC = true;
    }
    this.flight = parseObject.get('flight') && new HolFlight(parseObject.get('flight'));
    this.archived = parseObject.get('archived');
    this.station = parseObject.get('station');
  }

  protected setAttachments(jsonAttachments) {
    if (typeof jsonAttachments === 'string') {
      jsonAttachments = JSON.parse(jsonAttachments);
    }
    if (jsonAttachments) {
      this.attachments = new HolAttachments(
        jsonAttachments.note,
        jsonAttachments.file,
        jsonAttachments.image,
        jsonAttachments.noteFile,
        jsonAttachments.link,
        jsonAttachments.files
      );
    } else {
      this.attachments = new HolAttachments();
    }
  }

  protected getContentText(parseObject: Parse.Object) {
    return parseObject.get('logbook')
      ? parseObject.get('logbook').get('text')
      : parseObject.get('decision')
      ? parseObject.get('decision').get('message')
      : parseObject.get('occLogbook')
      ? parseObject.get('occLogbook').get('text')
      : parseObject.get('text');
  }
}
