import { HolisFile } from 'src/app/common/models/hol-attachments.model';
import { HolisLink } from './../../models/hol-attachments.model';
import { ConfirmationModalComponent } from 'src/app/common/modals/confirmation-modal/confirmation-modal.component';
import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FileUploadAttachmentModalComponent } from '../file-upload-attachment-modal/file-upload-attachment-modal.component';
import { HolAttachments } from '../../models/hol-attachments.model';
import { HolFlight } from '../../models/hol-flight.model';

@Component({
  selector: 'app-file-and-image-upload',
  templateUrl: './file-and-image-upload.component.html',
  styleUrls: ['./file-and-image-upload.component.scss'],
})
export class FileAndImageUploadComponent implements OnInit {
  @Input()
  public attachments: HolAttachments;
  @Input()
  public column: boolean;
  @Input()
  public disabled;
  @Input()
  public hideImage;
  @Input()
  public hideFile;
  @Input()
  public hideLink;
  @Input()
  public hideNote;
  @Input()
  public hideButtonTitle;
  @Input()
  public hideNotePreview;
  @Input()
  public multipleFileEnabled;

  @Input()
  public context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlScenario?: string;
    htmlDate;
    htmlNextInfo?;
    htmlTemplate: string;
    flight: HolFlight;
  };

  @Input()
  public isPdf = false;
  @Output()
  public onAttachmentUpdated: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog, @Inject('$translate') private $translate) {}

  ngOnInit(): void {
    this.attachments = this.attachments || new HolAttachments();
  }

  openAttachmentModal(type): void {
    let newObject;
    if (!this.attachments[type]) {
      newObject = type === 'link' ? new HolisLink() : new HolisFile();
    }
    const dialogRef = this.dialog.open(FileUploadAttachmentModalComponent, {
      data: { attachment: this.attachments[type] || newObject, type },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type === 'link') {
          this.onLinkAdded(result);
        } else {
          if (result.uploadedUrl) {
            if (type === 'image') {
              this.onUploadImageSuccess(result);
            } else if (type === 'file') {
              this.onUploadFileSuccess(result);
            } else if (type === 'files') {
              this.onUploadFilesSuccess(result);
            }
          }
        }
      }
    });
  }

  onUploadFileSuccess($event) {
    this.attachments.file = {
      title: $event.title,
      fileName: $event.fileName,
      url: $event.uploadedUrl,
    };
    this.onAttachmentUpdated.emit();
  }

  onUploadFilesSuccess($event) {
    const file = {
      title: $event.title,
      fileName: $event.fileName,
      url: $event.uploadedUrl,
    };
    if (!this.attachments.files) {
      this.attachments.files = [];
    }

    this.attachments.files.push(file);

    this.onAttachmentUpdated.emit();
  }

  onNoteAdded($event: { note: any; file: any }) {
    this.attachments.note = $event.note;
    this.attachments.noteFile = {
      fileName: $event && $event.file ? $event.file.fileName : '',
      url: $event && $event.file ? $event.file.url : '',
    };
    this.onAttachmentUpdated.emit();
  }

  onUploadImageSuccess($event) {
    this.attachments.image = {
      title: $event.title,
      fileName: $event.fileName,
      url: $event.uploadedUrl,
    };

    this.onAttachmentUpdated.emit();
  }

  onLinkAdded($event) {
    this.attachments.link = $event;
    this.onAttachmentUpdated.emit();
  }

  removeFile(event) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_FILE_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_FILE_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.attachments.file = undefined;
          this.onAttachmentUpdated.emit();
        }
      });
  }

  removeFiles(event, index) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_FILE_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_FILE_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          if (index > -1) {
            this.attachments.files.splice(index, 1);
          }
          this.onAttachmentUpdated.emit();
        }
      });
  }

  removeImage(event) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_IMAGE_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_IMAGE_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.attachments.image = undefined;
          this.onAttachmentUpdated.emit();
        }
      });
  }

  removeNote(event) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.NOTE_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.NOTE_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.attachments.note = undefined;
          this.attachments.noteFile = undefined;
          this.onAttachmentUpdated.emit();
        }
      });
  }

  removeLink(event) {
    event.stopPropagation();

    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_LINK_TITLE'),
          modalContent: this.$translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_LINK_CONTENT'),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.attachments.link = undefined;
          this.onAttachmentUpdated.emit();
        }
      });
  }
}
