<div class="hol-clickable occ-decision-item" (click)="openDetailModal($event, item)">
  <app-hol-list-item [item]="item" [isReadOnly]="isReadOnly" [localHourOnly]="localHourOnly">
    <div class="header-addon">
      <div>
        <app-tag-chips [tags]="item.tags"></app-tag-chips>
      </div>
    </div>

    <div class="hol-spaced-line addon">
      <div class="col-attachements">
        <app-file-and-image-icons-display [attachments]="item.attachments"></app-file-and-image-icons-display>
      </div>
    </div>
  </app-hol-list-item>
</div>
