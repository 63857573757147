import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HolUser } from 'src/app/common/models/hol-user.model';
import { RequestService } from 'src/app/common/services/request.service';

@Injectable({
  providedIn: 'root',
})
export class FltUserAirportService {
  ParseGOCUserAirport = Parse.Object.extend('GOCUserAirport');

  private userAirports: string[];

  constructor(private requestService: RequestService) {}

  getUserAirports(): Promise<string[]> {
    if (this.userAirports) {
      return Promise.resolve(this.userAirports);
    }
    return new Promise((resolve, reject) => {
      const user = new HolUser(Parse.User.current());
      if (!user) {
        return reject('no user');
      }
      const query = new Parse.Query(this.ParseGOCUserAirport);
      query.equalTo('userId', user.userId);

      this.requestService.performFindQuery(
        query,
        parseUserAirports => {
          this.userAirports = _.map(parseUserAirports, pua => pua.get('airport'));
          return resolve(this.userAirports);
        },
        reject
      );
    });
  }
}
