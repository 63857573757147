import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { OclLogbook } from '../../models/ocl-logbook.model';
import {
  InitLogBooks,
  ResetLogBooks,
  UpdateLogBooksFromPooling,
  AddOneLogBook,
  UpdateOneLogBook,
  UpdateLogBooksFromLinkedValue,
  RemoveLogBooksFromLinkedValue,
  AddManyLogBooks,
  DeleteOneLogBook,
  UpdateManyLogBooks,
} from './ocl-logbooks.actions';

@Injectable({
  providedIn: 'root',
})
export class OclLogBooksStoreManager {
  private _logBooksState: Observable<OclLogbook[]>;
  protected $currentOclModule: Observable<string>;

  constructor(private store: Store<AppState>) {
    this._logBooksState = this.store.select('ocl', 'logBooks');
    this.$currentOclModule = store.select('common', 'currentOclModule');
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  get logBooksState(): Observable<OclLogbook[]> {
    return this._logBooksState;
  }

  initLogBooks(logBooks: Array<OclLogbook>): void {
    this.store.dispatch(new InitLogBooks(logBooks));
  }

  updateLogBooksFromPooling(logBooks: Array<OclLogbook>, currentModule): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateLogBooksFromPooling(logBooks));
    }
  }

  updateManyLogBooks(logBooks: Array<OclLogbook>, currentModule): void {
    if (this.getValue(this.$currentOclModule) === currentModule) {
      this.store.dispatch(new UpdateManyLogBooks(logBooks));
    }
  }

  updateLogBooksFromLinkedValue(logBooks: Array<OclLogbook>, linkUid: string): void {
    this.store.dispatch(new UpdateLogBooksFromLinkedValue(logBooks, linkUid));
  }

  removeLogBooksFromLinkedValue(linkUid: string): void {
    this.store.dispatch(new RemoveLogBooksFromLinkedValue(linkUid));
  }

  addOneLogBook(logBook: OclLogbook): void {
    this.store.dispatch(new AddOneLogBook(logBook));
  }

  addManyLogBook(logBooks: OclLogbook[]): void {
    this.store.dispatch(new AddManyLogBooks(logBooks));
  }

  updateOneLogBook(logBook: OclLogbook): void {
    this.store.dispatch(new UpdateOneLogBook(logBook));
  }

  resetLogBooks(): void {
    this.store.dispatch(new ResetLogBooks());
  }

  deleteOneLogBook(logBookObjectId: string): void {
    this.store.dispatch(new DeleteOneLogBook(logBookObjectId));
  }
}
