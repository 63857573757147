'use strict';

/**
 * @ngdoc directive
 * @name gestiondecriseApp.directive:iscrollIndicator
 * @description
 * # iscrollIndicator
 */
angular.module('gestiondecriseApp').directive('iscrollWrapper', function ($timeout, $window) {
  return {
    restrict: 'C',
    link: function (scope, element) {
      const $shadow = $(
        '<div class="iscroll-shadow-container no-scroll"><div class="iscroll-shadow top"></div><div class="iscroll-shadow bottom"></div></div>'
      );
      const $bottom = $shadow.find('.bottom');
      const $top = $shadow.find('.top');
      let interval;
      element.parent().append($shadow);

      function setScrollIndicatorPosition() {
        let offset = element[0].getBoundingClientRect();
        let offsetP = element.offsetParent()[0].getBoundingClientRect();

        $shadow.css('top', offset.top - offsetP.top);
        $shadow.css('height', offset.height);
        $shadow.css('left', offset.left - offsetP.left);
        $shadow.css('width', offset.width);
      }

      function onScroll(target) {
        setScrollIndicatorPosition();
        if (target.scrollHeight > target.clientHeight) {
          $shadow.removeClass('no-scroll');
          if (target.scrollTop <= 0) {
            //top
            $top.addClass('hidden');
            $bottom.removeClass('hidden').addClass('full');
          } else if (target.scrollTop >= target.scrollHeight - target.offsetHeight) {
            // bottom
            $top.removeClass('hidden').addClass('full');
            $bottom.addClass('hidden');
          } else {
            //middle
            $top.removeClass('hidden').removeClass('full');
            $bottom.removeClass('hidden').removeClass('full');
          }
        } else {
          $shadow.addClass('no-scroll');
        }
      }

      function init() {
        onScroll(element[0]);
        interval = setInterval(function () {
          onScroll(element[0]);
        }, 500);

        element.on('scroll', function (ev) {
          onScroll(ev.target);
        });

        $($window).on('resize.iscroll-indicator', setScrollIndicatorPosition);
      }

      $timeout(function () {
        // Double timeout to wait element resize
        $timeout(init);
      });

      scope.$on('$destroy', function () {
        clearInterval(interval);
        element.off('scroll');
        $($window).off('resize.iscroll-indicator');
      });
    },
  };
});
