import { HolObject } from './hol-object';

export class HolCrisis extends HolObject {
  objectId: string;
  crisisTypeId: string;
  isTraining: boolean;
  isInPreparation: boolean;
  inProgress: boolean;
  clockDeparture: string;
  clockArrival: string;
  clockOnSite: string;
  mainTitle: string;
  subTitle: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.crisisTypeId = parseObject.get('crisisTypeId');
    this.isTraining = parseObject.get('isTraining');
    this.isInPreparation = parseObject.get('isInPreparation');
    this.inProgress = parseObject.get('inProgress');
    this.clockDeparture = parseObject.get('clock_departure');
    this.clockArrival = parseObject.get('clock_arrival');
    this.clockOnSite = parseObject.get('clock_onSite');
    this.mainTitle = parseObject.get('mainTitle');
    this.subTitle = parseObject.get('subtitle');
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('mainTitle');
  }
}
