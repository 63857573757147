import { Inject, Injectable } from '@angular/core';
import { OclMailService } from '../../../ocl/services/ocl-mail-service/ocl-mail.service';
import { GocModuleConfigService } from '../goc-module-config/goc-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class GocMailService extends OclMailService {
  protected constructor(
    @Inject('MailSenderService') protected mailSenderService,
    @Inject('$translate') protected $translate,
    @Inject('CONSTANTS') protected CONSTANTS,
    @Inject('MailTemplateService') protected mailTemplateService,
    protected moduleConfig: GocModuleConfigService
  ) {
    super(mailSenderService, $translate, CONSTANTS, mailTemplateService, moduleConfig);
    this.$translate.onReady(() => {
      this.SENDER_SUFFIX = this.$translate.instant('MAIL.GOC.SENDER_SUFFIX') || 'GOC';
    });
  }
}
