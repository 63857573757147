'use strict';

angular.module('gestiondecriseApp').directive('fileUpload', function (FilesService) {
  return {
    template:
      '<span class="file-upload">' +
      '<md-button class="md-raised" ng-disabled="uploading || disabled" aria-label="select pdf" ng-click="selectPdf()">' +
      '   <md-icon ng-if="icon === \'clip\'" md-svg-src="assets/images/clip.svg"></md-icon>' +
      '   <md-icon ng-if="icon === \'picture\'" md-svg-src="assets/images/picture.svg"></md-icon>' +
      '   {{ label }}' +
      '</md-button>' +
      '<input type="file" accept="{{ accept }}" style="display:none;" />' +
      '<em class="info" ng-if="uploading">uploading...</em>' +
      '</span>',
    restrict: 'E',
    replace: true,
    scope: {
      onUploadSuccess: '&',
      disabled: '=',
      label: '@',
      icon: '@',
      accept: '@',
    },
    link: function postLink(scope, element, attrs) {
      scope.uploading = false;
      scope.label = scope.label || 'Add a document';
      scope.icon = scope.icon || 'upload';
      scope.accept = scope.accept || '*/*';

      scope.selectPdf = function () {
        element.find('input[type=file]').click();
      };

      scope.removeDiacritics = function (str) {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      };

      element.find('input[type=file]').bind('change', function () {
        var self = this;
        if (self.files.length > 0) {
          var file = self.files[0];
          var fileName = scope.removeDiacritics(file.name);
          scope.uploading = true;

          FilesService.uploadFile(fileName, file).then(
            function (url) {
              scope.onUploadSuccess({
                fileName: fileName,
                uploadedUrl: url,
              });

              element.find('input[type=file]').val(null);

              scope.uploading = false;
            },
            function (err) {
              scope.uploading = false;
              console.error(err);
            }
          );
        }
      });
    },
  };
});
