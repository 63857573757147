export const UTC_ZONES = [
  'UTC-12:00, Y',
  'UTC-11:00, X',
  'UTC-10:00, W',
  'UTC-09:30, V†',
  'UTC-09:00, V',
  'UTC-08:00, U',
  'UTC-07:00, T',
  'UTC-06:00, S',
  'UTC-05:00, R',
  'UTC-04:00, Q',
  'UTC-03:30, P†',
  'UTC-03:00, P',
  'UTC-02:00, O',
  'UTC-01:00, N',
  'UTC±00:00, Z',
  'UTC+01:00, A',
  'UTC+02:00, B',
  'UTC+03:00, C',
  'UTC+03:30, C†',
  'UTC+04:00, D',
  'UTC+04:30, D†',
  'UTC+05:00, E',
  'UTC+05:30, E†',
  'UTC+05:45, E*',
  'UTC+06:00, F',
  'UTC+06:30, F†',
  'UTC+07:00, G',
  'UTC+08:00, H',
  'UTC+08:30, H†',
  'UTC+08:45, H*',
  'UTC+09:00, I',
  'UTC+09:30, I†',
  'UTC+10:00, K',
  'UTC+10:30, K†',
  'UTC+11:00, L',
  'UTC+12:00, M',
  'UTC+12:45, M*',
  'UTC+13:00, M†',
  'UTC+14:00, M†',
];
