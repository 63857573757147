import { OclGlobalInstruction } from './ocl-global-instruction.model';
import { OclGroup } from './ocl-group.model';
import { orderBy } from 'lodash';

export class OclGlobalInstructionGroup extends OclGroup<OclGlobalInstruction> {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.items =
      parseObject.get('globalInstructions') && parseObject.get('globalInstructions').length
        ? orderBy(
            parseObject.get('globalInstructions').map(gi => new OclGlobalInstruction(gi)),
            'createdAt',
            'desc'
          )
        : [];
  }

  getMostRecentGlobalInstruction(): OclGlobalInstruction {
    return this.items[0];
  }
}
