'use strict';

angular.module('gestiondecriseApp').directive('holMarkdownEditor', function ($mdDialog) {
  return {
    restrict: 'A',
    scope: {
      title: '@',
      onNoteAdded: '&',
      note: '=holMarkdownEditor',
      toOccEnabled: '<',
    },
    link: function postLink(scope, element) {
      element.on('click', function ($event) {
        $mdDialog
          .show({
            controller: 'MarkdownModalCtrl',
            templateUrl: 'scripts/directives/widgets/markdownEditor/markdownEditor.modal.html',
            targetEvent: $event,
            skipHide: true,
            focusOnOpen: false,
            locals: {
              title: scope.title,
              note: scope.note,
              toOccEnabled: scope.toOccEnabled,
            },
          })
          .then(function (res) {
            if (res) {
              if (res.text && res.text.trim()) {
                scope.onNoteAdded({ note: res });
              } else if (res.trim()) {
                scope.onNoteAdded({ note: res });
              }
            }
          });
      });
    },
  };
});
