import { OclGlobalInstructionsStoreManager } from './../../../ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { Subscription } from 'rxjs';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { OclGlobalInstructionGroup } from '../../../ocl/models/ocl-global-instruction-group.model';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { OclGlobalInstruction } from 'src/app/ocl/models/ocl-global-instruction.model';
import { orderBy } from 'lodash';
import { OclGroupsStoreManager } from '../../../ocl/store/groups/ocl-groups.store-manager';

@Component({
  selector: 'app-crew-dashboard',
  templateUrl: './crew-dashboard.component.html',
  styleUrls: ['./crew-dashboard.component.sass'],
})
export class CrewDashboardComponent implements OnInit, OnDestroy {
  public globalInstructionGroups: OclGlobalInstructionGroup[];
  public globalInstructions: OclGlobalInstruction[];
  private instructionStoreSub: Subscription;
  private groupsStoreManagerSub: Subscription;

  constructor(
    @Inject('$scope') protected $scope,
    public moduleConfig: ModuleConfigService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    protected groupsStoreManager: OclGroupsStoreManager
  ) {}

  ngOnInit() {
    this.instructionStoreSub = this.globalInstructionsStoreManager.globalInstructionsState.subscribe(globalInstructions => {
      this.globalInstructions = orderBy(globalInstructions, 'createdAt', 'desc');
    });

    this.groupsStoreManagerSub = this.groupsStoreManager.groupsState.subscribe(groups => {
      this.globalInstructionGroups = groups.globalInstructions;
    });
  }

  ngOnDestroy(): void {
    this.instructionStoreSub.unsubscribe();
    this.groupsStoreManagerSub.unsubscribe();
  }
}
