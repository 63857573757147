'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CommonService
 * @description
 * # CommonService
 * Service in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').service('CommonService', function ($q, OptionsService, RequestService, CONSTANTS) {
  this.getCrisisDirector = function () {
    const deferred = $q.defer();

    // Get Crisis director short title
    const shortTitleForCrisisDirector = OptionsService.getCrisisDirectorShortTitlesList();
    if (shortTitleForCrisisDirector != null) {
      const query = new Parse.Query('GDCFunction');
      query.containedIn('shortTitle', shortTitleForCrisisDirector);
      // get crisis director function
      RequestService.performFindQuery(
        query,
        function (functions) {
          if (functions && functions.length > 0) {
            const query = new Parse.Query('GDCUserFunction');
            query.containedIn(
              'functionId',
              _.map(functions, function (fn) {
                return fn.get('functionId');
              })
            );
            query.equalTo('isHolder', true);
            // Get crisis director user function
            RequestService.performFindQuery(
              query,
              function (userFunctions) {
                if (userFunctions.length > 0) {
                  let userId;
                  // On prend le premier crisisDirector dans l'ordre des shortTitles
                  for (let i = 0; i < shortTitleForCrisisDirector.length; i++) {
                    const shortTitle = shortTitleForCrisisDirector[i];

                    const func = _.find(functions, function (f) {
                      return f.get('shortTitle') === shortTitle;
                    });
                    const userFunction = _.find(userFunctions, function (uf) {
                      return uf.get('functionId') === func.get('functionId');
                    });
                    if (userFunction) {
                      userId = userFunction.get('userId');
                      break;
                    }
                  }

                  var query = new Parse.Query(Parse.User);
                  query.equalTo('userId', userId);
                  // Get crisis director user
                  RequestService.performFindQuery(
                    query,
                    function (users) {
                      if (users.length > 0) {
                        var user = users[0];
                        deferred.resolve({
                          id: user.id,
                          name: user.get('firstName') + ' ' + user.get('lastName'),
                          phone: user.get('phone'),
                          mail: user.get('email') || user.get('userEmail'),
                        });
                      } else {
                        deferred.resolve();
                      }
                    },
                    function () {
                      deferred.reject('Error getting crisis director user');
                    }
                  );
                } else {
                  deferred.resolve();
                }
              },
              function () {
                deferred.reject('Error getting crisis director user function');
              }
            );
          } else {
            deferred.resolve();
          }
        },
        function () {
          deferred.reject('Error getting crisis director function');
        }
      );
    } else {
      deferred.reject('Error getting crisis director short title');
    }
    return deferred.promise;
  };

  this.getCrisisDirectors = function () {
    const deferred = $q.defer();

    // Get Crisis director short title
    const shortTitleForCrisisDirector = OptionsService.getCrisisDirectorShortTitlesList();
    if (shortTitleForCrisisDirector != null) {
      const query = new Parse.Query('GDCFunction');
      query.containedIn('shortTitle', shortTitleForCrisisDirector);
      // get crisis director function
      RequestService.performFindQuery(
        query,
        function (functions) {
          if (functions && functions.length > 0) {
            const query = new Parse.Query('GDCUserFunction');
            query.containedIn(
              'functionId',
              _.map(functions, function (fn) {
                return fn.get('functionId');
              })
            );
            query.equalTo('isHolder', true);
            // Get crisis director user function
            RequestService.performFindQuery(
              query,
              function (userFunctions) {
                if (userFunctions.length > 0) {
                  const query = new Parse.Query(Parse.User);
                  query.containedIn(
                    'userId',
                    _.map(userFunctions, function (uf) {
                      return uf.get('userId');
                    })
                  );
                  // Get crisis director user
                  RequestService.performFindQuery(
                    query,
                    function (users) {
                      console.log('users', users);
                      if (users.length > 0) {
                        deferred.resolve(
                          _.map(users, function (user) {
                            return {
                              id: user.id,
                              name: user.get('firstName') + ' ' + user.get('lastName'),
                              phone: user.get('phone'),
                              mail: user.get('email') || user.get('userEmail'),
                            };
                          })
                        );
                      } else {
                        deferred.resolve();
                      }
                    },
                    function () {
                      deferred.reject('Error getting crisis director user');
                    }
                  );
                } else {
                  deferred.resolve();
                }
              },
              function () {
                deferred.reject('Error getting crisis director user function');
              }
            );
          } else {
            deferred.resolve();
          }
        },
        function () {
          deferred.reject('Error getting crisis director function');
        }
      );
    } else {
      deferred.reject('Error getting crisis director short title');
    }
    return deferred.promise;
  };

  this.isSeen = function (item, lastTime) {
    return moment(item.createdAt).isBefore(lastTime) || moment(item.createdAt).add(CONSTANTS.TIME_TO_SEE, 'm').isBefore(moment());
  };
});
