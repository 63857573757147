'use strict';

/**
 * @ngdoc directive
 * @name gestiondecriseApp.directive:stopClickOnSelection
 * @description Stops the propagation of click events if some text is selected
 */
angular.module('gestiondecriseApp').directive('stopClickOnSelection', function ($window) {
  return {
    priority: -1, // to execute this directive before ngClick
    link: function postLink(scope, element) {
      element.on('click', function (event) {
        if ($window.getSelection().rangeCount > 0 && !$window.getSelection().getRangeAt(0).collapsed) {
          event.stopImmediatePropagation();
        }
      });

      scope.$on('$destroy', function () {
        element.off('click');
      });
    },
  };
});
