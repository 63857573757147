'use strict';

/**
 * @ngdoc directive
 * @name gestiondecriseApp.directive:linkCatcher
 * @description
 * # linkCatcher
 */
angular.module('gestiondecriseApp').directive('linkCatcher', function ($timeout, CONSTANTS, $compile, $sce) {
  return {
    restrict: 'A',
    scope: {
      linkCatcher: '=',
    },
    link: function (scope, element, attrs) {
      var html = angular.element($('<div>' + scope.linkCatcher + '</div>'));
      element.append(html);
      $compile(html)(scope);

      scope.openLink = function (link) {
        window.open(link, '_blank');
        return false;
      };
    },
  };
});
