import { cloneDeep } from 'lodash';
import { HolVacation, HolVacationChecklist, HolVacationChecklistItem } from 'src/app/common/models/hol-vacation.model';
import { GocFlight } from 'src/app/goc/models/goc-flight.model';
import { GocStopover } from 'src/app/goc/models/goc-stopover.model';

import { CommonActions, CommonActionTypes } from './common.actions';
import { CommonStoreState } from './common.model';

const commonInitialState: CommonStoreState = {
  currentOclModule: undefined,
  eclInfos: {
    moduleName: undefined,
    moduleIsActivated: undefined,
    occEventId: undefined,
    eclOptionsId: undefined,
  },
  opsInfos: {
    moduleIsActivated: undefined,
  },
  flights: [],
  stopovers: [],
  opsLayoutInfo: {
    filterSelectedOfficer: undefined,
    filterStatusFilterActivated: false,
    filterChecklistActivated: true,
    filterAirportSelected: undefined,
    filterIndicatorFilterSelected: undefined,
    filterTimeDraftFilterActivated: false,
    filterApplicabilityFilterActivated: undefined,
    displayStopovers: false,
    filterDataStartDate: undefined,
    groups: {
      decisionsGroupEnabled: true,
      logbooksGroupEnabled: true,
      eventsGroupEnabled: true,
      consigneGroupEnabled: true,
    },
  },
  currentFlight: null,
  currentStopover: null,
  currentUser: null,
  crisis: null,
  crisisRolesRef: null,
  holFunctions: [],
  holVacations: [],
  holUserFunctions: [],
};

export function commonReducer(state: any = cloneDeep(commonInitialState), action: CommonActions): CommonStoreState {
  switch (action.type) {
    case CommonActionTypes.INIT_COMMON:
      return cloneDeep(action.payload);
    case CommonActionTypes.UPDATE_CURRENT_OCL_MODULE:
      return cloneDeep({ ...state, currentOclModule: action.payload });
    case CommonActionTypes.UPDATE_ECL_INFOS:
      return cloneDeep({
        ...state,
        eclInfos: {
          ...state.eclInfos,
          moduleName: action.payload.moduleName,
          moduleIsActivated: action.payload.moduleIsActivated,
          occEventId: action.payload.occEventId,
          eclOptionsId: action.payload.eclOptionsId,
        },
      });
    case CommonActionTypes.UPDATE_OPS_LAYOUT_INFOS:
      return cloneDeep({
        ...state,
        opsLayoutInfo: {
          ...state.opsLayoutInfo,
          ...action.payload,
        },
      });
    case CommonActionTypes.UPDATE_OPS_INFOS:
      return cloneDeep({
        ...state,
        opsInfos: {
          ...state.opsInfos,
          moduleIsActivated: action.payload.moduleIsActivated,
        },
      });
    case CommonActionTypes.UPDATE_LAYOUT_GROUPS:
      return cloneDeep({
        ...state,
        opsLayoutInfo: {
          ...state.opsLayoutInfo,
          groups: { ...state.opsLayoutInfo.groups, ...action.payload },
        },
      });
    case CommonActionTypes.UPDATE_FLIGHTS:
      return cloneDeep({
        ...state,
        flights: action.payload,
      });
    case CommonActionTypes.UPDATE_ONE_FLIGHT:
      return cloneDeep({
        ...state,
        flights: state.flights.map(flight => {
          if (flight.objectId === action.payload.objectId) {
            return action.payload;
          }
          return flight;
        }),
        stopvers: state.stopovers.map(stopover => {
          if (stopover.inFlight.objectId === action.payload.objectId) {
            stopover = new GocStopover(action.payload as GocFlight, stopover.outFlight);
          }
          if (stopover.outFlight.objectId === action.payload.objectId) {
            stopover = new GocStopover(stopover.inFlight, action.payload as GocFlight);
          }
          return stopover;
        }),
        currentStopover:
          state.currentStopover && state.currentStopover.inFlight && state.currentStopover.inFlight.objectId === action.payload.objectId
            ? new GocStopover(action.payload as GocFlight, state.currentStopover.outFlight)
            : state.currentStopover &&
              state.currentStopover.outFlight &&
              state.currentStopover.outFlight.objectId === action.payload.objectId
            ? new GocStopover(state.currentStopover.inFlight, action.payload as GocFlight)
            : state.currentStopover,
      });
    case CommonActionTypes.UPDATE_STOPOVERS:
      return cloneDeep({
        ...state,
        stopovers: action.payload,
      });

    case CommonActionTypes.UPDATE_CURRENT_FLIGHT:
      return cloneDeep({
        ...state,
        flights: state.flights.map(flight => {
          if (flight.objectId === action.payload.objectId) {
            return action.payload;
          }
          return flight;
        }),
        stopvers: state.stopovers.map(stopover => {
          if (stopover.inFlight.objectId === action.payload.objectId) {
            stopover = new GocStopover(action.payload as GocFlight, stopover.outFlight);
          }
          if (stopover.outFlight.objectId === action.payload.objectId) {
            stopover = new GocStopover(stopover.inFlight, action.payload as GocFlight);
          }
          return stopover;
        }),
        currentFlight: action.payload,
        currentStopover:
          state.currentStopover && state.currentStopover.inFlight && state.currentStopover.inFlight.objectId === action.payload.objectId
            ? new GocStopover(action.payload as GocFlight, state.currentStopover.outFlight)
            : state.currentStopover &&
              state.currentStopover.outFlight &&
              state.currentStopover.outFlight.objectId === action.payload.objectId
            ? new GocStopover(state.currentStopover.inFlight, action.payload as GocFlight)
            : state.currentStopover,
      });
    case CommonActionTypes.UPDATE_CURRENT_STOPOVER:
      return cloneDeep({
        ...state,
        flights: state.flights.map(flight => {
          if (flight.objectId === action.payload.outFlight.objectId) {
            return action.payload.outFlight;
          }
          if (flight.objectId === action.payload.inFlight.objectId) {
            return action.payload.inFlight;
          }
          return flight;
        }),
        stopvers: state.stopovers.map(stopover => {
          if (stopover.naturalKey === action.payload.naturalKey) {
            return action.payload;
          }
          return stopover;
        }),
        currentStopover: action.payload,
      });
    case CommonActionTypes.UPDATE_CURRENT_USER:
      return cloneDeep({
        ...state,
        currentUser: action.payload,
      });
    case CommonActionTypes.INIT_CRISIS:
      return cloneDeep({
        ...state,
        crisis: action.crisis,
      });
    case CommonActionTypes.INIT_CRISIS_ROLES_REF:
      return cloneDeep({
        ...state,
        crisisRolesRef: action.roles,
      });
    case CommonActionTypes.INIT_HOL_FUNCTIONS:
      return cloneDeep({
        ...state,
        holFunctions: action.holFunctions,
      });

    case CommonActionTypes.INIT_HOL_VACATIONS:
      return cloneDeep({
        ...state,
        holVacations: action.holVacations,
      });

    case CommonActionTypes.UPDATE_HOL_VACATION:
      return cloneDeep({
        ...state,
        holVacations: state.holVacations.map((vacation: HolVacation) => {
          if (action.vacationId === vacation.objectId) {
            if (!action.checklistId) {
              vacation = { ...vacation, ...action.partial };
            } else {
              vacation.checklists = cloneDeep(
                vacation.checklists.map((checklist: HolVacationChecklist) => {
                  if (action.checklistId === checklist.objectId) {
                    if (!action.checklistItemId) {
                      checklist = Object.assign(checklist, action.partial);
                    } else {
                      checklist.checklistItems = checklist.checklistItems.map((checklistItem: HolVacationChecklistItem) => {
                        if (action.checklistItemId === checklistItem.objectId) {
                          checklistItem = { ...checklistItem, ...action.partial };
                        }
                        return checklistItem;
                      });
                    }
                  }
                  return checklist;
                })
              );
            }
          }
          return vacation;
        }),
      });

    case CommonActionTypes.REMOVE_HOL_VACATION:
      if (action.holVacationId && cloneDeep(state).holVacations.findIndex(el => el.objectId === action.holVacationId) !== -1) {
        return {
          ...cloneDeep(state),
          holVacations: state.holVacations.filter(el => el.objectId !== action.holVacationId),
        };
      } else {
        return cloneDeep(state);
      }
    case CommonActionTypes.INIT_USER_FUNCTIONS:
      return {
        ...cloneDeep(state),
        holUserFunctions: action.payload,
      };

    case CommonActionTypes.UPDATE_MANY_USER_FUNCTIONS:
      return {
        ...cloneDeep(state),
        holUserFunctions: state.holUserFunctions.map(uf => {
          const newUf = action.userFunctions.find(data => data.objectId === uf.objectId);
          return newUf || uf;
        }),
      };

    default:
      return state;
  }
}
