import { HolOptions } from './../models/hol-options';
import { RequestService } from 'src/app/common/services/request.service';
import { Injectable, Inject } from '@angular/core';
import * as moment from 'moment';
import * as Raven from 'raven-js';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  private ParseOptions = Parse.Object.extend('GDCOptions');

  public constructor(@Inject('CONSTANTS') private CONSTANTS, private requestService: RequestService) {}

  private options: HolOptions; // @cache : options de la crise

  // @hasCache options
  get(forceRefresh?: boolean): Promise<HolOptions> {
    return new Promise((resolve, reject) => {
      if (this.options !== undefined && !forceRefresh) {
        resolve(this.options);
      } else {
        const query = new Parse.Query(this.ParseOptions);
        this.requestService
          .performFirstQuery(query)
          .then(result => {
            if (result) {
              const defaultCrisisImage = result.get('defaultCrisisImage');
              const companyLogo = result.get('companyLogo');
              this.options = {
                shortTitleForCrisisObservor: result.get('shortTitleForCrisisObservor'),
                shortTitleForCrisisDirector: result.get('shortTitleForCrisisDirector'),
                hasToSendMails: result.get('hasToSendMails'),
                hasToSendSms: result.get('hasToSendSms'),
                crisisInitializerCanActivate: result.get('crisisInitializerCanActivate'),
                shortTitleForHumanResources: result.get('shortTitleForHumanResources'),
                shortTitleForOCC: result.get('shortTitleForOCC'),
                shortTitleForCrewManager: result.get('shortTitleForCrewManager'),
                shortTitleForChat: result.get('shortTitleForChat'),
                shortTitleForMCC: result.get('shortTitleForMCC'),
                env: result.get('env'),
                nextInfoLimit: result.get('nextInfoLimit'),
                shortTitleForGOC: result.get('shortTitleForGOC'),
                shortTitleForCrisisInitializer: result.get('shortTitleForCrisisInitializer'),
                crisisTimeRemainingOpen: result.get('crisisTimeRemainingOpen'),
                formIoAdmin: result.get('formIoAdmin'),
                formIoPwd: result.get('formIoPwd'),
                formIoBackEnd: result.get('formIoBackEnd'),
                defaultCrisisImageUrl: defaultCrisisImage === undefined ? null : defaultCrisisImage.url(),
                companyLogoUrl: companyLogo === undefined ? null : companyLogo.url(),
                defaultCrisisImageName: defaultCrisisImage === undefined ? null : defaultCrisisImage.name(),
                companyLogoName: companyLogo === undefined ? null : companyLogo.name(),
              };
              Raven.setTagsContext({
                company: this.CONSTANTS.COMPANY_NAME,
                env: result.get('env'),
              });
            } else {
              this.options = new HolOptions();
            }
            resolve(this.options);
          })
          .catch(reject);
      }
    });
  }

  uploadImage(file, name) {
    return new Promise((resolve, reject) => {
      const query = new Parse.Query(this.ParseOptions);
      this.requestService.performFirstQuery(
        query,
        option => {
          if (!option) {
            option = new this.ParseOptions();
          }
          option.set(name, new Parse.File(file.name, file));
          this.requestService.performSaveQuery(option, null, resolve, reject);
        },
        reject
      );
    });
  }

  getCrisisDirectorShortTitlesList(): string[] {
    return (this.options && this.options.shortTitleForCrisisDirector && this.options.shortTitleForCrisisDirector.split('|')) || [];
  }

  getHumanResourcesShortTitlesList(): string[] {
    return (this.options && this.options.shortTitleForHumanResources && this.options.shortTitleForHumanResources.split('|')) || [];
  }

  getOCCShortTitlesList(): string[] {
    return (this.options && this.options.shortTitleForOCC && this.options.shortTitleForOCC.split('|')) || [];
  }

  getCrisisObservorShortTitlesList(): string[] {
    return (this.options && this.options.shortTitleForCrisisObservor && this.options.shortTitleForCrisisObservor.split('|')) || [];
  }

  getCrewManagerShortTitlesList(): string[] {
    return (this.options && this.options.shortTitleForCrewManager && this.options.shortTitleForCrewManager.split('|')) || [];
  }

  getChatShortTitlesList(): string[] {
    return (this.options && this.options.shortTitleForChat && this.options.shortTitleForChat.split('|')) || [];
  }

  getMCCShortTitleList(): string[] {
    return (this.options && this.options.shortTitleForMCC && this.options.shortTitleForMCC.split('|')) || [];
  }

  getGOCShortTitleList(): string[] {
    return (this.options && this.options.shortTitleForGOC && this.options.shortTitleForGOC.split('|')) || [];
  }

  getCrisisInitializerShortTitlesList(): string[] {
    return (this.options && this.options.shortTitleForCrisisInitializer && this.options.shortTitleForCrisisInitializer.split('|')) || [];
  }

  hasToSendMails() {
    if (this.options) {
      return this.options.hasToSendMails;
    }
  }

  hasToSendSms() {
    if (this.options) {
      return this.options.hasToSendSms;
    }
  }

  getDefaultCrisisImageUrl() {
    if (this.options) {
      return this.options.defaultCrisisImageUrl;
    }
  }

  getCompanyLogoUrl() {
    if (this.options) {
      return this.options.companyLogoUrl;
    }
  }

  getEnv() {
    if (this.options) {
      return this.options.env;
    }
  }

  crisisInitializerCanActivate() {
    if (this.options) {
      return this.options.crisisInitializerCanActivate === true;
    }

    return false;
  }

  getNextInfoLimit() {
    if (this.options) {
      return this.options.nextInfoLimit;
    }
  }

  crisisShouldBeDisplayOnMenu(crisis) {
    if (this.options && this.options.crisisTimeRemainingOpen >= 0) {
      return moment().diff(moment(crisis.updatedAt, 'YYYY-MM-DD'), 'hours') < this.options.crisisTimeRemainingOpen;
    } else {
      return true;
    }
  }

  getFormIoCredentials(): FormIoCredentials {
    if (this.options && this.options.formIoAdmin && this.options.formIoBackEnd && this.options.formIoPwd) {
      return {
        formIoBackEnd: this.options.formIoBackEnd,
        dataLogin: {
          data: {
            email: this.options.formIoAdmin,
            password: this.options.formIoPwd,
          },
        },
      };
    } else {
      return undefined;
    }
  }
}

export interface FormIoCredentials {
  formIoBackEnd: string;
  dataLogin: {
    data: {
      email: string;
      password: string;
    };
  };
}
