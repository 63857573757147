import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as _ from 'lodash';
import { TranslatePipe } from '../../../common/pipes/translate/translate.pipe';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { OclLogBookGroup } from '../../models/ocl-logbook-group.model';
import { OclDecisionGroup } from '../../models/ocl-decision-group.model';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';

@Component({
  selector: 'app-ocl-group-modal',
  templateUrl: './ocl-group-modal.component.html',
  styleUrls: ['./ocl-group-modal.component.scss'],
  providers: [TranslatePipe],
})
export class OclGroupModalComponent {
  public isCreate: boolean;
  public isReadOnly: boolean;
  public modalTitle: string;
  public group?: any;
  public groupsFromApi?: any[];
  public loading = false;
  public form: FormGroup;
  public items: any[];
  type: string;
  i18nkey: string;

  get titleForm() {
    return this.form.get('titleForm') as FormControl;
  }

  get itemsForm() {
    return this.form.get('itemsForm') as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private translatePipe: TranslatePipe,
    private groupService: OclGroupService,
    public dialogRef: MatDialogRef<OclGroupModalComponent>,
    public moduleConfig: ModuleConfigService
  ) {
    this.isCreate = !data.group;
    this.isReadOnly = data.isReadOnly;

    this.group = data.group;
    this.type = data.type;
    this.groupsFromApi = data.groupsFromApi;
    this.items = data.events || data.logBookItems || data.decisionItems || data.globalInstructionItems;
    this.isCreate ? this.createForm() : this.setForm();
    if (this.type === 'EVENT') {
      this.i18nkey = 'EVENTS';
    }
    if (this.type === 'LOGBOOK') {
      this.i18nkey = 'LOGBOOK';
    }
    if (this.type === 'DECISION') {
      this.i18nkey = 'STATUS_DECISIONS';
    }
    if (this.type === 'GLOBAL_INSTRUCTION') {
      this.i18nkey = 'GLOBAL_INSTRUCTION';
    }
    this.modalTitle = this.isCreate
      ? this.translatePipe.transform(moduleConfig.config.translateKey + '.DASHBOARD.' + this.i18nkey + '.MODALS.GROUP_MODAL.CREATE_TITLE')
      : this.translatePipe.transform(moduleConfig.config.translateKey + '.DASHBOARD.' + this.i18nkey + '.MODALS.GROUP_MODAL.UPDATE_TITLE');
  }

  public createForm(): void {
    this.form = this.fb.group({
      titleForm: [undefined, [Validators.required, this.uniqueTitle()]],
      itemsForm: new FormArray([], this.minNumberOfCheckBoxes()),
    });
    this.addCheckboxes();
  }

  public setForm(): void {
    this.form = this.fb.group({
      titleForm: [undefined, [Validators.required, this.uniqueTitle()]],
      itemsForm: new FormArray([], this.minNumberOfCheckBoxes()),
    });
    this.titleForm.setValue(this.group.title);
    this.setCheckboxes();
  }

  public uniqueTitle(): any {
    return (formControl: FormControl) => {
      const title = formControl.value;
      let aGroups = this.groupsFromApi;
      if (!this.isCreate) {
        aGroups = _.reduce(
          aGroups,
          (groups, next) => {
            // no need to validate for own title when update
            if (next.objectId !== this.group.objectId) {
              groups.push(next);
            }
            return groups;
          },
          []
        );
      }
      return _.find(aGroups, group => group.title === title) ? { notUnique: true } : null;
    };
  }

  public minNumberOfCheckBoxes(min = 1): any {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.value.reduce((prev, next) => (next ? prev + next : prev), 0);
      return totalSelected >= min ? null : { required: true };
    };
    return validator;
  }

  public onCreateGroup(): void {
    this.loading = true;
    let group;

    if (this.type === 'EVENT') {
      group = new OclEventGroup();
    }
    if (this.type === 'LOGBOOK') {
      group = new OclLogBookGroup();
    }
    if (this.type === 'DECISION') {
      group = new OclDecisionGroup();
    }
    if (this.type === 'GLOBAL_INSTRUCTION') {
      group = new OclGlobalInstructionGroup();
    }
    group.title = this.titleForm.value;
    group.items = _.filter(
      _.map(this.itemsForm.value, (checked, index) => (checked ? this.items[index] : null)),
      value => !!value
    );
    this.groupService
      .save(group, this.type)
      .then(groupSaved => {
        this.dialogRef.close(groupSaved);
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  }

  public onUpdateGroup(): void {
    this.loading = true;
    this.group.title = this.titleForm.value;
    this.group.items = _.filter(
      _.map(this.itemsForm.value, (checked, index) => (checked ? this.items[index] : null)),
      value => !!value
    );
    this.groupService
      .save(this.group, this.type)
      .then(groupSaved => this.dialogRef.close(groupSaved))
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }

  public onDeleteGroup(): void {
    this.groupService
      .delete(this.group, this.type)
      .then(() => this.dialogRef.close())
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }

  public getAlreadyInGroups(item): any[] {
    return _.reduce(
      this.groupsFromApi,
      (groups, next) => {
        if (_.find(next.items, e => e.objectId === item.objectId) && !_.find(groups, group => group.objectId === next.objectId)) {
          groups.push(next);
        }
        return groups;
      },
      []
    );
  }

  private setCheckboxes(): void {
    this.items.forEach(e => {
      const isChecked = !!_.find(this.group.items, el => el.objectId === e.objectId);
      const control = new FormControl(isChecked);
      if (this.isReadOnly) {
        control.disable();
      }
      (this.form.controls.itemsForm as FormArray).push(control);
    });
  }

  private addCheckboxes(): void {
    this.items.forEach(() => {
      const control = new FormControl(false);
      if (this.isReadOnly) {
        control.disable();
      }
      (this.form.controls.itemsForm as FormArray).push(control);
    });
  }
}
