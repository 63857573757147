<div>
  <div class="header-component hol-spaced-line">
    <button *ngIf="$rootScope.isPhone" class="header-button hamburger-button" (click)="toggleSideNav()">
      <i class="fa fa-bars fa-2x menu-icon"></i>
    </button>
    <div class="content-group">
      <ng-content></ng-content>
    </div>
    <!-- Right Menu -->
    <div class="buttons-group">
      <ng-content select=".buttons-addon"></ng-content>
      <button class="header-button" (click)="toggleIndicatorPanel()" *ngIf="indicators" type="button">
        <img alt="icon indicators" src="../../../assets/images/indicators.svg" />
      </button>
      <app-indicators-panel
        *ngIf="indicators"
        [accessRights]="accessRights"
        [expanded]="showIndicators"
        [isInBox]="false"
      ></app-indicators-panel>
      <button class="header-button" *ngIf="canAddBreakingNews" (click)="addBreakingNews($event)">
        <img alt="icon info" src="../../../assets/images/flash-info.svg" />
      </button>
      <app-chat-component *ngIf="context && showChat" [context]="context"></app-chat-component>
      <button *ngIf="!$rootScope.isPhone" class="header-button button-logout" (click)="logout($event)">
        <span class="user-name" title="{{ username }}">{{ username }}</span
        ><i class="icon icon-logout"></i>
      </button>
    </div>
  </div>

  <ng-marquee class="breaking-news-container" *ngIf="breakingNews?.length" speed="slow">
    <span class="news" *ngFor="let bn of breakingNews; let i = index">
      <span *ngIf="i !== 0" class="separator">-</span>
      <span class="createdat">{{ bn.createdAt | date: "dd/MM/yy 'at' HH:mm UTC":'UTC' }}</span>
      <span> - </span>
      <span>{{ bn.news }}</span>
    </span>
  </ng-marquee>
</div>
