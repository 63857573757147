'use strict';
angular.module('gestiondecriseApp').directive('logBox', function () {
  return {
    restrict: 'E',
    templateUrl: 'scripts/directives/crew/logBox/logBox.html',
    scope: {
      currentFilter: '=',
      isReadOnly: '=',
    },
    controller: function ($scope, $mdDialog, $timeout, $q, CrewSituationsService, CrewFunctionsService, UserService, $rootScope) {
      $scope.logs = [];
      $scope.loading = false;

      function init() {
        $scope.loading = true;
        CrewSituationsService.getAllLogs($scope.currentFilter).then(function (logs) {
          $scope.logs = logs;
          $scope.loading = false;
        });
      }

      var unregisterWatchFilters = $scope.$watch('currentFilter', init);
      var unregisterSituationEvent = $scope.$on('crew-situation-updated', init);
      var unregisterPoolService = $scope.$on('crewPoolService-logs', function (e, logs) {
        $scope.logs = logs;
      });

      $scope.openUserModal = function ($event, situation) {
        $mdDialog.show({
          controller: 'CrewUserModalCtrl',
          templateUrl: 'views/crew/modals/user.modal.html',
          clickOutsideToClose: false,
          targetEvent: $event,
          locals: {
            user: situation && situation.user,
            event: null,
            situation: situation,
            isReadOnly: $scope.isReadOnly,
            isArchivesOpened: false,
          },
        });
      };

      $scope.isInFunction = function (situation) {
        return (
          !$rootScope.crewSelectedFunction ||
          (situation.user.functions && situation.user.functions.indexOf($rootScope.crewSelectedFunction) !== -1)
        );
      };

      $scope.getUserInitials = UserService.getUserInitials;

      $scope.$on('$destroy', function () {
        unregisterWatchFilters();
        unregisterSituationEvent();
        unregisterPoolService();
      });
    },
  };
});
