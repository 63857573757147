import { OclEvent } from './ocl-event.model';
import { HolTag } from '../../common/models/hol-tag';
import { HolFlight } from '../../common/models/hol-flight.model';
import { OclLinkedDisplayedItem } from './ocl-linked-displayed-items.model';
import { HolObject } from '../../common/models/hol-object';
import { HolUser } from '../../common/models/hol-user.model';
import { HolAttachments } from '../../common/models/hol-attachments.model';

export class OclDecision extends HolObject {
  public message: string;
  public event?: OclEvent;
  public flight?: HolFlight;
  public tags?: HolTag[];
  public done?: boolean;
  public isPinned?: boolean;
  public isTodo?: boolean;
  public nextInfoTime?: Date;
  public fromERP?: boolean;
  public erpDecision?: any;
  public toERP?: boolean;

  displayFromLinkedValues?: string[];
  linkedData?: OclLinkedDisplayedItem;

  constructor(parseObject?: Parse.Object, tags?: HolTag[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.message = parseObject.get('message');
    this.event = parseObject.get('event') && new OclEvent(parseObject.get('event'));
    this.flight = parseObject.get('flight') && new HolFlight(parseObject.get('flight'));
    // tags need to set separately due to join table, this.tags = parseObject.get('tags').map(tag => new HolTag(tag));
    this.done = parseObject.get('done');
    this.nextInfoTime = parseObject.get('nextInfoTime');
    this.isPinned = parseObject.get('isPinned');
    this.isTodo = parseObject.get('isTodo');
    this.tags = tags;
    this.fromERP = parseObject.get('fromERP');
    this.erpDecision = parseObject.get('erpDecision') && new OclDecision(parseObject.get('erpDecision'));
    this.toERP = parseObject.get('toERP');

    if (parseObject && parseObject.get('erpDecision')) {
      // this.erpDecision = new OclDecision(parseObject.get('erpDecision'));
      this.createdBy = new HolUser(parseObject.get('erpDecision').get('createdBy'));
      const jsonAttachments = parseObject.get('erpDecision').get('attachments');
      this.setAttachments(jsonAttachments);
      this.fromERP = true;
      this.toERP = false;
    }
  }

  protected getContentText(parseObject: Parse.Object) {
    return parseObject.get('erpDecision') // else if
      ? parseObject.get('erpDecision').get('title')
      : parseObject.get('message');
  }

  protected setAttachments(jsonAttachments) {
    if (typeof jsonAttachments === 'string') {
      jsonAttachments = JSON.parse(jsonAttachments);
    }
    if (jsonAttachments) {
      this.attachments = new HolAttachments(
        jsonAttachments.note,
        jsonAttachments.file,
        jsonAttachments.image,
        jsonAttachments.noteFile,
        jsonAttachments.link,
        jsonAttachments.files
      );
    } else {
      this.attachments = new HolAttachments();
    }
  }
}
