import { OclHistoryService } from 'src/app/ocl/services/ocl-history-service/ocl-history.service';
import { HistoryService } from 'src/app/common/services/history.service';
import { OCL_DYNAMIC_COMPONENT } from './index';
import { DynamicModule } from 'ng-dynamic-component';
import { OCL_GLOBAL_INSTRUCTION_MODAL_TOKEN } from './modals/ocl-global-instruction-modal/ocl-global-instruction-modal.token';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OclLogbookListItemComponent } from './components/ocl-logbook-list-item/ocl-logbook-list-item.component';
import { OclTimelineComponent } from './pages/ocl-timeline/ocl-timeline.component';
import { OclHistoryComponent } from './components/ocl-history/ocl-history.component';
import { OclHistoryTimeSlotComponent } from './components/ocl-history/ocl-history-time-slot/ocl-history-time-slot.component';
import { OclEventItemComponent } from './components/ocl-event-item/ocl-event-item.component';
import { OclGroupModalComponent } from './modals/ocl-group-modal/ocl-group-modal.component';
import { OclTimelineSearchModalComponent } from './modals/ocl-timeline-search-modal/ocl-timeline-search-modal.component';
import { OclContainerGroupItemComponent } from './components/ocl-container-group-item/ocl-container-group-item.component';
import { OclDecisionsModalComponent } from './modals/ocl-decisions-modal/ocl-decisions-modal.component';
import { OclLogbookModalComponent } from './modals/ocl-logbook-modal/ocl-logbook-modal.component';
import { OclEventModalComponent } from './modals/ocl-event-modal/ocl-event-modal.component';
import { CommonComponentsModule } from '../common/common.components.module';
import { OclLinkedDisplayedItemsStoreManager } from './store/linked-display-items/ocl-linked-displayed-items-store-manager.service';
import { OCL_DECISIONS_MODAL_TOKEN } from './modals/ocl-decisions-modal/ocl-decisions-modal.token';
import { OCL_LOGBOOK_MODAL_TOKEN } from './modals/ocl-logbook-modal/ocl-logbook-modal.token';
import { OclStatusDecisionsBoxComponent } from './components/ocl-status-decisions-box/ocl-status-decisions-box.component';
import { OclEventBoxComponent } from './components/ocl-event-box/ocl-event-box.component';
import { OclLogbookBoxComponent } from './components/ocl-logbook-box/ocl-logbook-box.component';
import { OclEventCloseModalComponent } from './modals/ocl-event-close-modal/ocl-event-close-modal.component';
import { OclCriticalityMatrixModalComponent } from './modals/ocl-criticality-matrix-modal/ocl-criticality-matrix-modal.component';
import { OclCriticalityLoadingComponent } from './components/ocl-criticality-loading/ocl-criticality-loading.component';
import { OclManagersModalComponent } from './modals/ocl-managers-modal/ocl-managers-modal.component';
import { OclManagersBoxComponent } from './components/ocl-managers-box/ocl-managers-box.component';
import { OclTasksComponent } from './pages/ocl-tasks/ocl-tasks.component';
import { OCL_EVENT_MODAL_TOKEN } from './modals/ocl-event-modal/ocl-event-modal-tokens';
import { OclGlobalInstructionItemComponent } from './components/ocl-global-instruction-item/ocl-global-instruction-item.component';
import { OclGlobalInstructionBoxComponent } from './components/ocl-global-instruction-box/ocl-global-instruction-box.component';
import { OclGlobalInstructionModalComponent } from './modals/ocl-global-instruction-modal/ocl-global-instruction-modal.component';
import { OclTaskComponent } from './pages/ocl-task/ocl-task.component';
import { NotificationsService } from '../common/services/notifications/notifications.service';
import { OclNotificationsService } from './services/ocl-notifications-service/ocl-notifications.service';
import { OclTasksService } from './services/ocl-tasks-service/ocl-tasks.service';
import { TasksService } from '../common/services/tasks.service';

@NgModule({
  declarations: [
    OclTimelineComponent,
    OclTasksComponent,
    OclTaskComponent,
    OclHistoryComponent,
    OclHistoryTimeSlotComponent,
    OclEventBoxComponent,
    OclEventItemComponent,
    OclGroupModalComponent,
    OclTimelineSearchModalComponent,
    OclContainerGroupItemComponent,
    OclStatusDecisionsBoxComponent,
    OclLogbookBoxComponent,
    OclDecisionsModalComponent,
    OclLogbookModalComponent,
    OclEventModalComponent,
    OclEventCloseModalComponent,
    OclCriticalityMatrixModalComponent,
    OclCriticalityLoadingComponent,
    OclManagersModalComponent,
    OclManagersBoxComponent,
    OclGlobalInstructionItemComponent,
    OclGlobalInstructionBoxComponent,
    OclGlobalInstructionModalComponent,
    ...OCL_DYNAMIC_COMPONENT,
  ],
  imports: [CommonModule, CommonComponentsModule, DynamicModule.withComponents(OCL_DYNAMIC_COMPONENT)],
  entryComponents: [
    OclGroupModalComponent,
    OclTimelineSearchModalComponent,
    OclDecisionsModalComponent,
    OclLogbookModalComponent,
    OclEventModalComponent,
    OclEventCloseModalComponent,
    OclCriticalityMatrixModalComponent,
    OclEventItemComponent,
    OclManagersBoxComponent,
    OclManagersModalComponent,
    OclGlobalInstructionModalComponent,
    ...OCL_DYNAMIC_COMPONENT,
  ],
  exports: [
    CommonModule,
    CommonComponentsModule,
    OclLogbookListItemComponent,
    OclTimelineComponent,
    OclTasksComponent,
    OclTaskComponent,
    OclHistoryComponent,
    OclHistoryTimeSlotComponent,
    OclEventBoxComponent,
    OclEventItemComponent,
    OclGroupModalComponent,
    OclTimelineSearchModalComponent,
    OclContainerGroupItemComponent,
    OclStatusDecisionsBoxComponent,
    OclLogbookBoxComponent,
    OclDecisionsModalComponent,
    OclLogbookModalComponent,
    OclEventModalComponent,
    OclManagersBoxComponent,
    OclCriticalityLoadingComponent,
    OclGlobalInstructionItemComponent,
    OclGlobalInstructionBoxComponent,
    OclGlobalInstructionModalComponent,
  ],
  providers: [
    OclLinkedDisplayedItemsStoreManager,
    { provide: OCL_DECISIONS_MODAL_TOKEN, useValue: OclDecisionsModalComponent },
    { provide: OCL_LOGBOOK_MODAL_TOKEN, useValue: OclLogbookModalComponent },
    { provide: OCL_EVENT_MODAL_TOKEN, useValue: OclEventModalComponent },
    { provide: OCL_GLOBAL_INSTRUCTION_MODAL_TOKEN, useValue: OclGlobalInstructionModalComponent },
    { provide: HistoryService, useExisting: OclHistoryService },
    { provide: NotificationsService, useExisting: OclNotificationsService },
    { provide: TasksService, useExisting: OclTasksService },
  ],
})
export class OclModule {}
