<div class="hol-dialog-wrapper add-next-info-modal">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      <span [innerHTML]="modalTitle"></span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <p class="{{ modalType }}" [innerHTML]="modalContent"></p>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="fill-space"></div>

    <button (click)="close()" class="hol-validate-btn" mat-raised-button>
      <mat-icon>done</mat-icon>
      <span>{{ 'COMMON.BUTTON.OK' | translate | uppercase }}</span>
    </button>
  </mat-dialog-actions>
</div>
