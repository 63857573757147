import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HolNotification } from '../../models/hol-notification.model';
import { RequestService } from '../request.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  // tslint:disable:variable-name
  protected ParseNotifications;
  // tslint:enabled
  private _notifications: HolNotification[]; // @cache

  constructor(protected requestService: RequestService, @Inject('$translate') private translatePipe) {}

  public getAll(): Promise<HolNotification[]> {
    if (this._notifications) {
      return Promise.resolve(this._notifications);
    }
    const notificationsQuery = new Parse.Query(this.ParseNotifications);
    notificationsQuery.ascending('name');
    return this.requestService.performFindQuery(notificationsQuery).then(results => {
      this._notifications = results.map(s => new HolNotification(s));
      return Promise.resolve(
        this._notifications.map(notif => {
          notif.sendBySms = false;
          notif.sendByMail = false;
          return notif;
        })
      );
    });
  }

  public getAddressMailToSend(notifications: HolNotification[]): string[] {
    const notificationMail = _.filter(notifications, n => {
      return n.sendByMail;
    });
    const addressMailToSend = [];
    for (const notif of notificationMail) {
      addressMailToSend.push(...notif.mailingList);
    }
    return addressMailToSend;
  }

  public getPhoneNumbersToSend(notifications: HolNotification[]): string[] {
    const notificationSMS = _.filter(notifications, n => {
      return n.sendBySms;
    });
    const phoneNumbersToSend = [];
    for (const notif of notificationSMS) {
      phoneNumbersToSend.push(...notif.smsList);
    }
    return phoneNumbersToSend;
  }
}
