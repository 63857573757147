import { RolesService } from './../../../common/services/roles.service';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { FltApplicabilityService } from './../../../flt/services/flt-applicability.service';
import { Inject, Injectable } from '@angular/core';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { OpsDecisionTagService } from '../ops-decision-tag-service/ops-decision-tag.service';
import { OpsHistoryService } from '../ops-history-service/ops-history.service';
import { OpsOptionsService } from '../ops-options-service/ops-options.service';
import { FltDecisionService } from '../../../flt/services/flt-decision.service';
import { OclDecisionsStoreManager } from '../../../ocl/store/decisions/ocl-decisions.store-manager';
import { OpsMailService } from '../ops-mail-service/ops-mail.service';
import { OpsSmsService } from '../ops-sms-service/ops-sms.service';
import { OpsModuleConfigService } from '../ops-module-config/ops-module-config.service';
import { OpsFlightInstructionService } from '../ops-flight-instruction-service/ops-flight-instruction.service';
import { OpsFlightService } from '../ops-flight-service/ops-flight.service';
import { OpsFlightLogbookService } from '../ops-flight-logbook-service/ops-flight-logbook.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';

@Injectable({
  providedIn: 'root',
})
export class OpsDecisionService extends FltDecisionService {
  // tslint:disable:variable-name
  protected ParseDecision = Parse.Object.extend('OPSDecisions');
  protected ParseEvent = Parse.Object.extend('OPSEvents');
  protected ParseDecisionTag = Parse.Object.extend('OPSDecisionTag');
  protected ParseTag = Parse.Object.extend('OPSTag');
  protected ParseFlightInstruction = Parse.Object.extend('OPSFlightInstruction');
  protected ParseFlightLogbook = Parse.Object.extend('OPSFlightLogbook');
  // tslint:enabled

  public constructor(
    @Inject('$filter') protected $filter,
    @Inject('FilesService') protected filesService,
    @Inject('$translate') protected $translate: any,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected parseMapper: ParseMapperService,
    protected requestService: RequestService,
    protected optionsService: OpsOptionsService,
    protected notificationsService: NotificationsService,
    protected smsService: OpsSmsService,
    protected mailService: OpsMailService,
    protected historyService: OpsHistoryService,
    protected decisionTagService: OpsDecisionTagService,
    public moduleConfig: OpsModuleConfigService,
    protected flightInstructionService: OpsFlightInstructionService,
    protected flightService: OpsFlightService,
    protected flightLogbookService: OpsFlightLogbookService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService,
    public commonStoreManager: CommonStoreManager
  ) {
    super(
      $filter,
      filesService,
      $translate,
      decisionsStoreManager,
      parseMapper,
      requestService,
      optionsService,
      notificationsService,
      smsService,
      mailService,
      historyService,
      decisionTagService,
      moduleConfig,
      flightInstructionService,
      flightService,
      flightLogbookService,
      markdownService,
      applicabilityService,
      commonStoreManager
    );
  }
}
