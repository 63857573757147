<div class="hol-dialog-wrapper" style="position: relative;">
  <app-loading *ngIf="loading" class="full-loader"></app-loading>

  <mat-toolbar>
    <div mat-dialog-title>
      <i class="fa fa-plus"></i>
      {{ data.title }}
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <mat-form-field *ngIf="noteTemplates.length" class="mat-form-field">
      <mat-label>Note Template</mat-label>
      <mat-select (selectionChange)="changeTemplate($event)" matNativeControl>
        <mat-option *ngFor="let noteTemplate of noteTemplates" value="{{ noteTemplate.templateUrl }}">{{ noteTemplate.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <form name="markdownForm">
      <md-editor #mdEditor [(ngModel)]="attachment" [options]="options" mode="editor" name="Content"> </md-editor>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button (click)="validateNote()" [disabled]="!attachment" class="hol-validate-btn" mat-raised-button>
      <i class="fa fa-check"></i> {{ 'COMMON.BUTTON.VALIDATE' | translate | uppercase }}
    </button>
  </mat-dialog-actions>
</div>
