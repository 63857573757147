export class HolUser {
  objectId: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  userId: string;
  userMonogram: string;
  fullName: string;
  isExternal: boolean;
  isBlacklisted: boolean;
  isAvailableOffline: boolean;
  lastSeenAt: Date;
  createdBy?: HolUser;

  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.firstName = parseObject.get('firstName');
    this.lastName = parseObject.get('lastName');
    this.username = parseObject.get('username');
    this.email = parseObject.get('email') || parseObject.get('userEmail');
    this.phone = parseObject.get('phone');
    this.userId = parseObject.get('userId');
    this.isExternal = parseObject.get('isExternal');
    this.isBlacklisted = parseObject.get('isBlacklisted');
    this.isAvailableOffline = parseObject.get('isAvailableOffline');
    this.lastSeenAt = parseObject.get('lastSeenAt');
    this.userMonogram =
      (this.firstName && this.firstName.length ? this.firstName[0] : '') + (this.lastName && this.lastName.length ? this.lastName[0] : '');
    this.fullName = (this.firstName ? this.firstName + ' ' : '') + (this.lastName ? this.lastName : '');
    this.createdBy = new HolUser(parseObject.get('createdBy'));
  }

  monogram() {
    return (
      (this.firstName && this.firstName.length ? this.firstName[0] : '') + (this.lastName && this.lastName.length ? this.lastName[0] : '')
    );
  }
}

export class HolUserWithCompanies extends HolUser {
  public companies?: { name: string; read: boolean; write: boolean }[];
}
