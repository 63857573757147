'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.CrewEventsService
 * @description
 * # CrewEventsService
 * Service in the gestiondecriseApp.
 */
angular
  .module('gestiondecriseApp')
  .service('CrewEventsService', function (
    $q,
    RequestService,
    ParseMapper,
    CrewUsersService,
    $rootScope,
    CrewLogsService,
    CrewLogsTypes,
    $mdDialog,
    ChatService,
    CrewOptionsService,
    UserService,
    RolesService
  ) {
    var ParseUser = Parse.Object.extend('_User');
    var CrewEvent = Parse.Object.extend('CrewEvent');
    var CrewFunction = Parse.Object.extend('CrewFunction');
    var CrewEventUser = Parse.Object.extend('CrewEventUser');
    var CrewUser = Parse.Object.extend('CrewUsers');
    var CrewSituation = Parse.Object.extend('CrewSituations');
    var CrewEventNote = Parse.Object.extend('CrewEventNote');
    var OccLogbook = Parse.Object.extend('OCCLogBook');
    var that = this;

    this.getAll = function (dateFilter, dateLimits) {
      var deferred = $q.defer();

      var query = new Parse.Query(CrewEvent);
      query.include('function');
      //query.greaterThanOrEqualTo('date', moment().startOf('day').toDate());
      query.doesNotExist('closeReason');
      if (dateFilter) {
        if (dateFilter.threshold === 1) {
          //just today events
          query.greaterThanOrEqualTo('date', moment().startOf('day').toDate());
          query.lessThan('date', moment().startOf('day').add(dateFilter.threshold, 'day').toDate());
        } else {
          //events for the threshold and events coming after the date of threshold
          query.greaterThanOrEqualTo(
            'date',
            moment()
              .startOf('day')
              .add(dateFilter.threshold - 1, 'day')
              .toDate()
          );
        }
      }

      if (dateLimits) {
        query.lessThan('date', moment(dateLimits.endDate).toDate());
        query.greaterThanOrEqualTo('date', moment(dateLimits.startDate).toDate());
      }
      query.ascending('date');
      _getEventsForQuery(query, deferred);

      return deferred.promise;
    };

    this.startArchivingTask = function () {
      //do archiving if last arhive was on different date
      if (
        moment(CrewOptionsService.getLastArchivedDoneEventsAt()).isBefore(moment().startOf('day')) ||
        !CrewOptionsService.getLastArchivedDoneEventsAt()
      ) {
        that.archiveDoneEvents().then(
          function () {
            CrewOptionsService.setLastArchivedDoneEventsAt(new Date()).then(
              function (updatedOption) {
                console.log('archiving done events with success', updatedOption);
                $rootScope.$broadcast('crew-archive-done');
              },
              function (error) {
                console.log('update occoptins failed: ', error);
              }
            );
          },
          function (error) {
            console.log('archiving events failed:', error);
          }
        );
      } else {
        console.log('already archived events before');
      }
    };

    this.archiveDoneEvents = function () {
      var deferred = $q.defer();
      this.getAll(null, null)
        .then(function (events) {
          var promises = [];
          events = _.filter(events, function (event) {
            let eventIsBeforeToday = moment(event.date).isBefore(moment().startOf('day'));
            return eventIsBeforeToday && isClosedEvent(event);
          });
          _.forEach(events, function (event) {
            // 2 is index for closeReason with description
            promises.push(that.close(event, 2, 'automatic_archiving'));
          });

          $q.all(promises)
            .then(function () {
              deferred.resolve();
            })
            .catch(function (error) {
              deferred.reject(error);
            });
        })
        .catch(function (error) {
          deferred.reject(error);
        });

      return deferred.promise;
    };

    function isClosedEvent(event) {
      return that.getNbUserBySituationStatus(event, 'ACCEPTED') >= event.nbPersons;
    }

    this.getById = function (id) {
      var deferred = $q.defer();

      var query = new Parse.Query(CrewEvent);
      query.equalTo('objectId', id);
      _getEventsForQuery(query, deferred);

      return deferred.promise;
    };

    this.search = function (searchData) {
      var deferred = $q.defer();

      var query = new Parse.Query(CrewEvent);
      if (searchData.text && searchData.text.length) {
        var regExp = new RegExp(searchData.text, 'gi');
        query.matches('impact', regExp);
      }
      if (!searchData.onlyClosed) {
        query.doesNotExist('closeReason');
      }
      if (searchData.date && searchData.endDate) {
        query.greaterThanOrEqualTo('date', searchData.date);
        query.lessThanOrEqualTo('date', searchData.endDate);
      }

      if (searchData.function) {
        console.log('searchData.function', searchData.function);
        query.equalTo('function', new CrewFunction({ id: searchData.function.objectId }));
      }
      query.descending('date');
      _getEventsForQuery(query, deferred);

      return deferred.promise;
    };

    this.getAllForFunctions = function (functionsList, includeClosed) {
      var deferred = $q.defer();

      var query = new Parse.Query(CrewEvent);
      //query.greaterThanOrEqualTo('date', moment().startOf('day').toDate());
      if (!includeClosed) {
        query.doesNotExist('closeReason');
      }
      query.ascending('date');

      var functionQuery = new Parse.Query(CrewFunction);
      functionQuery.containedIn('code', functionsList);
      query.matchesQuery('function', functionQuery);

      RequestService.performFindQuery(
        query,
        function (parseCrewEvents) {
          deferred.resolve(
            _.map(parseCrewEvents, function (ce) {
              return ParseMapper.crewEventToObject(ce);
            })
          );
        },
        deferred.reject
      );

      return deferred.promise;
    };

    this.getAllForUser = function (user) {
      var deferred = $q.defer();
      var parseCrewUser = new CrewUser({ id: user.objectId });

      var query = new Parse.Query(CrewEvent);
      query.doesNotExist('closeReason');
      query.ascending('date');

      var crewEventUserQuery = new Parse.Query(CrewEventUser);
      crewEventUserQuery.equalTo('user', parseCrewUser);
      crewEventUserQuery.include('event');
      crewEventUserQuery.matchesQuery('event', query);

      RequestService.performFindQuery(
        crewEventUserQuery,
        function (parseCrewEventsUsers) {
          that.getAllForFunctions(user.functions).then(function (functionsEvents) {
            var userEvents = _.map(parseCrewEventsUsers, function (crewEventUser) {
              return ParseMapper.crewEventToObject(crewEventUser.get('event'));
            });
            var allEvents = userEvents.concat(functionsEvents);
            deferred.resolve(_.orderBy(_.uniqBy(allEvents, 'objectId'), 'date'));
          }, deferred.reject);
        },
        deferred.reject
      );

      return deferred.promise;
    };

    this.create = function (event, noSelect) {
      if (!event.function) {
        return $q.reject('CrewEventsService::can not create event without function');
      }
      if (_.isString(event.function)) {
        event.function = { code: event.function };
      }

      var deferred = $q.defer();
      this.getAllForFunctions([event.function.code], true).then(function (events) {
        // filter the events on the same day as the one we're creating
        events = _.filter(events, function (ev) {
          return moment(ev.date).isSame(moment(event.date), 'day');
        });

        for (var i = 0; i < events.length; i++) {
          var ev = events[i];
          ev.matches = compareEventTitle(event.impact, ev.impact);
          ev.score = ev.matches.stringMatch.score + ev.matches.numberMatch.score + ev.matches.flightNumberMatch.score;
        }
        events = _.orderBy(
          _.filter(events, function (e) {
            return e.score > 10;
          }),
          ['score'],
          ['desc']
        );

        if (events.length) {
          $mdDialog
            .show({
              templateUrl: 'views/crew/modals/similarEvents.modal.html',
              controller: 'CrewSimilarEventsModalCtrl',
              clickOutsideToClose: false,
              skipHide: true,
              hasBackdrop: false,
              locals: {
                events: events,
                eventToCreate: event,
                isSelectable: !noSelect,
              },
            })
            .then(
              function (res) {
                if (res === true) {
                  doCreate(event).then(deferred.resolve, deferred.reject);
                } else if (res !== undefined) {
                  deferred.resolve(res);
                } else {
                  deferred.reject('Do not create because similar event');
                }
              },
              function () {
                deferred.reject('Do not create because similar event');
              }
            );
        } else {
          doCreate(event).then(deferred.resolve, deferred.reject);
        }
      }, deferred.reject);

      return deferred.promise;
    };

    function doCreate(event) {
      var deferred = $q.defer();

      var functionQuery = new Parse.Query(CrewFunction);
      functionQuery.equalTo('code', event.function.code);

      RequestService.performFirstQuery(
        functionQuery,
        function (parseFunction) {
          var parseEvent = new CrewEvent({
            nbPersons: event.nbPersons,
            function: parseFunction,
            impact: event.impact,
            date: event.date,
            createdBy: Parse.User.current(),
          });

          RequestService.performSaveQuery(
            parseEvent,
            null,
            function (savedEvent) {
              var parseCrewEventUsers = _.map(event.users, function (user) {
                return new CrewEventUser({
                  event: savedEvent,
                  user: new CrewUser({ id: user.objectId }),
                });
              });
              var parseCrewEventNotes = _.map(
                _.filter(event.notes, n => !n.toOCC),
                function (note) {
                  return new CrewEventNote({
                    text: note.text,
                    createdBy: Parse.User.current(),
                    event: savedEvent,
                    toOCC: false,
                    attachments: undefined,
                  });
                }
              );
              var parseCrewEventNotesToOcc = _.map(
                _.filter(event.notes, n => n.toOCC),
                function (note) {
                  return new CrewEventNote({
                    text: note.text,
                    createdBy: Parse.User.current(),
                    event: savedEvent,
                    toOCC: note.toOCC,
                    attachments: note.attachments,
                  });
                }
              );
              RequestService.performSaveAllQuery(
                parseCrewEventUsers,
                function (savedCrewEventUsers) {
                  RequestService.performSaveAllQuery(
                    parseCrewEventNotes,
                    function (savedCrewEventNotes) {
                      if (parseCrewEventNotesToOcc.length !== 0) {
                        RequestService.performSaveAllQuery(
                          parseCrewEventNotesToOcc,
                          function (savedCrewEventNotesToOcc) {
                            duplicateNotesToOtherModuleLogbook(event, savedCrewEventNotesToOcc)
                              .then(parseOccLogbooks => {
                                RequestService.performSaveAllQuery(
                                  parseOccLogbooks,
                                  function () {
                                    var newEvent = ParseMapper.crewEventToObject(savedEvent, savedCrewEventUsers, savedCrewEventNotes);
                                    CrewLogsService.postLog(CrewLogsTypes.EVENT_CREATE, null, newEvent);
                                    deferred.resolve(newEvent);
                                  },
                                  deferred.reject
                                );
                              })
                              .catch(err => console.log(err));
                          },
                          deferred.reject
                        );
                      } else {
                        var newEvent = ParseMapper.crewEventToObject(savedEvent, savedCrewEventUsers, savedCrewEventNotes);
                        CrewLogsService.postLog(CrewLogsTypes.EVENT_CREATE, null, newEvent);
                        deferred.resolve(newEvent);
                      }
                    },
                    deferred.reject
                  );
                },
                deferred.reject
              );
            },
            deferred.reject
          );
        },
        deferred.reject
      );

      return deferred.promise;
    }

    this.update = function (event) {
      var deferred = $q.defer();
      var parseEvent = new CrewEvent({
        id: event.objectId,
        nbPersons: event.nbPersons,
      });
      parseEvent.set('impact', event.impact);
      RequestService.performSaveQuery(
        parseEvent,
        null,
        function (savedEvent) {
          var parseCrewEventUsers = _.map(
            _.filter(event.users, function (user) {
              return !user.eventUserObjectId;
            }),
            function (user) {
              return new CrewEventUser({
                event: savedEvent,
                user: new CrewUser({ id: user.objectId }),
              });
            }
          );
          var destroyQuery = new Parse.Query(CrewEventUser);
          destroyQuery.equalTo('event', savedEvent);
          //destroyQuery.include('user');
          destroyQuery.notContainedIn(
            'user',
            _.map(event.users, function (user) {
              return new CrewUser({ id: user.objectId });
            })
          );
          var newUsersPromise = $q.defer();
          var oldUsersPromise = $q.defer();
          RequestService.performSaveAllQuery(parseCrewEventUsers, newUsersPromise.resolve, newUsersPromise.reject);
          RequestService.performFindQuery(
            destroyQuery,
            function (res) {
              RequestService.performDestroyAllQuery(res, oldUsersPromise.resolve, oldUsersPromise.reject);
            },
            oldUsersPromise.reject
          );

          $q.all([newUsersPromise, oldUsersPromise]).then(function () {
            var parseCrewEventNotes = _.map(
              _.filter(event.notes, n => !n.toOCC),
              function (note) {
                var parseUser = null;

                if (note.createdBy) {
                  parseUser = new Parse.User({ objectId: note.createdBy.objectId });
                } else {
                  parseUser = Parse.User.current();
                }

                return new CrewEventNote({
                  text: note.text,
                  createdBy: parseUser,
                  event: savedEvent,
                  id: note.objectId,
                  toOCC: false,
                  attachments: undefined,
                });
              }
            );
            var parseCrewEventNotesToOcc = _.map(
              _.filter(event.notes, n => n.toOCC),
              function (note) {
                var parseUser = null;

                if (note.createdBy) {
                  parseUser = new Parse.User({ objectId: note.createdBy.objectId });
                } else {
                  parseUser = Parse.User.current();
                }

                return new CrewEventNote({
                  text: note.text,
                  createdBy: parseUser,
                  event: savedEvent,
                  id: note.objectId,
                  toOCC: note.toOCC,
                  attachments: note.attachments,
                });
              }
            );
            RequestService.performSaveAllQuery(
              parseCrewEventNotes,
              function () {
                if (parseCrewEventNotesToOcc.length !== 0) {
                  RequestService.performSaveAllQuery(
                    parseCrewEventNotesToOcc,
                    function (savedCrewEventNotesToOcc) {
                      const createdCrewEventNotesToOcc = _.filter(
                        savedCrewEventNotesToOcc,
                        parseObject =>
                          !_.map(
                            _.filter(event.notes, n => n.toOCC),
                            c => c.objectId
                          ).includes(parseObject.id)
                      );
                      if (createdCrewEventNotesToOcc.length !== 0) {
                        duplicateNotesToOtherModuleLogbook(event, createdCrewEventNotesToOcc)
                          .then(parseOccLogbooks => {
                            RequestService.performSaveAllQuery(
                              parseOccLogbooks,
                              function () {
                                CrewLogsService.postLog(CrewLogsTypes.EVENT_UPDATE, null, event);
                                deferred.resolve(event);
                              },
                              deferred.reject
                            );
                          })
                          .catch(err => console.log(err));
                      } else {
                        CrewLogsService.postLog(CrewLogsTypes.EVENT_UPDATE, null, event);
                        deferred.resolve(event);
                      }
                    },
                    deferred.reject
                  );
                } else {
                  CrewLogsService.postLog(CrewLogsTypes.EVENT_UPDATE, null, event);
                  deferred.resolve(event);
                }
              },
              deferred.reject
            );
          }, deferred.reject);
        },
        deferred.reject
      );

      return deferred.promise;
    };

    this.getNbUserBySituationStatus = function (event, status) {
      return _.filter(event.users, function (user) {
        return user.lastSituation && user.lastSituation.status === status;
      }).length;
    };

    this.getNbPotentiallyRemainingUser = function (event) {
      return _.filter(event.users, function (user) {
        return !user.lastSituation || user.lastSituation.status === 'NEUTRAL' || user.lastSituation.status === 'WAITING';
      }).length;
    };

    this.close = (event, reason, reasonText) => {
      const deferred = $q.defer();
      const parseEvent = new CrewEvent({
        id: event.objectId,
      });
      parseEvent.set('closeBy', new ParseUser({ id: UserService.getCurrentUserObject().objectId }));
      RequestService.performSaveQuery(
        parseEvent,
        {
          closeAt: new Date(),
          closeReason: reason,
          closeReasonText: reasonText,
        },
        () => {
          event.closeReason = reason;
          event.closeReasonText = reasonText;
          CrewLogsService.postLog(CrewLogsTypes.EVENT_CLOSE, null, event);
          if (event.channelId) {
            ChatService.archiveChannel(event.channelId).then(
              () => console.log('channel archived !'),
              error => console.error('Error while archiving channel', error.data.body.error)
            );
          }
          const waitingSituationsQuery = new Parse.Query(CrewSituation);
          waitingSituationsQuery.equalTo('event', parseEvent);
          waitingSituationsQuery.equalTo('status', 'WAITING');
          RequestService.performFindQuery(
            waitingSituationsQuery,
            situations => {
              situations.map(situation => situation.set('status', 'NEUTRAL'));
              RequestService.performSaveAllQuery(situations, () => deferred.resolve(event), deferred.reject);
            },
            deferred.reject
          );
        },
        deferred.reject
      );
      return deferred.promise;
    };

    this.reopen = function (event) {
      var deferred = $q.defer();
      event = _.cloneDeep(event);
      delete event.closeReason;
      delete event.closeReasonText;
      var parseEvent = new CrewEvent({
        id: event.objectId,
      });
      parseEvent.unset('closeReason');
      parseEvent.unset('closeReasonText');
      RequestService.performSaveQuery(
        parseEvent,
        {},
        function (parseEvent) {
          CrewLogsService.postLog(CrewLogsTypes.EVENT_REOPEN, null, event);
          if (event.channelId) {
            ChatService.unarchiveChannel(event.channelId).then(
              function () {
                console.log('channel unarchived !');
              },
              function (error) {
                console.error('Error while unarchiving channel', error.data.body.error);
              }
            );
          }
          deferred.resolve(ParseMapper.crewEventToObject(parseEvent));
        },
        deferred.reject
      );

      return deferred.promise;
    };

    this.addUser = function (event, user) {
      var deferred = $q.defer();
      var parseCrewEvent = new CrewEvent({ id: event.objectId });
      var parseCrewUser = new CrewUser({ id: user.objectId });

      // check if the user is not already in event
      var query = new Parse.Query(CrewEventUser);
      query.equalTo('event', parseCrewEvent);
      query.equalTo('user', parseCrewUser);

      RequestService.performFindQuery(
        query,
        function (parseCrewEventUsers) {
          if (parseCrewEventUsers.length === 0) {
            var parseCrewEventUser = new CrewEventUser({
              event: parseCrewEvent,
              user: parseCrewUser,
            });
            RequestService.performSaveQuery(parseCrewEventUser, null, deferred.resolve, deferred.reject);
          } else {
            deferred.resolve();
          }
        },
        deferred.reject
      );
      return deferred.promise;
    };

    this.setChannelId = function (event) {
      var deferred = $q.defer();

      var parseEvent = new CrewEvent({ id: event.objectId });
      parseEvent.set('channelId', event.channelId);
      RequestService.performSaveQuery(parseEvent, null, deferred.resolve, deferred.reject);

      return deferred.promise;
    };

    let compareFlightNumbers = function (title1, title2) {
      var flightNumberRegExp = /\w{2,3}\d{3,4}/i;
      var flightNumber1Res = flightNumberRegExp.exec(title1);
      var flightNumber2Res = flightNumberRegExp.exec(title2);

      if (
        flightNumber1Res &&
        flightNumber2Res &&
        flightNumber1Res.length &&
        flightNumber2Res.length &&
        flightNumber1Res[0] === flightNumber2Res[0]
      ) {
        return { score: 20, message: 'FULL MATCH : Les numéros de vol sont identiques' };
      } else {
        return { score: 0, message: 'NO MATCH : Les numéros de vol sont différents' };
      }
    };

    function compareEventTitle(title1, title2) {
      // 1 - extract number part vs alpha part
      var letters1 = title1.toUpperCase().replace(/[^A-ZÀ-ÿ]/g, '');
      var letters2 = title2.toUpperCase().replace(/[^A-ZÀ-ÿ]/g, '');

      var numbers1 = title1.replace(/[^\d]/g, '');
      var numbers2 = title2.replace(/[^\d]/g, '');

      var stringReturn = compare(letters1, letters2);
      var numberReturn = compare(numbers1, numbers2, true);
      var flightNumberReturn = compareFlightNumbers(title1, title2);

      return {
        stringMatch: stringReturn,
        numberMatch: numberReturn,
        flightNumberMatch: flightNumberReturn,
      };
    }

    //region Pool functions

    this.fetchAllEvents = function () {
      var deferred = $q.defer();

      this.getAll($rootScope.crewEventFilter).then(function (events) {
        $rootScope.$broadcast('crewPoolService-events', events);
        deferred.resolve(events);
      }, deferred.reject);

      return deferred.promise;
    };

    //endregion

    function _getEventsForQuery(query, deferred) {
      RequestService.performFindAllQuery(
        query,
        function (parseCrewEvents) {
          var userEventQuery = new Parse.Query(CrewEventUser);
          userEventQuery.include('user');
          userEventQuery.matchesQuery('event', query);

          var noteEventQuery = new Parse.Query(CrewEventNote);
          noteEventQuery.include('toOCC');
          noteEventQuery.matchesQuery('event', query);
          noteEventQuery.ascending('createdAt');

          RequestService.performFindAllQuery(userEventQuery, function (parseCrewEventUsers) {
            RequestService.performFindAllQuery(
              noteEventQuery,
              function (parseCrewNotes) {
                parseCrewEventUsers = _.uniqBy(
                  _.filter(parseCrewEventUsers, function (eu) {
                    return !!eu.get('user') && !!eu.get('event');
                  }),
                  function (eu) {
                    return eu.get('user').id + eu.get('event').id;
                  }
                );

                var situationQuery = new Parse.Query(CrewSituation);
                situationQuery.containedIn('event', parseCrewEvents);
                situationQuery.containedIn(
                  'user',
                  _.map(parseCrewEventUsers, function (ceu) {
                    return ceu.get('user');
                  })
                );
                situationQuery.descending('createdAt');

                RequestService.performFindAllQuery(
                  situationQuery,
                  function (parseSituations) {
                    CrewUsersService.getUsersFunctionsMap(
                      _.map(parseCrewEventUsers, function (eu) {
                        return eu.get('user');
                      })
                    ).then(function (ufMap) {
                      var groupedEvents = _.groupBy(parseCrewEventUsers, function (ceu) {
                        return ceu.get('event').id;
                      });

                      var events = [];
                      for (var i = 0; i < parseCrewEvents.length; i++) {
                        var parseCrewEvent = parseCrewEvents[i];
                        var eventUsers = groupedEvents[parseCrewEvent.id];
                        var newEvent = ParseMapper.crewEventToObject(
                          parseCrewEvent,
                          eventUsers,
                          _.filter(parseCrewNotes, function (notes) {
                            return notes.get('event').id === parseCrewEvent.id;
                          })
                        );

                        for (var j = 0; j < newEvent.users.length; j++) {
                          var user = newEvent.users[j];
                          user.functions = ufMap[user.userId];
                          user.lastSituation = _.first(
                            _.map(
                              _.filter(parseSituations, function (sit) {
                                return sit.get('event').id === newEvent.objectId && sit.get('user').id === user.objectId;
                              }),
                              function (sit) {
                                return ParseMapper.crewSituationToObject(sit);
                              }
                            )
                          );
                        }
                        events.push(newEvent);
                      }
                      deferred.resolve(events);
                    }, deferred.reject);
                  },
                  deferred.reject
                );
              },
              deferred.reject
            );
          });
        },
        deferred.reject
      );
    }

    function compare(str1, str2, isNumber) {
      if (str1 === str2) {
        if (str1.length) {
          return { score: isNumber ? 10 : 20, message: 'FULL MATCH : Les chaînes sont identiques' };
        } else {
          return { score: isNumber ? 10 : 1, message: 'EMPTY MATCH : Les chaines sont vides' };
        }
      }
      if (isIncluded(str1, str2, 0.4) || isIncluded(str2, str1, 0.4)) {
        return {
          score: isNumber ? 5 : 10,
          message: "CLOSE MATCH : L'une des chaînes est incluse dans l'autre avec un faible écart de taille",
        };
      }
      if (isEqualSorted(str1, str2)) {
        return {
          score: isNumber ? 0 : 8,
          message: 'MEDIUM MATCH : Les chaînes contiennent les mêmes caractères mais dans un ordre différent',
        };
      }
      if (isEqualSortedWithDelta(str1, str2, 0.4) || isEqualSortedWithDelta(str2, str1, 0.4)) {
        return {
          score: isNumber ? 0 : 5,
          message: 'LOW MATCH : Les chaînes sont différentes mais ont peu de caractères différents',
        };
      }
      return { score: 0, message: "NO MATCH : Aucune règle d'identité n'est passée" };
    }

    function isEqualSorted(str1, str2) {
      return str1.split('').sort().join('') === str2.split('').sort().join('');
    }

    function isEqualSortedWithDelta(str1, str2, delta) {
      var sorted1 = str1.split('').sort().join('');
      var sorted2 = str2.split('').sort().join('');
      if (sorted1.length === sorted2.length) {
        var count = 0;
        for (var i = 0; i < sorted1.length; i++) {
          if (sorted1[i] === sorted2[i]) {
            count++;
          }
        }
        if (count + Math.floor(sorted1.length * delta) >= sorted1.length) {
          return true;
        }
      }
      return false;
    }

    function isIncluded(str1, str2, delta) {
      return _.includes(str1, str2) && str2.length + Math.floor(str1.length * delta) >= str1.length;
    }

    function duplicateNotesToOtherModuleLogbook(event, parseNotes) {
      return RolesService.getAll().then(allRoles => {
        const occRoles = allRoles.filter(r => r && r.universe === 'OCC');
        const acl = new Parse.ACL();
        occRoles.forEach(r => {
          acl.setRoleReadAccess(r.name, true);
          if (r.write) {
            acl.setRoleWriteAccess(r.name, true);
          }
        });

        return _.map(parseNotes, function (parseNote) {
          var parseObject = new OccLogbook({
            crewLogbook: parseNote,
            text:
              event.impact +
              ' - ' +
              moment(event.date).format('DD MMM') +
              ' (' +
              that.getNbUserBySituationStatus(event, 'ACCEPTED') +
              '/' +
              event.nbPersons +
              ' ' +
              event.function.code +
              ')',
          });
          parseObject.setACL(acl);
          return parseObject;
        });
      });
    }
  });
