import { OCL_GLOBAL_INSTRUCTION_MODAL_TOKEN } from './../../modals/ocl-global-instruction-modal/ocl-global-instruction-modal.token';
import { MatDialog } from '@angular/material';
import { OclGlobalInstruction } from './../../models/ocl-global-instruction.model';
import { Component, OnInit, Input, Inject } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-ocl-global-instruction-item',
  templateUrl: './ocl-global-instruction-item.component.html',
  styleUrls: ['./ocl-global-instruction-item.component.scss'],
})
export class OclGlobalInstructionItemComponent implements OnInit {
  @Input() isReadOnly: boolean;
  @Input() localHourOnly: boolean;
  @Input() item: OclGlobalInstruction;

  constructor(protected dialog: MatDialog, @Inject(OCL_GLOBAL_INSTRUCTION_MODAL_TOKEN) protected globalInstructionModal) {}

  ngOnInit() {}

  openDetailModal(event, item): void {
    event.stopPropagation();
    this.dialog.open(this.globalInstructionModal, {
      data: {
        globalInstuction: _.cloneDeep(item),
        isReadOnly: this.isReadOnly,
      },
    });
  }
}
