import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OccOptionsService } from '../occ-options-service/occ-options.service';
import { OclHistoryService } from '../../../ocl/services/ocl-history-service/ocl-history.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class OccHistoryService extends OclHistoryService {
  // tslint:disable:variable-name
  protected ParseHistory = Parse.Object.extend('OCCLogs');
  protected ParseEvents = Parse.Object.extend('OCCEvents');
  protected ParseDecisions = Parse.Object.extend('OCCDecisions');
  protected ParseLogbooks = Parse.Object.extend('OCCLogBook');
  // tslint:enable

  public constructor(
    protected requestService: RequestService,
    @Inject('$translate') protected $translate,
    @Inject('$rootScope') protected $rootScope,
    protected optionsService: OccOptionsService,
    protected parseMapper: ParseMapperService
  ) {
    super(requestService, $translate, $rootScope, optionsService, parseMapper);
  }
}
