import { Inject, Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionsStoreManager } from 'src/app/ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { OccGlobalInstructionTagService } from '../occ-global-instruction-tag-service/occ-global-instruction-tag.service';
import { OccModuleConfigService } from '../occ-module-config/occ-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class OccGlobalInstructionService extends OclGlobalInstructionService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('OCCGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('OCCGlobalInstruction');
  protected ParseTag = Parse.Object.extend('OCCTag');
  // tslint:enable
  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    @Inject('UserService') protected userService,
    protected globalInstructionTagService: OccGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    public moduleConfig: OccModuleConfigService
  ) {
    super($rootScope, requestService, userService, globalInstructionTagService, globalInstructionsStoreManager, moduleConfig);
  }
}
