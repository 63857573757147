import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolTag } from '../models/hol-tag';

@Injectable({
  providedIn: 'root',
})
export abstract class TagsService {
  // tslint:disable-next-line:variable-name
  protected abstract ParseTag;

  private _tags: HolTag[];

  public constructor(private requestService: RequestService) {}

  public getAll(force?: boolean): Promise<HolTag[]> {
    if (this._tags && !force) {
      return Promise.resolve(this._tags);
    } else {
      const query = new Parse.Query(this.ParseTag);
      query.ascending('name');
      return this.requestService.performFindQuery(query).then(parseTags => {
        this._tags = parseTags.map(parseTag => new HolTag(parseTag));
        return this._tags;
      });
    }
  }

  public create(color: string, name: string): Promise<any> {
    const dataToCreate = new this.ParseTag({ color, name });
    return this.requestService.performSaveQuery(dataToCreate);
  }
}
