import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MatButtonToggleGroup } from '@angular/material';

import { difference, remove, uniq } from 'lodash';

import { FltApplicability } from '../../../flt/models/flt-applicability';
import { HolObject } from '../../models/hol-object';
import { HolRole } from '../../models/hol-role';
import { HelperService } from '../../services/helper.service';
import { RolesService } from '../../services/roles.service';

class AclSelectorRole {
  initialWriteRoles: HolRole[];
  selected?: boolean;
  company: string;
  color: string;
  writeRoles: HolRole[];
  readRoles: HolRole[];
  userWriteRoles: HolRole[];
  userReadRoles: HolRole[];
  disabledFromLimit: boolean;
  constructor(props) {
    if (props) {
      this.company = props.company;
      this.writeRoles = props.writeRoles;
      this.readRoles = props.readRoles;
      this.userWriteRoles = props.userWriteRoles;
      this.userReadRoles = props.userReadRoles;
      this.color = props.color;
    }
    this.disabledFromLimit = false;
    this.selected = false;
  }
}

@Component({
  selector: 'app-acl-selector',
  templateUrl: './acl-selector.component.html',
  styleUrls: ['./acl-selector.component.scss'],
})
export class AclSelectorComponent implements OnInit, OnChanges {
  roles: AclSelectorRole[] = [];
  @Input() object: HolObject;
  @Input() formGroup: FormGroup;
  @Input() moduleName = '';
  @Input() readOnly;
  @Input() applicability?: FltApplicability;
  @Input() alwaysOneSelected?: boolean;
  @Output()
  public updateACLFromHand = new EventEmitter();
  @ViewChild('group') btnGroup: MatButtonToggleGroup;
  private aclControl;
  private matchApplicabilityRoles;
  @Input() extendACLOnly = false;

  private _limitTo: Parse.ACL;
  enabledRoles: AclSelectorRole[] = [];
  @Input()
  get limitTo(): Parse.ACL {
    return this._limitTo;
  }
  set limitTo(value) {
    this.limitACL(value);
    this._limitTo = value;
  }

  constructor(private rolesService: RolesService) { }

  async ngOnInit() {
    if (!this.object) {
      throw new Error('Object is required for AclSelector');
    }
    this.aclControl = new FormControl(this.object.acl, Validators.required);
    if (this.formGroup) {
      this.formGroup.addControl('acl', this.aclControl);
    }

    this.roles = (await this.rolesService.getUserCompanyRolesByUniverse(this.moduleName.toUpperCase())).map(r => new AclSelectorRole(r));
    this.limitACL(this.limitTo);
    setTimeout(() => {
      if (this.object.acl && Object.keys(this.object.acl.permissionsById).length && !this.object.acl.getPublicWriteAccess()) {
        this.checkFromACL();
      } else {
        this.checkDefaultRole();
      }
      this.updateACL();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const changedApplicability = changes.applicability;
    if (changedApplicability && !changedApplicability.firstChange) {
      const newApplicability: FltApplicability = changedApplicability.currentValue;
      if (
        (this.matchApplicabilityRoles && !newApplicability.flights) ||
        (newApplicability.flights && newApplicability.flights.length >= 1)
      ) {
        const newSelectedRoles = this.btnGroup.value;
        const flightsACL: Parse.ACL[] = newApplicability.flights ? uniq(newApplicability.flights.map(f => f.acl)) : null;
        const newMatchApplicabilityRoles = [];
        if (flightsACL) {
          flightsACL.forEach(acl => {
            if (acl) {
              this.roles.filter(r => {
                if (this.matchAcl(r, acl)) {
                  newMatchApplicabilityRoles.push(r);
                }
              });
            }
          });
        }

        const applicabilityRolesToRemove = difference(this.matchApplicabilityRoles, newMatchApplicabilityRoles);
        if (applicabilityRolesToRemove.length) {
          remove(newSelectedRoles, r => {
            return applicabilityRolesToRemove.includes(r);
          });
        }

        const applicabilityRolesToAdd = difference(newMatchApplicabilityRoles, this.matchApplicabilityRoles);
        if (applicabilityRolesToAdd.length) {
          applicabilityRolesToAdd.forEach(r => {
            newSelectedRoles.push(r);
          });
        }

        this.matchApplicabilityRoles = newMatchApplicabilityRoles;
        this.btnGroup.value = newSelectedRoles;
        this.updateACL();
      }
    }
  }

  updateACL(fromButton = false) {
    if (this.readOnly) {
      return;
    }
    const selectedRoles = this.btnGroup.value;
    const acl = new Parse.ACL();
    if (!selectedRoles || !selectedRoles.length) {
      this.aclControl.setValue(null);
    } else {
      selectedRoles.forEach(r => {
        if (r.initialWriteRoles) {
          r.initialWriteRoles.forEach(wr => {
            acl.setRoleWriteAccess(wr.parseRole, true);
          });
        }
        r.userWriteRoles.forEach(wr => {
          acl.setRoleWriteAccess(wr.parseRole, true);
        });
        r.writeRoles.forEach(wr => {
          acl.setRoleReadAccess(wr.parseRole, true);
        });
        r.readRoles.forEach(rr => {
          acl.setRoleReadAccess(rr.parseRole, true);
        });
      });
      this.aclControl.setValue(acl);
    }
    this.object.acl = acl;
    if (fromButton) {
      this.updateACLFromHand.emit(true);
    }
  }

  private checkFromACL() {
    this.roles.forEach(r => {
      r.initialWriteRoles = r.writeRoles.filter(uwr => this.object.acl.getRoleWriteAccess(uwr.parseRole));
    });
    this.btnGroup.value = this.roles.filter(r => {
      return !!r.writeRoles.find(uwr => this.object.acl.getRoleWriteAccess(uwr.parseRole));
    });
  }

  private checkDefaultRole() {
    const rolesEnabled = this.roles.filter(r => r.userWriteRoles.length);
    if (rolesEnabled && rolesEnabled.length === 1) {
      this.btnGroup.value = rolesEnabled;
    }
  }

  mixColors(color1, color2, weight) {
    return HelperService.mixColors(color1, color2, weight);
  }

  isSelected(role: AclSelectorRole) {
    return this.btnGroup.value && this.btnGroup.value.indexOf(role) > -1;
  }

  private limitACL(value: Parse.ACL) {
    this.roles.forEach(r => {
      r.disabledFromLimit = !this.matchAcl(r, value);
    });
    this.enabledRoles = this.roles.filter(r => r.userWriteRoles.length && !r.disabledFromLimit);
    if (this.btnGroup.value && this.btnGroup.value.length) {
      this.btnGroup.value = this.btnGroup.value.filter(r => !r.disabledFromLimit);
      this.updateACL();
    }
  }

  private matchAcl(r: AclSelectorRole, value: Parse.ACL): boolean {
    if (!value || value.getPublicWriteAccess()) {
      return true;
    }
    for (const writeRole of r.writeRoles) {
      if (value.getRoleWriteAccess(writeRole.parseRole)) {
        return true;
      }
    }

    return false;
  }

  toggleSelectAll() {
    if (this.enabledRoles.length === this.btnGroup.value.length) {
      this.btnGroup.value = [];
    } else {
      this.btnGroup.value = this.enabledRoles;
    }
    this.updateACL();
  }
}
