import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclHistoryService } from '../../../ocl/services/ocl-history-service/ocl-history.service';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class EclHistoryService extends OclHistoryService {
  // tslint:disable:variable-name
  protected ParseHistory = Parse.Object.extend('ECLLogs');
  protected ParseEvents = Parse.Object.extend('ECLEvents');
  protected ParseDecisions = Parse.Object.extend('ECLDecisions');
  protected ParseLogbooks = Parse.Object.extend('ECLLogBook');
  // tslint:enable

  public constructor(
    protected requestService: RequestService,
    @Inject('$translate') protected $translate,
    @Inject('$rootScope') protected $rootScope,
    protected optionsService: EclOptionsService,
    protected parseMapper: ParseMapperService
  ) {
    super(requestService, $translate, $rootScope, optionsService, parseMapper);
  }
}
