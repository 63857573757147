<div class="next-info-container" *ngIf="nextInfo.nextInfoTime">
  <div [ngClass]="{ 'hol-success': nextInfo.nextInfoDone }" class="col-done">
    <button
      class="next-info-icon"
      [ngClass]="{ success: nextInfo.nextInfoDone }"
      (click)="markAsDone($event)"
      aria-label="mark as done"
      [disabled]="isReadOnly || !canUpdate"
      type="button"
    >
      <span class="hol-icon-done"></span>
    </button>
  </div>
  <div
    [ngClass]="{
      'hol-warn': isElapsed(nextInfo.nextInfoTime),
      'hol-accent': isAlmostElapsed(nextInfo.nextInfoTime),
      'hol-success': nextInfo.nextInfoDone
    }"
    class="next-info"
  >
    <span>{{ nextInfo.nextInfoTime | time: isUtc }}</span>
  </div>
</div>
