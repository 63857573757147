'use strict';

/**
 * @ngdoc service
 * @name gestiondecriseApp.constants
 * @description
 * # constants
 * Constant in the gestiondecriseApp.
 */
angular.module('gestiondecriseApp').constant('CONSTANTS', {
  ANIM_TIME: 400,
  HIGHLIGHT_TIME: 800,
  POOL_ENABLED: true,
  POOL_DELAY: 11000,

  COMPANY_NAME: 'DEV',
  CRISIS_SUFFIX: ' Crisis',
  OCC_SUFFIX: ' OCC',
  CREW_SUFFIX: ' Crew',
  MCC_SUFFIX: ' MCC',
  GOC_SUFFIX: ' GOC',

  STATE_CREATED: 'CREATED',
  STATE_DONE: 'DONE',
  STATE_TODO: 'TODO',
  STATE_FROZEN: 'FROZEN',
  STATE_NOT_APPLICABLE: 'NOT_APPLICABLE',

  DUMMY_TASK_CODE: '---',

  LOG_TYPE_TASK: 'TASK',
  LOG_TYPE_INFO: 'INFO',

  DEFAULT_PASSWORD: '123456',

  TIME_BEFORE_WARNING: 15,
  TIME_TO_SEE: 60, // minutes
});
