import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HolUserRights } from '../../../enums/hol-user-rights.enum';
import * as _ from 'lodash';
import { HolFlight } from '../../../models/hol-flight.model';
import { OpsOptionsService } from '../../../../ops/services/ops-options-service/ops-options.service';
import { FltFlightService } from '../../../../flt/services/flt-flight.service';

@Component({
  selector: 'app-indicator-status',
  templateUrl: './indicator-status.component.html',
  styleUrls: ['./indicator-status.component.scss'],
})
export class IndicatorStatusComponent implements OnInit, OnChanges {
  @Input() flight: HolFlight;
  @Input() accessRights;
  @Input() mode;
  universeRights: { universe: string; right: HolUserRights }[];

  constructor(private opsOptionsService: OpsOptionsService) {}

  ngOnInit(): void {
    this.accessRights = _.cloneDeep(this.accessRights);
    // remove access rights without indicators
    delete this.accessRights.crisis;
    delete this.accessRights.ecl;

    this.opsOptionsService
      .get(false)
      .then(options => {
        if (!options.moduleIsActivated) {
          delete this.accessRights.ops;
        }
      })
      .finally(() => {
        this.universeRights = this.createUniverseRights(this.accessRights);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flight && changes.flight.currentValue) {
      if (FltFlightService.hasTakenOff(this.flight.status)) {
        this.setAllToReadonly();
      } else {
        this.universeRights = this.createUniverseRights(this.accessRights);
      }
    }
  }

  setAllToReadonly() {
    this.universeRights = _.map(this.universeRights, universeRight => {
      universeRight.right = HolUserRights.READ;
      return universeRight;
    });
  }

  createUniverseRights(accessRights): { universe: string; right: HolUserRights }[] {
    let universeRights = Object.keys(accessRights).map(key => {
      if (FltFlightService.hasTakenOff(this.flight.status)) {
        return { universe: key, right: HolUserRights.READ };
      } else {
        // if occ write access, can edit any indicator
        return accessRights.occ === HolUserRights.WRITE
          ? { universe: key, right: HolUserRights.WRITE }
          : {
              universe: key,
              right: this.accessRightStringToUserRights(accessRights[key]),
            };
      }
    });
    if (this.mode === 'OPS') {
      universeRights = universeRights.filter(el => el.universe.toLowerCase() === 'ops');
    }
    if (this.mode === 'GOC') {
      universeRights = universeRights.filter(el => el.universe.toLowerCase() === 'goc');
    }
    return universeRights;
  }

  accessRightStringToUserRights(accessRightString: string): HolUserRights {
    switch (accessRightString) {
      case 'WRITE':
        return HolUserRights.WRITE;
      case 'READ':
        return HolUserRights.READ;
      default:
        return HolUserRights.UNAUTHORIZED;
    }
  }
}
