'use strict';

angular
  .module('gestiondecriseApp')
  .controller('MarkdownModalCtrl', function ($scope, title, $mdDialog, $timeout, note, toOccEnabled, MarkdownService, FilesService) {
    $timeout(function () {
      $timeout(function () {
        $('.modal-markdown-editor textarea').focus().val($scope.note);
      });
    });

    $scope.title = title;
    $scope.note = note || '';
    $scope.toOccEnabled = toOccEnabled ? toOccEnabled : false;
    $scope.toOCC = false;
    $scope.toOCC = {
      checkboxValue: false,
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.validateNote = function () {
      if ($scope.toOccEnabled) {
        const nameFile = `note-crew-eve-${moment().utc().format('DD-MM-YYYY')}.html`;
        const context = {
          module: 'CREW',
          category: 'EVENT',
          htmlTemplate: 'A',
        };

        const htmlContent = MarkdownService.parseMdToHtml($scope.note);
        var htmlTemplate = MarkdownService.createHtmlContent(htmlContent, context);

        setTimeout(() => {
          const blob = new Blob([htmlTemplate], { type: 'text/html' });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            var base64pdf = reader.result;
            $scope.sendHtlmToDatabase(base64pdf, nameFile);
          };
        }, 10);
      } else {
        $mdDialog.hide($scope.note);
      }
    };

    $scope.sendHtlmToDatabase = function (data, nameFile) {
      FilesService.uploadFile(nameFile, { base64: data }).then(
        url => {
          var noteAttachments = {
            note: $scope.note,
            noteFile: {
              url: url,
              fileName: nameFile,
            },
          };
          $mdDialog.hide({ text: $scope.note, attachments: noteAttachments, toOCC: $scope.toOCC.checkboxValue });
        },
        err => {
          console.error(err);
        }
      );
    };
  });
