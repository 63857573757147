import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { take } from 'rxjs/operators';
import { HolFunction } from './../../../common/models/hol-function';
import { HolVacation, VACATION_LOG_STATUS } from 'src/app/common/models/hol-vacation.model';
import { Component, Inject, OnInit } from '@angular/core';
import { HolManagersService } from '../../../common/services/hol-managers.service';
import { VacationService } from '../../../common/services/vacation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { cloneDeep, join } from 'lodash';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { HolManagerFunction } from '../../../common/store/hol-managers.selectors';
import * as moment from 'moment';
import { ArchiveService } from '../../../common/services/archive.service';

@Component({
  selector: 'app-ocl-managers-modal',
  templateUrl: './ocl-managers-modal.component.html',
  styleUrls: ['./ocl-managers-modal.component.scss'],
})
export class OclManagersModalComponent implements OnInit {
  managerFunction: HolManagerFunction;
  holFunctions: HolFunction[];
  loading = false;

  constructor(
    private managersService: HolManagersService,
    private vacationService: VacationService,
    @Inject('MailSenderService') private mailSenderService,
    @Inject('$translate') private $translate,
    @Inject(MAT_DIALOG_DATA) private data: { managerFunction: HolManagerFunction; holFunctions: HolFunction[] },
    public dialogRef: MatDialogRef<OclManagersModalComponent>,
    public moduleConfig: ModuleConfigService,
    @Inject('UserService') private userService,
    private commonStoreManager: CommonStoreManager,
    private archiveService: ArchiveService
  ) {
    this.managerFunction = cloneDeep(data.managerFunction);
    this.holFunctions = cloneDeep(data.holFunctions);
  }

  ngOnInit() {}

  validate() {
    this.loading = true;
    const prevManagerFunction: HolManagerFunction = this.data.managerFunction;
    return Promise.all([
      this.managersService.updateManagerFunction(this.managerFunction, prevManagerFunction),
      this.userService.getAllUsers(),
    ])
      .then(([holUserFunctions, allUsers]) => {
        if (!holUserFunctions || !allUsers) {
          this.dialogRef.close();
        }
        if (holUserFunctions && holUserFunctions.length) {
          holUserFunctions.forEach(holUserFunction => {
            if (holUserFunction.isHolder) {
              const user = allUsers.find(u => u.userId === holUserFunction.userId);
              const func = this.holFunctions.find(f => f.functionId === holUserFunction.functionId);
              this.vacationService.closePreviousVacations(holUserFunction, func).then(() => {
                this.vacationService.getVacationREFByFunction(holUserFunction.functionId).then(vacationsByFunction => {
                  if (vacationsByFunction) {
                    this.vacationService
                      .createVacation(vacationsByFunction, holUserFunction, user, func, this.moduleConfig.config.moduleName)
                      .finally(() => {
                        this.managerFunction.companies.forEach((company, i) => {
                          const prevCompany = prevManagerFunction.companies[i];
                          if (
                            company.holder &&
                            (!prevCompany.holder ||
                              company.holder.objectId !== prevCompany.holder.objectId ||
                              !moment(company.holder.expiredAtNextInfo.nextInfoTime).isSame(
                                prevCompany.holder.expiredAtNextInfo.nextInfoTime
                              ))
                          ) {
                            const recipients = [];
                            if (this.managerFunction.email) {
                              recipients.push({ email: this.managerFunction.email });
                            }
                            if (company.holder.user && company.holder.user.email) {
                              recipients.push({ email: company.holder.user.email });
                            }
                            this.mailSenderService.sendMail(
                              {
                                recipients,
                                subject: this.$translate.instant('MAIL.VACATION.SUBJECT', {
                                  function: this.managerFunction.title,
                                  user: company.holder.user.fullName,
                                  company: company.name,
                                }),
                                contentHtml: join(
                                  [
                                    this.$translate.instant('MAIL.VACATION.BODY', {
                                      function: this.managerFunction.title,
                                      user: company.holder.user.fullName,
                                      company: company.name,
                                    }),
                                  ],
                                  '<br/><br/>'
                                ),
                              },
                              true,
                              true
                            );
                          }
                        });
                        this.loading = false;
                        this.dialogRef.close();
                      });
                  }
                });
              });
            }
            this.dialogRef.close();
          });
        }
        this.dialogRef.close();
      })
      .catch(error => {
        console.error(error);
      });
  }
}
