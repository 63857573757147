import { HolisFile } from 'src/app/common/models/hol-attachments.model';
import { FileUploadAttachmentModalComponent } from './../file-upload-attachment-modal/file-upload-attachment-modal.component';
import { MatDialog } from '@angular/material';
import { HelperService } from './../../services/helper.service';
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  @Input()
  public accept;
  @Input()
  public attachment;
  @Input()
  public column;
  @Input()
  public disabled;
  @Input()
  public icon;
  @Input()
  public label;
  @Input()
  public hideDeleteButton;
  @Output()
  public onFileRemoved = new EventEmitter<any>();
  @Output()
  public onUploadSuccess = new EventEmitter<any>();
  @ViewChild('inputUpload') inputFileRef: ElementRef;
  public uploading = false;

  public fileName = null;

  private file = null;
  private url = null;

  constructor(@Inject('FilesService') private filesService, private helperService: HelperService, public dialog: MatDialog) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.attachment && changes.attachment.currentValue) {
      this.fileName = changes.attachment.currentValue.fileName;
      this.url = changes.attachment.currentValue.url;
    }
  }

  public openFileModal(): void {
    const dialogRef = this.dialog.open(FileUploadAttachmentModalComponent, {
      data: { attachment: this.attachment || new HolisFile(), type: 'file' },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.attachment = result;
        this.onFileInputChange(result);
      }
    });
  }

  public onFileInputChange($event): void {
    if ($event.target.files.length > 0) {
      this.file = $event.target.files[0];
      this.fileName = this.helperService.removeDiacritics(this.file.name);
      this.uploading = true;

      this.filesService.uploadFile(this.fileName, this.file).then(
        url => {
          this.onUploadSuccess.emit({
            uploadedUrl: url,
            fileName: this.fileName,
          });
          this.inputFileRef.nativeElement.value = null;
          this.uploading = false;
        },
        err => {
          console.error(err);
          this.uploading = false;
        }
      );
    }
  }

  public selectPdf(): void {
    this.inputFileRef.nativeElement.click();
  }
}
