import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { clone } from 'lodash';
import { AuthenticationService } from '../../../login/services/authentication.service';
import { CommonStoreManager } from '../../store/common.store-manager';
import { Subscription } from 'rxjs';
import { HolCrisis } from '../../models/hol-crisis';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() context: string;
  @Input() canAddBreakingNews: boolean;
  @Input() indicators = false;
  breakingNews: any[];
  username = '';
  showIndicators: boolean;
  accessRights: any;
  showChat: boolean;
  private breakingNewsEditedListener;
  private poolServiceBreakingNewsListener;
  private crisisSub: Subscription;
  private crisis: HolCrisis;

  constructor(
    @Inject('$rootScope') public $rootScope,
    @Inject('BreakingNewsService') private breakingNewsService,
    @Inject('$translate') private $translate,
    @Inject('UserService') private userService,
    @Inject('$mdDialog') private $mdDialog,
    private authenticationService: AuthenticationService,
    private commonStoreManager: CommonStoreManager
  ) {
    this.username = userService.getCurrentUser();
    this.accessRights = $rootScope.accessRights;
  }

  ngOnInit() {
    this.refreshBreakingNews();
    this.breakingNewsEditedListener = this.$rootScope.$on('breakingNewsEdited', () => this.refreshBreakingNews());
    this.poolServiceBreakingNewsListener = this.$rootScope.$on('poolService-breaking-news', (ev, data) => {
      this.breakingNews = clone(data);
    });
    if (this.context !== 'crisis' && this.context !== 'mcc' && this.context !== 'goc') {
      this.userService.isCurrentUserInTheChatTeam().then(isInTeam => {
        this.showChat = isInTeam;
      });
    }
    this.crisisSub = this.commonStoreManager.crisis.subscribe(crisis => {
      this.crisis = crisis;
    });
  }

  toggleSideNav() {
    this.$rootScope.isSideNavOpen = !this.$rootScope.isSideNavOpen;
  }

  refreshBreakingNews() {
    this.breakingNewsService.getAllBreakingNews(this.context).then(data => {
      this.breakingNews = clone(data);
    });
  }

  ngOnDestroy(): void {
    this.breakingNewsEditedListener();
    this.poolServiceBreakingNewsListener();
    this.crisisSub.unsubscribe();
  }

  logout(ev) {
    const confirmOptions = {
      title: this.$translate.instant('SETTINGS.CONFIRM_LOGOUT'),
      targetEvent: ev,
      htmlContent: '',
    };
    this.userService
      .getCurrentUserFunctions()
      .then(functions => {
        functions = functions.filter(f => f.isHolder);
        if (functions.length > 0) {
          const functionsStr = functions.map(f => f.shortTitle).join(', ');
          confirmOptions.htmlContent = this.$translate.instant('SETTINGS.LOGOUT_WARNING_HOLDER', { functions: functionsStr });
        }
      })
      .finally(() => {
        this.$mdDialog.show(this.$mdDialog.holConfirm(confirmOptions)).then(res => {
          if (res) {
            this.authenticationService.logout(this.crisis && this.crisis.inProgress).then();
          }
        });
      });
  }

  addBreakingNews($event: MouseEvent) {
    this.$mdDialog.show({
      templateUrl: 'views/breakingNews/new-breaking-news-modal.html',
      controller: 'BreakingNewsModalCtrl',
      parent: document.body,
      targetEvent: $event,
      clickOutsideToClose: true,
      locals: {
        context: this.context,
      },
    });
  }

  toggleIndicatorPanel() {
    this.showIndicators = !this.showIndicators;
  }
}
