import { MarkdownService } from './../../../common/components/markdown-editor/markdown.service';
import { HolAttachments, HolisFile } from './../../../common/models/hol-attachments.model';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { Inject, Injectable } from '@angular/core';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclOptionsService } from '../ocl-options-service/ocl-options.service';
import { OclDecisionTagService } from '../ocl-decision-tag-service/ocl-decision-tag.service';
import { OclHistoryService } from '../ocl-history-service/ocl-history.service';
import { OclDecisionsStoreManager } from '../../store/decisions/ocl-decisions.store-manager';
import { OclMailService } from '../ocl-mail-service/ocl-mail.service';
import { OclSmsService } from '../ocl-sms-service/ocl-sms.service';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { HolTag } from '../../../common/models/hol-tag';
import { HolContext } from '../../../common/models/hol-context.model';
import { OclHistoryLog } from '../../models/ocl-history-log.model';
import { isEqual } from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export abstract class OclDecisionService<T extends OclDecision = OclDecision, U extends HolContext = HolContext> {
  // tslint:disable:variable-name
  protected ParseDecision;
  protected ParseEvent;
  protected ParseDecisionTag;
  protected ParseTag;
  protected ParseFlight = Parse.Object.extend('GOCFlight');
  protected ParseErpDecision = Parse.Object.extend('GDCDecisions');
  // tslint:enabled
  protected _decisions: T[]; // @cache
  protected loadMoreCount = 1;
  public dateTo: moment.Moment;
  public totalDecisions: number;

  protected constructor(
    @Inject('$filter') protected $filter,
    @Inject('FilesService') protected filesService,
    @Inject('$translate') protected $translate,
    protected decisionsStoreManager: OclDecisionsStoreManager,
    protected parseMapper: ParseMapperService,
    protected requestService: RequestService,
    protected optionsService: OclOptionsService,
    protected notificationsService: NotificationsService,
    protected smsService: OclSmsService,
    protected mailService: OclMailService,
    protected historyService: OclHistoryService,
    protected decisionTagService: OclDecisionTagService,
    protected moduleConfig: ModuleConfigService,
    protected markdownService: MarkdownService,
    protected commonStoreManager: CommonStoreManager
  ) {}

  private getLogMessage(statusDecision) {
    if (!statusDecision) {
      return;
    }

    if (statusDecision.nextInfoTime) {
      const filteredDate = this.$filter('date')(statusDecision.nextInfoTime, 'EEE dd MMM');
      return statusDecision.message + '<br/> Next info time: ' + filteredDate;
    }

    return statusDecision.message;
  }

  public getAll(forceToRefresh, loadMore: boolean = false, isFromPooling?, isPrivate?, filterDataStartDate?: Date): Promise<T[]> {
    if (loadMore) {
      this.loadMoreCount = this.loadMoreCount + 1;
    } else if (!isFromPooling) {
      this.loadMoreCount = 1;
    }
    if (this._decisions !== undefined && this._decisions.length && !forceToRefresh) {
      return Promise.resolve(this._decisions);
    } else {
      const decisionsToDisplay = this.optionsService.getDecisionsToDisplay();
      const query = new Parse.Query(this.ParseDecision);

      let today: Date;
      if (this.moduleConfig.config.canChooseDataStartDate) {
        if (filterDataStartDate instanceof Date && filterDataStartDate !== undefined) {
          today = filterDataStartDate;
        } else {
          today = new Date();
        }
      }

      if (decisionsToDisplay) {
        if (this.moduleConfig.config.canChooseDataStartDate && filterDataStartDate instanceof Date && filterDataStartDate !== undefined) {
          const dateFrom = moment.utc(filterDataStartDate).endOf('day');
          const dateTo = moment.utc(filterDataStartDate).subtract(decisionsToDisplay, 'hours');
          this.dateTo = dateTo;
          query.lessThanOrEqualTo('createdAt', dateFrom.toDate());
          query.greaterThanOrEqualTo('createdAt', dateTo.toDate());
        } else {
          const dateFrom = isFromPooling ? moment.utc() : moment.utc().subtract(decisionsToDisplay * (this.loadMoreCount - 1), 'hours');
          const dateTo = moment.utc().subtract(decisionsToDisplay * this.loadMoreCount, 'hours');
          this.dateTo = dateTo;
          query.lessThanOrEqualTo('createdAt', dateFrom.toDate());
          query.greaterThanOrEqualTo('createdAt', dateTo.toDate());
        }
      }

      const queryPinned = new Parse.Query(this.ParseDecision);
      queryPinned.descending('createdAt');
      queryPinned.equalTo('isPinned', true);

      const decisionQuery = this.getAdditionnalQueries(query, queryPinned, today);

      decisionQuery.descending('createdAt');

      if (isPrivate) {
        decisionQuery.notEqualTo('isPrivate', true);
      }
      decisionQuery.include('flight');
      decisionQuery.include('createdBy');
      decisionQuery.include('applFlights');
      decisionQuery.include('event.scenario');
      decisionQuery.include('erpDecision');
      decisionQuery.doesNotExist('isFromFlight');

      const totalQuery = new Parse.Query(this.ParseDecision);
      return Promise.all([this.requestService.performFindQuery(decisionQuery), this.requestService.performCountQuery(totalQuery)]).then(
        ([results, totalCount]) => {
          this.totalDecisions = totalCount;
          const decisionTagQuery = new Parse.Query(this.ParseDecisionTag);
          decisionTagQuery.include('tag');
          decisionTagQuery.descending('createdAt');
          // decisionTagQuery.matchesQuery('decision', decisionQuery);
          decisionTagQuery.containedIn('decision', results);

          return this.requestService.performFindQuery(decisionTagQuery).then(decisionTags => {
            const decisions = [];
            for (const decision of results) {
              const tags = this.getTagsForDecision(decisionTags, decision);
              decisions.push(this.newDecision(decision, tags));
            }

            this._decisions = decisions;
            return decisions;
          });
        }
      );
    }
  }

  protected getTagsForDecision(decisionsTags: Parse.Object[], decision: Parse.Object): Parse.Object[] {
    return decisionsTags.filter(dt => {
      return dt.get('decision').id === decision.id;
    });
  }

  public create(decision: T, notifications: HolNotification[], context?: U, duplicateToOtherModule?: boolean): Promise<T> {
    let addBreakLinesBefore = false;
    if (decision.attachments && decision.attachments.note) {
      addBreakLinesBefore = true;
    }

    const currentUser = Parse.User.current();

    const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
    const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);

    const parseDecision = new this.ParseDecision();
    parseDecision.setACL(decision.acl);
    parseDecision.set('message', decision.message);
    parseDecision.set('nextInfoTime', decision.nextInfoTime);
    parseDecision.set('isPinned', decision.isPinned);
    parseDecision.set('isTodo', decision.isTodo ? decision.isTodo : false);
    if (decision.attachments) {
      parseDecision.set('attachments', JSON.stringify(decision.attachments));
    }
    if (decision.event && decision.event.objectId !== null) {
      parseDecision.set(
        'event',
        new this.ParseEvent({
          id: decision.event.objectId,
        })
      );
    }
    if (decision.flight && decision.flight.objectId !== null) {
      parseDecision.set(
        'flight',
        new this.ParseFlight({
          id: decision.flight.objectId,
        })
      );
    }
    if (decision.toERP) {
      parseDecision.set('toERP', decision.toERP);
    }
    parseDecision.set('createdBy', currentUser);
    this.setAdditionalFields(decision, parseDecision);

    return this.requestService.performSaveQuery(parseDecision).then(async savedDecision => {
      let parseTags;
      if (decision.tags) {
        const decisionTags = decision.tags.map(tag => {
          return new this.ParseDecisionTag({
            decision: savedDecision,
            tag: new this.ParseTag({ id: tag && tag.objectId }),
          });
        });
        parseTags = await this.requestService.performSaveAllQuery(decisionTags);
      }

      const newStatusDecisions = this.newDecision(savedDecision, parseTags);

      if (decision.isTodo) {
        return this.nextInfoHistoryInNotes(newStatusDecisions, context, true, true, false, addBreakLinesBefore).then(updatedAttachments => {
          decision.attachments = updatedAttachments;
          const parseDecision = new this.ParseDecision();
          parseDecision.id = newStatusDecisions.objectId;
          parseDecision.set('attachments', JSON.stringify(updatedAttachments));
          return this.requestService.performSaveQuery(parseDecision).then(updatedDecision => {
            const newUpdatedDecision = this.newDecision(updatedDecision, updatedDecision.tags);
            return this.afterSave(
              decision,
              newUpdatedDecision,
              updatedDecision,
              addressMailToSend,
              phoneNumbersToSend,
              duplicateToOtherModule
            );
          });
        });
      }

      return this.afterSave(decision, newStatusDecisions, savedDecision, addressMailToSend, phoneNumbersToSend, duplicateToOtherModule);
    });
  }

  public afterSave(
    decision: T,
    newDecision: T,
    parseDecision: Parse.Object,
    addressMailToSend: string[],
    phoneNumbersToSend: string[],
    duplicateToOtherModule?: boolean
  ) {
    if (addressMailToSend.length) {
      this.mailService.sendNewDecisionMail(newDecision, addressMailToSend);
    }
    if (phoneNumbersToSend.length) {
      this.smsService.sendNewDecisionSMS(newDecision, phoneNumbersToSend);
    }

    if (duplicateToOtherModule) {
      this.duplicateDecisionToOtherModule(newDecision);
    }

    this.historyService
      .postLog(
        OclHistoryLog.create(
          this.getLogMessage({
            message: decision.message,
            nextInfoTime: decision.nextInfoTime,
          }),
          'decision',
          'create',
          decision.attachments,
          newDecision,
          parseDecision.getACL(),
          parseDecision
        )
      )
      .then();
    // STORE
    this.decisionsStoreManager.addOneDecision(newDecision);
    return newDecision;
  }

  public update(decision: T, context: U, oldDuplicateToOtherModuleValue?: boolean, newDuplicateToOtherModuleValue?: boolean): Promise<T> {
    const oldDecision = new OclDecision(new this.ParseDecision({ id: decision.objectId }));
    const oldNIvalue = oldDecision.nextInfoTime;
    const oldNIdone = oldDecision.done;

    const parseDecision = new this.ParseDecision();
    parseDecision.id = decision.objectId;
    parseDecision.setACL(decision.acl);
    parseDecision.set('attachments', JSON.stringify(decision.attachments));
    parseDecision.set('isPinned', decision.isPinned ? decision.isPinned : false);
    parseDecision.set('isTodo', decision.isTodo ? decision.isTodo : false);
    parseDecision.set('nextInfoTime', decision.isTodo ? decision.nextInfoTime : undefined);
    parseDecision.set('done', decision.isTodo ? decision.done : undefined);

    this.setAdditionalFields(decision, parseDecision);
    if (decision.event && decision.event.objectId !== null) {
      parseDecision.set(
        'event',
        new this.ParseEvent({
          id: decision.event.objectId,
        })
      );
    } else {
      parseDecision.set('event', null);
    }
    if (decision.flight && decision.flight.objectId !== null) {
      parseDecision.set(
        'flight',
        new this.ParseFlight({
          id: decision.flight.objectId,
        })
      );
    } else {
      parseDecision.set('flight', null);
    }
    if (decision.message) {
      parseDecision.set('message', decision.message);
    }
    if (decision.customCreatedAt) {
      parseDecision.set('customCreatedAt', decision.customCreatedAt);
    }
    parseDecision.set('toERP', decision.toERP);

    return this.requestService.performSaveQuery(parseDecision).then(res => {
      return this.decisionTagService.updateTags(decision).then(async newTags => {
        const newDecision = this.newDecision(res, newTags);

        if (
          decision.isTodo &&
          decision.nextInfoTime &&
          (!moment(oldNIvalue).isSame(decision.nextInfoTime) || oldNIdone !== decision.done)
        ) {
          let isTodoChangeDate = false;
          let isDoneCheck = false;
          if (oldNIdone !== decision.done && !!decision.done) {
            isDoneCheck = true;
          }
          if (!moment(oldNIvalue).isSame(decision.nextInfoTime)) {
            isTodoChangeDate = true;
          }
          return this.nextInfoHistoryInNotes(newDecision, context, false, isTodoChangeDate, isDoneCheck).then(updatedAttachments => {
            decision.attachments = updatedAttachments;
            const parseDecision = new this.ParseDecision();
            parseDecision.id = newDecision.objectId;
            parseDecision.set('attachments', JSON.stringify(updatedAttachments));
            return this.requestService.performSaveQuery(parseDecision).then(updatedDecision => {
              const newUpdatedDecision = this.newDecision(updatedDecision, updatedDecision.tags);
              return this.afterUpdate(
                decision,
                newUpdatedDecision,
                updatedDecision,
                newDuplicateToOtherModuleValue,
                oldDuplicateToOtherModuleValue
              );
            });
          });
        }

        return this.afterUpdate(decision, newDecision, res, newDuplicateToOtherModuleValue, oldDuplicateToOtherModuleValue);
      });
    });
  }

  public afterUpdate(
    decision: T,
    newDecision: T,
    parseDecision: Parse.Object,
    newDuplicateToOtherModuleValue?: boolean,
    oldDuplicateToOtherModuleValue?: boolean
  ) {
    this.historyService.postLog(
      OclHistoryLog.create(
        this.getLogMessage({
          message: decision.message,
          nextInfoTime: decision.nextInfoTime,
        }),
        'decision',
        'update',
        decision.attachments,
        decision,
        decision.acl,
        parseDecision
      )
    );

    if (!isEqual(newDuplicateToOtherModuleValue, oldDuplicateToOtherModuleValue)) {
      // Supprimer
      if (!newDuplicateToOtherModuleValue && oldDuplicateToOtherModuleValue) {
        this.deleteDuplicateDecisionFromModule(newDecision);
      }
      // Ajouter
      if (newDuplicateToOtherModuleValue && !oldDuplicateToOtherModuleValue) {
        this.duplicateDecisionToOtherModule(newDecision);
      }
    }
    // STORE
    // OccDecisionsStoreManager.updateOneDecision(newDecision)

    return newDecision;
  }

  public markAsDone(decision: T, done: boolean, context?: U): Promise<T> {
    const parseDecision = new this.ParseDecision();
    parseDecision.id = decision.objectId;
    parseDecision.set('done', done);

    return this.requestService.performSaveQuery(parseDecision).then(parseData => {
      decision.done = parseData.get('done');

      if (decision.isTodo && !!done) {
        // generate note with new next info done
        return this.nextInfoHistoryInNotes(decision, context, false, false, true).then(updatedAttachments => {
          const parseDecisionUpdate = new this.ParseDecision();
          parseDecisionUpdate.id = decision.objectId;
          parseDecisionUpdate.set('attachments', JSON.stringify(updatedAttachments));
          return this.requestService.performSaveQuery(parseDecisionUpdate).then(updatedParseData => {
            decision.attachments = updatedAttachments;
            this.historyService.postLog(
              OclHistoryLog.create(
                this.getLogMessage({
                  message: decision.message,
                  nextInfoTime: decision.nextInfoTime,
                }),
                'decision',
                decision.done ? 'done' : 'backtodo',
                null,
                null,
                decision.acl,
                updatedParseData
              )
            );
            return decision;
          });
        });
      } else {
        this.historyService.postLog(
          OclHistoryLog.create(
            this.getLogMessage({
              message: decision.message,
              nextInfoTime: decision.nextInfoTime,
            }),
            'decision',
            decision.done ? 'done' : 'backtodo',
            null,
            null,
            decision.acl,
            parseData
          )
        );

        return decision;
      }
    });
  }

  public fetchNewData(isFromPooling: boolean = false, filterDataStartDate?: Date) {
    return this.getAll(true, false, isFromPooling, false, filterDataStartDate).then(decisions => {
      // STORE
      this.decisionsStoreManager.updateDecisionsFromPooling(decisions, this.moduleConfig.config.moduleName);
    });
  }

  public nextInfoHistoryInNotes(
    decision: OclDecision,
    context?: HolContext,
    isTodoCreation = false,
    isTodoChangeDate = false,
    isDoneCheck = false,
    addMdBreakLinesBefore = false
  ) {
    let content = '';
    let nameFile;
    const dateFormat = 'DD/MM/YY HH:mm[Z]';
    const attachments = decision.attachments ? decision.attachments : new HolAttachments();
    const noteFile: HolisFile = new HolisFile();

    if (decision.attachments && decision.attachments.note) {
      content += decision.attachments.note;
      nameFile = decision.attachments.noteFile.fileName;
    } else {
      nameFile = `note-${this.moduleConfig.config.moduleName.toLocaleLowerCase()}-${context.category
        .substring(0, 3)
        .toLocaleLowerCase()
        .replace(/é|è|ê/g, 'e')}-${moment().utc().format('DD-MM-YYYY')}.html`;
      addMdBreakLinesBefore = true;
    }

    content += addMdBreakLinesBefore ? '\n' + '\n' + '\n' + '---' + '\n' + '\n' + '\n' : '';

    if (!!isTodoChangeDate) {
      const txt1: string = this.$translate.instant('NEXT_INFO.NEW_NI', {
        date: moment(decision.nextInfoTime).format(dateFormat),
      });
      content += '- **';
      content += isTodoCreation
        ? this.$translate.instant('NEXT_INFO.CREATE_NI', {
            date: moment().utc().format(dateFormat),
          })
        : this.$translate.instant('NEXT_INFO.EDIT_NI', {
            date: moment().utc().format(dateFormat),
          });
      content += '**' + ' ' + txt1 + ' ' + '\n';
    }
    if (!!isDoneCheck) {
      const txt2: string = this.$translate.instant('NEXT_INFO.DONE_BY', {
        firstName: decision.updatedBy.firstName ? decision.updatedBy.firstName : decision.createdBy.firstName,
        lastName: decision.updatedBy.lastName ? decision.updatedBy.lastName : decision.createdBy.lastName,
      });
      content += '- **';
      content += this.$translate.instant('NEXT_INFO.DONE_NI', {
        date: moment().utc().format(dateFormat),
      });
      content += '**' + ' ' + txt2 + ' ' + '\n';
    }

    const mdTemplate = content;

    const htmlContent = this.markdownService.parseMdToHtml(mdTemplate);
    const htmlTemplate = this.markdownService.createHtmlContent(htmlContent, context);

    const blob = new Blob([htmlTemplate], { type: 'text/html' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        this.filesService.uploadFile(nameFile, { base64: reader.result }).then(
          url => {
            noteFile.url = url;
            noteFile.fileName = nameFile;
            attachments.note = mdTemplate;
            attachments.noteFile = noteFile;
            resolve(attachments);
          },
          err => {
            reject(err);
          }
        );
      };
    });
  }

  protected setAdditionalFields(decision: T, parseDecision: Parse.Object) {}

  protected newDecision(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return new OclDecision(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }

  protected getAdditionnalQueries(query, queryPinned, today) {
    return Parse.Query.or(query, queryPinned);
  }

  deleteErpDecision(oclDecision: T): void {
    const parseOclLogbook = new this.ParseDecision({ id: oclDecision.objectId });
    const parseErpLogbook = new this.ParseErpDecision({ id: oclDecision.erpDecision.objectId });
    parseErpLogbook.set('toECL', false);
    this.requestService.performSaveQuery(parseErpLogbook).then(() => {
      this.requestService.performDestroyQuery(
        parseOclLogbook,
        () => {
          // STORE
          this.decisionsStoreManager.deleteOneDecison(oclDecision.objectId);
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  protected duplicateDecisionToOtherModule(decision: T) {}

  protected deleteDuplicateDecisionFromModule(decision: T) {}
}
