import { ErpHistoryLog } from './../../models/erp-historyLog';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { ErpCrisis } from '../../models/erp-crisis';
// tslint:disable-next-line: max-line-length
import {
  InitCrisis,
  ResetCrisis,
  UpdateCrisis,
  UpdateOneCrisisNew,
  UpdateOneCrisisTask,
  CreateOneCrisisNew,
  RemoveOneCrisisNew,
  CreateOneCrisisTask,
  DeleteOneCrisisData,
  CreateOneCrisisData,
  UpdateOneCrisisData,
  CreateManyCrisisData,
  CreateManyCrisisTasks,
  UpdateManyCrisisData,
  UpdateOneCrisisAnnouncement,
  CreateOneCrisisAnnouncement,
  UpdateCrisisFromPooling,
  RemoveOneCrisisTask,
  CreateOneCrisisHistoryLog,
  UpdateOneCrisisDecision,
  CreateOneCrisisDecision,
  RemoveOneCrisisDecision,
} from './crisis.actions';
import { ErpCrisisNew } from '../../models/erp-crisisNew';
import { ErpCrisisTask } from '../../models/erp-crisisTask';
import { ErpCrisisData } from '../../models/erp-crisisData';
import { ErpCrisisAnnouncement } from '../../models/erp-crisisAnnouncement';
import { ErpCrisisDecision } from '../../models/erp-crisisDecision';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { HolCrisis } from '../../../common/models/hol-crisis';
import { skip, map, pairwise, take, takeLast, filter } from 'rxjs/operators';
import { max, difference, concat, cloneDeep, flattenDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CrisisStoreManager {
  // tslint:disable:variable-name
  ParseCrisis = Parse.Object.extend('GDCCrisis');

  private _crisisErpState: Observable<ErpCrisis>;
  private _lastCrisisAnnouncement: Observable<ErpCrisisAnnouncement>;
  private _lastTaskModified: Observable<ErpCrisisTask>;

  constructor(private store: Store<AppState>, private commonStoreManager: CommonStoreManager) {

    this._crisisErpState = this.store.select('erp', 'crisis');

    this._lastCrisisAnnouncement = this.store.select('erp', 'crisis', 'announcements').pipe(
      pairwise(),
      map(data => {
        if (data[0].length !== data[1].length) {
          return data[1][0];
        }
      })
    );

    this._lastTaskModified = this.store.select('erp', 'crisis', 'tasks').pipe(
      pairwise(),
      map(data => {
        const oldTaskList = data[0];
        const newTaskList = data[1];
        const taskWithNewStatus = newTaskList.filter(newTask => oldTaskList.some(oldTask => {return newTask.objectId === oldTask.objectId && newTask.status !== oldTask.status && ['DONE', 'FROZEN','NOT_APPLICABLE'].includes(newTask.status)}));
        if(taskWithNewStatus && taskWithNewStatus.length===1){
          return taskWithNewStatus[0];
        }
      })
    );
  }

  get crisisErpState(): Observable<ErpCrisis> {
    return this._crisisErpState;
  }

  get lastTaskModified(): Observable<ErpCrisisTask> {
    return this._lastTaskModified;
  }

  get lastCrisisAnnouncement(): Observable<ErpCrisisAnnouncement> {
    return this._lastCrisisAnnouncement;
  }

  initCrisis(crisis: Partial<ErpCrisis>): void {
    this.store.dispatch(new InitCrisis({ ...crisis }));
    this.commonStoreManager.initCrisis(new HolCrisis(new this.ParseCrisis({ id: crisis.objectId })));
  }

  resetCrisis(): void {
    this.store.dispatch(new ResetCrisis());
  }

  updateCrisis(crisis: Partial<ErpCrisis>): void {
    this.store.dispatch(new UpdateCrisis({ ...crisis }));
    this.commonStoreManager.initCrisis(new HolCrisis(new this.ParseCrisis({ id: crisis.objectId })));
  }

  updateCrisisFromPooling(crisis: Partial<ErpCrisis>): void {
    this.store.dispatch(new UpdateCrisisFromPooling({ ...crisis }));
    this.commonStoreManager.initCrisis(new HolCrisis(new this.ParseCrisis({ id: crisis.objectId })));
  }

  updateOneCrisisNew(crisisNew: ErpCrisisNew): void {
    this.store.dispatch(new UpdateOneCrisisNew(crisisNew));
  }

  updateOneCrisisTask(crisisTask: ErpCrisisTask): void {
    this.store.dispatch(new UpdateOneCrisisTask(crisisTask));
  }

  updateOneCrisisData(crisisData: ErpCrisisData): void {
    this.store.dispatch(new UpdateOneCrisisData(crisisData));
  }

  updateOneCrisisAnnouncement(crisisAnnouncement: ErpCrisisAnnouncement): void {
    this.store.dispatch(new UpdateOneCrisisAnnouncement(crisisAnnouncement));
  }

  updateOneCrisisDecision(crisisDecision: ErpCrisisDecision): void {
    this.store.dispatch(new UpdateOneCrisisDecision(crisisDecision));
  }

  updateManyCrisisData(crisisDataArray: ErpCrisisData[]): void {
    this.store.dispatch(new UpdateManyCrisisData(crisisDataArray));
  }

  createOneCrisisNew(crisisNew: ErpCrisisNew): void {
    this.store.dispatch(new CreateOneCrisisNew(crisisNew));
  }

  createOneCrisisTask(crisisTask: ErpCrisisTask): void {
    this.store.dispatch(new CreateOneCrisisTask(crisisTask));
  }

  createOneCrisisData(crisisData: ErpCrisisData): void {
    this.store.dispatch(new CreateOneCrisisData(crisisData));
  }

  createOneCrisisAnnouncement(crisisAnnouncement: ErpCrisisAnnouncement): void {
    this.store.dispatch(new CreateOneCrisisAnnouncement(crisisAnnouncement));
  }

  createOneCrisisHistoryLog(crisisHistoryLog: ErpHistoryLog): void {
    this.store.dispatch(new CreateOneCrisisHistoryLog(crisisHistoryLog));
  }

  createOneCrisisDecision(crisisDecision: ErpCrisisDecision): void {
    this.store.dispatch(new CreateOneCrisisDecision(crisisDecision));
  }

  createManyCrisisData(crisisDataArray: ErpCrisisData[]): void {
    this.store.dispatch(new CreateManyCrisisData(crisisDataArray));
  }

  createManyCrisisTasks(crisisTasksArray: ErpCrisisTask[]): void {
    this.store.dispatch(new CreateManyCrisisTasks(crisisTasksArray));
  }

  removeOneCrisisNew(crisisNewObjectId: string): void {
    this.store.dispatch(new RemoveOneCrisisNew(crisisNewObjectId));
  }

  deleteOneCrisisData(crisisDataObjectId: string): void {
    this.store.dispatch(new DeleteOneCrisisData(crisisDataObjectId));
  }

  removeOneCrisisTask(crisisTaskObjectId: string): void {
    this.store.dispatch(new RemoveOneCrisisTask(crisisTaskObjectId));
  }

  removeOneCrisisDecision(crisisDecisionObjectId: string): void {
    this.store.dispatch(new RemoveOneCrisisDecision(crisisDecisionObjectId));
  }
}
