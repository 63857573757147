import { HelperService } from 'src/app/common/services/helper.service';
import { ErpFunctionCategory } from '../../models/erp-functionCategory';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { AppState } from 'src/app/store';
import { ErpFunctions } from '../../erp.model';
import {
  AddOneFunctionAllUser,
  AddOneFunctionCrisis,
  AddOneUser,
  InitFunctions,
  RemoveOneFunctionAllUser,
  ResetFunctions,
  UpdateFunctionsFromPooling,
  UpdateOneFunctionAllUser,
  UpdateOneFunctionCrisis,
  UpdateOneUser,
} from './functions.action';
import { ErpFunctionUser, FunctionUserForCrisis } from '../../models/erp-functionUser';
import { ErpFunctionCrisis } from '../../models/erp-functionCrisis';
import { RolesService } from '../../../common/services/roles.service';
import { HolUser, HolUserWithCompanies } from '../../../common/models/hol-user.model';
import { ErpCrisisTask } from '../../models/erp-crisisTask';
import { filter, map } from 'rxjs/operators';
import { OptionsService } from '../../../common/services/options.service';
import { ACL } from 'parse';
import { intersectionWith, isEqual, uniqBy } from 'lodash';

export interface TeamRole {
  isInTeam: boolean;
  inTeamFor: string[];
  isHolder: boolean;
  holderFor: string[];
}

export interface CrisisRoles {
  crisisDirector?: TeamRole;
  humanResource?: TeamRole;
  crisisObservor?: TeamRole;
  crisisOCC?: TeamRole;
  canEditCrisis?: boolean;
  allCrisisRoles?: any[];
}

export interface ErpHolderChange {
  objectId: string;
  company: string;
  user: HolUserWithCompanies;
  func: ErpFunctionCrisis;
  updatedAt: Date;
  isHolder: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FunctionsStoreManager {
  private static prevUfs: ErpFunctionUser[];
  private _functionsAllUser: Observable<ErpFunctionUser[]>;

  constructor(
    private store: Store<AppState>,
    private rolesService: RolesService,
    private optionsService: OptionsService,
    private helperService: HelperService
  ) {
    this._functionsErpState = this.store.select('erp', 'functions');
    this._functionsCrisisErpState = this.store.select('erp', 'functions', 'functionsCrisis');
    this._functionsCategoryErpState = this.store.select('erp', 'functions', 'functionsCategory');
    this._functionsAllUser = this.store.select('erp', 'functions', 'functionsAllUser');
    this._allUsers = this.store.select('erp', 'functions', 'allUsers');
    this._allTasks = this.store.select('erp', 'crisis', 'tasks');
  }

  private _functionsCategoryErpState: Observable<ErpFunctionCategory[]>;

  get functionsCategoryErpState(): Observable<ErpFunctionCategory[]> {
    return this._functionsCategoryErpState;
  }

  private _allUsers: Observable<HolUserWithCompanies[]>;

  get allUsers(): Observable<HolUserWithCompanies[]> {
    return this._allUsers;
  }

  private _allTasks: Observable<ErpCrisisTask[]>;

  get allTasks(): Observable<ErpCrisisTask[]> {
    return this._allTasks;
  }

  private _functionsErpState: Observable<ErpFunctions>;

  get functionsErpState(): Observable<ErpFunctions> {
    return this._functionsErpState;
  }

  private _functionsCrisisErpState: Observable<ErpFunctionCrisis[]>;

  get functionsCrisisErpState(): Observable<ErpFunctionCrisis[]> {
    return this._functionsCrisisErpState;
  }

  get functionsAllUserErpState(): Observable<ErpFunctionUser[]> {
    return this._functionsAllUser;
  }

  get functionsUserErpState(): Observable<ErpFunctionUser[]> {
    return combineLatest([this._functionsAllUser, this.store.select('common', 'currentUser')]).pipe(
      map(([ufs, user]) => {
        if (ufs && user) {
          return ufs.filter(uf => uf.userId === user.userId);
        } else {
          return [];
        }
      })
    );
  }

  get holderChanges(): Observable<ErpHolderChange[]> {
    // let prevHolders: ErpFunctionUser[] = null;
    return combineLatest([this._functionsAllUser, this._allUsers, this._functionsCrisisErpState]).pipe(
      map(([newUfs, users, functions]) => {
        const holderChanges: ErpHolderChange[] = [];
        if (FunctionsStoreManager.prevUfs) {
          newUfs.forEach(newUf => {
            const oldUf = FunctionsStoreManager.prevUfs.find(uf => uf.objectId === newUf.objectId);
            if (newUf.isHolder && (!oldUf || !oldUf.isHolder)) {
              holderChanges.push({
                objectId: newUf.objectId,
                company: newUf.companies[0],
                updatedAt: newUf.updatedAt,
                isHolder: true,
                user: users.find(u => u.userId === newUf.userId) || new HolUserWithCompanies(),
                func: functions.find(f => f.functionId === newUf.functionId) || new ErpFunctionCrisis(),
              });
            } else if (!newUf.isHolder && oldUf && oldUf.isHolder) {
              holderChanges.push({
                objectId: newUf.objectId,
                company: newUf.companies[0],
                updatedAt: newUf.updatedAt,
                isHolder: false,
                user: users.find(u => u.userId === newUf.userId) || new HolUserWithCompanies(),
                func: functions.find(f => f.functionId === newUf.functionId) || new ErpFunctionCrisis(),
              });
            }
          });
        }
        FunctionsStoreManager.prevUfs = newUfs;
        return holderChanges;
      }),
      filter(holders => !!holders.length)
    );
  }

  get functionsAllowedToAddTask(): Observable<FunctionUserForCrisis[]> {
    return combineLatest(this._functionsCrisisErpState, this._functionsAllUser, this.currentUserCrisisRoles).pipe(
      map(([functionsCrisis, functionUser, crisisRoles]) => {
        let functionsUserForCrisis: FunctionUserForCrisis[];
        if (functionsCrisis && functionsCrisis.length && functionUser && functionUser.length) {
          functionUser = functionUser.filter(
            uf =>
              crisisRoles.crisisDirector.holderFor.includes(uf.companies[0]) ||
              crisisRoles.crisisObservor.holderFor.includes(uf.companies[0]) ||
              (uf.isHolder && uf.userId === Parse.User.current().get('userId'))
          );
          functionUser = uniqBy(functionUser, 'functionId');

          functionsUserForCrisis = functionUser.map(fUser => {
            const matchFunction: ErpFunctionCrisis = functionsCrisis.find(fCrisis => fCrisis.functionId === fUser.functionId);
            return {
              functionId: fUser.functionId,
              title: matchFunction && matchFunction.title,
              shortTitle: matchFunction && matchFunction.shortTitle,
              isHolder: fUser.isHolder,
              categoryDocumentId: matchFunction && matchFunction.tagId,
            };
          });
          return functionsUserForCrisis;
        }
      })
    );
  }

  get functionsAllowedToAddDocument(): Observable<FunctionUserForCrisis[]> {
    return combineLatest(this._functionsCrisisErpState, this._functionsAllUser, this.currentUserCrisisRoles).pipe(
      map(([functionsCrisis, functionUser, crisisRoles]) => {
        let functionsUserForCrisis: FunctionUserForCrisis[];
        if (functionsCrisis && functionsCrisis.length && functionUser && functionUser.length) {
          functionUser = functionUser.filter(
            uf =>
              crisisRoles.crisisDirector.inTeamFor.includes(uf.companies[0]) ||
              crisisRoles.crisisObservor.inTeamFor.includes(uf.companies[0]) ||
              uf.userId === Parse.User.current().get('userId')
          );
          functionUser = uniqBy(functionUser, 'functionId');

          functionsUserForCrisis = functionUser.map(fUser => {
            const matchFunction: ErpFunctionCrisis = functionsCrisis.find(fCrisis => fCrisis.functionId === fUser.functionId);
            return {
              functionId: fUser.functionId,
              title: matchFunction && matchFunction.title,
              shortTitle: matchFunction && matchFunction.shortTitle,
              isHolder: fUser.isHolder,
              categoryDocumentId: matchFunction && matchFunction.tagId,
            };
          });
          return functionsUserForCrisis;
        }
      })
    );
  }

  get teamUsersByFunction(): Observable<any> {
    const $usersByFunction: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    combineLatest(
      this.rolesService.$companiesRolesFilter,
      this._functionsCrisisErpState,
      this._functionsCategoryErpState,
      this._functionsAllUser,
      this._allUsers,
      this._allTasks
    ).subscribe(([companiesRolesFilter, functionsCrisis, functionsTags, usersFunctions, users, tasks]) => {
      const team = [];
      if (companiesRolesFilter && functionsCrisis && functionsTags && usersFunctions && users && tasks) {
        tasks = this.rolesService.filterFromCompanyRoles(tasks.filter(t => !t.isDocumentOnly));
        functionsCrisis = this.rolesService.filterFromCompanyRoles(functionsCrisis);
        users = this.rolesService.filterUserFromCompanyRoles(users);
        usersFunctions = this.rolesService.filterFromCompanyRoles(usersFunctions);
        functionsTags.forEach(ft => {
          const functions: any[] = functionsCrisis.filter(fc => fc.tagId === ft.tagId);

          functions.forEach(f => {
            const userFunctions = usersFunctions.filter(uf => uf.functionId === f.functionId);
            const usersInFunction = userFunctions.map(uf => {
              const user = users.find(u => u.userId === uf.userId);
              if (!user) {
                // console.warn(`User ${uf.userId} not found for function ${uf.functionId}`);
                return;
              }
              return { ...user, isHolder: uf.isHolder, holderSince: uf.updatedAt };
            });
            f.tasks = tasks
              .filter(t => t.functionId === f.functionId)
              .sort((a, b) => {
                return a.order - b.order;
              });
            f.countFrozen = f.tasks.filter(t => t.status === 'FROZEN').length;
            f.countTodo = f.tasks.filter(t => !t.status || t.status === 'TODO').length;
            f.users = usersInFunction.filter(u => !!u); // remove not found users
            f.holder = f.users.find(u => u.isHolder);
          });

          team.push({
            ...ft,
            functions,
          });
        });
      }
      $usersByFunction.next(team);
    });
    return $usersByFunction;
  }

  get teamFunctionsByUser(): Observable<any> {
    const $functionsByUser: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    combineLatest(this.rolesService.$companiesRolesFilter, this._functionsCrisisErpState, this._functionsAllUser, this._allUsers).subscribe(
      ([companiesRolesFilter, functionsCrisis, usersFunctions, users]) => {
        const teamUsers = [];
        if (companiesRolesFilter && functionsCrisis && usersFunctions && users) {
          functionsCrisis = this.rolesService.filterFromCompanyRoles(functionsCrisis);
          usersFunctions = this.rolesService.filterFromCompanyRoles(usersFunctions);
          users = this.rolesService.filterUserFromCompanyRoles(users);
          users.forEach(u => {
            const userFunctions = usersFunctions.filter(uf => uf.userId === u.userId);
            const functionsForUser = userFunctions.map(uf => {
              const func = functionsCrisis.find(f => f.functionId === uf.functionId);
              if (!func) {
                // console.warn(`Functions ${uf.functionId} not found for user ${uf.userId}`);
                return;
              }

              return {
                ...func,
                isHolder: uf.isHolder,
                holderSince: uf.isHolder ? uf.updatedAt : null,
              };
            });

            teamUsers.push({
              ...u,
              functions: functionsForUser.filter(f => !!f), // remove not fouond functions
            });
          });
        }
        $functionsByUser.next(teamUsers);
      }
    );
    return $functionsByUser;
  }

  get currentUserCrisisRoles(): Observable<CrisisRoles> {
    return combineLatest(
      this.store.select('erp', 'crisis'),
      this.rolesService.$companiesRolesFilter,
      this._functionsCrisisErpState,
      this.functionsUserErpState.pipe(filter(ufs => !!ufs)),
      this.rolesService.getUserCompanyRolesByUniverse('ERP')
    )
      .pipe(
        map(([crisis, , functionsCrisis, usersFunctions, roles]) => {
          if (functionsCrisis && usersFunctions && roles && crisis) {
            const crisisCompanies = this.helperService.parseACL(crisis.acl);
            const writeCompanies = roles
              .filter(role => role.userWriteRoles.length && crisisCompanies.includes(role.company))
              .map(r => r.company);
            usersFunctions = usersFunctions.filter(uf => writeCompanies.includes(uf.companies[0]));
            functionsCrisis = this.rolesService.filterFromAcl(functionsCrisis, crisis.acl);
            usersFunctions = this.rolesService.filterFromAcl(usersFunctions, crisis.acl);
            const functionsCrisisFiltered = this.rolesService.filterFromCompanyRoles(functionsCrisis);
            const usersFunctionsFiltered = this.rolesService.filterFromCompanyRoles(usersFunctions);

            return {
              crisisDirector: this.getTeamRole(
                this.optionsService.getCrisisDirectorShortTitlesList(),
                functionsCrisis,
                functionsCrisisFiltered,
                usersFunctions,
                usersFunctionsFiltered
              ),
              humanResource: this.getTeamRole(
                this.optionsService.getHumanResourcesShortTitlesList(),
                functionsCrisis,
                functionsCrisisFiltered,
                usersFunctions,
                usersFunctionsFiltered
              ),
              crisisObservor: this.getTeamRole(
                this.optionsService.getCrisisObservorShortTitlesList(),
                functionsCrisis,
                functionsCrisisFiltered,
                usersFunctions,
                usersFunctionsFiltered
              ),
              crisisOCC: this.getTeamRole(
                this.optionsService.getOCCShortTitlesList(),
                functionsCrisis,
                functionsCrisisFiltered,
                usersFunctions,
                usersFunctionsFiltered
              ),
              canEditCrisis: writeCompanies.length >= 1 && crisis.inProgress,
              allCrisisRoles: roles,
            };
          }
        })
      )
      .pipe(filter(roles => !!roles));
  }

  get crisisDirector(): Observable<HolUser> {
    return combineLatest(
      this.rolesService.$companiesRolesFilter,
      this._functionsCrisisErpState,
      this._functionsAllUser,
      this._allUsers
    ).pipe(
      map(([, functionsCrisis, allUsersFunctions, users]) => {
        functionsCrisis = this.rolesService.filterFromCompanyRoles(functionsCrisis);
        allUsersFunctions = this.rolesService.filterFromCompanyRoles(allUsersFunctions);
        const crisisDirectorFunctionsIds = functionsCrisis
          .filter(f => this.optionsService.getCrisisDirectorShortTitlesList().includes(f.shortTitle))
          .map(f => f.functionId);
        const holderUF = allUsersFunctions.find(uf => uf.isHolder && crisisDirectorFunctionsIds.includes(uf.functionId));
        if (holderUF) {
          return users.find(u => u.userId === holderUF.userId);
        }
        return null;
      })
    );
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }

  async getUsersByFunction(functionId: string, acl?: ACL) {
    let companies = [];
    if (acl) {
      companies = this.helperService.parseACL(acl);
    }

    const allUser = this.getValue(this._allUsers);
    const functionsAllUser = this.getValue(this._functionsAllUser);
    const usersIdToNotify = functionsAllUser.filter(func => func.functionId === functionId).map(el => el.userId);
    const usersToNotify = allUser.filter(user => usersIdToNotify.findIndex(userId => userId === user.userId) !== -1);

    if (acl) {
      const promises = usersToNotify.map(el => this.rolesService.getUserCompaniesByUniverse(el.objectId, 'ERP'));
      return Promise.all(promises).then(el => {
        usersToNotify.forEach((user, index) => {
          user.companies = el[index];
        });
        return usersToNotify.filter(user => intersectionWith(companies, user.companies, isEqual).length > 0);
      });
      // return new Promise<any>((resolve, reject) => {

      //   usersToNotify.forEach(async (user, index, array) => {
      //     user.companies = await this.rolesService.getUserCompaniesByUniverse(user.objectId, 'ERP');
      //     if (index === array.length - 1) {
      //       resolve(usersToNotify.filter(user => intersectionWith(companies, user.companies, isEqual).length > 0));
      //     }
      //   });
      //   });
    } else {
      return usersToNotify;
    }
  }

  initFunctions(functions: Partial<ErpFunctions>): void {
    this.store.dispatch(new InitFunctions({ ...functions }));
  }

  updateOneFunctionAllUser(functionUser: ErpFunctionUser): void {
    this.store.dispatch(new UpdateOneFunctionAllUser(functionUser));
  }

  addOneFunctionAllUser(functionUser: ErpFunctionUser): void {
    this.store.dispatch(new AddOneFunctionAllUser(functionUser));
  }

  removeOneFunctionAllUser(functionUser: ErpFunctionUser): void {
    this.store.dispatch(new RemoveOneFunctionAllUser(functionUser));
  }

  updateOneFunctionCrisis(functionCrisis: ErpFunctionCrisis): void {
    this.store.dispatch(new UpdateOneFunctionCrisis(functionCrisis));
  }

  addOneFunctionCrisis(functionCrisis: ErpFunctionCrisis): void {
    this.store.dispatch(new AddOneFunctionCrisis(functionCrisis));
  }

  updateFunctionsFromPooling(functions: Partial<ErpFunctions>): void {
    this.store.dispatch(new UpdateFunctionsFromPooling({ ...functions }));
  }

  resetFunctions(): void {
    this.store.dispatch(new ResetFunctions());
  }

  addOneUser(user: HolUserWithCompanies): void {
    this.store.dispatch(new AddOneUser(user));
  }

  updateOneUser(user: HolUser): void {
    this.store.dispatch(new UpdateOneUser(user));
  }

  private getTeamRole(
    shortTitlesList: string[],
    allFunctionsCrisis: ErpFunctionCrisis[],
    currentCompanyFunctionsCrisis: ErpFunctionCrisis[],
    allUsersFunctions: ErpFunctionUser[],
    currentCompanyUsersFunctions: ErpFunctionUser[]
  ): TeamRole {
    const allFunctionsIds = allFunctionsCrisis.filter(f => shortTitlesList.includes(f.shortTitle)).map(f => f.functionId);
    const currentCompanyFunctionsIds = currentCompanyFunctionsCrisis
      .filter(f => shortTitlesList.includes(f.shortTitle))
      .map(f => f.functionId);
    const currentUfs = currentCompanyUsersFunctions.filter(uf => currentCompanyFunctionsIds.includes(uf.functionId));
    const allUfs = allUsersFunctions.filter(uf => allFunctionsIds.includes(uf.functionId));
    return {
      isInTeam: !!currentUfs.length,
      isHolder: !!currentUfs.find(uf => uf.isHolder),
      inTeamFor: allUfs.map(uf => uf.companies[0]),
      holderFor: allUfs.filter(uf => uf.isHolder).map(uf => uf.companies[0]),
    };
  }
}
