import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { RolesService } from 'src/app/common/services/roles.service';
import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { OclEventService } from '../../../ocl/services/ocl-event-service/ocl-event.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';
import { EclEventTagService } from '../ecl-event-tag-service/ecl-event-tag.service';
import { OclEventsStoreManager } from '../../../ocl/store/events/ocl-events-store-manager.service';
import { OclEvent } from '../../../ocl/models/ocl-event.model';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { EclMailService } from '../ecl-mail-service/ecl-mail.service';
import { EclSmsService } from '../ecl-sms-service/ecl-sms.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { MccOptionsService } from '../../../mcc/services/mcc-options.service';
import { OclTasksStoreManager } from '../../../ocl/store/tasks/ocl-tasks.store-manager';
import { EclTagsService } from '../ecl-tags-service/ecl-tags.service';
import { EclTasksService } from '../ecl-tasks.service';

@Injectable({
  providedIn: 'root',
})
export class EclEventService extends OclEventService {
  // tslint:disable:variable-name
  protected ParseEvents = Parse.Object.extend('ECLEvents');
  protected ParseScenario = Parse.Object.extend('ECLScenario');
  protected ParseEventInfos = Parse.Object.extend('ECLEventInfos');
  protected ParseTaskRef = Parse.Object.extend('ECLTask_REF');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseEventTag = Parse.Object.extend('ECLEventTag');
  // tslint:enable
  protected taskEventColumn = 'eclEvent';

  public constructor(
    protected requestService: RequestService,
    protected mccOptionsService: MccOptionsService,
    @Inject('UserService') protected userService,
    protected parseMapper: ParseMapperService,
    protected notificationsService: NotificationsService,
    protected historyService: EclHistoryService,
    protected eventTagService: EclEventTagService,
    protected eventsStoreManager: OclEventsStoreManager,
    protected mailService: EclMailService,
    protected smsService: EclSmsService,
    @Inject('ChatService') protected chatService,
    @Inject('$rootScope') protected $rootScope,
    public moduleConfig: EclModuleConfigService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected tasksService: EclTasksService,
    protected tagsService: EclTagsService,
    protected rolesService: RolesService,
    public commonStoreManager: CommonStoreManager
  ) {
    super(
      requestService,
      mccOptionsService,
      userService,
      parseMapper,
      notificationsService,
      historyService,
      eventTagService,
      eventsStoreManager,
      mailService,
      smsService,
      chatService,
      $rootScope,
      moduleConfig,
      tasksStoreManager,
      tasksService,
      tagsService,
      rolesService,
      commonStoreManager
    );
  }

  protected markPreviousInfosAsDone(event: OclEvent, nextInfoTime: Date): Promise<HolNextInfo[]> {
    // Nothing to do for ECL
    return Promise.resolve([]);
  }

  public activateECLModule(eclOptionsId: string, moduleName: string, event: OclEvent, infos: any[]): void {}
  public deactivateECLModule(eclOptionsId: string): void {}

  // protected getAllGOCEvents(): Promise<any[]> {
  //   return Promise.resolve([]);
  // }

  protected getAllMCCEvents(): Promise<any[]> {
    return Promise.resolve([]);
  }
}
