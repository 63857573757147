<div class="acl-selector-container" [class.hidden]="!roles || roles.length <= 1">
  <div>
    <span class="acl-text" *ngIf="!readOnly">{{ 'COMMON.ACL' | translate }}</span>
    <mat-button-toggle-group class="roles-buttons" multiple #group="matButtonToggleGroup" appearance="legacy">
      <ng-container *ngFor="let role of roles">
        <mat-button-toggle
          (change)="updateACL(true)"
          [value]="role"
          [disabled]="
            (extendACLOnly && !!role.initialWriteRoles?.length) ||
            role.disabledFromLimit ||
            !role.userWriteRoles.length ||
            readOnly ||
            (alwaysOneSelected && btnGroup.value.length === 1 && isSelected(role))
          "
          [ngStyle]="{
            color: isSelected(role) ? 'white' : role.color,
            'border-color': role.color,
            'background-color': isSelected(role) ? role.color : mixColors('#FFF', role.color, 90)
          }"
        >
          {{ role.company }}
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
  </div>
  <mat-checkbox
    class="select-all"
    *ngIf="!extendACLOnly && !readOnly && enabledRoles.length > 3"
    (change)="toggleSelectAll()"
    [checked]="group.value.length === enabledRoles.length"
  >
    {{ 'COMMON.SELECT_ALL' | translate }}
  </mat-checkbox>
</div>
