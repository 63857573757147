import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { HolTag } from '../../models/hol-tag';
import { TagChangeService } from '../../services/tag-change.service';
import { BaseComponent } from '../base/base.component';
import { TagsService } from '../../services/tags.service';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-tag-selector',
  templateUrl: './tag-selector.component.html',
  styleUrls: ['./tag-selector.component.scss'],
})
export class TagSelectorComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  public readOnly: boolean;
  @Input()
  public addForm? = false;
  @Input()
  public showTitle? = true;
  @Input()
  public defaultTags: HolTag[] = [];
  @Input()
  public isInHeader: boolean; // if true global setter of filter
  @Input()
  public viewOnly: boolean;
  @Output()
  public selectedTagsChange = new EventEmitter();
  @Output()
  public updateTags = new EventEmitter();
  public tags: HolTag[] = [];
  public tagForm: FormGroup;
  public showInput = false;

  private pooling$ = interval(60000);
  private subscription;

  constructor(
    private fb: FormBuilder,
    public moduleConfig: ModuleConfigService,
    private tagsService: TagsService,
    private tagChangeService: TagChangeService,
    @Inject('$rootScope') public $rootScope
  ) {
    super();
  }

  private _selectedTags: HolTag[] = [];

  @Input()
  get selectedTags() {
    return this._selectedTags || [];
  }

  set selectedTags(value: HolTag[]) {
    this._selectedTags = value;
    this.selectedTagsChange.emit(this._selectedTags);
  }

  public ngOnInit(): void {
    this.subscription = this.pooling$.subscribe(() => this.getAllTag(true));
    this.getAllTag();
  }

  public getAllTag(forceRefresh?: boolean): void {
    if (this.viewOnly) {
      this.tags = this.selectedTags;
      this.selectedTags = this.selectedTags || [];
    } else {
      this.tagsService.getAll(forceRefresh).then((tags: HolTag[]) => {
        this.tags = tags;
        if (this.isInHeader) {
          this.selectedTags = this.tagChangeService.getCurrentTagFilter();
          this.tagChangeService.setCurrentTagFilter(this.selectedTags);
        } else {
          this.selectedTags = this.selectedTags || [];
        }
      });
    }
  }

  public addTags(): void {
    if (!this.showInput) {
      this.initTagForm();
    }
    this.showInput = !this.showInput;
  }

  public showToggleAll(): any {
    return !this.readOnly && this.tags.length > 0 && this.selectedTags.length !== this.tags.length;
  }

  public showUntoggleAll(): any {
    return !this.readOnly && this.selectedTags.length > 0;
  }

  public toggleTag(tag): void {
    const removed = _.remove(this.selectedTags, { objectId: tag.objectId });
    if (removed.length === 0) {
      this.selectedTags.push(tag);
    }
    if (this.isInHeader) {
      this.tagChangeService.setCurrentTagFilter(this.selectedTags);
    }
    this.selectedTagsChange.emit(this.selectedTags);
    this.updateTags.emit(true);
  }

  public setAllTag(): void {
    this.selectedTags = _.cloneDeep(this.tags);
    if (this.isInHeader) {
      this.tagChangeService.setCurrentTagFilter(this.selectedTags);
    }
  }

  public unsetAllTag(): void {
    this.selectedTags = [];
    if (this.isInHeader) {
      this.tagChangeService.setCurrentTagFilter(this.selectedTags);
    }
  }

  public isSelectedTag(tag): any {
    return !!_.find(this.selectedTags, { objectId: tag.objectId });
  }

  public isDefaultag(tag): any {
    return !!_.find(this.defaultTags, { objectId: tag.objectId });
  }

  // https://gist.github.com/jedfoster/7939513
  public mixColors(color1, color2, weight): any {
    return HelperService.mixColors(color1, color2, weight);
  }

  public cancelAddTag(): void {
    this.tagForm = null;
    this.showInput = false;
  }

  public onSubmitAddTag(): void {
    const { color, name } = this.tagForm.value;
    this.tagsService.create(color, name).then(() => this.endSaveTag());
  }

  public buttonStyle(tag: HolTag): any {
    return {
      'background-color': this.isSelectedTag(tag) || this.isDefaultag(tag) ? tag.color : this.mixColors('#FFFFFF', tag.color, 90),
      'border-color': tag.color,
      color: this.isSelectedTag(tag) || this.isDefaultag(tag) ? 'white' : tag.color,
    };
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  private uniqueValidator(control: AbstractControl): any {
    const name = control.get('name').value;
    if (control.get('name').valid) {
      const isNameUnique = this.tags.map(tag => tag.name).some(value => value === name);
      if (isNameUnique) {
        control.get('name').setErrors({ error: true });
      }
    }
  }

  private endSaveTag(): void {
    this.getAllTag(true);
    this.tagForm = null;
    this.showInput = false;
  }

  private initTagForm(): void {
    this.tagForm = this.fb.group(
      {
        color: ['#FFFF00', Validators.required],
        name: [null, [Validators.minLength(2), Validators.required, this.noWhitespaceValidator]],
      },
      { validator: this.uniqueValidator.bind(this) }
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
