import { Action } from '@ngrx/store';
import { OclLogbook } from '../../models/ocl-logbook.model';

export enum OclLogBooksActionTypes {
  INIT_LOGBOOKS = '[OCL LOGBOOKS] Init LogBooks',
  RESET_LOGBOOKS = '[OCL LOGBOOKS] Reset LogBooks',
  UPDATE_ONE_LOGBOOK = '[OCL LOGBOOKS] Update One LogBook',
  UPDATE_MANY_LOGBOOKS = '[OCL LOGBOOKS] Update Many LogBooks',
  UPDATE_LOGBOOKS_FROM_POOLING = '[OCL LOGBOOKS] Update LogBooks From Pooling',
  UPDATE_LOGBOOKS_FROM_LINKED_VALUE = '[OCL LOGBOOKS] Update LogBooks From Linked Value',
  REMOVE_LOGBOOKS_FROM_LINKED_VALUE = '[OCL LOGBOOKS] Remove LogBooks From Linked Value',
  ADD_ONE_LOGBOOK = '[OCL LOGBOOKS] Add One LogBook',
  ADD_MANY_LOGBOOK = '[OCL LOGBOOKS] Add Many LogBook',
  DELETE_ONE_LOGBOOK = '[OCL LOGBOOKS] Delete One LogBook',
}

export class InitLogBooks implements Action {
  readonly type = OclLogBooksActionTypes.INIT_LOGBOOKS;
  constructor(public payload: Array<OclLogbook>) {}
}

export class UpdateOneLogBook implements Action {
  readonly type = OclLogBooksActionTypes.UPDATE_ONE_LOGBOOK;
  constructor(public payload: OclLogbook) {}
}
export class AddOneLogBook implements Action {
  readonly type = OclLogBooksActionTypes.ADD_ONE_LOGBOOK;
  constructor(public payload: OclLogbook) {}
}

export class AddManyLogBooks implements Action {
  readonly type = OclLogBooksActionTypes.ADD_MANY_LOGBOOK;
  constructor(public payload: Array<OclLogbook>) {}
}

export class UpdateManyLogBooks implements Action {
  readonly type = OclLogBooksActionTypes.UPDATE_MANY_LOGBOOKS;
  constructor(public payload: Array<OclLogbook>) {}
}

export class UpdateLogBooksFromPooling implements Action {
  readonly type = OclLogBooksActionTypes.UPDATE_LOGBOOKS_FROM_POOLING;
  constructor(public payload: Array<OclLogbook>) {}
}

export class UpdateLogBooksFromLinkedValue implements Action {
  readonly type = OclLogBooksActionTypes.UPDATE_LOGBOOKS_FROM_LINKED_VALUE;
  constructor(public payload: Array<OclLogbook>, public linkedUid: string) {}
}

export class RemoveLogBooksFromLinkedValue implements Action {
  readonly type = OclLogBooksActionTypes.REMOVE_LOGBOOKS_FROM_LINKED_VALUE;
  constructor(public linkedUid: string) {}
}

export class ResetLogBooks implements Action {
  readonly type = OclLogBooksActionTypes.RESET_LOGBOOKS;
}

export class DeleteOneLogBook implements Action {
  readonly type = OclLogBooksActionTypes.DELETE_ONE_LOGBOOK;
  constructor(public logBookObjectId: string) {}
}

export type LogBooksActions =
  | InitLogBooks
  | ResetLogBooks
  | UpdateOneLogBook
  | AddOneLogBook
  | AddManyLogBooks
  | UpdateManyLogBooks
  | UpdateLogBooksFromPooling
  | UpdateLogBooksFromLinkedValue
  | RemoveLogBooksFromLinkedValue
  | DeleteOneLogBook;
