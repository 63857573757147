import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { RolesService } from 'src/app/common/services/roles.service';
import { MarkdownService } from './../../common/components/markdown-editor/markdown.service';
import { HolAttachments, HolisFile } from 'src/app/common/models/hol-attachments.model';
import { Inject, Injectable } from '@angular/core';
import { HolNotification } from '../../common/models/hol-notification.model';
import { ParseMapperService } from '../../common/services/parse-mapper.service';
import { RequestService } from '../../common/services/request.service';
import { NotificationsService } from '../../common/services/notifications/notifications.service';
import { OclSmsService } from '../../ocl/services/ocl-sms-service/ocl-sms.service';
import { OclMailService } from '../../ocl/services/ocl-mail-service/ocl-mail.service';
import { OclHistoryService } from '../../ocl/services/ocl-history-service/ocl-history.service';
import { ModuleConfigService } from '../../common/services/module-config/module-config.service';
import { HolTag } from '../../common/models/hol-tag';
import { FltFlightService } from './flt-flight.service';
import { HolFlight } from '../../common/models/hol-flight.model';
import { flatten, differenceBy, intersectionBy } from 'lodash';
import * as moment from 'moment';
import { FltEvent } from '../models/flt-event';
import { OclEventService } from 'src/app/ocl/services/ocl-event-service/ocl-event.service';
import { MccOptionsService } from 'src/app/mcc/services/mcc-options.service';
import { OclEventTagService } from 'src/app/ocl/services/ocl-event-tag-service/ocl-event-tag.service';
import { OclEventsStoreManager } from 'src/app/ocl/store/events/ocl-events-store-manager.service';
import { HolNextInfo } from 'src/app/common/models/hol-next-info.model';
import { HolScenario } from 'src/app/common/models/hol-scenario';
import { EclInfos } from 'src/app/common/store/common.model';
import * as _ from 'lodash';
import { HolContext } from 'src/app/common/models/hol-context.model';
import { FltApplicabilityService } from './flt-applicability.service';
import { FltFlightEventService } from './flt-flight-event.service';
import { OclTasksStoreManager } from '../../ocl/store/tasks/ocl-tasks.store-manager';
import { OclTasksService } from '../../ocl/services/ocl-tasks-service/ocl-tasks.service';
import { TagsService } from '../../common/services/tags.service';

@Injectable({
  providedIn: 'root',
})
export abstract class FltEventService<T extends FltEvent = FltEvent> extends OclEventService<FltEvent> {
  // tslint:disable:variable-name
  protected ParseFlightEvents;
  // tslint:enabled

  protected constructor(
    @Inject('$translate') protected $translate: any,
    protected requestService: RequestService,
    protected mccOptionsService: MccOptionsService,
    @Inject('UserService') protected userService,
    protected parseMapper: ParseMapperService,
    protected notificationsService: NotificationsService,
    protected historyService: OclHistoryService,
    protected eventTagService: OclEventTagService,
    protected eventsStoreManager: OclEventsStoreManager,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    @Inject('ChatService') protected chatService,
    @Inject('$rootScope') protected $rootScope,
    public moduleConfig: ModuleConfigService,
    public markdownService: MarkdownService,
    protected flightService: FltFlightService,
    @Inject('$filter') protected $filter,
    @Inject('FilesService') protected filesService,
    protected applicabilityService: FltApplicabilityService,
    protected flightEventService: FltFlightEventService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected tasksService: OclTasksService,
    protected tagsService: TagsService,
    protected rolesService: RolesService,
    public commonStoreManager: CommonStoreManager
  ) {
    super(
      requestService,
      mccOptionsService,
      userService,
      parseMapper,
      notificationsService,
      historyService,
      eventTagService,
      eventsStoreManager,
      mailService,
      smsService,
      chatService,
      $rootScope,
      moduleConfig,
      tasksStoreManager,
      tasksService,
      tagsService,
      rolesService,
      commonStoreManager
    );
  }

  public setAdditionalFields(event: FltEvent, parseEvent: Parse.Object) {
    if (event.applicability) {
      event.applicability.updateParseObject(parseEvent);
    }
  }

  public create(
    description: string,
    scenario: HolScenario,
    notifications: HolNotification[],
    activateCheckLists: boolean,
    attachments: HolAttachments,
    infos: { info: HolNextInfo; notifications: HolNotification[] }[],
    tags: HolTag[],
    acl: Parse.ACL,
    hasToActivateECL: boolean = false,
    eclInfos?: EclInfos,
    eclName?: string,
    initialEvent?: FltEvent,
    context?: HolContext,
    duplicateToOtherModule?: boolean
  ): Promise<T> {
    let addBreakLinesBefore = false;
    if (attachments.note) {
      addBreakLinesBefore = true;
    }
    return super
      .create(
        description,
        scenario,
        notifications,
        activateCheckLists,
        attachments,
        infos,
        tags,
        acl,
        hasToActivateECL,
        eclInfos,
        eclName,
        initialEvent,
        context,
        duplicateToOtherModule
      )
      .then(savedEvent => {
        initialEvent = savedEvent;
        if (initialEvent.applicability) {
          this.flightService.getFlightsForApplicability(initialEvent.applicability, initialEvent.acl).then(applicableFlights => {
            const events = flatten(
              applicableFlights.map(f => {
                return this.getFlightEventFromEvent(f, initialEvent);
              })
            );
            this.requestService.performSaveAllQuery(events).then();
          });
        }
        return this.applicabilityHistoryInNotes(initialEvent, context, addBreakLinesBefore).then(attachments => {
          initialEvent.attachments = attachments;
          const parseEvent = new this.ParseEvents();
          parseEvent.id = initialEvent.objectId;
          parseEvent.set('attachments', JSON.stringify(attachments));
          return this.requestService.performSaveQuery(parseEvent).then(l => {
            return this.newEvent(l, l.tags);
          });
        });
      });
  }

  public async update(
    event: T,
    activateCheckLists?: boolean,
    context?: HolContext,
    newDuplicateToOtherModuleValue?: boolean,
    oldDuplicateToOtherModuleValue?: boolean
  ): Promise<T> {
    const query = new Parse.Query(this.ParseEvents);
    return await this.requestService.performFirstQuery(query).then(result => {
      const oldEvent = _.cloneDeep(new FltEvent(result));
      const oldApplicability = oldEvent.applicability;
      const newApplicability = event.applicability;

      return super.update(event, activateCheckLists, context, newDuplicateToOtherModuleValue, oldDuplicateToOtherModuleValue).then(res => {
        return Promise.all([
          this.flightEventService.getAllFromEvent(event),
          this.flightService.getFlightsForApplicability(event.applicability, event.acl),
        ]).then(([oldFlightLogbooks, matchingFlights]) => {
          const newFlightLogbooks = flatten(
            matchingFlights.map(f => {
              return this.getFlightEventFromEvent(f, event);
            })
          );
          const compareFlightLogbooks = flb => {
            return flb.get('flight').id + '#' + flb.get('station');
          };
          const toRemove = differenceBy(oldFlightLogbooks, newFlightLogbooks, compareFlightLogbooks);
          const toCreate = differenceBy(newFlightLogbooks, oldFlightLogbooks, compareFlightLogbooks);

          return Promise.all([
            this.requestService.performDestroyAllQuery(toRemove),
            this.requestService.performSaveAllQuery(toCreate),
          ]).then(() => {
            if (!_.isEqual(newApplicability, oldApplicability)) {
              return this.applicabilityHistoryInNotes(res, context).then(attachments => {
                event.attachments = attachments;
                const parseEvent = new this.ParseEvents();
                parseEvent.id = res.objectId;
                parseEvent.set('attachments', JSON.stringify(attachments));
                return this.requestService.performSaveQuery(parseEvent).then(l => {
                  return this.newEvent(l, l.tags);
                });
              });
            } else {
              return res as T;
            }
          });
        });
      });
    });
  }

  public close(event: FltEvent, reason: number, reasonText: string, notifications: HolNotification[]): Promise<void> {
    return super.close(event, reason, reasonText, notifications).then(() => {
      this.flightEventService.deleteAllFtlEventsFromEvent(event.objectId);
    });
  }

  private getFlightEventFromEvent(flight: HolFlight, event: FltEvent): Parse.Object[] {
    const flightEvent = this.initFlightEventFromEvent(flight, event);

    if (event.applicability.flightsDirection === 'DEP' && event.applicability.stationsDirection === 'OUT') {
      flightEvent.set('station', flight.departure);
    } else if (event.applicability.flightsDirection === 'ARR' && event.applicability.stationsDirection === 'IN') {
      flightEvent.set('station', flight.destination);
    } else if (!event.applicability.stationsDirection && event.applicability.stations) {
      const flightEvents = [];
      event.applicability.stations.forEach(station => {
        if (flight.departure === station || flight.destination === station) {
          const tempEvent = this.initFlightEventFromEvent(flight, event);
          tempEvent.set('station', station);
          flightEvents.push(tempEvent);
        }
      });
      return flightEvents;
    }
    return [flightEvent];
  }

  protected initFlightEventFromEvent(flight: HolFlight, event: FltEvent) {
    const flightEvent = new this.ParseFlightEvents();
    flightEvent.set('flight', new this.ParseFlight({ id: flight.objectId }));
    flightEvent.set('event', new this.ParseEvents({ id: event.objectId }));
    if (!flight.acl.getPublicWriteAccess()) {
      Object.entries(flight.acl.permissionsById).forEach(([key, value]) => {
        if (key.startsWith('role:') && !_.startsWith(key.replace('role:', ''), this.moduleConfig.config.moduleName.toUpperCase())) {
          delete flight.acl.permissionsById[key];
        }
      });
      flightEvent.setACL(flight.acl);
    } else {
      flightEvent.setACL(event.acl);
    }
    return flightEvent;
  }

  protected newEvent(parseObject?: Parse.Object, eventTags?: Parse.Object[], infosToMap?: []): T {
    if (!parseObject) {
      return null;
    }
    const infos = _.map(infosToMap, info => new HolNextInfo(info));
    const tags = _.orderBy(
      _.map(eventTags, et => new HolTag(et.get('tag'))),
      'name'
    );
    return new FltEvent(parseObject, tags, infos) as T;
  }

  protected markPreviousInfosAsDone(event: FltEvent, nextInfoTime: Date): Promise<HolNextInfo[]> {
    const infosToMarkAsDone = event.infos.filter(info => !info.done && moment(info.nextInfoTime).isSameOrBefore(nextInfoTime));
    const promises = infosToMarkAsDone.map(info => this.markInfoAsDone(info, event, true, false));
    return Promise.all(promises);
  }

  public applicabilityHistoryInNotes(event: FltEvent, context?: HolContext, addMdBreakLinesBefore = false) {
    let content = '';
    let nameFile;
    const dateFormat = 'DD/MM/YY HH:mm[Z]';
    const applicabilityDate = moment.utc(event.updatedAt ? event.updatedAt : event.createdAt).format(dateFormat);
    const attachments = event.attachments ? event.attachments : new HolAttachments();
    const applicability = event.applicability;
    const noteFile: HolisFile = new HolisFile();

    if (event.attachments && event.attachments.note) {
      content += event.attachments.note;
      nameFile = event.attachments.noteFile.fileName;
    } else {
      nameFile = `note-${context.module.toLocaleLowerCase()}-${context.category
        .substring(0, 3)
        .toLocaleLowerCase()
        .replace(/é|è|ê/g, 'e')}-${moment().utc().format('DD-MM-YYYY')}.html`;
      addMdBreakLinesBefore = true;
    }

    content += addMdBreakLinesBefore ? '\n' + '\n' + '\n' : '';
    content += this.applicabilityService.applicabilityHistoryTxt(applicability, applicabilityDate);

    const mdTemplate = content;

    const htmlContent = this.markdownService.parseMdToHtml(content);
    const htmlTemplate = this.markdownService.createHtmlContent(htmlContent, context);

    const blob = new Blob([htmlTemplate], { type: 'text/html' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        this.filesService.uploadFile(nameFile, { base64: reader.result }).then(
          url => {
            noteFile.url = url;
            noteFile.fileName = nameFile;
            attachments.note = mdTemplate;
            attachments.noteFile = noteFile;
            resolve(attachments);
          },
          err => {
            reject(err);
          }
        );
      };
    });
  }

  protected getAdditionnalQueries(query, today) {
    // Filtrage du tableau de bord par date
    // - Soit T = date sélectionnée à 00:00Z ou date à 00:00Z si aucune date n’est sélectionnée
    // - Soit C = date de création d’une carte `createdAt`
    // - Soit S = date de fin d’applicabilité escale d’une carte
    // - Soit F = Max(STA) des vols applicables à une carte
    // - Soit N = NEXT INFO le plus élevé
    // | Applicabilité EVENT | Visibilité       |
    // | ------------------- | ---------------- |
    // | ESCALES             | `C ≤ T && S ≥ T` |
    // | VOLS                | `C ≤ T && F ≥ T` |
    // | TOUS VOLS           | Toujours         | => NON DEV pour l'instant
    // | AUCUNE              | `C ≤ T && N ≥ T` | => Fait dans ocl-event.service.ts
    if (this.moduleConfig.config.canChooseDataStartDate && today instanceof Date && today !== undefined) {
      const todayStart = moment.utc(today).startOf('day');
      const todayEnd = moment.utc(today).endOf('day');

      query.doesNotExist('applStations');
      query.doesNotExist('applFlights');
      query.lessThanOrEqualTo('createdAt', todayEnd.toDate());

      const queryStationApplicability = new Parse.Query(this.ParseEvents);
      queryStationApplicability.exists('applStations');
      queryStationApplicability.notEqualTo('applStations', '');
      queryStationApplicability.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryStationApplicability.greaterThanOrEqualTo('validTo', todayStart.toDate());

      const queryStationApplicability2 = new Parse.Query(this.ParseEvents);
      queryStationApplicability2.exists('applStations');
      queryStationApplicability2.notEqualTo('applStations', '');
      queryStationApplicability2.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryStationApplicability2.doesNotExist('validTo');

      const queryLinkedFlights = new Parse.Query(this.ParseFlight);
      queryLinkedFlights.greaterThanOrEqualTo('sta', todayStart.toDate());
      queryLinkedFlights.notEqualTo('status', 'C');

      const queryFlightsApplicability = new Parse.Query(this.ParseEvents);
      queryFlightsApplicability.exists('applFlights');
      queryFlightsApplicability.notEqualTo('applFlights', '');
      queryFlightsApplicability.lessThanOrEqualTo('createdAt', todayEnd.toDate());
      queryFlightsApplicability.matchesQuery('applFlights', queryLinkedFlights);

      return Parse.Query.or(query, queryStationApplicability, queryStationApplicability2, queryFlightsApplicability);
    } else {
      return query;
    }
  }
}
