import { BreakpointObserver, BreakpointState, MediaMatcher } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Injector, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

/*
var Breakpoints = {
  XSmall: '(max-width: 599.99px)',
  Small: '(min-width: 600px) and (max-width: 959.99px)',
  Medium: '(min-width: 960px) and (max-width: 1279.99px)',
  Large: '(min-width: 1280px) and (max-width: 1919.99px)',
  XLarge: '(min-width: 1920px)',
  Handset: '(max-width: 599.99px) and (orientation: portrait), ' +
      '(max-width: 959.99px) and (orientation: landscape)',
  Tablet: '(min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), ' +
      '(min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape)',
  Web: '(min-width: 840px) and (orientation: portrait), ' +
      '(min-width: 1280px) and (orientation: landscape)',
  HandsetPortrait: '(max-width: 599.99px) and (orientation: portrait)',
  TabletPortrait: '(min-width: 600px) and (max-width: 839.99px) and (orientation: portrait)',
  WebPortrait: '(min-width: 840px) and (orientation: portrait)',
  HandsetLandscape: '(max-width: 959.99px) and (orientation: landscape)',
  TabletLandscape: '(min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape)',
  WebLandscape: '(min-width: 1280px) and (orientation: landscape)',
};
*/

interface HolResponsiveBreakpoint {
  phone: boolean;
  tablet: boolean;
  mobile: boolean;
  desktop: boolean;
}

export class BaseComponent implements OnInit, OnDestroy {
  // --------------- Destroy system -------------------

  protected destroyed: Subject<void> = null;

  // --------------- Breakpoint system -------------------

  private readonly injector = Injector.create({
    providers: [
      { provide: BreakpointObserver, deps: [MediaMatcher, NgZone] },
      { provide: MediaMatcher, deps: [Platform] },
      { provide: Platform, deps: [] },
      { provide: NgZone, useFactory: () => new NgZone({}), deps: [] },
    ],
  });

  private breakpointObserver: BreakpointObserver;

  private breaksSubject: BehaviorSubject<HolResponsiveBreakpoint>;
  public breaksObs: Observable<HolResponsiveBreakpoint>;

  get mobileResolution(): boolean {
    return this.breaksSubject.getValue().mobile;
  }

  get tabletResolution(): boolean {
    return this.breaksSubject.getValue().tablet;
  }

  get phoneResolution(): boolean {
    return this.breaksSubject.getValue().phone;
  }

  get desktopResolution(): boolean {
    return this.breaksSubject.getValue().desktop;
  }

  constructor() {
    // --------------- Destroy system -------------------

    this.destroyed = new Subject<void>();

    // System to avoid super.ngOnDestroy();
    const destroyCycleRef = this.ngOnDestroy.bind(this);
    this.ngOnDestroy = () => {
      destroyCycleRef();

      // Put here ngOnDestroy content!
      this.destroyed.next();
      this.destroyed.complete();

      this.breaksSubject.complete();
    };

    // --------------- Breakpoint system -------------------

    this.breaksSubject = new BehaviorSubject<HolResponsiveBreakpoint>({
      phone: undefined,
      tablet: undefined,
      mobile: undefined,
      desktop: undefined,
    });
    this.breaksObs = this.breaksSubject.asObservable().pipe(skip(1));

    this.breakpointObserver = this.injector.get(BreakpointObserver);

    const phoneBreakpoint = '(max-width: 959.99px)';
    const mobileBreakpoint = '(max-width: 1279.99px)';
    this.breakpointObserver
      .observe([phoneBreakpoint, mobileBreakpoint])
      .pipe(takeUntil(this.destroyed))
      .subscribe((state: BreakpointState) => {
        this.breaksSubject.next({
          phone: state.breakpoints[phoneBreakpoint],
          tablet: !state.breakpoints[phoneBreakpoint] && state.breakpoints[mobileBreakpoint],
          mobile: state.breakpoints[mobileBreakpoint],
          desktop: !state.breakpoints[mobileBreakpoint],
        });
      });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    // Keep this empty!
  }

  public trackByFunction(index, item) {
    if (item && item.objectId) {
      return item.objectId;
    }
    if (item && !item.objectId && item.items && item.items[0].objectId) {
      return item.items[0].objectId;
    }

    return index;
  }
}
