<app-loading *ngIf="loading" class="full-loader"></app-loading>
<div class="hol-dialog-wrapper group-details-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <span>{{ modalTitle }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="col full-width">
        <mat-form-field tabindex="-1" class="description-form-field full-width">
          <textarea
            class="description-textarea"
            formControlName="titleForm"
            matInput
            matTextareaAutosize
            maxlength="64"
            placeholder="{{
              moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
            }}"
            type="text"
          >
          </textarea>
        </mat-form-field>

        <div class="event-group">
          <span class="selection-text">{{
            moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.SELECT_TEXT' | translate
          }}</span>
          <ul class="hol-list">
            <li formArrayName="itemsForm" *ngFor="let item of itemsForm.controls; let i = index">
              <mat-checkbox *ngIf="type === 'EVENT'" class="checkbox" color="primary" [formControlName]="i" [labelPosition]="'after'">
                <span class="checkbox-label scenario" *ngIf="items[i]">{{ items[i].scenario.code }}{{ items[i].order }}</span>
                <span class="checkbox-label description" *ngIf="items[i]">{{ items[i].description }}</span>
              </mat-checkbox>
              <mat-checkbox *ngIf="type === 'LOGBOOK'" class="checkbox" color="primary" [formControlName]="i" [labelPosition]="'after'">
                <span class="checkbox-label description" *ngIf="items[i]">{{ items[i].text }}</span>
              </mat-checkbox>
              <mat-checkbox *ngIf="type === 'DECISION'" class="checkbox" color="primary" [formControlName]="i" [labelPosition]="'after'">
                <span class="checkbox-label description" *ngIf="items[i]">{{ items[i].message }}</span>
              </mat-checkbox>
              <mat-checkbox
                *ngIf="type === 'GLOBAL_INSTRUCTION'"
                class="checkbox"
                color="primary"
                [formControlName]="i"
                [labelPosition]="'after'"
              >
                <span class="checkbox-label description" *ngIf="items[i]">{{ items[i].description }}</span>
              </mat-checkbox>

              <div class="already-group-label" *ngIf="getAlreadyInGroups(items[i]).length > 0">
                <p class="" *ngFor="let g of getAlreadyInGroups(items[i])">
                  <img src="../../../../assets/images/group-attach.svg" />
                  <span>{{ g.title }}</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!isReadOnly">
    <div class="bottom-icon" *ngIf="!isCreate">
      <button mat-button tabindex="-1" class="hol-archive-btn" (click)="onDeleteGroup()">
        <img src="../../../../assets/images/group-delete.svg" />
        <span class="delete-text">{{
          moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.DELETE_GROUP_TEXT' | translate
        }}</span>
      </button>
    </div>

    <div class="fill-space"></div>

    <button (click)="isCreate ? onCreateGroup() : onUpdateGroup()" [disabled]="form.invalid" class="hol-validate-btn" mat-raised-button>
      <mat-icon>done</mat-icon>
      <span *ngIf="isCreate">{{ 'GOC.EVENT_MODAL.ADD' | translate | uppercase }}</span>
    </button>
  </mat-dialog-actions>
</div>
