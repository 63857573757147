import * as _ from 'lodash';
import { Component, Inject, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '../../../common/components/base/base.component';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { NotificationsService } from '../../../common/services/notifications/notifications.service';
import { OclGroupModalComponent } from '../../modals/ocl-group-modal/ocl-group-modal.component';
import { MatDialog } from '@angular/material';
import { OclEvent } from '../../models/ocl-event.model';
import * as moment from 'moment';
import { FlyIn } from 'src/app/common/models/hol-animations';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { OclDecision } from '../../models/ocl-decision.model';
import { RolesService } from '../../../common/services/roles.service';
import { OclGroupService } from '../../services/ocl-group-service/ocl-group.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';

@Component({
  selector: 'app-ocl-container-group-item',
  templateUrl: './ocl-container-group-item.component.html',
  styleUrls: ['./ocl-container-group-item.component.scss'],
  animations: [FlyIn],
})
export class OclContainerGroupItemComponent extends BaseComponent implements OnInit {
  @Input() eclInfos;
  @Input()
  public group: any;
  @Input()
  public groupsFromApi: OclEventGroup[];
  @Input()
  public isReadOnly: boolean;
  @Input()
  public events: OclEvent[];
  @Input()
  public decisionItems: OclDecision[];
  @Input()
  public logBookItems: OclLogbook[];
  @Input()
  public globalInstructionItems: OclGlobalInstructionGroup[];
  @Input()
  public localHourOnly: boolean;
  @Input()
  mostCriticalNextInfo: HolNextInfo;
  @Input() modeLinkIsActivate;
  @Input() decisionToDisplay; // soit la plus récente // soit la pus critique
  @Input() mostRecentLogBook;
  @Input() mostCriticalDecisionInfo;
  @Input() globalInstructionToDisplay; // la plus récente

  @Input() linkedIds;
  @Input() type;

  @Input() airportsCode;

  @Output()
  public updatedEvent: EventEmitter<OclEvent> = new EventEmitter();

  public isOpened: boolean;
  public lastDisplayTime: Date;
  public isUtc: boolean;
  i18nkey: string;
  public RolesService = RolesService;

  constructor(
    @Inject('$mdDialog') protected $mdDialog,
    @Inject('CommonService') protected commonService,
    @Inject('localStorage') protected localStorage,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected notificationsService: NotificationsService,
    protected dialog: MatDialog,
    protected groupService: OclGroupService,
    public moduleConfig: ModuleConfigService
  ) {
    super();

    this.lastDisplayTime = this.localStorage.getOccDashboardLastDisplayTime() || new Date();
  }

  public ngOnInit(): void {
    this.isUtc = this.localHourOnly ? false : !this.isReadOnly;
    if (this.type === 'EVENT') {
      this.i18nkey = 'EVENTS';
    }
    if (this.type === 'LOGBOOK') {
      this.i18nkey = 'LOGBOOK';
    }
    if (this.type === 'DECISION') {
      this.i18nkey = 'STATUS_DECISIONS';
    }
    if (this.type === 'GLOBAL_INSTRUCTION') {
      this.i18nkey = 'GLOBAL_INSTRUCTION';
    }
  }

  public onEditGroup(): void {
    event.stopPropagation();

    const dialogRef = this.dialog.open(OclGroupModalComponent, {
      data: {
        decisionItems: this.type === 'DECISION' ? this.decisionItems : null,
        logBookItems: this.type === 'LOGBOOK' ? this.logBookItems : null,
        events: this.type === 'EVENT' ? this.events : null,
        globalInstructionItems: this.type === 'GLOBAL_INSTRUCTION' ? this.globalInstructionItems : null,
        isReadOnly: this.isReadOnly,
        group: this.group,
        groupsFromApi: this.groupsFromApi,
        type: this.type,
      },
    });
    dialogRef.afterClosed().subscribe(group => {
      // if (group) {
      //   // UPDATE
      //   const g = group;
      //   g.events = _.map(group.items, event => {
      //     return _.find(this.events, (e: OclEvent) => {
      //       return e.objectId === event.objectId;
      //     });
      //   });
      //   this.group = g;
      //   this.updateNextInfo();
      // }
      // this.eventsService.fetchNewData();
      if (this.type === 'EVENT') {
        this.groupService.fetchNewDataEventGroup();
      }
      if (this.type === 'LOGBOOK') {
        this.groupService.fetchNewDataLogBookGroup();
      }
      if (this.type === 'DECISION') {
        this.groupService.fetchNewDataDecisionGroup();
      }
      if (this.type === 'GLOBAL_INSTRUCTION') {
        this.groupService.fetchNewDataGlobalInstructionGroup();
      }
    });
  }

  public onEventUpdated(event): void {
    if (event) {
      //  this.updateNextInfo();
      // const toUpdate = this.group.items.find(e => e.objectId === event.objectId);
      // if (toUpdate) {
      //   _.extend(toUpdate, event);
      //   this.updatedEvent.emit(toUpdate)
      // }
      const toUpdateG = this.events.find(e => e.objectId === event.objectId);
      if (toUpdateG) {
        _.extend(toUpdateG, event);
        this.updatedEvent.emit(toUpdateG);
      }
    }
    //  this.eventsService.fetchNewData();
    // this.groupService.fetchNewDataEventGroup();
  }

  // public updateNextInfo(): void {
  //   if (!this.group.objectId && this.group.items.length !== 0) {
  //     return;
  //   }
  //   this.mostCriticalNextInfo = this.group.getMostCriticalNextInfo();
  // }

  public isSeen(item: OclEvent): any {
    return item && item.createdAt ? this.commonService.isSeen(item, this.lastDisplayTime) : true;
  }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }
}
