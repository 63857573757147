import { orderBy } from 'lodash';
import { OclGroup } from './ocl-group.model';
import { OclDecision } from './ocl-decision.model';

export class OclDecisionGroup extends OclGroup<OclDecision> {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.items =
      parseObject.get('decisions') && parseObject.get('decisions').length
        ? orderBy(
            parseObject.get('decisions').map(dcn => new OclDecision(dcn)),
            'createdAt',
            'desc'
          )
        : [];
  }

  getMostRecentDecision(): OclDecision {
    return this.items[0];
  }

  getMostCriticalInfo(): OclDecision {
    const buffer = orderBy(
      this.items.filter(dcn => {
        if (dcn.nextInfoTime && !dcn.done) {
          return dcn;
        }
      }),
      'nextInfoTime',
      'asc'
    )[0];
    return buffer && buffer.nextInfoTime ? buffer : null;
  }
}
