import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CustomTabsComponent } from './custom-tabs.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-custom-tab',
  styleUrls: ['./custom-tabs.scss'],
  template: `
    <div class="tab-content" [hidden]="!selected" [@active]="!selected">
      <ng-content></ng-content>
    </div>
  `,
  animations: [
    trigger('active', [
      state('true', style({ opacity: 0 })),
      state('false', style({ opacity: 1 })),
      transition('1 => 0', animate('500ms')),
      transition('0 => 1', animate('500ms')),
    ]),
  ],
})
export class CustomTabComponent implements OnInit {
  @Input() tabTitle: string;
  @Input() tabValue: string;
  @HostBinding('class.custom-tab') true;
  @HostBinding('class.isVisible') selected = false;

  constructor(private tabsComponent: CustomTabsComponent) {}

  ngOnInit(): void {
    this.tabsComponent.addTab(this);
  }
}
