import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { Tab } from './tab.interface';

@Component({
  selector: 'app-custom-tabs',
  template: ` <div class="tabs-title">
      <div *ngFor="let tab of tabs">
        <button [ngClass]="{ selected: tab.selected }" (click)="selectTab(tab)" mat-raised-button>
          <span>{{ tab.tabTitle }}</span>
        </button>
      </div>
    </div>
    <ng-content></ng-content>`,
  styleUrls: ['./custom-tabs.scss'],
})
export class CustomTabsComponent {
  @Output() selected = new EventEmitter();
  tabs: Tab[] = [];
  @HostBinding('class.custom-tabs') true;

  addTab(tab: Tab): void {
    if (!this.tabs.length) {
      tab.selected = true;
      this.selected.emit({ selectedTab: tab });
    }
    this.tabs.push(tab);
  }

  selectTab(tab: Tab): void {
    this.tabs.forEach(tabItem => {
      tabItem.selected = false;
    });
    tab.selected = true;
    this.selected.emit({ selectedTab: tab });
  }
}
