/**
 * @ngdoc function
 * @name gestiondecriseApp.component:MenuSideNavController
 * @description
 * # MenuSideNavController
 * header component of the gestiondecriseApp
 */

angular
  .module('gestiondecriseApp')
  .controller('MenuSideNavController', function (
    $state,
    $rootScope,
    $mdSidenav,
    $translate,
    $mdDialog,
    OptionsService,
    UserService,
    AuthenticationService,
    CommonStoreManager
  ) {
    var $ctrl = this;

    this.toggleLeft = buildToggler('left');
    this.companyLogoUrl = null;
    this.username = UserService.getCurrentUser();

    this.hasCrewAccess = $rootScope.accessRights.crew !== 'UNAUTHORIZED';
    this.hasOccAccess = $rootScope.accessRights.occ !== 'UNAUTHORIZED';
    this.hasEclAccess = $rootScope.accessRights.ecl !== 'UNAUTHORIZED';
    this.hasOpsAccess = $rootScope.accessRights.ops !== 'UNAUTHORIZED';
    this.hasExerciseAccess = $rootScope.accessRights.crisis !== 'UNAUTHORIZED';
    this.hasMccAccess = $rootScope.accessRights.mcc !== 'UNAUTHORIZED';

    this.crisis = null;
    this.crisisShoulBeDisplayOnMenu = null;
    this.crisisSubItemsActivated = false;

    this.$onInit = function () {
      this.companyLogoUrl = OptionsService.getCompanyLogoUrl();

      CommonStoreManager.crisis.subscribe(crisis => {
        if (crisis && crisis.objectId) {
          this.crisis = crisis;
          this.crisisShoulBeDisplayOnMenu =
            crisis.isInPreparation || crisis.inProgress || OptionsService.crisisShouldBeDisplayOnMenu(crisis);
        }
      });
    };

    this.$onDestroy = function () {
      unregisterWatchSideNav();
    };

    function buildToggler(componentId) {
      return function () {
        $mdSidenav(componentId).toggle();
      };
    }

    this.logout = function (ev) {
      $ctrl.toggleLeft();

      // Appending dialog to document.body to cover sidenav in docs app
      var confirmOptions = {
        title: $translate.instant('SETTINGS.CONFIRM_LOGOUT'),
        targetEvent: ev,
      };
      UserService.getCurrentUserFunctions()
        .then(function (functions) {
          functions = _.filter(functions, { isHolder: true });
          if (functions.length > 0) {
            var functionsStr = _.map(functions, function (f) {
              return f.shortTitle;
            }).join(', ');
            confirmOptions.htmlContent = $translate.instant('SETTINGS.LOGOUT_WARNING_HOLDER', { functions: functionsStr });
          }
        })
        .finally(function () {
          $mdDialog.show($mdDialog.holConfirm(confirmOptions)).then(function (res) {
            if (res) {
              _logout();
            }
          });
        });
    };

    function _logout() {
      AuthenticationService.logout(true);
    }

    var unregisterWatchSideNav = $rootScope.$watch(
      function () {
        return $rootScope.isSideNavOpen;
      },
      function (isOpen) {
        if (isOpen !== undefined) {
          $ctrl.toggleLeft();
        }
      },
      true
    );

    this.isState = function (stateId) {
      return $state.current.name.indexOf(stateId) !== -1;
    };

    this.toggleCrisisSubItems = function () {
      this.crisisSubItemsActivated = !this.crisisSubItemsActivated;
    };
  });
