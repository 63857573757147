import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-crew-events-list',
  templateUrl: './crew-events-list.component.html',
  styleUrls: ['./crew-events-list.component.scss'],
})
export class CrewEventsListComponent implements OnInit, OnDestroy {
  @Input()
  public currentFilter: any;
  @Input()
  public eventFilter: string;
  @Input()
  public isClosedEvents: boolean;
  @Input()
  public isReadOnly: boolean;

  public events: any[];
  public loading: boolean;
  public groupedEvents: any[];

  public unregisterArchiveDone = this.$scope.$on('crew-archive-done', () => this.init());
  public unregisterPoolService = this.$scope.$on('crewPoolService-events', (e, events) => (this.events = events));
  public unregisterSituationEvent = this.$scope.$on('crew-situation-updated', () => this.init());
  public unregisterWatchFilters = this.$scope.$watch('currentFilter', () => this.init());
  public unregisterWatchSelectedFunction = this.$scope.$watch(
    'crewSelectedFunction',
    () => (this.groupedEvents = this.getGroupByImpactAndDate())
  );

  constructor(
    @Inject('$scope') private $scope,
    @Inject('$mdDialog') private $mdDialog,
    @Inject('$timeout') private $timeout,
    @Inject('CrewFunctionsService') private CrewFunctionsService,
    @Inject('CrewEventsService') private CrewEventsService,
    @Inject('$rootScope') private $rootScope
  ) {}

  public ngOnDestroy(): void {
    this.unregisterPoolService();
    this.unregisterSituationEvent();
    this.unregisterWatchFilters();
    this.unregisterWatchSelectedFunction();
    this.unregisterArchiveDone();
  }

  public ngOnInit(): void {
    this.init();
  }

  getGroupByImpactAndDate = (): any[] => {
    if (this.events && this.events.length) {
      const filtered = this.events.filter(event => this.isInFunction(event) && this.matchSearchFilter(event) && this.matchIsClosed(event));
      const groupedByImpact = _.groupBy(filtered, 'impact');
      const grouped = Object.keys(groupedByImpact).map(impactKey => {
        const groupedByDate = _.groupBy(groupedByImpact[impactKey], event => moment(event.date).startOf('day'));
        return Object.keys(groupedByDate).map(key => groupedByDate[key]);
      });
      const array = [];
      _.forEach(grouped, item => {
        _.forEach(item, i => {
          array.push(i);
        });
      });
      return _.sortBy(array, element => element[0].date);
    } else {
      return [];
    }
  };

  public init(): void {
    this.loading = true;
    this.currentFilter = this.$rootScope.crewEventFilter;
    this.CrewEventsService.getAll(this.currentFilter)
      .then(events => {
        this.events = events;
        this.groupedEvents = this.getGroupByImpactAndDate();
      })
      .finally(() => (this.loading = false));
  }

  public trackByFunction(index, item): any {
    return !item ? null : item[0].objectId;
  }

  // context of this change in a callback, should use arrow function
  isInFunction = (event): boolean => {
    if (event && event.function) {
      return !this.$rootScope.crewSelectedFunction || (event.function && event.function.code === this.$rootScope.crewSelectedFunction);
    }
    return false;
  };

  matchSearchFilter = (event): boolean => {
    if (this.eventFilter === '') {
      return true;
    }

    if (event instanceof Array) {
      return event[0].impact.toUpperCase().indexOf(this.eventFilter.toUpperCase()) > -1;
    } else if (event && event.impact) {
      return event.impact.toUpperCase().indexOf(this.eventFilter.toUpperCase()) > -1;
    }
    return false;
  };

  matchIsClosed = (event): boolean => {
    if (event) {
      return this.isClosedEvents ? this.isClosedEvent(event) : !this.isClosedEvent(event);
    }
    return false;
  };

  public isClosedEvent(event): any {
    return this.CrewEventsService.getNbUserBySituationStatus(event, 'ACCEPTED') >= event.nbPersons;
  }

  // for create button
  public openEventModal($event, event): any {
    this.$mdDialog
      .show({
        controller: 'CrewEventModalCtrl',
        templateUrl: 'views/crew/modals/event.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          functions: this.CrewFunctionsService.getAll,
          isReadOnly: this.$scope.isReadOnly,
          event,
        },
      })
      .then(retVal => {
        if (retVal) {
          this.init();
        }
      });
  }

  // for create button
  public openProperEventModal($event, event): void {
    if (this.$rootScope.isPhone) {
      this.openEventModalPhone($event, event);
    } else {
      this.openEventModal($event, event);
    }
  }

  // for create button
  public openEventModalPhone($event, event): void {
    this.$mdDialog
      .show({
        controller: 'CrewEventModalCtrl',
        templateUrl: 'views/crew/modals/event.responsive.modal.html',
        clickOutsideToClose: false,
        targetEvent: $event,
        locals: {
          functions: this.CrewFunctionsService.getAll,
          isReadOnly: this.$scope.isReadOnly,
          event,
        },
      })
      .then(retVal => {
        if (retVal) {
          this.init();
        }
      });
  }
}
