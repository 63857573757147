import { Inject, Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { delay, distinct, filter, map, skip } from 'rxjs/operators';

import { RolesService } from 'src/app/common/services/roles.service';
import { HolVacationChecklist, HolVacationChecklistItem } from 'src/app/common/models/hol-vacation.model';
import { AppState } from 'src/app/store';
import { GocStopover } from 'src/app/goc/models/goc-stopover.model';
import { ACL } from 'parse';
import { intersectionWith, isEqual } from 'lodash';

import { Store } from '@ngrx/store';

import { HolCrisis } from '../models/hol-crisis';
import { HolFlight } from '../models/hol-flight.model';
import { HolFunction } from '../models/hol-function';
import { HolUserFunction } from '../models/hol-user-function';
import { HolUser } from '../models/hol-user.model';
import { HolVacation } from '../models/hol-vacation.model';
import { CrisisRolesRef } from '../services/functions.service';
import { HelperService } from '../services/helper.service';
import {
  InitCommon,
  InitCrisis,
  InitCrisisRolesRef,
  InitHolFunctions,
  InitHolVacations,
  InitUserFunctions,
  RemoveHolVacations,
  UpdateCurrentFlight,
  UpdateCurrentOclModule,
  UpdateCurrentStopover,
  UpdateCurrentUser,
  UpdateEclInfos,
  UpdateFlights,
  UpdateHolVacation,
  UpdateLayoutGroups,
  UpdateManyUserFunctions,
  UpdateOneFlight,
  UpdateOpsInfos,
  UpdateOpsLayoutInfos,
  UpdateStopovers,
} from './common.actions';
import { CommonStoreState, EclInfos, OpsInfos, OPSLayoutGroups, OPSLayoutInfos } from './common.model';

@Injectable({
  providedIn: 'root',
})
export class CommonStoreManager {
  private _commonState: Observable<CommonStoreState>;
  private _currentFlightState: Observable<HolFlight>;
  private _flights: Observable<HolFlight[]>;
  private _currentModule: Observable<string>;
  private _currentUser: Observable<HolUser>;
  private _crisis: Observable<HolCrisis>;
  private _holVacations: Observable<HolVacation[]>;
  private _currentUserCrisisRolesRef: Observable<CrisisRolesRef>;
  private _filterDataStartDate: Observable<Date>;
  private _layoutInfos: Observable<OPSLayoutInfos>;
  public crisisActivated: Observable<boolean>;
  public crisisInPreparation: Observable<boolean>;
  protected _functionsAllUser: Observable<HolUserFunction[]>;

  constructor(
    private store: Store<AppState>,
    private rolesService: RolesService,
    private helperService: HelperService,
    @Inject('UserService') private userService
  ) {
    this._commonState = this.store.select('common');
    this._currentFlightState = this.store.select('common', 'currentFlight');
    this._flights = this.store.select('common', 'flights');
    this._currentModule = this.store.select('common', 'currentOclModule');
    this._currentUser = this.store.select('common', 'currentUser');
    this._crisis = this.store.select('common', 'crisis');
    this._holVacations = this.store.select('common', 'holVacations');
    this._currentUserCrisisRolesRef = this.store.select('common', 'crisisRolesRef');
    this._filterDataStartDate = this.store.select('common', 'opsLayoutInfo', 'filterDataStartDate');
    this._layoutInfos = this.store.select('common', 'opsLayoutInfo');
    this.crisisActivated = this.store.select('common', 'crisis', 'inProgress').pipe(
      skip(1),
      distinct(),
      filter(inProgress => inProgress),
      delay(1000)
    );
    this.crisisInPreparation = this.store.select('common', 'crisis', 'isInPreparation').pipe(
      skip(1),
      distinct(),
      filter(isInPreparation => isInPreparation),
      delay(1000)
    );
    this._functionsAllUser = this.store.select('common', 'holUserFunctions');
  }

  get commonState(): Observable<CommonStoreState> {
    return this._commonState;
  }

  get currentFlightState(): Observable<HolFlight> {
    return this._currentFlightState;
  }

  get flightList(): Observable<HolFlight[]> {
    return this._flights;
  }

  get currentModule(): Observable<string> {
    return this._currentModule;
  }

  get currentUser(): Observable<HolUser> {
    return this._currentUser;
  }

  get crisis(): Observable<HolCrisis> {
    return this._crisis;
  }

  get holVacations(): Observable<HolVacation[]> {
    return combineLatest(this._holVacations, this.rolesService.$companiesRolesFilter).pipe(
      map(([vac]) => this.rolesService.filterFromCompanyRoles(vac))
    );
  }

  get currentUserCrisisRolesRef(): Observable<CrisisRolesRef> {
    return this._currentUserCrisisRolesRef;
  }

  get filterDataStartDate(): Observable<Date> {
    return this._filterDataStartDate;
  }

  get layoutInfos(): Observable<OPSLayoutInfos> {
    return this._layoutInfos;
  }

  initCommon(linkedItems: { [key: string]: any }): void {
    this.store.dispatch(new InitCommon(linkedItems));
  }

  updateCurrentOclModule(moduleName: string): void {
    this.store.dispatch(new UpdateCurrentOclModule(moduleName));
  }

  updateEclInfos(eclInfos: EclInfos): void {
    this.store.dispatch(new UpdateEclInfos(eclInfos));
  }

  updateOpsLayoutInfos(opsLayoutInfos: Partial<OPSLayoutInfos>): void {
    this.store.dispatch(new UpdateOpsLayoutInfos(opsLayoutInfos));
  }

  updateOpsInfos(opsInfos: OpsInfos): void {
    this.store.dispatch(new UpdateOpsInfos(opsInfos));
  }

  updateLayoutGroups(groups: OPSLayoutGroups): void {
    this.store.dispatch(new UpdateLayoutGroups(groups));
  }

  updateFlights(flights: HolFlight[]): void {
    this.store.dispatch(new UpdateFlights(flights));
  }

  updateStopovers(stopovers: GocStopover[]): void {
    this.store.dispatch(new UpdateStopovers(stopovers));
  }

  updateOneFlight(flight: HolFlight): void {
    this.store.dispatch(new UpdateOneFlight(flight));
  }

  updateCurrentFlight(flight: HolFlight) {
    this.store.dispatch(new UpdateCurrentFlight(flight));
  }

  updateCurrentStopover(stopover: GocStopover) {
    this.store.dispatch(new UpdateCurrentStopover(stopover));
  }

  updateCurrentUser(user: HolUser) {
    this.store.dispatch(new UpdateCurrentUser(user));
  }

  initCrisis(crisis: Partial<HolCrisis>) {
    this.store.dispatch(new InitCrisis(crisis));
  }

  initCrisisRolesRef(crisisRoles: CrisisRolesRef) {
    this.store.dispatch(new InitCrisisRolesRef(crisisRoles));
  }

  initHolFunctions(holFunctions: HolFunction[]) {
    this.store.dispatch(new InitHolFunctions(holFunctions));
  }

  initHolVacations(holVacations: HolVacation[]) {
    this.store.dispatch(new InitHolVacations(holVacations));
  }

  updateHolVacation(
    vacationId: string,
    checklistId: string,
    checklistItemId: string,
    partial: Partial<HolVacation | HolVacationChecklist | HolVacationChecklistItem>
  ) {
    this.store.dispatch(new UpdateHolVacation(vacationId, checklistId, checklistItemId, partial));
  }

  removeHolVacations(holVacationId: string) {
    this.store.dispatch(new RemoveHolVacations(holVacationId));
  }

  initHolUserFunctions(userFunctions: HolUserFunction[]): void {
    this.store.dispatch(new InitUserFunctions(userFunctions));
  }

  updateManyUserFunctions(userFunctions: HolUserFunction[]) {
    this.store.dispatch(new UpdateManyUserFunctions(userFunctions));
  }

  async getUsersByFunction(functionId: string, acl?: ACL) {
    let companies = [];
    if (acl) {
      companies = this.helperService.parseACL(acl);
    }

    const allUser = await this.userService.getAllUsers();
    const functionsAllUser = this.getValue(this._functionsAllUser);
    const usersIdToNotify = functionsAllUser.filter(func => func.functionId === functionId).map(el => el.userId);
    const usersToNotify = allUser.filter(user => usersIdToNotify.findIndex(userId => userId === user.userId) !== -1);

    if (acl) {
      const promises = usersToNotify.map(el => this.rolesService.getUserCompaniesByUniverse(el.objectId, 'ERP'));
      return Promise.all(promises).then(el => {
        usersToNotify.forEach((user, index) => {
          user.companies = el[index];
        });
        return usersToNotify.filter(user => intersectionWith(companies, user.companies, isEqual).length > 0);
      });
    } else {
      return usersToNotify;
    }
  }

  getValue(obj: Observable<any>) {
    let value: any;
    obj.subscribe(v => (value = v));
    return value;
  }
}
