'use strict';

/**
 * @ngdoc directive
 * @name gestiondecriseApp.directive:highlight
 * @description
 * # highlight
 */
angular.module('gestiondecriseApp').directive('highlight', function ($timeout, CONSTANTS) {
  return {
    restrict: 'A',
    scope: {
      model: '=highlight',
    },
    link: function (scope, element) {
      scope.$watch('model', function (nv, ov) {
        if (nv !== ov) {
          // apply class
          element.addClass('highlight');

          // auto remove after some delay
          $timeout(function () {
            element.removeClass('highlight');
          }, CONSTANTS.HIGHLIGHT_TIME);
        }
      });
    },
  };
});
