<app-loading *ngIf="loading" class="full-loader"></app-loading>
<app-hol-modal
  [item]="decision"
  [isReadOnly]="isReadOnly || isHistory"
  [isCreate]="isCreate"
  [type]="'DECISION'"
  [context]="context"
  [contentTextLength]="200"
  [notifications]="isCreate ? notifications : []"
  [canArchive]="false"
  [historyItemComponent]="data.itemComponent"
  [historyItemListMode]="true"
  [historyItemObjectId]="decision.objectId"
  [historyItemType]="'decision'"
  [historyTitle]="moduleConfig.config.translateKey + '.MODALS.HISTORY.DECISION'"
  [canEditTitle]="!isHistory"
  [isHistory]="isHistory"
  [nextInfo]="nextInfo"
  [disabledValidateActions]="decision.isTodo && !nextInfo?.nextInfoTime"
  [isUTC]="isUtc"
  (saveItem)="saveDecision($event)"
>
  <div class="logbook-modal">
    <div *ngIf="isTodoConfigActivated" class="hol-dialog-section--col">
      <mat-checkbox
        [disabled]="isReadOnly"
        (ngModelChange)="toggleIsTodo()"
        [(ngModel)]="decision.isTodo"
        aria-label="is todo"
        color="accent"
      >
        {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.CONVERT_TO_TODO' | translate }}
      </mat-checkbox>
      <p>
        <i class="text-muted" *ngIf="decision.isTodo">
          {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.TODO_HINT' | translate }}
        </i>
      </p>
    </div>
    <!-- TAGS -->
    <div class="hol-dialog-section">
      <app-tag-selector class="tag-selector" [readOnly]="isReadOnly" [(selectedTags)]="decision.tags"></app-tag-selector>
    </div>
    <!-- FROM OTHER MODULE -->
    <div *ngIf="decision.fromERP && hasCrisisInProgress" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="fromERP"
        [labelPosition]="'after'"
        color="primary"
        [disabled]="isHistory || decision.readOnly || isReadOnly"
      >
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.FROM_ERP' | translate }}
      </mat-checkbox>
    </div>
    <!-- TO OTHER MODULE -->
    <div *ngIf="moduleConfig.config.displayToERP && !decision.fromERP" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="toERP"
        [labelPosition]="'after'"
        color="primary"
        [disabled]="isHistory || decision.readOnly || !hasCrisisInProgress || !hasErpAccess"
      >
        {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.TO_ERP' | translate }}
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
      </mat-checkbox>
    </div>
    <!-- LINKED EVENTS -->
    <div class="hol-dialog-section" *ngIf="!isFromLogBook">
      <div class="linked-section" *ngIf="linkedEvents; else linkedEventLoadingTemplate">
        <div class="linked-section__top">
          <div class="left">
            <span class="title-read-only" *ngIf="(isReadOnly || modeLinkIsActivate) && linkedEventsDisplay.length">{{
              moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LABEL' | translate
            }}</span>
            <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
              <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LABEL' | translate }}</mat-label>
              <mat-select
                ngModel
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="linkedEvents"
                (ngModelChange)="changelinkedEvent($event)"
                multiple
                [disabled]="events.length === 0"
              >
                <mat-select-trigger>
                  <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_SEARCH' | translate }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let event of events" [value]="event.objectId">{{
                  event.scenario.code + event.order + ' - ' + event.description
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="right">
            <button
              *ngIf="!isReadOnly && isCreate && !modeLinkIsActivate"
              class="add-button mat-icon-button"
              color="primary"
              (click)="addEvent($event)"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="linked-section__bottom">
          <div *ngFor="let event of linkedEventsDisplay" class="linked-section__bottom__row">
            <div class="left">
              {{ event.scenario.code + event.order + ' - ' + event.description }}
              <span *ngIf="disabledLinks[event.objectId]">{{
                moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
              }}</span>
            </div>
            <div class="right">
              <button
                *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[event.objectId]"
                (click)="removeEvent(event)"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #linkedEventLoadingTemplate>
        <div class="linked-section">
          <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LOADING' | translate }}</span>
        </div>
      </ng-template>
    </div>
    <!-- LINKED LOGBOOKS -->
    <div class="hol-dialog-section" *ngIf="!isFromLogBook">
      <div class="linked-section" *ngIf="linkedLogBooks; else linkedLogBooksLoadingTemplate">
        <div class="linked-section__top">
          <div class="left">
            <span class="title-read-only" *ngIf="(isReadOnly || modeLinkIsActivate) && linkedLogBooksDisplay.length">{{
              moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.LOGBOOK_LABEL' | translate
            }}</span>
            <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
              <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.LOGBOOK_LABEL' | translate }}</mat-label>
              <mat-select
                ngModel
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="linkedLogBooks"
                (ngModelChange)="changelinkedLogBook($event)"
                multiple
                [disabled]="logBookItems.length === 0"
              >
                <mat-select-trigger>
                  <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.LOGBOOK_SEARCH' | translate }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let logBook of logBookItems" [value]="logBook.objectId">{{ logBook.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="right">
            <button
              *ngIf="!isReadOnly && isCreate && !modeLinkIsActivate"
              class="add-button mat-icon-button"
              color="primary"
              (click)="addLogbook($event)"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="linked-section__bottom">
          <div *ngFor="let logBook of linkedLogBooksDisplay" class="linked-section__bottom__row">
            <div class="left">
              {{ logBook.text }}
              <span *ngIf="disabledLinks[logBook.objectId]">{{
                moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
              }}</span>
            </div>
            <div class="right">
              <button
                *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[logBook.objectId]"
                (click)="removeLogBook(logBook)"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #linkedLogBooksLoadingTemplate>
        <div class="linked-section">
          <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.LOGBOOK_LOADING' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- COLUMN RIGHT -->
  <div class="content-right-top-addon">
    <!-- LINKED OCC FLIGHT -->
    <div class="hol-dialog-section" *ngIf="moduleConfig.config.showLinkToFlightOcc && !decision.isTodo">
      <div class="linked-flight">
        <label id="favoriteStateLabel">{{
          moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.FLIGHT' | translate
        }}</label>
        <div class="date input-group">
          <mat-form-field class="full-width no-label">
            <input
              [disabled]="isReadOnly"
              tabindex="-1"
              [matDatepicker]="flightPicker"
              [ngModelOptions]="{ timezone: 'utc', standalone: true }"
              (ngModelChange)="changeDate($event)"
              [ngModel]="searchData.date"
              matInput
              placeholder="DD/MM/YYYY"
            />
            <mat-datepicker-toggle [for]="flightPicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #flightPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flight">
          <mat-form-field>
            <span matPrefix class="icon-container">
              <i class="hol-icon-airplane-mode-active"></i>
            </span>
            <input
              type="text"
              matInput
              [matAutocomplete]="auto"
              name="searchDataFight"
              [disabled]="!searchData.date || searchData.flights.length === 0 || isReadOnly"
              placeholder="{{ autocompletePlaceHolder }}"
              [ngModelOptions]="{ standalone: true }"
              [value]="searchData.flightNumber"
              [ngModel]="searchData.flightNumber"
              (ngModelChange)="querySearch($event)"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedItemChange($event)">
              <mat-option *ngFor="let item of searchData.flightsFiltered" [value]="item.objectId">
                {{ item.flightNumber }} {{ item.std | date: 'HH:mm ddMMM':'UTC' }}
              </mat-option>
              <mat-option *ngIf="searchData.flightsFiltered.length === 0 && searchData.date"
                >No results found with this search.
              </mat-option>
            </mat-autocomplete>
            <button
              *ngIf="selectedFlight && !isReadOnly"
              mat-icon-button
              matSuffix
              (click)="resetFlight()"
              mat-icon-button
              aria-label="Clear"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <!-- PINNED BTN -->
  <div class="actions-right-addon">
    <div *ngIf="!isReadOnly" class="pinned-item-btn" [ngClass]="{ isPinned: decision.isPinned }">
      <mat-checkbox [(ngModel)]="decision.isPinned" aria-label="is Pinned" color="primary">
        <i class="hol-icon-pin"></i>
      </mat-checkbox>
    </div>
    <div *ngIf="isReadOnly && decision.isPinned" class="pinned-item-btn isPinned">
      <i class="hol-icon-pin"></i>
    </div>
  </div>
</app-hol-modal>
