import { Inject, Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionsStoreManager } from 'src/app/ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { OpsGlobalInstructionTagService } from '../ops-global-instruction-tag-service/ops-global-instruction-tag.service';
import { OpsModuleConfigService } from '../ops-module-config/ops-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class OpsGlobalInstructionService extends OclGlobalInstructionService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('OPSGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('OPSGlobalInstruction');
  protected ParseTag = Parse.Object.extend('OPSTag');
  // tslint:enable
  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    @Inject('UserService') protected userService,
    protected globalInstructionTagService: OpsGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    public moduleConfig: OpsModuleConfigService
  ) {
    super($rootScope, requestService, userService, globalInstructionTagService, globalInstructionsStoreManager, moduleConfig);
  }
}
