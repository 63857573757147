import { ConfirmationModalComponent } from 'src/app/common/modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { cloneDeep } from 'lodash';
import { HolAttachments } from 'src/app/common/models/hol-attachments.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { OccGlobalInstructionService } from 'src/app/occ/services/occ-global-instruction-service/occ-global-instruction.service';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';
import { OclGlobalInstructionService } from '../../services/ocl-global-instruction-service/ocl-global-instruction.service';

@Component({
  selector: 'app-ocl-global-instruction-modal',
  templateUrl: './ocl-global-instruction-modal.component.html',
  styleUrls: ['./ocl-global-instruction-modal.component.scss'],
})
export class OclGlobalInstructionModalComponent implements OnInit {
  loading: boolean;
  isCreate: boolean;
  isReadOnly: boolean;
  form: FormGroup;
  attachments = new HolAttachments();
  tags: HolTag[];
  globalInstuction: OclGlobalInstruction;

  constructor(
    public moduleConfig: ModuleConfigService,
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<OclGlobalInstructionModalComponent>,
    private globalInstructionService: OclGlobalInstructionService,
    private dialog: MatDialog,
    @Inject('$translate') private $translate
  ) {
    this.isReadOnly = data.isReadOnly;
    this.isCreate = !data.globalInstuction;
    this.globalInstuction = cloneDeep(data.globalInstuction) || new OclGlobalInstruction();
  }

  ngOnInit() {
    this.form = new FormGroup({
      description: new FormControl('', [Validators.required]),
    });
    if (!this.isCreate) {
      if (this.globalInstuction.tags) {
        this.tags = cloneDeep(this.globalInstuction.tags);
      }
      if (this.globalInstuction.attachments) {
        this.attachments = cloneDeep(this.globalInstuction.attachments);
      }
      this.form.setValue({
        description: this.globalInstuction.description,
      });
    }
  }

  save() {
    this.loading = true;
    this.globalInstuction.description = this.form.get('description').value;
    this.globalInstuction.tags = this.tags;
    this.globalInstuction.attachments = this.attachments;
    this.globalInstructionService
      .save(this.globalInstuction)
      .then(saveGlobalInstruction => {
        this.globalInstuction = cloneDeep(saveGlobalInstruction);
      })
      .finally(() => {
        this.dialogRef.close(this.globalInstuction);
        this.loading = false;
      });
  }

  archive(): void {
    this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: this.$translate.instant('COMMON.MODALS.CONFIRMATION.ARCHIVE_TITLE'),
          modalContent: this.$translate.instant(
            this.moduleConfig.config.translateKey + '.DASHBOARD.GLOBAL_INSTRUCTION.MODAL.WARNING_CANCEL'
          ),
          modalQuestion: this.$translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
        },
      })
      .afterClosed()
      .toPromise()
      .then(confirm => {
        if (confirm) {
          this.loading = true;
          this.globalInstructionService.archive(this.globalInstuction).then((archivedGlobalInstuction: OclGlobalInstruction) => {
            this.loading = false;
            this.dialogRef.close(archivedGlobalInstuction);
          });
        }
      });
  }
}
