import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { ErpCrisisType } from '../models/erp-crisisTypes';

@Injectable({
  providedIn: 'root',
})
export class ErpCrisisTypeService {
  // tslint:disable:variable-name
  ParseCrisisType = Parse.Object.extend('GDCCrisisTypes');
  // tslint:enabled

  constructor(private requestService: RequestService) {}

  getCrisisTypebyCrisisTypeId(crisisTypeId): Promise<ErpCrisisType> {
    const crisisTypeQuery = new Parse.Query(this.ParseCrisisType);
    crisisTypeQuery.include('ACL');
    crisisTypeQuery.equalTo('crisisTypeId', crisisTypeId);
    return this.requestService.performFirstQuery(crisisTypeQuery).then(crisisTasksFromApi => {
      return new ErpCrisisType(crisisTasksFromApi);
    });
  }

  getAll() {
    const query = new Parse.Query(this.ParseCrisisType);
    query.ascending('title');
    return this.requestService.performFindQuery(query).then(parseCrisisTypes => {
      return parseCrisisTypes.map(pct => new ErpCrisisType(pct));
    });
  }
}
