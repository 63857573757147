import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  // tslint:disable-next-line
  selector: 'app-crew-dashboard-ctrl',
})
export class CrewDashboardComponentDirective extends UpgradeComponent {
  @Input() instructions: any;
  @Input() instructionGroups: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('crewDashboardComponent', elementRef, injector);
  }
}
