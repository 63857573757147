import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolCrisis } from '../models/hol-crisis';

@Injectable({
  providedIn: 'root',
})
export class CrisisService {
  public crisisInCreation = false;
  public crisisInPreparation = false;

  constructor(protected requestService: RequestService) {}

  get(): Promise<HolCrisis> {
    return this.requestService.performCloudCode<Parse.Object>('getCrisis', null).then(parseCrisis => {
      return new HolCrisis(parseCrisis);
    });
  }
}
