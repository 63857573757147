<div class="hol-dialog-wrapper hol-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <span class="title" *ngIf="isCreate">
        {{ moduleConfig.config.translateKey + '.MODALS.CRISIS.NEW_TITLE' | translate }}
      </span>
    </div>

    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-form-field class="mat-form-field">
        <mat-label>{{ 'ERP.MODALS.CRISIS.CRISIS_TYPE_LABEL' | translate }}</mat-label>
        <mat-select formControlName="crisisType">
          <mat-option *ngFor="let crisisType of crisisTypeList" [value]="crisisType">{{ crisisType.title }} </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="form.get('crisisType').value">
        <div>
          <mat-radio-group formControlName="exercice">
            <mat-radio-button color="accent" [value]="true">
              {{ 'CRISIS.MODAL.BUTTON_EXERCISE' | translate }}
            </mat-radio-button>
            <mat-radio-button color="warn" [value]="false">
              {{ 'CRISIS.MODAL.BUTTON_NOT_EXERCISE' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field class="mat-form-field">
          <mat-label>{{ form.get('crisisType').value.descriptionTitle }}</mat-label>
          <input matInput [placeholder]="form.get('crisisType').value.descriptionHint" formControlName="subTitle" />
        </mat-form-field>
        <div>
          <mat-form-field class="mat-form-field">
            <mat-label>{{ form.get('crisisType').value.info1Title }}</mat-label>
            <input matInput [placeholder]="form.get('crisisType').value.info1Hint" maxLength="12" formControlName="info1" />
          </mat-form-field>
          <mat-form-field class="mat-form-field">
            <mat-label>{{ form.get('crisisType').value.info2Title }}</mat-label>
            <input matInput [placeholder]="form.get('crisisType').value.info2Hint" maxLength="12" formControlName="info2" />
          </mat-form-field>
          <mat-form-field class="mat-form-field">
            <mat-label>{{ form.get('crisisType').value.info3Title }}</mat-label>
            <input matInput [placeholder]="form.get('crisisType').value.info3Hint" maxLength="12" formControlName="info3" />
          </mat-form-field>
          <mat-form-field class="mat-form-field">
            <mat-label>{{ form.get('crisisType').value.info4Title }}</mat-label>
            <input matInput [placeholder]="form.get('crisisType').value.info4Hint" maxLength="12" formControlName="info4" />
          </mat-form-field>
        </div>
        <mat-form-field class="mat-form-field full-width">
          <mat-label>{{ 'DASHBOARD.MODAL.LABEL.DESC' | translate }}</mat-label>
          <input matInput [placeholder]="'DASHBOARD.MODAL.LABEL.DESC_PLACEHOLDER' | translate" formControlName="mainTitle" />
        </mat-form-field>
        <!--      <div>-->
        <!--        <div>-->
        <!--          <mat-label>{{ 'DASHBOARD.CLOCKS.TITLE' | translate }}</mat-label>-->
        <!--        </div>-->
        <!--        <mat-form-field class="mat-form-field">-->
        <!--          <mat-label>{{ 'DASHBOARD.MODAL.LABEL.CLOCK_DEPARTURE' | translate }}</mat-label>-->
        <!--          <mat-select formControlName="clockDeparture">-->
        <!--            <mat-option [value]="null"></mat-option>-->
        <!--            <mat-option *ngFor="let zone of utcZones" [value]="zone"> {{ zone }} </mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field class="mat-form-field">-->
        <!--          <mat-label>{{ 'DASHBOARD.MODAL.LABEL.CLOCK_ON_SITE' | translate }}</mat-label>-->
        <!--          <mat-select formControlName="clockOnSite">-->
        <!--            <mat-option [value]="null"></mat-option>-->
        <!--            <mat-option *ngFor="let zone of utcZones" [value]="zone"> {{ zone }} </mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
        <!--        <mat-form-field class="mat-form-field">-->
        <!--          <mat-label>{{ 'DASHBOARD.MODAL.LABEL.CLOCK_ARRIVAL' | translate }}</mat-label>-->
        <!--          <mat-select formControlName="clockArrival">-->
        <!--            <mat-option [value]="null"></mat-option>-->
        <!--            <mat-option *ngFor="let zone of utcZones" [value]="zone"> {{ zone }} </mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
        <!--      </div>-->
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-acl-selector
      class="acl-selector"
      [moduleName]="moduleConfig.config.moduleName"
      [limitTo]="form.get('crisisType').value?.acl"
      [object]="crisis"
      [formGroup]="form"
      [extendACLOnly]="!isCreate"
    ></app-acl-selector>
    <div class="actions-container">
      <button
        class="actions-addon"
        [disabled]="form.invalid"
        *ngIf="canActivateCrisis && isCreate"
        mat-raised-button
        (click)="saveCrisis(true)"
        color="warn"
      >
        {{ 'ERP.MODALS.CRISIS.ACTIVATE_BUTTON' | translate }}
      </button>
      <button class="actions-addon hol-validate-btn-secondary" [disabled]="form.invalid" mat-raised-button (click)="saveCrisis(false)">
        {{ 'ERP.MODALS.CRISIS.PREPARE_BUTTON' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
