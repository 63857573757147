import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { OptionsService } from 'src/app/common/services/options.service';
import * as moment from 'moment';
import { MarkdownEditorComponent, MdEditorOption } from 'ngx-markdown-editor';

import { HolisFile } from '../../models/hol-attachments.model';
import { HolNoteTemplate } from '../../models/hol-note-template';
import { TranslatePipe } from '../../pipes/translate/translate.pipe';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { NoteTemplateService } from '../../services/note-template.service';
import { MarkdownService } from './markdown.service';

@Component({
  selector: 'app-markdown-editor-modal',
  templateUrl: './markdown-editor-modal.component.html',
})
export class MarkdownEditorModalComponent implements OnInit, AfterViewInit {
  public options: MdEditorOption = {
    resizable: false,
    showBorder: false,
    hideIcons: ['FullScreen', 'Image'],
  };
  public attachment: string;
  public title: string;
  public noteFile: HolisFile;
  i18nTitle: string;
  i18nSubTitle: string;
  i18nUnivers: string;
  isUTC: boolean;

  public context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlScenario?: string;
    htmlDate;
    htmlNextInfo?;
    htmlTemplate: string;
  };
  localHourOnly: boolean;
  public htmlTemplate: string;
  public loading = false;
  public noteTemplates: HolNoteTemplate[] = [];
  public displayName = '';
  public today = moment();
  public todayUTC = moment.utc();
  public nextInfoHtmlSource: string;
  @ViewChild('mdEditor') mdEditor: MarkdownEditorComponent;
  // this.translatePipe.transform('TASKS.ARCHIVE'),
  constructor(
    private translatePipe: TranslatePipe,
    private moduleConfig: ModuleConfigService,
    private optionsService: OptionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MarkdownEditorModalComponent>,
    @Inject('FilesService') private filesService,
    private noteTemplateService: NoteTemplateService,
    @Inject('UserService') private userService,
    private http: HttpClient,
    @Inject('CONSTANTS') private CONSTANTS,
    public markdownService: MarkdownService
  ) {
    this.attachment = this.data.attachment;
    this.context = this.data.context;
    let dateFormat = '';
    dateFormat = dateFormat + 'DD/MM/YY[\r\n]';
    dateFormat += 'HH:mm';
    this.isUTC = !this.moduleConfig.config.localHours;
    if (this.isUTC) {
      dateFormat += '[Z]';
      if (this.context.htmlDate) {
        this.context.htmlDate = moment.utc(this.context.htmlDate).format(dateFormat);
      } else {
        this.context.htmlDate = this.todayUTC.format(dateFormat);
      }
      if (this.context.htmlNextInfo && this.context.htmlNextInfo.createdAt) {
        this.context.htmlNextInfo.createdAt = moment.utc(this.context.htmlNextInfo.createdAt).format(dateFormat);
      } else if (this.context.htmlNextInfo && !this.context.htmlNextInfo.createdAt) {
        this.context.htmlNextInfo.createdAt = this.todayUTC.format(dateFormat);
      }
      if (this.context.htmlNextInfo && this.context.htmlNextInfo.nextInfoTime) {
        this.context.htmlNextInfo.nextInfoTime = moment.utc(this.context.htmlNextInfo.nextInfoTime).format(dateFormat);
      } else if (this.context.htmlNextInfo && !this.context.htmlNextInfo.nextInfoTime) {
        this.context.htmlNextInfo.nextInfoTime = this.todayUTC.format(dateFormat);
      }
    } else {
      if (!this.context.htmlDate) {
        this.context.htmlDate = moment(this.context.htmlDate).format(dateFormat);
      } else {
        this.context.htmlDate = this.today.format(dateFormat);
      }
      if (this.context.htmlNextInfo && !this.context.htmlNextInfo.createdAt) {
        this.context.htmlNextInfo.createdAt = moment(this.context.htmlNextInfo.createdAt).format(dateFormat);
      } else if (this.context.htmlNextInfo && !this.context.htmlNextInfo.createdAt) {
        this.context.htmlNextInfo.createdAt = this.today.format(dateFormat);
      }
      if (this.context.htmlNextInfo && this.context.htmlNextInfo.nextInfoTime) {
        this.context.htmlNextInfo.nextInfoTime = moment(this.context.htmlNextInfo.nextInfoTime).format(dateFormat);
      } else if (this.context.htmlNextInfo && !this.context.htmlNextInfo.nextInfoTime) {
        this.context.htmlNextInfo.nextInfoTime = this.today.format(dateFormat);
      }
    }

    this.displayName = this.userService.getCurrentUserObject().fullName;
    this.noteFile = this.data.noteFile
      ? this.data.noteFile
      : {
          fileName: '',
          url: '',
        };
  }

  public ngOnInit(): void {
    this.mdEditor.togglePreview();
    this.noteTemplateService.getAllNoteTemplate().then(data => (this.noteTemplates = data));
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.mdEditor.aceEditorContainer.nativeElement.getElementsByTagName('textarea')[0].focus(), 500);
  }

  public changeTemplate(event): void {
    this.loading = true;
    this.http.get(event.value, { responseType: 'text' }).subscribe(data => {
      this.loading = false;
      this.attachment = data;
    });
  }

  public validateNote(): void {
    const nameFile = `note-${this.context.module.toLocaleLowerCase()}-${this.context.category
      .substring(0, 3)
      .toLocaleLowerCase()
      .replace(/é|è|ê/g, 'e')}-${moment().utc().format('DD-MM-YYYY')}.html`;
    this.loading = true;
    let base64pdf;

    const htmlSource = document.getElementsByClassName('preview-panel')[0];

    // TODO: Don't use this jQuery selector!
    const $htmlContent = $(htmlSource.outerHTML);
    // Replace new lines by <br/> and spaces by &nbsp in code nodes
    // $htmlContent.find('code').each((i, elm: any) => {
    //   elm.innerHTML = elm.innerText.replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;');
    // });
    const lineSeparator = '<div>____________________________________________________________________________________</div>';
    // replace code and pre nodes by div (fromHTML ignores code and pre nodes)
    // $htmlContent.html(
    //   $htmlContent
    //     .html()
    //     .replace(/(<pre)/g, lineSeparator + '<div style="font-family: monospace"')
    //     .replace(/’/g, "'")
    //     .replace(/€/g, 'Euro')
    //     .replace(/(<code\sclass=\"[^\"]*\")/g, '<span')
    //     .replace(/(<code)/g, '<span')
    //     .replace(/(<\/code>)/g, '</span>')
    //     .replace(/(<\/pre>)/g, '</div>' + lineSeparator)
    // );
    this.htmlTemplate = this.markdownService.createHtmlContent($htmlContent[0].outerHTML, this.context);

    setTimeout(() => {
      const blob = new Blob([this.htmlTemplate], { type: 'text/html' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        base64pdf = reader.result;
        this.sendHtlmToDatabase(base64pdf, nameFile);
      };
    }, 10);
  }

  public sendHtlmToDatabase(data: string, nameFile: string): void {
    this.filesService.uploadFile(nameFile, { base64: data }).then(
      url => {
        this.noteFile.url = url;
        this.noteFile.fileName = nameFile;
        this.loading = false;
        this.dialogRef.close([this.attachment, this.noteFile]);
      },
      err => {
        console.error(err);
        this.loading = false;
        this.dialogRef.close([this.attachment, this.noteFile]);
      }
    );
  }
}
