import { HolisFile, HolAttachments } from 'src/app/common/models/hol-attachments.model';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { FltApplicabilityService } from './flt-applicability.service';
import { HolContext } from './../../common/models/hol-context.model';
import { FltApplicability } from './../models/flt-applicability';
import { Injectable, Inject } from '@angular/core';
import { FltFlightInstruction } from '../models/flt-flight-instruction';
import { RequestService } from '../../common/services/request.service';
import { HolTag } from '../../common/models/hol-tag';
import { ENDED_STATUS, HolFlight } from '../../common/models/hol-flight.model';
import { Dictionary, groupBy } from 'lodash';
import { FltDecision } from '../models/flt-decision';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export abstract class FltFlightInstructionService<T extends FltFlightInstruction = FltFlightInstruction> {
  // tslint:disable:variable-name
  protected abstract ParseFlightInstruction;
  protected abstract ParseFlightInstructionTag;
  protected abstract ParseDecision;
  protected abstract ParseDecisionTag;
  protected abstract ParseTag;
  protected ParseFlight = Parse.Object.extend('GOCFlight');
  // tslint:enable

  protected constructor(
    @Inject('FilesService') protected filesService,
    protected requestService: RequestService,
    protected markdownService: MarkdownService,
    protected applicabilityService: FltApplicabilityService
  ) {}

  public create(flightInstruction: T, applicability?: FltApplicability, context?: HolContext): Promise<T> {
    let addBreakLinesBefore = false;
    if (flightInstruction.attachments && flightInstruction.attachments.note) {
      addBreakLinesBefore = true;
    }
    const parseFI = new this.ParseFlightInstruction();
    parseFI.set('message', flightInstruction.message);
    parseFI.set('station', flightInstruction.station);
    parseFI.set('attachments', flightInstruction.attachments);
    parseFI.set('nextInfoTime', flightInstruction.nextInfoTime);
    parseFI.set('createdBy', Parse.User.current());
    parseFI.setACL(flightInstruction.acl);
    parseFI.set('decision', flightInstruction.decision ? new this.ParseDecision({ id: flightInstruction.decision.objectId }) : null);
    if (flightInstruction.flight) {
      parseFI.set('flight', new this.ParseFlight({ id: flightInstruction.flight.objectId }));
    }
    if (applicability) {
      if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
        parseFI.set('station', flightInstruction.flight.departure);
      } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
        parseFI.set('station', flightInstruction.flight.destination);
      }
    }
    this.setAdditionalFields(flightInstruction, parseFI);
    return this.requestService.performSaveQuery(parseFI).then(async savedFlightInstruction => {
      const fltInstructionTags = await this.addTags(flightInstruction, savedFlightInstruction);
      if (applicability && !savedFlightInstruction.decision) {
        const newSavedFlightInstruction = this.newFlightInstruction(savedFlightInstruction, savedFlightInstruction.tags);
        return this.applicabilityHistoryInNotes(newSavedFlightInstruction, applicability, context, addBreakLinesBefore).then(
          attachments => {
            const parseFlightInstruction = new this.ParseFlightInstruction();
            parseFlightInstruction.id = newSavedFlightInstruction.objectId;
            parseFlightInstruction.set('attachments', attachments);
            return this.requestService.performSaveQuery(parseFlightInstruction).then(fi => {
              return this.newFlightInstruction(fi, fltInstructionTags);
            });
          }
        );
      } else {
        return this.newFlightInstruction(savedFlightInstruction, savedFlightInstruction.tags);
      }
    });
  }

  public markAsDone(flightInstruction: T, done: boolean): Promise<T> {
    const parseFlightInstruction = new this.ParseFlightInstruction();
    parseFlightInstruction.id = flightInstruction.objectId;
    parseFlightInstruction.set('done', done);
    if (done) {
      parseFlightInstruction.set('doneDateTime', moment.utc().toDate());
    } else {
      parseFlightInstruction.unset('doneDateTime');
    }

    return this.requestService.performSaveQuery(parseFlightInstruction).then(async parseData => {
      const newFlightInstruction = this.newFlightInstruction(parseData);
      newFlightInstruction.tags = flightInstruction.tags;

      Object.assign(flightInstruction, newFlightInstruction);

      return newFlightInstruction;
    });
  }

  public update(flightInstruction: T, applicability?: FltApplicability, context?: HolContext): Promise<T> {
    const promises = [];
    let updateApplicabilityHistory = false;
    if (flightInstruction.decision) {
      // const parseDecision = new this.ParseDecision({ id: flightInstruction.decision.objectId });
      // parseDecision.set('attachments', JSON.stringify(flightInstruction.attachments));
      // parseDecision.setACL(flightInstruction.acl);
      const parseFlightInstruction = new this.ParseFlightInstruction({ id: flightInstruction.objectId });
      parseFlightInstruction.set('nextInfoTime', flightInstruction.nextInfoTime);
      parseFlightInstruction.setACL(flightInstruction.acl);
      parseFlightInstruction.unset('attachments');
      this.setAdditionalFields(flightInstruction, parseFlightInstruction);
      promises.push(this.requestService.performSaveQuery(parseFlightInstruction));
      // promises.push(this.requestService.performSaveQuery(parseDecision));
    } else {
      const parseFlightInstruction = new this.ParseFlightInstruction({ id: flightInstruction.objectId });
      parseFlightInstruction.set('attachments', flightInstruction.attachments);
      parseFlightInstruction.set('nextInfoTime', flightInstruction.nextInfoTime);
      parseFlightInstruction.setACL(flightInstruction.acl);
      if (applicability) {
        if (applicability.flightsDirection === 'DEP' || applicability.stationsDirection === 'OUT') {
          parseFlightInstruction.set('station', flightInstruction.flight.departure);
        } else if (applicability.flightsDirection === 'ARR' || applicability.stationsDirection === 'IN') {
          parseFlightInstruction.set('station', flightInstruction.flight.destination);
          updateApplicabilityHistory = flightInstruction.station !== flightInstruction.flight.destination ? true : false;
        } else {
          parseFlightInstruction.set('station', undefined);
          updateApplicabilityHistory = flightInstruction.station !== undefined ? true : false;
        }
      }
      this.setAdditionalFields(flightInstruction, parseFlightInstruction);
      promises.push(this.requestService.performSaveQuery(parseFlightInstruction));
    }
    return Promise.all(promises).then(async ([savedFlightInstruction]) => {
      if (flightInstruction.decision) {
        await this.removeDecisionTags(flightInstruction.decision);
        await this.addDecisionTags(flightInstruction);
      } else {
        // remove old tags
        await this.removeTags(savedFlightInstruction);
        // add new tags
        await this.addTags(flightInstruction, savedFlightInstruction);

        if (applicability && updateApplicabilityHistory) {
          return await this.applicabilityHistoryInNotes(flightInstruction, applicability, context).then(attachments => {
            const parseFI = new this.ParseFlightInstruction({ id: flightInstruction.objectId });
            parseFI.set('attachments', attachments);
            return this.requestService.performSaveQuery(parseFI).then(async f => {
              return this.newFlightInstruction(f, f.tags);
            });
          });
        }
      }

      return flightInstruction;
    });
  }

  public getForFlights(flights: HolFlight[], isPrivate?: boolean): Promise<Dictionary<T[]>> {
    const parseFlights = flights.map(f => new this.ParseFlight({ id: f.objectId }));
    const query = new Parse.Query(this.ParseFlightInstruction);
    query.containedIn('flight', parseFlights);
    query.notEqualTo('archived', true);
    query.include('decision');
    query.addDescending('createdAt');
    if (isPrivate) {
      query.notEqualTo('isPrivate', true);
    }
    return this.requestService.performFindQuery(query).then(parseInstructions => {
      const parseDecisions = parseInstructions.filter(pi => pi.get('decision')).map(pi => pi.get('decision'));
      const flightInstructionTagsQuery = new Parse.Query(this.ParseFlightInstructionTag);
      flightInstructionTagsQuery.containedIn('flightInstruction', parseInstructions);
      flightInstructionTagsQuery.include('tag');
      flightInstructionTagsQuery.descending('createdAt');
      const decisionTagsQuery = new Parse.Query(this.ParseDecisionTag);
      decisionTagsQuery.containedIn('decision', parseDecisions);
      decisionTagsQuery.include('tag');
      decisionTagsQuery.descending('createdAt');

      return Promise.all([
        this.requestService.performFindQuery(flightInstructionTagsQuery),
        this.requestService.performFindQuery(decisionTagsQuery),
      ]).then(([fits, dts]) => {
        const instructions = parseInstructions.map(log => this.newFlightInstruction(log));
        instructions.forEach(instruction => {
          if (instruction.decision) {
            instruction.tags = dts
              .filter(dt => dt.get('decision').id === instruction.decision.objectId)
              .map(dt => new HolTag(dt.get('tag')));
          } else {
            instruction.tags = fits
              .filter(fit => fit.get('flightInstruction').id === instruction.objectId)
              .map(flt => new HolTag(flt.get('tag')));
          }
        });
        return groupBy(instructions, l => l.flight.objectId);
      });
    });
  }

  public archive(flightInstruction: T): Promise<T> {
    const parseInstruction = new this.ParseFlightInstruction({ id: flightInstruction.objectId });
    parseInstruction.set('archived', true);
    parseInstruction.set('archivedDate', new Date());
    return this.requestService.performSaveQuery(parseInstruction).then(async savedInstruction => {
      return this.newFlightInstruction(savedInstruction, savedInstruction.tags);
    });
  }

  private async addTags(flightInstruction: T, savedFlightInstruction): Promise<Parse.Object[]> {
    if (flightInstruction.tags) {
      const parseTags = flightInstruction.tags.map(tag => {
        return new this.ParseFlightInstructionTag({
          flightInstruction: savedFlightInstruction,
          tag: new this.ParseTag({ id: tag && tag.objectId }),
        });
      });
      return this.requestService.performSaveAllQuery(parseTags);
    }
  }

  private async removeTags(parseFI) {
    const oldTagQuery = new Parse.Query(this.ParseFlightInstructionTag);
    oldTagQuery.equalTo('flightInstruction', parseFI);
    const oldTags = await this.requestService.performFindQuery(oldTagQuery);
    await this.requestService.performDestroyAllQuery(oldTags);
  }

  private async addDecisionTags(flightInstruction: T) {
    if (flightInstruction.tags) {
      const parseTags = flightInstruction.tags.map(tag => {
        return new this.ParseDecisionTag({
          decision: new this.ParseDecision({ id: flightInstruction.decision.objectId }),
          tag: new this.ParseTag({ id: tag && tag.objectId }),
        });
      });
      await this.requestService.performSaveAllQuery(parseTags);
    }
  }

  private async removeDecisionTags(decision: FltDecision) {
    const oldTagQuery = new Parse.Query(this.ParseDecisionTag);
    oldTagQuery.equalTo('decision', new this.ParseDecision({ id: decision.objectId }));
    const oldTags = await this.requestService.performFindQuery(oldTagQuery);
    await this.requestService.performDestroyAllQuery(oldTags);
  }

  public updateInstructionDecision(flightInstruction: T, flightDecision: FltDecision): Promise<T> {
    const parseFlightInstruction = new this.ParseFlightInstruction({ id: flightInstruction.objectId });
    parseFlightInstruction.set('decision', new this.ParseDecision({ id: flightDecision.objectId }));
    parseFlightInstruction.unset('attachments');
    return this.requestService.performSaveQuery(parseFlightInstruction).then(updatedFlightInstruction => {
      return updatedFlightInstruction;
    });
  }

  public async deleteInstructionFromCancelAppl(decisionId) {
    const queryFlight = new Parse.Query(this.ParseFlight);
    queryFlight.greaterThanOrEqualTo('std', moment.utc().toDate());

    const query = new Parse.Query(this.ParseFlightInstruction);
    query.equalTo('decision', new this.ParseDecision({ id: decisionId }));
    query.matchesQuery('flight', queryFlight);

    const intructionsToDelete = await this.requestService.performFindAllQuery(query);

    return await this.requestService.performDestroyAllQuery(intructionsToDelete);
  }

  getAllFromDecision(decision: FltDecision): Promise<Parse.Object[]> {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const parseDecision = new this.ParseDecision({ id: decision.objectId });
    const flightQuery = new Parse.Query(this.ParseFlight);
    flightQuery.notContainedIn('status', ENDED_STATUS);
    flightQuery.greaterThan('std', yesterday);
    const flightInstructionQuery = new Parse.Query(this.ParseFlightInstruction);
    flightInstructionQuery.equalTo('decision', parseDecision);
    flightInstructionQuery.notEqualTo('archived', true);
    flightInstructionQuery.include('flight');
    flightInstructionQuery.matchesQuery('flight', flightQuery);

    return this.requestService.performFindAllQuery(flightInstructionQuery);
  }

  applicabilityHistoryInNotes(flightInstruction: T, applicability: FltApplicability, context: HolContext, addMdBreakLinesBefore = false) {
    let content = '';
    let nameFile;
    const dateFormat = 'DD/MM/YY HH:mm[Z]';
    const applicabilityDate = moment
      .utc(flightInstruction.updatedAt ? flightInstruction.updatedAt : flightInstruction.createdAt)
      .format(dateFormat);
    const attachments = flightInstruction.attachments ? flightInstruction.attachments : new HolAttachments();
    const noteFile: HolisFile = new HolisFile();

    if (flightInstruction.attachments && flightInstruction.attachments.note) {
      content += flightInstruction.attachments.note;
      nameFile = flightInstruction.attachments.noteFile.fileName;
    } else {
      nameFile = `note-${context.module.toLocaleLowerCase()}-${context.category
        .substring(0, 3)
        .toLocaleLowerCase()
        .replace(/é|è|ê/g, 'e')}-${moment().utc().format('DD-MM-YYYY')}.html`;
      addMdBreakLinesBefore = true;
    }

    content += addMdBreakLinesBefore ? '\n' + '\n' + '\n' : '';
    content += this.applicabilityService.applicabilityHistoryTxt(applicability, applicabilityDate);

    const mdTemplate = content;

    const htmlContent = this.markdownService.parseMdToHtml(content);
    const htmlTemplate = this.markdownService.createHtmlContent(htmlContent, context);

    const blob = new Blob([htmlTemplate], { type: 'text/html' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        this.filesService.uploadFile(nameFile, { base64: reader.result }).then(
          url => {
            noteFile.url = url;
            noteFile.fileName = nameFile;
            attachments.note = mdTemplate;
            attachments.noteFile = noteFile;
            resolve(attachments);
          },
          err => {
            reject(err);
          }
        );
      };
    });
  }

  protected setAdditionalFields(flightInstruction: T, parseFlightInstruction: Parse.Object) {}

  protected newFlightInstruction(parseObject?: Parse.Object, tags?: Parse.Object[]): T {
    return new FltFlightInstruction(parseObject, tags && tags.map(t => new HolTag(t.get('tag')))) as T;
  }
}
