import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MarkdownEditorModalComponent } from './markdown-editor-modal.component';
import { HolisFile } from '../../models/hol-attachments.model';

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss'],
})
export class MarkdownEditorComponent {
  @Input() attachment: string;
  @Input() column: boolean;
  @Input() hideButtonTitle: boolean;
  @Input() isPdf: boolean;
  @Input() noteFile: HolisFile;
  @Input() label: string;
  @Input() disabled;

  @Input()
  public context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlScenario?: string;
    htmlDate;
    htmlNextInfo?;
    htmlTemplate: string;
  };

  @Output() noteAdded = new EventEmitter<any>();
  @Output() noteRemoved = new EventEmitter<string>();

  constructor(public dialog: MatDialog, @Inject('$translate') private $translate: any) {}

  openMarkdownEditor(): void {
    const dialogTitle = this.attachment
      ? this.$translate.instant('GOC.EVENT_MODAL.EDIT_NOTE')
      : this.$translate.instant('GOC.EVENT_MODAL.ADD_NOTE');
    const dialogRef = this.dialog.open(MarkdownEditorModalComponent, {
      data: {
        title: dialogTitle,
        attachment: this.attachment,
        noteFile: this.noteFile,
        context: this.context,
      },
    });

    const overlayRef = '_overlayRef';
    dialogRef[overlayRef].hostElement.style.zIndex = '10001';
    dialogRef[overlayRef].backdropElement.style.zIndex = '10001';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.attachment = result[0];
        this.noteAdded.emit({ note: result[0], file: result[1] });
      }
    });
  }

  removeNote($event): void {
    $event.stopPropagation();
    this.attachment = null;
    this.noteRemoved.emit();
  }
}
