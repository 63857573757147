import { OclGroup } from './ocl-group.model';
import { OclLogbook } from './ocl-logbook.model';
import { orderBy } from 'lodash';

export class OclLogBookGroup extends OclGroup<OclLogbook> {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.items =
      parseObject.get('logBooks') && parseObject.get('logBooks').length
        ? orderBy(
            parseObject.get('logBooks').map(lg => new OclLogbook(lg)),
            'createdAt',
            'desc'
          )
        : [];
  }

  getMostRecentLogBook(): OclLogbook {
    return this.items[0];
  }
}
