import { OclTask } from './../../models/ocl-task';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';

@Injectable({
  providedIn: 'root',
})
export class OclSmsService {
  protected SENDER_SUFFIX = '';

  constructor(
    @Inject('SmsSenderService') protected smsSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    @Inject('$translate') protected $translate,
    protected moduleConfig: ModuleConfigService
  ) {}

  public sendNewDecisionSMS(decision, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getDecisionMessage(decision),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewEventSMS(event, infos, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getNewEventMessage(event, infos, false),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendEclActivatedSMS(event, infos, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getECLActivateMessage(event, infos),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewEventChecklistActivatedSMS(event, infos, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getNewEventMessage(event, infos, true),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewEventInfoSMS(event, eventInfo, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getNewEventInfoMessage(event, eventInfo),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendNewEventInfoChecklistActivatedSMS(event, eventInfo, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getNewEventInfoMessage(event, eventInfo, true),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  public sendCloseEventSMS(event, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getCloseEventMessage(event),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  private getDecisionMessage(decision) {
    return this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.NEW_DECISION', { decision: decision.message });
  }

  private getECLActivateMessage(event, infos) {
    const lastInfo = infos && infos.length ? infos[0] : null;
    const translateKey = 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_FROM_ECL_ACTIVATED';

    return this.$translate.instant(translateKey, {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      scenario: event.scenario ? event.scenario.title : '',
      description: event.description,
      nextInfoTime: lastInfo && lastInfo && lastInfo.nextInfoTime && moment(lastInfo.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
      nextInfoText: lastInfo && lastInfo && lastInfo.message,
      eclUrl: location.origin + '/#/ecl/dashboard',
    });
  }

  private getNewEventMessage(event, infos, isFromChecklistActivate?) {
    const lastInfo = infos && infos.length ? infos[0] : null;
    const translateKey = isFromChecklistActivate
      ? 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_FROM_CHECKLIST_ACTIVATED'
      : 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT';

    return this.$translate.instant(translateKey, {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      scenario: event.scenario ? event.scenario.title : '',
      description: event.description,
      nextInfoTime:
        lastInfo &&
        lastInfo.info &&
        lastInfo.info.nextInfoTime &&
        moment(lastInfo.info.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
      nextInfoText: lastInfo && lastInfo.info && lastInfo.info.message,
    });
  }

  private getNewEventInfoMessage(event, eventInfo, isFromChecklistActivate?) {
    if (eventInfo.length) {
      eventInfo = eventInfo[0];
    }
    const translateKey = isFromChecklistActivate
      ? 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_INFO_FROM_CHECKLIST_ACTIVATED'
      : 'SMS.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_INFO';
    return this.$translate.instant(translateKey, {
      nbInfos: event.infos.length,
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      description: eventInfo.message,
      nextInfoTime: moment(eventInfo.nextInfoTime).utc().format('ddd DD [at] HH:mm [UTC]'),
      appUrl: location.origin,
    });
  }

  private getCloseEventMessage(event) {
    let closeReason;
    if (event.closeReason === 0) {
      closeReason = this.$translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_1');
    } else if (event.closeReason === 1) {
      closeReason = this.$translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_2');
    } else {
      closeReason = event.closeReasonText;
    }
    return this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.CLOSE_EVENT', {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      description: event.description,
      closeReason,
    });
  }

  public sendNewLogbookSMS(logbook, phoneNumbers) {
    if (phoneNumbers && phoneNumbers.length) {
      const messages = phoneNumbers.map(phoneNumber => {
        return {
          to: phoneNumber,
          text: this.getLogbookMessage(logbook),
        };
      });
      this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
    }
  }

  private getLogbookMessage(logbook) {
    return this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.NEW_LOGBOOK', { logbook: logbook.text });
  }

  sendTaskDoneSms(task: OclTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getTaskDoneMail(task, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
  }

  getTaskDoneMail(task: OclTask, functionTitle, comment): string {
    let msg = this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_DONE.SUBJECT') + ' ';
    msg += this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_DONE.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskTitle: task.taskTitle,
      functionTitle,
      taskResult: task.outputTitle,
    });
    if (comment) {
      msg += this.$translate.instant('SMS.CRISIS_TASK_TO_DONE.SECOND_PART', {
        comment,
      });
    }
    return msg;
  }

  sendTaskFrozenSms(task: OclTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getTaskFrozenMail(task, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
  }

  getTaskFrozenMail(task: OclTask, functionTitle, comment): string {
    let msg = this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_FROZEN.SUBJECT') + ' ';
    msg += this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_FROZEN.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskTitle: task.taskTitle,
      functionTitle,
      taskResult: task.outputTitle,
      comment,
    });
    return msg;
  }

  sendTaskBackTodoSms(task: OclTask, functionTitle: string, comment: string, phoneNumbersToSend: string[]) {
    const messages = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getTaskBackTodoMail(task, functionTitle, comment),
      });
    }
    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX);
  }

  getTaskBackTodoMail(task: OclTask, functionTitle, comment): string {
    let msg = this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.SUBJECT');
    msg += this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.FISRT_PART', {
      firstName: task.updatedBy.firstName,
      lastName: task.updatedBy.lastName,
      taskTitle: task.taskTitle,
      functionTitle,
      taskResult: task.outputTitle,
    });
    if (comment) {
      msg += this.$translate.instant('SMS.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.SECOND_PART', {
        comment,
      });
    }
    return msg;
  }
}
