'use strict';

/**
 * @ngdoc function
 * @name gestiondecriseApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the gestiondecriseApp
 */
angular
  .module('gestiondecriseApp')
  .controller('MainCtrl', function (
    $rootScope,
    $scope,
    $translate,
    $state,
    AuthenticationService,
    OptionsService,
    OCCOptionsService,
    ECLOptionsService,
    OPSOptionsService,
    RequestService,
    CommonStoreManager,
    ErpCrisisService,
    FunctionsStoreManager,
    ModalsService,
    CrisisService
  ) {
    var activateCrisisPopupSeenForThisInstance = false;

    $scope.crisis = null;
    $scope.crisisStateLoading = false;
    $scope.isExternal = true;
    $scope.fullOverlayActive = false;
    $scope.canPrepareCrisis = false;
    $scope.canActivateCrisis = false;
    $scope.canCloseCrisis = false;
    $scope.companyLogoUrl = null;
    $scope.hasCrewAccess = $rootScope.accessRights.crew !== 'UNAUTHORIZED';
    $scope.hasOccAccess = $rootScope.accessRights.occ !== 'UNAUTHORIZED';
    $scope.hasEclAccess = $rootScope.accessRights.ecl !== 'UNAUTHORIZED';
    $scope.hasOpsAccess = $rootScope.accessRights.ops !== 'UNAUTHORIZED';
    $scope.hasExerciseAccess = $rootScope.accessRights.crisis !== 'UNAUTHORIZED';
    $scope.hasMccAccess = $rootScope.accessRights.mcc !== 'UNAUTHORIZED';
    $scope.hasGocAccess = $rootScope.accessRights.goc !== 'UNAUTHORIZED';
    $scope.eclTitle = $translate.instant('MENU.ECL');
    var isGettingLogout = false;

    $scope.accordions = {
      occ: false,
      crisis: false,
      training: false,
    };

    $scope.occDisplay = {
      checklist: true,
      dashboard: true,
      team: true,
      timeline: true,
    };

    $scope.eclDisplay = {
      checklist: true,
      dashboard: true,
      team: true,
      timeline: true,
    };

    $scope.opsDisplay = {
      checklist: true,
      dashboard: true,
      team: true,
      timeline: true,
    };

    function getOCCOptions() {
      OCCOptionsService.get().then(occOptions => {
        if (occOptions && occOptions.hiddenButtons !== null) {
          const hiddenButtons = occOptions.hiddenButtons ? occOptions.hiddenButtons.split('|') : [];
          $scope.occDisplay.checklist = !hiddenButtons.includes('CHECKLIST');
          $scope.occDisplay.dashboard = !hiddenButtons.includes('DASHBOARD');
          $scope.occDisplay.team = !hiddenButtons.includes('TEAM');
          $scope.occDisplay.timeline = !hiddenButtons.includes('TIMELINE');
        }
      });
    }

    function getOPSOptions() {
      OPSOptionsService.get().then(opsOptions => {
        CommonStoreManager.updateOpsInfos({
          moduleIsActivated: opsOptions.moduleIsActivated,
        });
        if (opsOptions && opsOptions.hiddenButtons !== null) {
          const hiddenButtons = opsOptions.hiddenButtons ? opsOptions.hiddenButtons.split('|') : [];
          $scope.opsDisplay.checklist = !hiddenButtons.includes('CHECKLIST');
          $scope.opsDisplay.dashboard = !hiddenButtons.includes('DASHBOARD');
          $scope.opsDisplay.team = !hiddenButtons.includes('TEAM');
          $scope.opsDisplay.timeline = !hiddenButtons.includes('TIMELINE');
        }
      });
    }

    function getECLOptions() {
      ECLOptionsService.get(true).then(eclOptions => {
        CommonStoreManager.updateEclInfos({
          moduleName: eclOptions.moduleName,
          moduleIsActivated: eclOptions.moduleIsActivated,
          occEventId: eclOptions.occEvent && eclOptions.occEvent.id,
          eclOptionsId: eclOptions.objectId,
        });

        if (eclOptions && eclOptions.hiddenButtons !== null) {
          const hiddenButtons = eclOptions.hiddenButtons ? eclOptions.hiddenButtons.split('|') : [];
          $scope.eclDisplay.checklist = !hiddenButtons.includes('CHECKLIST');
          $scope.eclDisplay.dashboard = !hiddenButtons.includes('DASHBOARD');
          $scope.eclDisplay.team = !hiddenButtons.includes('TEAM');
          $scope.eclDisplay.timeline = !hiddenButtons.includes('TIMELINE');
        }
      });
    }

    getOCCOptions();
    getECLOptions();
    getOPSOptions();

    let crisisActivationModal;
    let crisisPreparationModal;
    function init() {
      CommonStoreManager.commonState.subscribe(common => {
        $scope.eclModuleInfos = {
          moduleName: common.eclInfos.moduleName,
          moduleIsActivated: common.eclInfos.moduleIsActivated,
        };
        $scope.opsModuleInfos = {
          moduleIsActivated: common.opsInfos.moduleIsActivated,
        };
        if ($scope.eclModuleInfos.moduleName && $scope.eclModuleInfos.moduleName.trim().length > 0) {
          $scope.eclTitle = $scope.eclModuleInfos.moduleName;
        }
        $scope.isExternal = common.currentUser && common.currentUser.isExternal;
      });
      FunctionsStoreManager.currentUserCrisisRoles.subscribe(roles => {
        $scope.canCloseCrisis = !!roles.crisisDirector.holderFor.length;
      });
      CommonStoreManager.currentUserCrisisRolesRef.subscribe(roles => {
        $scope.canActivateCrisis =
          roles.isInCrisisDirectorTeam || (roles.isInCrisisInitializerTeam && OptionsService.crisisInitializerCanActivate());
        $scope.canPrepareCrisis = roles.isInCrisisInitializerTeam || roles.isInCrisisDirectorTeam;
      });

      CommonStoreManager.crisisInPreparation.subscribe(() => {
        if ($scope.canActivateCrisis && !CrisisService.crisisInPreparation && !crisisPreparationModal) {
          $scope.showConfirmCrisisActivation();
        }
        CrisisService.crisisInPreparation = false;
      });
      CommonStoreManager.crisisActivated.subscribe(() => {
        if (!CrisisService.crisisInCreation && !crisisActivationModal) {
          var translationKey = $scope.crisis.isTraining ? 'EXERCISE' : 'CRISIS';
          crisisActivationModal = ModalsService.openAlertModal({
            title: $translate.instant('ERP.MODALS.ALERT_' + translationKey + '_CREATED.TITLE'),
            content: $translate.instant('ERP.MODALS.ALERT_' + translationKey + '_CREATED.CONTENT'),
          }).then(() => {
            crisisActivationModal = null;
            $state.go('app.crisis.dashboard', {}, { reload: true });
          });
        }
        CrisisService.crisisInCreation = false;
      });

      CommonStoreManager.crisis.subscribe(crisis => {
        if (crisis && crisis.objectId) {
          $scope.crisis = crisis;
          $scope.crisisShoulBeDisplayOnMenu =
            crisis.isInPreparation || crisis.inProgress || OptionsService.crisisShouldBeDisplayOnMenu(crisis);
        }
      });
      $scope.companyLogoUrl = OptionsService.getCompanyLogoUrl();
    }

    init();

    $scope.isState = function (stateId) {
      return $state.current.name.indexOf(stateId) !== -1;
    };

    $scope.closeCurrentCrisis = ev => {
      // Appending dialog to document.body to cover sidenav in docs app
      ModalsService.openConfirmModal({
        title: $translate.instant('ERP.MODALS.CONFIRM_CLOSE_CRISIS.TITLE'),
        content: $translate.instant('ERP.MODALS.CONFIRM_CLOSE_CRISIS.CONTENT'),
        modalType: 'info',
        targetEvent: ev,
      }).then(res => {
        if (res) {
          // Close the current crisis
          $scope.crisisStateLoading = true;
          return ErpCrisisService.closeCrisis($scope.crisis).finally(() => {
            // Re-start the polling
            $scope.crisisStateLoading = false;
          });
        }
      });
    };

    $rootScope.hideFullOverlay = () => ($scope.fullOverlayActive = false);

    $rootScope.showFullOverlay = () => ($scope.fullOverlayActive = true);

    $scope.showConfirmCrisisActivation = () => {
      activateCrisisPopupSeenForThisInstance = true;
      crisisPreparationModal = ModalsService.openConfirmModal({
        title: $translate.instant(`ERP.MODALS.ACTIVATE_${$scope.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.TITLE`),
        content: $translate.instant(`ERP.MODALS.ACTIVATE_${$scope.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.CONTENT`),
        question: $translate.instant(`ERP.MODALS.ACTIVATE_${$scope.crisis.isTraining ? 'EXERCICE' : 'CRISIS'}.QUESTION`),
      }).then(res => {
        crisisPreparationModal = null;
        if (res === true) {
          ErpCrisisService.activateByCrisisDirector($scope.crisis).then(() => {
            $state.go('app.crisis.dashboard', {}, { reload: true });
          });
          activateCrisisPopupSeenForThisInstance = false;
        } else if (res === false) {
          $scope.closeCurrentCrisis();
          activateCrisisPopupSeenForThisInstance = false;
        }
      });
    };

    const userLogoutSub = RequestService.forceLogout.subscribe(() => {
      if (!isGettingLogout) {
        isGettingLogout = true;
        AuthenticationService.logout(true).finally(() => (isGettingLogout = false));
      }
    });

    /**
     * Destroy the scope.
     */
    $scope.$on('$destroy', () => {
      console.log('[MainCtlr] $destroy');
      // Remove all listeners on $rootScope
      userLogoutSub.unsubscribe();
    });
  });
