import { HolUser } from '../../common/models/hol-user.model';

export class OclGroup<T> {
  objectId?: string;
  title?: string;
  createdBy?: HolUser;
  createdAt?: Date;
  items: T[];
  displayInGroupViewStatus: string;
  constructor(parseObject?: Parse.Object) {
    if (!parseObject) {
      return;
    }
    this.objectId = parseObject.id;
    this.title = parseObject.get('title');
    if (parseObject.get('createdBy')) {
      this.createdBy = new HolUser(parseObject.get('createdBy'));
    }
    this.createdAt = parseObject.get('createdAt');
  }
}
