//router.config.js

'use strict';

angular
  .module('gestiondecriseApp')
  .config([
    '$urlServiceProvider',
    function ($urlService) {
      $urlService.deferIntercept();
    },
  ])
  .config(function ($stateProvider, $locationProvider, CONSTANTS, $urlRouterProvider) {
    document.title = 'HOLIS ' + CONSTANTS.COMPANY_NAME;
    $locationProvider.hashPrefix('');
    $stateProvider.state('app', {
      abstract: true,
      templateUrl: 'views/main.html',
      controller: 'MainCtrl',
      resolve: {
        init: [
          'AuthenticationService',
          function (AuthenticationService) {
            return AuthenticationService.tryLogin().then(user => {
              return AuthenticationService.initApp(user);
            });
          },
        ],
      },
    });

    ///////////////////////////////////////////
    //              OCC States               //
    ///////////////////////////////////////////
    // region OCC states
    // .state('app.occ.team', {
    //   url: '/team',
    //   templateUrl: 'views/team.html',
    //   controller: 'TeamCtrl',
    // })
    // endregion
    ///////////////////////////////////////////
    //              ECL States               //
    ///////////////////////////////////////////
    // region COVID states
    // .state('app.ecl.team', {
    //   url: '/team',
    //   templateUrl: 'views/team.html',
    //   controller: 'TeamCtrl',
    // });
    ///////////////////////////////////////////
    //              Crew States              //
    ///////////////////////////////////////////
    // region Crew States
    // .state('app.crew', {
    //   url: '/crew',
    //   template: '<div ui-view></div>',
    //   abstract: true,
    //   resolve: {
    //     isReadOnly: [
    //       '$rootScope',
    //       'USER_RIGHTS',
    //       function ($rootScope, USER_RIGHTS) {
    //         switch ($rootScope.accessRights.crew) {
    //           case USER_RIGHTS.WRITE:
    //             return $rootScope.isPhone;
    //           case USER_RIGHTS.READ:
    //             return true;
    //           case USER_RIGHTS.UNAUTHORIZED:
    //             throw USER_RIGHTS.UNAUTHORIZED;
    //           default:
    //             throw USER_RIGHTS.UNAUTHORIZED;
    //         }
    //       },
    //     ],
    //     options: [
    //       'CrewOptionsService',
    //       'UserService',
    //       'CrewEventsService',
    //       function (CrewOptionsService, UserService, CrewEventsService) {
    //         return CrewOptionsService.get().then(options => {
    //           //check if should archive closed crew events
    //           if (UserService.isCurrentUserInTheCrewManagerTeam()) {
    //             CrewEventsService.startArchivingTask();
    //           }
    //           return options;
    //         });
    //       },
    //     ],
    //     flights: [
    //       'FlightService',
    //       function (FlightService) {
    //         return FlightService.getAllFromYesterday();
    //       },
    //     ],
    //   },
    // })
    // .state('app.crew.dashboard', {
    //   url: '/dashboard',
    //   templateUrl: 'views/crew/dashboard.html',
    //   controller: 'CrewDashboardCtrl',
    // })
    // .state('app.crew.checklist', {
    //   url: '/checklist',
    //   templateUrl: 'views/crew/checklist.html',
    //   controller: 'CrewChecklistCtrl',
    // })
    // .state('app.crew.team', {
    //   url: '/team',
    //   templateUrl: 'views/crew/team.html',
    //   controller: 'CrewTeamCtrl',
    // })
    // .state('app.crew.history', {
    //   url: '/history',
    //   templateUrl: 'views/crew/history.html',
    //   controller: 'CrewHistoryCtrl',
    // });
    // endregion
    $urlRouterProvider.otherwise('/login');
    // location.hash = '/login';
  })
  .run(function ($transitions, $state, localStorage, $log, $mdDialog, USER_RIGHTS, $rootScope) {
    $transitions.onBefore({ to: 'app.**' }, function () {
      $rootScope.pageChanging = true;
    });

    $transitions.onSuccess({ to: 'app.**' }, function () {
      $rootScope.pageChanging = false;
      localStorage.setUrlLocation($state.current.name);
      localStorage.setUrlParams($state.params || {});
    });

    $transitions.onError({ to: 'app.**' }, function (transition) {
      $rootScope.pageChanging = false;

      if (transition.error().detail === USER_RIGHTS.UNAUTHORIZED) {
        const holAlert = $mdDialog.holAlert({
          title: 'Forbidden',
          htmlContent: 'You are not allowed to view this page...',
        });
        $mdDialog.show(holAlert);
      }
      if (transition.error().type === 6) {
        // Transitiion ERRORED => go to login
        $log.warn(transition.toString() + '::' + transition.error().toString());
        $state.go('login', { error: transition.error() });
      }
    });
  });
