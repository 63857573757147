import { Inject, Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { OclGlobalInstructionService } from 'src/app/ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OclGlobalInstructionsStoreManager } from 'src/app/ocl/store/global-instructions/ocl-global-instructions-store-manager.service';
import { EclGlobalInstructionTagService } from '../ecl-global-instruction-tag-service/ecl-global-instruction-tag.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';

@Injectable({
  providedIn: 'root',
})
export class EclGlobalInstructionService extends OclGlobalInstructionService {
  // tslint:disable:variable-name
  protected ParseGlobalInstructionTag = Parse.Object.extend('ECLGlobalInstructionTag');
  protected ParseGlobalInstruction = Parse.Object.extend('ECLGlobalInstruction');
  protected ParseTag = Parse.Object.extend('ECLTag');
  // tslint:enable
  constructor(
    @Inject('$rootScope') protected $rootScope,
    protected requestService: RequestService,
    @Inject('UserService') protected userService,
    protected globalInstructionTagService: EclGlobalInstructionTagService,
    protected globalInstructionsStoreManager: OclGlobalInstructionsStoreManager,
    public moduleConfig: EclModuleConfigService
  ) {
    super($rootScope, requestService, userService, globalInstructionTagService, globalInstructionsStoreManager, moduleConfig);
  }
}
