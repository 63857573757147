<div [class.hidden]="!roles || roles.length <= 1">
  <div *ngIf="mode === 'single'" [class.no-click]="disabled">
    <mat-select [(ngModel)]="selectedCompanyRole" [style.background]="selectedCompanyRole?.color">
      <mat-option
        *ngFor="let role of roles"
        [disabled]="
          (!role.userReadRoles.length && !role.userWriteRoles.length) ||
          (!!limitTo?.companies?.length && !limitTo.companies.includes(role.company))
        "
        [value]="role"
        [style.background]="role.color"
      >
        {{ role.company }}
      </mat-option>
    </mat-select>
  </div>
  <div *ngIf="mode === 'multi'" [class.no-click]="disabled">
    <mat-button-toggle-group class="roles-buttons" [multiple]="mode === 'multi'" #group="matButtonToggleGroup" appearance="legacy">
      <mat-button-toggle
        (change)="onChange()"
        *ngFor="let role of roles"
        [value]="role"
        [disabled]="!role.userReadRoles.length && !role.userWriteRoles.length"
        [checked]="!!(role.userWriteRoles.length || role.userReadRoles.length)"
        [ngStyle]="{
          color: isSelected(role) ? 'white' : role.color,
          'border-color': role.color,
          'background-color': isSelected(role) ? role.color : mixColors('#FFF', role.color, 90)
        }"
      >
        {{ role.company }}
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-checkbox
      class="select-all"
      *ngIf="availableCompaniesRoles.length > 3"
      [checked]="availableCompaniesRoles.length === btnGroup.value.length"
      (change)="toggleSelectAll()"
    >
      {{ 'COMMON.SELECT_ALL' | translate }}
    </mat-checkbox>
  </div>
</div>
