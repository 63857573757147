<div class="hol-dialog-wrapper">
  <mat-toolbar class="mat-toolbar">
    <div class="title" mat-dialog-title>
      {{
        moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_TITLE'
          | translate: { scenarioCode: event?.scenario?.code + event?.order }
      }}
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div>
      <mat-radio-group [(ngModel)]="closeReason">
        <mat-radio-button color="primary" [value]="0">
          {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_1' | translate }}
        </mat-radio-button>
        <mat-radio-button color="primary" [value]="1">
          {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_2' | translate }}
        </mat-radio-button>
        <mat-radio-button color="primary" [value]="2">
          {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_3' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <mat-form-field>
        <textarea
          matInput
          mat-autosize
          name="reasonText"
          [required]="closeReason === 2"
          [disabled]="closeReason !== 2"
          [(ngModel)]="reasonText"
          maxlength="200"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="row form-group form-inline">
      <div class="col-xs-12">
        <div class="hol-spaced-line">
          <div>
            <label ng-bind="::'COMMON.SEND_NOTIFICATIONS' | translate"></label>
            <app-notification-table [notifications]="notifications"></app-notification-table>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="fill-space"></div>

    <button (click)="closeEvent()" class="hol-validate-btn" mat-raised-button>
      <mat-icon>done</mat-icon>
      <span>{{ 'DASHBOARD.MODAL.SUBMIT' | translate | uppercase }}</span>
    </button>
  </mat-dialog-actions>
</div>
