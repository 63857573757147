import { ErpCrisisDecision } from './../models/erp-crisisDecision';
import { HelperService } from './../../common/services/helper.service';
import { ErpUsersService, HolUserWithFunctions } from './erp-users.service';
import { filter, take } from 'rxjs/operators';
import { FunctionsStoreManager } from 'src/app/erp/store/functions/functions.store-manager';
import { OptionsService } from 'src/app/common/services/options.service';
import { ErpCrisis } from './../models/erp-crisis';
import { ErpCrisisTask } from 'src/app/erp/models/erp-crisisTask';
import { Inject, Injectable } from '@angular/core';
import { ErpCrisisNew } from '../models/erp-crisisNew';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ErpCrisisAnnouncement } from '../models/erp-crisisAnnouncement';
import { HolUser } from '../../common/models/hol-user.model';
import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import marked from 'marked';
import { ErpFunctionsService } from './erp-functions.service';

@Injectable({
  providedIn: 'root',
})
export class ErpMailService {
  constructor(
    @Inject('MailSenderService') private mailSenderService,
    @Inject('CONSTANTS') private CONSTANTS,
    @Inject('$translate') private $translate,
    private optionsService: OptionsService,
    private functionsStoreManager: FunctionsStoreManager,
    private helperService: HelperService,
    private usersService: ErpUsersService,
    private commonStoreManager: CommonStoreManager,
    private erpFunctionsService: ErpFunctionsService
  ) {}

  sendCrisisNewMail(crisisNew: ErpCrisisNew, mailsToSend: string[]) {
    if (!crisisNew) {
      console.warn('ErpMailService::sendcrisisNew: crisisNew empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendcrisisNew: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.CRISIS.CRISIS_NEW.SUBJECT'),
        contentHtml: this.getCrisisNewMail(crisisNew),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true
    );
  }

  sendCrisisAnnouncementMail(crisisAnnouncement: ErpCrisisAnnouncement, mailsToSend: string[]) {
    if (!crisisAnnouncement) {
      console.warn('ErpMailService::sendCrisisAnnouncement: crisisAnnouncement empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisAnnouncement: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.CRISIS.CRISIS_ANNOUNCEMENT.SUBJECT'),
        contentHtml: this.getCrisisAnnouncementMail(crisisAnnouncement),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true
    );
  }

  sendCrisisTaskDoneMail(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!crisisTask) {
      console.warn('ErpMailService::sendCrisisTask: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisTask: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.CRISIS.CRISIS_TASK_TO_DONE.SUBJECT'),
        contentHtml: this.getCrisisTaskDoneMail(crisisTask, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true
    );
  }

  getCrisisTaskDoneMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(crisisTask.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(crisisTask.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.$translate.instant('MAIL.CRISIS.CRISIS_TASK_TO_DONE.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskResult: crisisTask.outputTitle,
      functionTitle,
    });
    if (comment) {
      msg += '&nbsp;';
      msg +=
        this.$translate.instant('MAIL.CRISIS.CRISIS_TASK_TO_DONE.SECOND_PART', {
          comment,
        }) + '&nbsp;';
    }
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  sendCrisisTaskFrozenMail(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!crisisTask) {
      console.warn('ErpMailService::sendCrisisTask: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisTask: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.CRISIS.CRISIS_TASK_TO_FROZEN.SUBJECT'),
        contentHtml: this.getCrisisTaskFrozenMail(crisisTask, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true
    );
  }

  getCrisisTaskFrozenMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(crisisTask.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(crisisTask.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.$translate.instant('MAIL.CRISIS.CRISIS_TASK_TO_FROZEN.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskResult: crisisTask.outputTitle,
      functionTitle,
      comment,
    });
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  sendCrisisTaskBackTodoMail(crisisTask: ErpCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!crisisTask) {
      console.warn('ErpMailService::sendCrisisTask: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisTask: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.$translate.instant('MAIL.CRISIS.CRISIS_TASK_TO_TODO.SUBJECT'),
        contentHtml: this.getCrisisTaskBackTodoMail(crisisTask, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true
    );
  }

  getCrisisTaskBackTodoMail(crisisTask: ErpCrisisTask, functionTitle, comment): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(crisisTask.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(crisisTask.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += this.$translate.instant('MAIL.CRISIS.CRISIS_TASK_TO_TODO.FISRT_PART', {
      firstName: crisisTask.updatedBy.firstName,
      lastName: crisisTask.updatedBy.lastName,
      taskResult: crisisTask.outputTitle,
      functionTitle,
    });
    if (comment) {
      msg += '&nbsp;';
      msg +=
        this.$translate.instant('MAIL.CRISIS.CRISIS_TASK_TO_TODO.SECOND_PART', {
          comment,
        }) + '&nbsp;';
    }
    msg += '.';
    msg += '</td>';
    msg += '</tr></table>';

    return msg;
  }

  sendCrisisTaskMail(isCreate: boolean, crisisTask: ErpCrisisTask, mailsToSend: string[], functionToNotify?: string) {
    if (!crisisTask) {
      console.warn('ErpMailService::sendCrisisTask: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisTask: no mailing list found');
    }
    if (isCreate && !functionToNotify) {
      console.warn('ErpMailService::sendCrisisTask: functionToNotify empty on creation');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: isCreate
          ? this.$translate.instant('MAIL.CRISIS.CRISIS_NEW_TASK.SUBJECT')
          : this.$translate.instant('MAIL.CRISIS.CRISIS_TASK.SUBJECT'),
        contentHtml: this.getCrisisTaskMail(isCreate, crisisTask, functionToNotify),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true
    );
  }

  getCrisisTaskMail(isCreate: boolean, crisisTask: ErpCrisisTask, functionToNotify?: string): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(crisisTask.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(crisisTask.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    if (isCreate) {
      msg += '<td>';
      msg += this.$translate.instant('MAIL.CRISIS.CRISIS_NEW_TASK.FISRT_PART', {
        firstName: crisisTask.createdBy.firstName,
        lastName: crisisTask.createdBy.lastName,
        taskTitle: crisisTask.taskTitle,
        functionToNotify,
      });
      if (crisisTask.nextInfoTime) {
        msg += '&nbsp;';
        msg += this.$translate.instant('MAIL.CRISIS.CRISIS_NEW_TASK.SECOND_PART') + '&nbsp;';
        msg += moment(crisisTask.nextInfoTime).utc().format('HH:mm[UTC]ddd[&nbsp;]DD');
      }
      msg += '.';
      msg += '</td>';
    } else {
      msg += '<td>' + crisisTask.taskTitle + '</td>';
    }
    msg += '</tr></table>';

    return msg;
  }

  sendCrisisDocumentMail(isCreate: boolean, crisisDocument: ErpCrisisTask, mailsToSend: string[], functionToNotify?: string) {
    if (!crisisDocument) {
      console.warn('ErpMailService::sendCrisisDocument: crisisDocument empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisDocument: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: isCreate
          ? this.$translate.instant('MAIL.CRISIS.CRISIS_DOCUMENT.NEW_ENTRY_SUBJECT')
          : this.$translate.instant('MAIL.CRISIS.CRISIS_DOCUMENT.UPDATE_ENTRY_SUBJECT'),
        contentHtml: this.getCrisisDocumentMail(isCreate, crisisDocument, functionToNotify),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true
    );
  }

  getCrisisDocumentMail(isCreate: boolean, crisisDocument: ErpCrisisTask, functionToNotify?): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(crisisDocument.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(crisisDocument.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += isCreate
      ? this.$translate.instant('MAIL.CRISIS.CRISIS_DOCUMENT.CREATED_BY', {
          firstName: crisisDocument.createdBy.firstName,
          lastName: crisisDocument.createdBy.lastName,
          title: crisisDocument.outputDataLabel,
        })
      : this.$translate.instant('MAIL.CRISIS.CRISIS_DOCUMENT.UPDATED_BY', {
          firstName: crisisDocument.updatedBy.firstName,
          lastName: crisisDocument.updatedBy.lastName,
          title: crisisDocument.outputDataLabel,
        });
    if (functionToNotify) {
      msg += '&nbsp;';
      msg += this.$translate.instant('MAIL.CRISIS.CRISIS_DOCUMENT.AFFECT_TO_FUNCTION', { functionToNotify });
    }
    if (crisisDocument.nextInfoTime) {
      msg += '&nbsp;';
      msg += this.$translate.instant('MAIL.CRISIS.CRISIS_DOCUMENT.NEXT_INFO') + '&nbsp;';
      msg += moment(crisisDocument.nextInfoTime).utc().format('HH:mm[UTC][&nbsp;]ddd[&nbsp;]DD');
    }
    msg += '.';
    msg += '</td>';
    if (
      crisisDocument.attachments &&
      (crisisDocument.attachments.note ||
        crisisDocument.attachments.noteFile ||
        crisisDocument.attachments.file ||
        crisisDocument.attachments.image)
    ) {
      msg += '<td width="1" style="white-space: nowrap">';
      if (crisisDocument.attachments.noteFile) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a href="' +
          crisisDocument.attachments.noteFile.url +
          '" target="_blank" title="' +
          crisisDocument.attachments.noteFile.fileName +
          '">📝</a>&nbsp;';
      } else if (crisisDocument.attachments.note) {
        msg += '<span title="' + crisisDocument.attachments.note + '">📝</span>&nbsp;';
      }
      if (crisisDocument.attachments.file) {
        msg +=
          '<a title="' + crisisDocument.attachments.file.fileName + '" href="' + crisisDocument.attachments.file.url + '">📎</a>&nbsp;';
      }
      if (crisisDocument.attachments.image) {
        msg +=
          '<a title="' + crisisDocument.attachments.image.fileName + '" href="' + crisisDocument.attachments.image.url + '">🖼</a>&nbsp;';
      }
      msg += '</td>';
    }
    msg += '</tr></table>';

    return msg;
  }

  sendCrisisDecisionMail(isCreate: boolean, crisisDecision: ErpCrisisDecision, mailsToSend: string[]) {
    if (!crisisDecision) {
      console.warn('ErpMailService::sendCrisisDecisionMail: crisisDecision empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('ErpMailService::sendCrisisDecisionMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: _.map(mailsToSend, m => {
          return { email: m };
        }),
        subject: isCreate
          ? this.$translate.instant('MAIL.CRISIS.CRISIS_DECISION.NEW_ENTRY_SUBJECT')
          : this.$translate.instant('MAIL.CRISIS.CRISIS_DECISION.UPDATE_ENTRY_SUBJECT'),
        contentHtml: this.getCrisisDecisionMail(isCreate, crisisDecision),
        sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      },
      true,
      true
    );
  }

  getCrisisDecisionMail(isCreate: boolean, crisisDecision: ErpCrisisDecision): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(crisisDecision.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(crisisDecision.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>';
    msg += isCreate
      ? this.$translate.instant('MAIL.CRISIS.CRISIS_DECISION.CREATED_BY', {
          firstName: crisisDecision.createdBy.firstName,
          lastName: crisisDecision.createdBy.lastName,
          title: crisisDecision.title,
        })
      : this.$translate.instant('MAIL.CRISIS.CRISIS_DECISION.UPDATED_BY', {
          firstName: crisisDecision.updatedBy.firstName,
          lastName: crisisDecision.updatedBy.lastName,
          title: crisisDecision.title,
        });
    if (crisisDecision.nextInfoTime) {
      msg += '&nbsp;';
      msg += this.$translate.instant('MAIL.CRISIS.CRISIS_DECISION.NEXT_INFO') + '&nbsp;';
      msg += moment(crisisDecision.nextInfoTime).utc().format('HH:mm[UTC][&nbsp;]ddd[&nbsp;]DD');
    }
    msg += '.';
    msg += '</td>';
    if (
      crisisDecision.attachments &&
      (crisisDecision.attachments.note ||
        crisisDecision.attachments.noteFile ||
        crisisDecision.attachments.file ||
        crisisDecision.attachments.image)
    ) {
      msg += '<td width="1" style="white-space: nowrap">';
      if (crisisDecision.attachments.noteFile) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a href="' +
          crisisDecision.attachments.noteFile.url +
          '" target="_blank" title="' +
          crisisDecision.attachments.noteFile.fileName +
          '">📝</a>&nbsp;';
      } else if (crisisDecision.attachments.note) {
        msg += '<span title="' + crisisDecision.attachments.note + '">📝</span>&nbsp;';
      }
      if (crisisDecision.attachments.file) {
        msg +=
          '<a title="' + crisisDecision.attachments.file.fileName + '" href="' + crisisDecision.attachments.file.url + '">📎</a>&nbsp;';
      }
      if (crisisDecision.attachments.image) {
        msg +=
          '<a title="' + crisisDecision.attachments.image.fileName + '" href="' + crisisDecision.attachments.image.url + '">🖼</a>&nbsp;';
      }
      msg += '</td>';
    }
    msg += '</tr></table>';

    return msg;
  }

  sendNewCrisisInPreparationEmails(crisis: ErpCrisis) {
    Promise.all([
      this.usersService.getAllCrisisDirectorsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]).then(([crisisDirectorsToNotify, currentUser]) => {
      this.mailSenderService.sendMail(
        {
          recipients: _.map(crisisDirectorsToNotify, cd => {
            return { email: cd.email, fields: { '<USERNAME>': cd.username } };
          }),
          subject: crisis.isTraining
            ? this.$translate.instant('MAIL.CRISIS.EXERCICE_HEADER')
            : this.$translate.instant('MAIL.CRISIS.NOT_EXERCICE_HEADER'),
          contentHtml:
            this.$translate.instant('MAIL.CRISIS.CRISIS_PREPARED') +
            '<br/><br/>' +
            this.$translate.instant('MAIL.CRISIS.NEW_CRISIS_PREPARED', {
              crisisCreatorName: currentUser.fullName,
              crisisMainTitle: crisis.mainTitle,
              crisisSubtitle: crisis.subTitle,
              crisisDirectorShortTitle: this.optionsService.getCrisisDirectorShortTitlesList().join(', '),
            }) +
            '<br/><br/>' +
            this.$translate.instant('MAIL.CRISIS.PLEASE_LOGIN', {
              appUrl: location.origin,
            }) +
            '<br/><br/>' +
            this.$translate.instant('MAIL.CRISIS.CONTACT_CREATOR', {
              crisisCreatorName: currentUser.fullName,
              crisisCreatorPhone: currentUser.phone || '',
              crisisCreatorEmail: currentUser.email || '',
            }) +
            '<br/><br/>' +
            this.$translate.instant('MAIL.CRISIS.ERP_MARKETING') +
            '<br/><br/>' +
            this.$translate.instant('MAIL.CRISIS.USE_IT') +
            '<br/><br/>',
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true
      );
    });
  }

  sendNewCrisisEmails(crisis: ErpCrisis, functionsIdToNotify?: string[], usersToNotify?: HolUser[], companies?: string[]) {
    return Promise.all([
      this.usersService.getUsersWithFunctionsForCrisis(crisis, functionsIdToNotify, usersToNotify, companies),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]).then(([usersWithFunctions, currentUser]) => {
      let sendedNewEmail = 0;
      for (const user of usersWithFunctions.users) {
        this.sendNewCrisisEmailToUser(crisis, user, currentUser);
        sendedNewEmail += 1;
      }
      if (sendedNewEmail === usersWithFunctions.users.length) {
        return this.erpFunctionsService.markAsNotified(usersWithFunctions.functionsIdsNotified);
      }
    });
  }

  private sendNewCrisisEmailToUser(crisis: ErpCrisis, user: HolUserWithFunctions, crisisCreator: HolUser) {
    if (user.email) {
      const msg = this.getNewCrisisMessage(crisis, user, crisisCreator);
      const subject = crisis.isTraining
        ? this.$translate.instant('MAIL.CRISIS.EXERCICE_HEADER')
        : this.$translate.instant('MAIL.CRISIS.NOT_EXERCICE_HEADER');
      this.mailSenderService.sendMail(
        {
          recipients: [{ email: user.email }],
          subject,
          contentHtml: msg,
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true
      );
    }
  }

  sendCrisisOverMail(crisis: ErpCrisis) {
    this.usersService.getUsersToSendMail().then(usersToNotify => {
      const companies = this.helperService.parseACL(crisis.acl);
      usersToNotify = usersToNotify.filter(d => !!d.companies.find(c => companies.includes(c.name)));

      this.mailSenderService.sendMail(
        {
          recipients: _.map(usersToNotify, user => {
            return { email: user.email };
          }),
          subject: this.$translate.instant('MAIL.CRISIS.CRISIS_OVER.SUBJECT'),
          contentText: this.$translate.instant('MAIL.CRISIS.CRISIS_OVER.CONTENT_TEXT'),
          sender: this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
        },
        false,
        true
      );
    });
  }

  private getCrisisNewMail(crisisNew: ErpCrisisNew): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(crisisNew.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(crisisNew.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>' + crisisNew.content + '</td>';
    // tslint:disable-next-line: max-line-length
    if (
      crisisNew.attachments &&
      (crisisNew.attachments.note || crisisNew.attachments.noteFile || crisisNew.attachments.file || crisisNew.attachments.image)
    ) {
      msg += '<td width="1" style="white-space: nowrap">';
      if (crisisNew.attachments.noteFile) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a href="' +
          crisisNew.attachments.noteFile.url +
          '" target="_blank" title="' +
          crisisNew.attachments.noteFile.fileName +
          '">📝</a>&nbsp;';
      } else if (crisisNew.attachments.note) {
        msg += '<span title="' + crisisNew.attachments.note + '">📝</span>&nbsp;';
      }
      if (crisisNew.attachments.file) {
        msg += '<a title="' + crisisNew.attachments.file.fileName + '" href="' + crisisNew.attachments.file.url + '">📎</a>&nbsp;';
      }
      if (crisisNew.attachments.image) {
        msg += '<a title="' + crisisNew.attachments.image.fileName + '" href="' + crisisNew.attachments.image.url + '">🖼</a>&nbsp;';
      }
      msg += '</td>';
    }

    msg += '</tr></table>';

    return msg;
  }

  private getCrisisAnnouncementMail(crisisAnnouncement: ErpCrisisAnnouncement): string {
    let msg = '<table style="border: 1px solid #E7E7E7; width:100%!important;" cellpadding="5px" cellspacing="0" width="100%"><tr>';
    // tslint:disable-next-line: max-line-length
    msg +=
      '<td width="1px" style="white-space: nowrap">' +
      moment(crisisAnnouncement.createdAt).utc().format('HH:mm[UTC]') +
      '<br/>' +
      moment(crisisAnnouncement.createdAt).utc().format('ddd[&nbsp;]DD') +
      '</td>';

    msg += '<td>' + crisisAnnouncement.message + '</td>';
    // tslint:disable-next-line: max-line-length
    if (
      crisisAnnouncement.attachments &&
      (crisisAnnouncement.attachments.note ||
        crisisAnnouncement.attachments.noteFile ||
        crisisAnnouncement.attachments.file ||
        crisisAnnouncement.attachments.image)
    ) {
      msg += '<td width="1" style="white-space: nowrap">';
      if (crisisAnnouncement.attachments.noteFile) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a href="' +
          crisisAnnouncement.attachments.noteFile.url +
          '" target="_blank" title="' +
          crisisAnnouncement.attachments.noteFile.fileName +
          '">📝</a>&nbsp;';
      } else if (crisisAnnouncement.attachments.note) {
        msg += '<span title="' + crisisAnnouncement.attachments.note + '">📝</span>&nbsp;';
      }
      if (crisisAnnouncement.attachments.file) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a title="' +
          crisisAnnouncement.attachments.file.fileName +
          '" href="' +
          crisisAnnouncement.attachments.file.url +
          '">📎</a>&nbsp;';
      }
      if (crisisAnnouncement.attachments.image) {
        // tslint:disable-next-line: max-line-length
        msg +=
          '<a title="' +
          crisisAnnouncement.attachments.image.fileName +
          '" href="' +
          crisisAnnouncement.attachments.image.url +
          '">🖼</a>&nbsp;';
      }
      msg += '</td>';
    }

    msg += '</tr></table>';

    return msg;
  }

  private getNewCrisisMessage(crisis: ErpCrisis, user: HolUserWithFunctions, crisisCreator: HolUser) {
    let msg = this.$translate.instant('MAIL.CRISIS.CRISIS_CREATED') + '<br/><br/>';

    msg += this.$translate.instant('MAIL.CRISIS.NEW_CRISIS_CREATED', {
      crisisCreatorName: crisisCreator.fullName,
      crisisMainTitle: crisis.mainTitle,
      crisisSubtitle: crisis.subTitle,
    });

    if (user.functions.length) {
      for (const func of user.functions) {
        try {
          msg += this.$translate.instant('MAIL.CRISIS.MEMBER_OF', { function: func.title, company: func.company });
          msg += this.$translate.instant('MAIL.CRISIS.FIRST_ACTIONS');
          msg += marked(func.tasksSummary);
        } catch (error) {
          console.warn('Invalid taskSummary for function ' + func.title + ': ', func.tasksSummary);
        }

        if (func.otherUsers.length > 0) {
          msg += this.$translate.instant('MAIL.CRISIS.COORDINATION') + '<ul>';
          for (const u of func.otherUsers) {
            msg += `<li>${u}</li>`;
          }
          msg += '</ul>';
        } else {
          msg += '<br/>';
        }

        msg += '<hr/>';
      }
    } else {
      console.warn('no function for user ', user);
    }

    msg += this.$translate.instant('MAIL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin }) + '<br/><br/>';

    msg +=
      this.$translate.instant('MAIL.CRISIS.CONTACT_CREATOR', {
        crisisCreatorName: crisisCreator.fullName,
        crisisCreatorPhone: crisisCreator.phone || '',
        crisisCreatorEmail: crisisCreator.email || '',
      }) + '<br/><br/>';

    msg += this.$translate.instant('MAIL.CRISIS.ERP_MARKETING') + '<br/><br/>';

    msg += this.$translate.instant('MAIL.CRISIS.USE_IT');

    return msg;
  }
}
