import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { GocMailService } from './goc-mail-service/goc-mail.service';
import { Injectable } from '@angular/core';
import { RequestService } from 'src/app/common/services/request.service';
import { OclTasksService } from '../../ocl/services/ocl-tasks-service/ocl-tasks.service';
import { OclTasksStoreManager } from '../../ocl/store/tasks/ocl-tasks.store-manager';
import { GocHistoryService } from './goc-history-service/goc-history.service';
import { GocTagsService } from './goc-tags-service/goc-tags.service';
import { GocModuleConfigService } from './goc-module-config/goc-module-config.service';
import { GocTaskTagService } from './goc-task-tag.service';
import { GocSmsService } from './goc-sms-service/goc-sms.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';

@Injectable({
  providedIn: 'root',
})
export class GocTasksService extends OclTasksService {
  // tslint:disable:variable-name
  protected ParseTask = Parse.Object.extend('HOLTask');
  protected ParseTag = Parse.Object.extend('GOCTag');
  protected ParseTaskTag = Parse.Object.extend('GOCTaskTag');
  // tslint:enabled

  constructor(
    protected requestService: RequestService,
    protected taskTagService: GocTaskTagService,
    protected historyService: GocHistoryService,
    protected tagsService: GocTagsService,
    protected moduleConfig: GocModuleConfigService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected commonStoreManager: CommonStoreManager,
    protected mailService: GocMailService,
    protected smsService: GocSmsService,
    protected notificationsService: NotificationsService
  ) {
    super(
      requestService,
      historyService,
      tagsService,
      taskTagService,
      moduleConfig,
      tasksStoreManager,
      commonStoreManager,
      mailService,
      smsService,
      notificationsService
    );
  }
}
